/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyPayeeChangedEvent } from './payeeChanged';
import ManagerResidencyPayeeChangedEventSchema from './payeeChanged.json';

export const isManagerResidencyPayeeChangedEvent = guard<ManagerResidencyPayeeChangedEvent>(ManagerResidencyPayeeChangedEventSchema as any);
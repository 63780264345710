import { AppBar as _AppBar, styled, Toolbar as _Toolbar } from '@mui/material';

export const AppBar = styled(_AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Toolbar = styled(_Toolbar)(({ theme }) => ({
  margin: theme.spacing(1.3, 4, 1.3, 4),
}));

export const GrowDiv = styled('div')({ flexGrow: 1 });

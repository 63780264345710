import { SvgIcon, SvgIconProps } from '@mui/material';
import { colours } from 'system';

export default function AddOwner({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 97 93" {...props}>
      <path
        d="M44.6627 29.9327C44.6627 37.6421 50.8396 43.8654 58.549 43.8654C66.2584 43.8654 72.4817 37.6421 72.4817 29.9327C72.4817 22.2233 66.2584 16 58.549 16C50.8396 16 44.6627 22.2233 44.6627 29.9327ZM69.7735 54.6701C65.6881 53.6593 61.6131 53.1538 58.549 53.1538C49.2605 53.1538 30.6836 57.7981 30.6836 67.0865V76.375H63.0962C62.3866 74.3823 62.0003 72.2363 62.0003 70C62.0003 63.7065 65.0602 58.1276 69.7735 54.6701Z"
        fill={colours.shamrock}
      />
      <path
        d="M21.4412 29.9327C21.4412 37.6421 27.618 43.8654 35.3275 43.8654C36.8136 43.8654 38.2533 43.6332 39.5537 43.2152C36.9529 39.4069 35.3739 34.902 35.3739 29.9327C35.3739 24.9634 36.9065 20.412 39.5537 16.6502C38.2533 16.2322 36.8136 16 35.3275 16C27.618 16 21.4412 22.2233 21.4412 29.9327Z"
        fill={colours.waterLeaf}
      />
      <path
        d="M21.3948 67.0865C21.3948 61.6063 23.9491 57.2872 27.8038 53.8969C18.4689 55.5224 7.46207 59.9344 7.46207 67.0865V76.375H21.3948V67.0865Z"
        fill={colours.waterLeaf}
      />
      <path
        d="M82.9993 57.6667C75.6393 57.6667 69.666 63.6401 69.666 71.0001C69.666 78.3601 75.6393 84.3334 82.9993 84.3334C90.3593 84.3334 96.3327 78.3601 96.3327 71.0001C96.3327 63.6401 90.3593 57.6667 82.9993 57.6667ZM89.666 72.3334H84.3327V77.6667H81.666V72.3334H76.3327V69.6667H81.666V64.3334H84.3327V69.6667H89.666V72.3334Z"
        fill={colours.purpleHeart}
      />
    </SvgIcon>
  );
}

import { useCallback, useEffect } from 'react';
import { useSessionContext } from 'Session';
import { noOp } from 'system';

type GaEventOptions<T extends Record<string, unknown>> = {
  name: string;
  payload?: T;
  fireOnMount?: boolean;
};

export const useGaEvent = <T extends Record<string, unknown> = Record<string, unknown>>(
  hookOptions?: GaEventOptions<T>
) => {
  const { fireEvent } = useSessionContext();
  const wrapperFunction = useCallback(
    (next: () => unknown, localOptions?: GaEventOptions<T>) => {
      const options = { ...hookOptions, ...localOptions };

      fireEvent({
        name: options.name ?? 'not set',
        payload: options.payload,
      });

      return next();
    },
    [fireEvent, hookOptions]
  );

  useEffect(() => {
    if (hookOptions?.fireOnMount) {
      wrapperFunction(noOp);
    }
  }, []);

  return wrapperFunction;
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitStartedReminderEvent } from './visitStartedReminder';
import OperatorVisitStartedReminderEventSchema from './visitStartedReminder.json';

export const isOperatorVisitStartedReminderEvent = guard<OperatorVisitStartedReminderEvent>(OperatorVisitStartedReminderEventSchema as any);
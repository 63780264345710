import { FractionInput } from 'api';
import { fraction, isFraction, multiply } from 'mathjs';

export const toMathFraction = (frac?: number | FractionInput | math.Fraction) =>
  frac
    ? typeof frac === 'number'
      ? fraction(frac)
      : isFraction(frac)
        ? frac
        : fraction(frac.numerator, frac.denominator)
    : fraction(0);

export const multiplyFractions = (
  x: number | FractionInput | math.Fraction = fraction(0),
  ...xs: (number | FractionInput | math.Fraction)[]
) => xs.map(toMathFraction).reduce((a, b) => multiply(a, b) as math.Fraction, toMathFraction(x));

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksJournalDeletedEvent } from './jeDeleted';
import ManagerBooksJournalDeletedEventSchema from './jeDeleted.json';

export const isManagerBooksJournalDeletedEvent = guard<ManagerBooksJournalDeletedEvent>(ManagerBooksJournalDeletedEventSchema as any);
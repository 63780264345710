/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAccountStatsInvalidatedEvent } from './statsInvalidated';
import ManagerAccountStatsInvalidatedEventSchema from './statsInvalidated.json';

export const isManagerAccountStatsInvalidatedEvent = guard<ManagerAccountStatsInvalidatedEvent>(ManagerAccountStatsInvalidatedEventSchema as any);
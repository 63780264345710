import { HandlerMap } from '@propra-system/registry';
import { useAccountEventsSubscription } from 'api';
import { useAuth } from 'context';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { createContext, useContext } from 'react';
import { Children, noOp } from 'system';
import BalanceCacheProvider from './BalanceCacheProvider';
import { useEventHandlers } from './useEventHandlers';

const EventsContext = createContext<ReturnType<typeof useEventHandlers>>({
  hashMap: [] as number[],
  handlerMap: [] as HandlerMap,
  addHandler: noOp,
  removeHandler: noOp,
  handleEvent: noOp,
  addHandlerMap: noOp,
  removeHandlerMap: noOp,
});

export const useEvents = () => useContext(EventsContext);

export default function EventsProvider({ children }: Children) {
  const value = useEventHandlers();
  const { handleEvent } = value;
  const { accountId } = useAuth();
  const { error: eventsError } = useAccountEventsSubscription({
    variables: { accountId },
    onData: ({ data: subscriptionResult }) => {
      subscriptionResult?.data?.onAccountEvent &&
        handleEvent(subscriptionResult?.data?.onAccountEvent);
    },
  });
  useAllErrors(eventsError);

  return (
    <EventsContext.Provider {...{ value }}>
      <BalanceCacheProvider>{children}</BalanceCacheProvider>
    </EventsContext.Provider>
  );
}

import { NetworkStatus } from '@apollo/client';

export const useNetworkStatusMeta = (
  ...metas: {
    networkStatus?: NetworkStatus;
    loading?: boolean;
    done?: boolean;
    called?: boolean;
  }[]
) => {
  const loading = metas.some(
    ({ networkStatus, ...meta }) =>
      meta.loading &&
      [NetworkStatus.loading, NetworkStatus.setVariables, undefined].includes(networkStatus)
  );

  const working = metas.some(
    ({ networkStatus, ...meta }) =>
      loading ||
      (meta.loading &&
        [NetworkStatus.fetchMore, NetworkStatus.refetch, undefined].includes(networkStatus))
  );

  const done = metas.every(
    ({ networkStatus, ...meta }) =>
      (meta.called && !working) || (!meta.loading && networkStatus === NetworkStatus.ready)
  );

  return {
    done,
    loading,
    working,
  };
};

import { FullScreenSpinner } from 'components';
import { AppRoutes } from 'pages/AppRoutes';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { RouteChangeListener } from './RouteChangeListener';

export default function Navigation() {
  return (
    <BrowserRouter>
      <RouteChangeListener>
        <ErrorBoundary>
          <Suspense fallback={<FullScreenSpinner />}>
            <AppRoutes />
          </Suspense>
        </ErrorBoundary>
      </RouteChangeListener>
    </BrowserRouter>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerUpdatedEvent } from './updated';
import ManagerOwnerUpdatedEventSchema from './updated.json';

export const isManagerOwnerUpdatedEvent = guard<ManagerOwnerUpdatedEvent>(ManagerOwnerUpdatedEventSchema as any);
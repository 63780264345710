/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseInspectionStartedEvent } from './inspectionStarted';
import ManagerLeaseInspectionStartedEventSchema from './inspectionStarted.json';

export const isManagerLeaseInspectionStartedEvent = guard<ManagerLeaseInspectionStartedEvent>(ManagerLeaseInspectionStartedEventSchema as any);
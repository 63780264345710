/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApplicationApprovedEvent } from './applicationStatusApproved';
import ManagerApplicationApprovedEventSchema from './applicationStatusApproved.json';

export const isManagerApplicationApprovedEvent = guard<ManagerApplicationApprovedEvent>(ManagerApplicationApprovedEventSchema as any);
import {
  ListItemButton as ListItemButton_,
  ListItemText,
  listItemTextClasses,
  styled,
} from '@mui/material';
import { spreadIf } from 'system';

export const ListItemButton = styled(ListItemButton_)(({ theme, onClick }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
  ...spreadIf(onClick, {
    cursor: 'pointer',
  }),
}));

export const MobileRow = styled('div')(({ onClick }) => ({
  ...spreadIf(onClick, {
    cursor: 'pointer',
  }),
}));

export const Item = styled('div')({
  display: 'flex',
  width: '100%',
});

export const RowCell = styled(ListItemText)({
  flex: 2,
  [`& .${listItemTextClasses.primary}`]: {
    mb: 1,
    fontSize: '1em',
  },
});

export const ColumnCell = styled(ListItemText)({
  flex: 1,
  [`& .${listItemTextClasses.primary}`]: {
    fontWeight: 500,
    fontSize: '1em',
  },
});

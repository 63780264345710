/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksJournalVoidedEvent } from './jeVoided';
import ManagerBooksJournalVoidedEventSchema from './jeVoided.json';

export const isManagerBooksJournalVoidedEvent = guard<ManagerBooksJournalVoidedEvent>(ManagerBooksJournalVoidedEventSchema as any);
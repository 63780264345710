import { Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { isObject } from 'lodash';
import { Fragment, ReactNode, useMemo } from 'react';
import { Chip } from './Chip';
import { Tooltip } from './Tooltip';

type ItemListProps<TItem extends string | number | Record<string, unknown>> = {
  items?: TItem[];
  baseKey?: string;
  max?: number;
  loading?: boolean;
  renderItem?: (val: TItem, index: number) => ReactNode;
  getItemId?: (item: TItem) => string;
  getItemLabel?: (item: TItem) => string;
  variant?: 'chip' | 'text';
} & StackProps;

const getDefaultItemId = (item: Record<string, unknown> | string | number) =>
  isObject(item)
    ? typeof item.id === 'string'
      ? item.id
      : typeof item.key === 'string'
        ? item.key
        : JSON.stringify(item)
    : String(item);

const getDefaultItemLabel = (item: Record<string, unknown> | string | number) =>
  isObject(item)
    ? typeof item.name === 'string'
      ? item.name
      : typeof item.label === 'string'
        ? item.label
        : JSON.stringify(item)
    : String(item);

export function ItemList<TItem extends string | number | Record<string, unknown>>({
  baseKey,
  items = [],
  max = items.length,
  getItemId = getDefaultItemId,
  getItemLabel = getDefaultItemLabel,
  variant = 'chip',
  renderItem: _renderItem,
  loading,
  ...stackProps
}: ItemListProps<TItem>) {
  const additionalLabelsCount = items.length - max;
  const renderItem = useMemo(
    () =>
      _renderItem ??
      ((item: TItem) => {
        const label = (
          <Typography variant="body2" textTransform="none">
            {getItemLabel(item)}
          </Typography>
        );
        return variant === 'text' ? label : <Chip label={label}></Chip>;
      }),
    [_renderItem, getItemLabel, variant]
  );

  return loading ? (
    <Stack direction="row" gap={0.5} alignItems="center" flexWrap="wrap" {...stackProps}>
      {variant === 'text' ? (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Skeleton variant="circular" width={5} height={5} />
          <Skeleton variant="circular" width={5} height={5} />
          <Skeleton variant="circular" width={5} height={5} />
        </Stack>
      ) : (
        <Chip
          label={
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Skeleton variant="circular" width={5} height={5} />
              <Skeleton variant="circular" width={5} height={5} />
              <Skeleton variant="circular" width={5} height={5} />
            </Stack>
          }
        ></Chip>
      )}
    </Stack>
  ) : (
    <Stack direction="row" gap={0.5} alignItems="center" flexWrap="wrap" {...stackProps}>
      {items.slice(0, max).map((item, i) => (
        <Fragment key={`${baseKey}_${getItemId(item)}_chip`}>
          {renderItem(item, i) ?? getItemLabel(item)}
        </Fragment>
      ))}

      {additionalLabelsCount > 0 && (
        <Tooltip
          title={
            <Stack gap={0.5} sx={{ maxHeight: 300, overflow: 'auto' }} {...stackProps}>
              {items.slice(max).map((item, i) => (
                <Fragment key={`${baseKey}_${getItemId(item)}_text`}>
                  {renderItem(item, i) ?? getItemLabel(item)}
                </Fragment>
              ))}
            </Stack>
          }
        >
          <Chip label={`+${additionalLabelsCount}`} />
        </Tooltip>
      )}
    </Stack>
  );
}

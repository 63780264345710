/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerTenantLeasesUpdatedEvent } from './leasesUpdated';
import ManagerTenantLeasesUpdatedEventSchema from './leasesUpdated.json';

export const isManagerTenantLeasesUpdatedEvent = guard<ManagerTenantLeasesUpdatedEvent>(ManagerTenantLeasesUpdatedEventSchema as any);
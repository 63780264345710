/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksAbortBatchAction } from './abortBatch';
import ManagerBooksAbortBatchActionSchema from './abortBatch.json';

export const isManagerBooksAbortBatchAction = guard<ManagerBooksAbortBatchAction>(ManagerBooksAbortBatchActionSchema as any);
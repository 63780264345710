import { MoreVert } from '@mui/icons-material';
import { BoxProps, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Tooltip } from 'components';
import { useAnchorEl } from 'hooks/useAnchorEl';
import { compact } from 'lodash';
import { ReactNode } from 'react';
import { spreadIf } from 'system';
import { Div, IconButton } from './ExpandableMenu.styles';

export type MenuOption = {
  id: string;
  actionName: string;
  icon?: ReactNode;
  onAction: VoidFunction;
  disabled?: boolean;
  disabledMessage?: string;
};

export default function ExpandableMenu({
  menuOptions,
  sx,
}: {
  menuOptions: MenuOption[];
  sx?: BoxProps['sx'];
}) {
  const { anchorEl, clearAnchorEl, clearAnchorElThen, handleAnchorElClick } = useAnchorEl();

  return (
    <Div {...spreadIf(sx, { sx })}>
      <IconButton
        aria-label="open-unit-action-menu"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleAnchorElClick(e);
        }}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        {...{
          anchorEl,
          open: Boolean(anchorEl),
          onClose: clearAnchorEl,
        }}
      >
        {compact(menuOptions).map(
          ({ id, actionName, icon, onAction, disabled, disabledMessage }) => (
            <Tooltip
              title={disabled ? (disabledMessage ?? '') : ''}
              disabled={disabled && !disabledMessage}
              key={id}
            >
              <MenuItem
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  clearAnchorElThen(onAction)();
                }}
                key={id}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                {actionName}
              </MenuItem>
            </Tooltip>
          )
        )}
      </Menu>
    </Div>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerEmailAddedEvent } from './emailAdded';
import ManagerOwnerEmailAddedEventSchema from './emailAdded.json';

export const isManagerOwnerEmailAddedEvent = guard<ManagerOwnerEmailAddedEvent>(ManagerOwnerEmailAddedEventSchema as any);
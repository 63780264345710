/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAvailabilityAllCalculateEvent } from './allCalculate';
import ManagerAvailabilityAllCalculateEventSchema from './allCalculate.json';

export const isManagerAvailabilityAllCalculateEvent = guard<ManagerAvailabilityAllCalculateEvent>(ManagerAvailabilityAllCalculateEventSchema as any);
import { useListAllPropertiesQuery } from 'api';
import { useMemo } from 'react';
import { useMeta } from './useMeta';
import { usePaginateAllQuery } from './usePaginateAllQuery';
import { useTeams } from './useTeams';

export const useAccountProperties = (
  { includeDisabled }: { includeDisabled?: boolean } = { includeDisabled: false }
) => {
  const { items, ...meta } = usePaginateAllQuery(useListAllPropertiesQuery, {
    getNextToken: (d) => d?.account?.listProperties?.nextToken,
    getItems: (d) => d.account?.listProperties?.items,
    variables: { filter: { includeDisabled } },
  });

  const { loading, working } = useMeta(meta);

  const { isPropertyVisible } = useTeams();

  const properties = useMemo(
    () => items?.filter(({ id }) => isPropertyVisible(id)),
    [isPropertyVisible, items]
  );

  return { properties, loading, working };
};

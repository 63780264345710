import {
  Box,
  List as _List,
  ListItemButton as _ListItemButton,
  ListItemText,
  Paper as _Paper,
  Skeleton,
  styled,
} from '@mui/material';
import { skipProps } from 'system';
import { AdaptiveTableProps } from '../types';
import TableToolbar from './TableToolbar';

const Paper = styled(_Paper)<{ hideBorder?: boolean }>(({ hideBorder, theme }) => ({
  width: '100%',
  border: hideBorder ? 0 : `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
}));

const List = styled(_List)({ padding: 0 });

const Item = styled('div')({
  display: 'flex',
  width: '100%',
});

const ListItemButton = styled(
  _ListItemButton,
  skipProps('hideBorder')
)<{ hideBorder?: boolean }>(({ hideBorder, theme }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: hideBorder ? 0 : `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
}));

const ColumnListItemText = styled(ListItemText)({
  flex: 1,
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '1em',
  },
});

const RowListItemText = styled(ListItemText)(({ theme }) => ({
  flex: 2,
  '& .MuiListItemText-primary': {
    fontSize: '1em',
    marginBottom: theme.spacing(1),
  },
}));

export default function MobileTable({
  rows,
  columns,
  onRowClick,
  loading,
  error,
  toolbarComponent,
  footerComponent,
  header = 'always',
  sx,
  hideBorder,
}: AdaptiveTableProps) {
  const showHeader = header === 'always' || header === 'mobileOnly';

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Paper elevation={0} hideBorder={hideBorder}>
        {toolbarComponent && (
          <TableToolbar
            toolbarComponent={
              typeof toolbarComponent === 'function' ? toolbarComponent({ rows }) : toolbarComponent
            }
          />
        )}
        {loading && (
          <ListItemButton>
            <Skeleton />
          </ListItemButton>
        )}
        {!loading && error && <ListItemButton>{JSON.stringify(error)}</ListItemButton>}
        {!loading && !error && (
          <List>
            {rows.map((row) => (
              <ListItemButton
                key={row.id}
                hideBorder={hideBorder}
                onClick={() => onRowClick?.(row.id, row)}
                sx={onRowClick ? { cursor: 'pointer' } : {}}
              >
                {columns
                  .filter(({ hidden }) => !hidden)
                  .map((column) => (
                    <Item key={`${column.field} ${row.id}`}>
                      {showHeader && <ColumnListItemText primary={column.headerName} />}
                      <RowListItemText
                        primary={
                          column.renderCell
                            ? column.renderCell({ id: row.id, value: row[column.field], row })
                            : row[column.field]
                        }
                      />
                    </Item>
                  ))}
              </ListItemButton>
            ))}
          </List>
        )}
        {footerComponent && <div>{footerComponent}</div>}
      </Paper>
    </Box>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReconciliationPreparedEvent } from './prepared';
import ManagerBooksReconciliationPreparedEventSchema from './prepared.json';

export const isManagerBooksReconciliationPreparedEvent = guard<ManagerBooksReconciliationPreparedEvent>(ManagerBooksReconciliationPreparedEventSchema as any);
import { Box, Hidden } from '@mui/material';
import { AdaptiveTableProps } from '../types';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

export default function AdaptiveTable(props: AdaptiveTableProps) {
  return (
    <Box sx={props.sx}>
      <Hidden mdUp>
        <MobileTable {...props} />
      </Hidden>
      <Hidden mdDown>
        <DesktopTable {...props} />
      </Hidden>
    </Box>
  );
}

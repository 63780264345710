import { Paper as _Paper, Typography, Button as _Button, Box as _Box, styled } from '@mui/material';

export const Button = styled(_Button)({
  margin: '20px auto',
});

export const Title = styled(Typography)({
  fontWeight: '500',
  textAlign: 'center',
  fontSize: '32px',
  fontFamily: 'Work Sans',
  lineHeight: '40px',
});

export const SubTitle = styled(Typography)({
  fontWeight: '400',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Poppins',
  lineHeight: '40px',
});

export const Paper = styled(_Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 3),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(10, 10),
  },
  margin: theme.spacing(2, 3, 2, 3),
  border: `1px solid ${theme.palette.divider}`,
}));

export const Box = styled(_Box)({
  textAlign: 'center',
});

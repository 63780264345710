import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  InputLabelProps,
  ListSubheader,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import equal from 'fast-deep-equal';
import { orderBy } from 'lodash';
import { MutableRefObject, ReactNode, memo, useMemo, useState } from 'react';
import { Option, spreadIf } from 'system';
import { StyledPopper } from './styles';
import {
  ItemData,
  ItemGroupData,
  hasGroup,
  listboxComponentFactory,
  renderVirtualGroup,
  renderVirtualOption,
  spacingToNumber,
} from './virtual';

export type AutocompleteFieldProps<TId extends string> = Omit<
  AutocompleteProps<Option<TId>, false, boolean | undefined, boolean | undefined>,
  'multiple' | 'renderInput'
> & {
  required?: boolean;
  hideIcons?: boolean;
  error?: { message?: string };
  label?: TextFieldProps['label'];
  helperText?: TextFieldProps['helperText'];
  variant?: 'filled' | 'standard' | 'outlined';
  manualSort?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
  startAdornment?: JSX.Element;
  InputLabelProps?: InputLabelProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: MutableRefObject<any | undefined>;
};

const OptionElement = memo(
  ({ index: _index, data }: { index: number; data: ItemData | ItemGroupData }) => {
    if (hasGroup(data)) {
      return <ListSubheader key={data.key}>{data.group}</ListSubheader>;
    }

    const [renderProps, option] = data;

    return (
      <Typography
        component="li"
        key={option.id}
        {...renderProps}
        style={{
          ...renderProps.style,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="body1" noWrap>
          {option?.text}
        </Typography>
        {option?.subText?.trim() && (
          <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'pre' }}>
            {option.subText}
          </Typography>
        )}
        {option?.disabled && option?.disabledMessage?.trim() && (
          <Typography variant="caption" color="error">
            {option.disabledMessage}
          </Typography>
        )}
      </Typography>
    );
  }
);

function renderOption(index: number, data: ItemData | ItemGroupData) {
  return <OptionElement index={index} data={data} />;
}

export function AutocompleteField<TId extends string = string>({
  error,
  freeSolo,
  variant = 'filled',
  size = 'small',
  className,
  getOptionLabel,
  getOptionDisabled,
  renderInput,
  hideIcons,
  helperText,
  sx,
  label,
  options,
  groupBy,
  manualSort,
  value,
  startAdornment,
  InputLabelProps,
  inputRef,
  ...props
}: AutocompleteFieldProps<TId>) {
  const theme = useTheme();
  const [hideIcon, setHideIcon] = useState(Boolean(hideIcons));

  const sortedOptions = useMemo(
    () => (groupBy || manualSort ? options : orderBy(options, 'text')),
    [groupBy, options, manualSort]
  );

  const localValue = useMemo(
    () =>
      value
        ? (sortedOptions.find((opt) =>
            typeof value === 'string' ? opt.id === value : equal(opt, value)
          ) ?? null)
        : null,
    [value, sortedOptions]
  );

  const ListboxComponent = useMemo(
    () =>
      listboxComponentFactory({
        renderOption,
        getItemHeight: (item) => {
          if (hasGroup(item)) {
            return spacingToNumber(theme.spacing(3));
          }

          const [, option] = item;
          const mainTextHeight = spacingToNumber(theme.spacing(3));
          const subTextHeight = option.subText?.trim()
            ? spacingToNumber(theme.spacing(2.5)) * option.subText.split('\n').length
            : 0;
          const disabledTextHeight =
            option.disabled && option.disabledMessage ? spacingToNumber(theme.spacing(2)) : 0;

          return (
            mainTextHeight +
            subTextHeight +
            disabledTextHeight +
            spacingToNumber(theme.spacing(1.5))
          );
        },
      }),
    [theme]
  );

  return (
    <FormControl
      variant={variant}
      fullWidth={props.fullWidth}
      className={clsx(className)}
      {...spreadIf(sx, { sx })}
    >
      <Autocomplete<Option<TId>, false, boolean | undefined, boolean | undefined>
        filterSelectedOptions
        {...(freeSolo && { filterOptions: (x) => x })}
        {...props}
        groupBy={groupBy}
        value={localValue}
        options={sortedOptions}
        autoSelect={false}
        freeSolo={freeSolo}
        multiple={false}
        getOptionLabel={getOptionLabel ?? ((opt) => (typeof opt === 'string' ? opt : opt.text))}
        getOptionDisabled={getOptionDisabled ?? ((opt) => Boolean(opt.disabled))}
        PopperComponent={StyledPopper}
        renderInput={
          renderInput ??
          ((params) => (
            <TextField
              {...params}
              size={size}
              label={label}
              variant={variant}
              InputProps={{
                ...params.InputProps,
                startAdornment,
                ref(instance) {
                  if (typeof params.InputProps.ref === 'function') {
                    params.InputProps.ref(instance);
                  }

                  if (inputRef) {
                    inputRef.current = instance;
                  }
                },
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                ...InputLabelProps,
              }}
              error={Boolean(error?.message)}
            />
          ))
        }
        onFocus={() => hideIcons && setHideIcon(false)}
        onBlur={() => hideIcons && setHideIcon(true)}
        forcePopupIcon={!hideIcons || !hideIcon}
        disableClearable={(hideIcons && hideIcon) || props.disableClearable}
        renderGroup={renderVirtualGroup}
        renderOption={renderVirtualOption}
        ListboxComponent={ListboxComponent}
      />
      {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

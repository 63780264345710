import { RecurrenceFieldsFragment, useDeleteRecurrenceMutation, useGetRecurrencesQuery } from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { find, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ensureArray, formatNth } from 'system';
import { isoTo5545, formatNextRecurrence, nextRecurrence } from 'system/util/recurrence';
import { dueOffsetOptions } from '../utils';

export type RecurrenceFormFields = {
  name: string;
  startDate: string;
  endDate?: string;
  noEnd: boolean;
  interval: string;
  dueOffset?: number;
};

export type RecurrenceFieldValues = {
  posted: string;
  recurrence?: { name: string; rrule: string; dueOffset?: number };
};

export const useRecurrence = () => {
  const intervals = (startDate: string) => {
    const base = DateTime.fromISO(startDate);
    const { day, month } = base;
    const date = base.toLocaleString({ month: 'long', day: 'numeric' });
    const dateRules = isNaN(Number(day))
      ? []
      : [
          {
            value: 'dayOfMonth',
            label: `The ${formatNth(day)} of each month`,
            order: '1.2',
            rrule: `RRULE:FREQ=MONTHLY;BYMONTHDAY=${day}`,
          },
          {
            value: 'dateOfYear',
            label: `On ${date} of each year`,
            order: '3.2',
            rrule: `RRULE:FREQ=YEARLY;BYMONTHDAY=${day};BYMONTH=${month}`,
          },
        ];

    return sortBy(
      [
        ...dateRules,
        {
          value: 'firstOfMonth',
          label: 'First day of each month',
          order: '1.1',
          rrule: 'RRULE:FREQ=MONTHLY;BYMONTHDAY=1',
        },
        {
          value: 'lastOfMonth',
          label: 'Last day of each month',
          order: '1.3',
          rrule: 'RRULE:FREQ=MONTHLY;BYMONTHDAY=-1',
        },
        {
          value: 'firstEvery3Months',
          label: 'First day every 3 months',
          order: '2.1',
          rrule: 'FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=1',
        },
        {
          value: 'lastEvery3Months',
          label: 'Last day every 3 months',
          order: '2.3',
          rrule: 'FREQ=MONTHLY;INTERVAL=3;BYMONTHDAY=-1',
        },
        {
          value: 'firstOfYear',
          label: 'First day of each year',
          order: '3.1',
          rrule: 'RRULE:FREQ=YEARLY;BYYEARDAY=1',
        },
        {
          value: 'lastOfYear',
          label: 'Last day of each year',
          order: '3.3',
          rrule: 'RRULE:FREQ=YEARLY;BYYEARDAY=-1',
        },
      ],
      'order'
    );
  };

  const nextPostDate = (values: Omit<RecurrenceFormFields, 'name'>) => {
    const rrule = getRrule(values);
    return formatNextRecurrence(rrule);
  };

  const getRrule = ({
    interval,
    startDate,
    noEnd,
    endDate = '',
  }: Omit<RecurrenceFormFields, 'name'>) => {
    const { rrule = '' } = find(intervals(startDate), { value: interval }) ?? {};
    const until =
      noEnd || !isoTo5545(endDate ? endDate : '')
        ? ''
        : `;UNTIL=${isoTo5545(endDate ? endDate : '')}`;
    return `DTSTART:${isoTo5545(startDate)}\n${rrule}${until}`;
  };

  return { intervals, getRrule, nextPostDate, dueOffsetOptions };
};

export const useRecurrences = () => {
  const { data, error: getError, loading } = useGetRecurrencesQuery();
  const [deleteRecurrenceMutation, { error: deleteError }] = useDeleteRecurrenceMutation();
  useAllErrors(getError, deleteError);

  const [recurrences, setRecurrences] = useState<RecurrenceFieldsFragment[]>([]);

  useEffect(() => {
    setRecurrences(ensureArray(data?.account?.books?.recurrences));
  }, [data]);

  const deleteRecurrence = async (id: string) => {
    await deleteRecurrenceMutation({
      variables: { id },
      update(cache, result) {
        if (result.data?.deleteRecurrence?.success) {
          cache.evict({ id: cache.identify({ id, __typename: 'Recurrence' }) });
          cache.gc();
        }
      },
    });
  };

  const nextPostDate = (rrule?: string) => (rrule ? nextRecurrence(rrule) : undefined);

  return { recurrences, loading, nextPostDate, deleteRecurrence };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerCampaignGenerateLinkAction } from './link';
import ManagerCampaignGenerateLinkActionSchema from './link.json';

export const isManagerCampaignGenerateLinkAction = guard<ManagerCampaignGenerateLinkAction>(ManagerCampaignGenerateLinkActionSchema as any);
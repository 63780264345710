import { CircularProgress, Grid, Typography } from '@mui/material';

export default function FullScreenSpinner({
  size = 240,
  message = '',
}: {
  size?: number;
  message?: string;
}) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{ height: '100vh' }}
    >
      <CircularProgress size={size} />
      {message && (
        <Typography variant="body1" align="center" marginTop={2}>
          {message}
        </Typography>
      )}
    </Grid>
  );
}

import {
  Drawer as _Drawer,
  FormControlLabel as _FormControlLabel,
  paperClasses,
  styled,
} from '@mui/material';
import { Logo as _Logo } from 'components';
import { skipProps, spreadIf } from 'system';
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from './useAuthFrame';

export const Logo = styled(_Logo)(({ theme, short }) => ({
  margin: short ? theme.spacing(3.3, 0, 3.8, 3.6) : theme.spacing(1.3, 0, 3, 4),
}));

export const Nav = styled('nav')<{ collapsed?: boolean }>(({ theme, collapsed }) => ({
  [theme.breakpoints.up('sm')]: {
    width: collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
    flexShrink: 0,
  },
}));

export const FormControlLabel = styled(_FormControlLabel)(({ theme }) => ({
  width: '100%',
  margin: 0,
  padding: theme.spacing(1.5),
}));

export const Drawer = styled(
  _Drawer,
  skipProps('collapse')
)<{ collapse: boolean }>(({ theme, open, collapse }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),

  width: collapse ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
  [theme.breakpoints.only('xs')]: {
    width: EXPANDED_WIDTH,
  },

  ...spreadIf(!open, {
    overflowX: 'hidden',
    '& . MuiDrawer-paper': {
      overflowX: 'hidden',
    },
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  }),

  [`& .${paperClasses.root}`]: {
    width: collapse ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
  },
}));

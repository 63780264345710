import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function OperatorAssigned({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();

  return (
    <SvgIcon
      fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M4.3924 4.53347H4.62814C4.62814 6.53726 6.25112 8.16024 8.25491 8.16024C10.2587 8.16024 11.8817 6.53726 11.8817 4.53347H12.1174C12.3622 4.53347 12.5617 4.33399 12.5617 4.08919V4.07105C12.5617 3.82625 12.3622 3.62677 12.1174 3.62677H11.8817C11.8817 2.28487 11.1473 1.13337 10.0683 0.498682V1.36004C10.0683 1.61391 9.86883 1.81339 9.61495 1.81339C9.36108 1.81339 9.16161 1.61391 9.16161 1.36004V0.126937C8.87147 0.0544015 8.57226 0 8.25491 0C7.93757 0 7.63836 0.0544015 7.34822 0.126937V1.36004C7.34822 1.61391 7.14875 1.81339 6.89487 1.81339C6.641 1.81339 6.44153 1.61391 6.44153 1.36004V0.498682C5.36256 1.13337 4.62814 2.28487 4.62814 3.62677H4.3924C4.14759 3.62677 3.94812 3.82625 3.94812 4.07105V4.09825C3.94812 4.33399 4.14759 4.53347 4.3924 4.53347ZM10.0683 4.53347C10.0683 5.53083 9.25228 6.34685 8.25491 6.34685C7.25755 6.34685 6.44153 5.53083 6.44153 4.53347H10.0683Z" />
        <path d="M2.74576 13.5452C2.94523 12.8924 6.03965 11.6532 8.47866 11.6532C8.47866 11.0185 8.50326 10.5888 8.50326 9.92751C6.02799 9.83684 0.932373 11.0699 0.932373 13.5452V15.3586H8.47866C8.47866 14.6689 8.47866 14.3141 8.47866 13.5452H2.74576Z" />
        <path d="M13.7265 13.5452C13.5271 12.8924 10.4326 11.6532 7.99363 11.6532C7.99363 11.0185 7.96903 10.5888 7.96903 9.92751C10.4443 9.83684 15.5399 11.0699 15.5399 13.5452V15.3586H7.99363C7.99363 14.6689 7.99363 14.3141 7.99363 13.5452H13.7265Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

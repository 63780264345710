/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerProfileAssociatedEvent } from './profileAssociated';
import ManagerOwnerProfileAssociatedEventSchema from './profileAssociated.json';

export const isManagerOwnerProfileAssociatedEvent = guard<ManagerOwnerProfileAssociatedEvent>(ManagerOwnerProfileAssociatedEventSchema as any);
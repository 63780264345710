/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksClearableDeletedEvent } from './clearableDeleted';
import ManagerBooksClearableDeletedEventSchema from './clearableDeleted.json';

export const isManagerBooksClearableDeletedEvent = guard<ManagerBooksClearableDeletedEvent>(ManagerBooksClearableDeletedEventSchema as any);
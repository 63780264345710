/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerRequestChecklistItemRemovedEvent } from './checklistItemRemoved';
import ManagerRequestChecklistItemRemovedEventSchema from './checklistItemRemoved.json';

export const isManagerRequestChecklistItemRemovedEvent = guard<ManagerRequestChecklistItemRemovedEvent>(ManagerRequestChecklistItemRemovedEventSchema as any);
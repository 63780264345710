/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerTenantAutopayInviteChangedEvent } from './autopayInviteChanged';
import ManagerTenantAutopayInviteChangedEventSchema from './autopayInviteChanged.json';

export const isManagerTenantAutopayInviteChangedEvent = guard<ManagerTenantAutopayInviteChangedEvent>(ManagerTenantAutopayInviteChangedEventSchema as any);
import { Reference, useApolloClient } from '@apollo/client';
import {
  Document,
  DocumentFieldsFragmentDoc,
  useBuildingDocumentsQuery,
  useRenameBuildingDocumentMutation,
} from 'api';
import { useAuth } from 'context';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { compact } from 'lodash';
import { DateTime } from 'luxon';
import plur from 'plur';
import { useCallback, useEffect, useState } from 'react';
import { DocumentType, ensureArray, S3KeyFolder } from 'system';

export const useBuildingDocuments = ({
  propertyId,
  buildingId,
}: {
  propertyId: string;
  buildingId: string;
}) => {
  const { cache } = useApolloClient();
  const [docError, setDocError] = useState('');
  const { accountId } = useAuth();
  const {
    data,
    loading,
    error: queryError,
  } = useBuildingDocumentsQuery({
    variables: { input: { buildingId, propertyId } },
  });
  const [renameDocumentMutation, { error: renameError }] = useRenameBuildingDocumentMutation({});
  const [documents, setDocuments] = useState<Document[]>([]);
  const fullS3Key = useCallback(
    (key: string) =>
      `accounts/${accountId}/${S3KeyFolder[DocumentType.PROPERTY]}/${propertyId}/${
        S3KeyFolder[DocumentType.BUILDING]
      }/${buildingId}/${key}`,
    [accountId, propertyId, buildingId, DocumentType.BUILDING]
  );

  useAllErrors(docError, queryError, renameError);

  useEffect(() => {
    setDocuments(ensureArray(data?.building?.documents));
  }, [data]);

  const removeDocument = (key: string) => {
    const docRef = cache.identify({ key, __typename: 'Document' });
    cache.evict({ id: docRef });
    cache.gc();
  };

  const refreshDocumentKey = (document: Document, newKey: string) => {
    const oldRef = cache.identify({ key: document.key, __typename: 'Document' });
    cache.updateFragment(
      {
        id: oldRef,
        fragment: DocumentFieldsFragmentDoc,
      },
      () => ({
        ...document,
        typename: document.typename ?? null,
        name: document.name,
        size: document.size,
        key: newKey,
        id: cache.writeFragment({
          data: {
            ...document,
            typename: document.typename ?? null,
            name: document.name,
            size: document.size,
            key: newKey,
          },
          fragment: DocumentFieldsFragmentDoc,
        }),
      })
    );
  };

  const addDocuments = async (uploadedFiles?: { file: File }[]) => {
    uploadedFiles?.forEach(({ file }) => {
      const newRef = cache.writeFragment({
        data: {
          __typename: 'Document',
          key: file.name,
          name: null,
          size: file.size,
          createdZ: DateTime.utc().toISO(),
        },
        fragment: DocumentFieldsFragmentDoc,
      });

      cache.modify({
        id: cache.identify({ id: buildingId, __typename: 'Building' }),
        fields: { documents: (existing?: Reference[]) => [...ensureArray(existing), newRef] },
      });
    });
  };

  const renameDocument = async ({
    document: { key },
    values: { name },
  }: {
    document: Document;
    values: { name: string };
  }) => {
    renameDocumentMutation({
      variables: {
        input: { buildingId, propertyId, key, name },
      },
    });
  };

  const validateFiles = (files: File[]) => {
    const dup = compact(files.map(({ name }) => documents.find(({ key }) => key === name)));

    if (dup.length > 0) {
      const dupKeys = dup.map(({ key }) => key);
      setDocError(`Duplicate ${plur('file', dup.length)}: ${JSON.stringify(dupKeys)}`);
    }

    return dup.length === 0;
  };

  return {
    documents,
    loading,
    fullS3Key,
    addDocuments,
    validateFiles,
    removeDocument,
    refreshDocumentKey,
    renameDocument,
  };
};

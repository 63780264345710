import { Zone } from 'luxon';
import { useEffect } from 'react';
import {
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useFormContext,
} from 'react-hook-form';
import { DateLike } from 'system';
import { DateField, DateFieldProps } from '../Fields/DateField';

export default function DateFieldController<
  TFields extends FieldValues = Record<string, unknown>,
  TPath extends FieldPathByValue<TFields, DateLike | undefined> = FieldPathByValue<
    TFields,
    DateLike | undefined
  >,
>({
  name,
  defaultValue,
  fullWidth = true,
  size = 'medium',
  variant = 'filled',
  onChange,
  ...props
}: Omit<DateFieldProps, 'name'> & {
  name: TPath;
  defaultValue?: PathValue<TFields, TPath>;
  timezone?: Zone | string;
}) {
  const { control, setValue } = useFormContext<TFields>();
  const { field, fieldState } = useController<TFields>({ name, control });

  useEffect(() => {
    if (defaultValue && !field.value) {
      setValue(name, defaultValue, { shouldValidate: true });
    }
  }, [defaultValue, field.value, name, setValue]);

  return (
    <DateField
      {...{
        ...props,
        ...field,
        size,
        variant,
        fullWidth,
      }}
      error={fieldState.error}
      value={field.value ?? null}
      onChange={(value) => {
        field.onChange(value);
        onChange?.(value);
      }}
    />
  );
}

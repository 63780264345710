import {
  IconButton as IconButtonBase,
  IconButtonProps as IconButtonBaseProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { Tooltip } from './Tooltip';

export type IconButtonProps = IconButtonBaseProps & {
  tooltip?: ReactNode;
  tooltipDisabled?: boolean;
  wrapperSx?: IconButtonBaseProps['sx'];
};

export const IconButton = ({
  tooltip,
  tooltipDisabled,
  children,
  wrapperSx,
  ...iconButtonProps
}: IconButtonProps) => {
  return (
    <Tooltip disabled={!tooltip || tooltipDisabled} title={tooltip} wrapperSx={wrapperSx}>
      <IconButtonBase {...iconButtonProps}>{children}</IconButtonBase>
    </Tooltip>
  );
};

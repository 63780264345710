/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertiesAutopayDeactivateAction } from './deactivateAction';
import ManagerPropertiesAutopayDeactivateActionSchema from './deactivateAction.json';

export const isManagerPropertiesAutopayDeactivateAction = guard<ManagerPropertiesAutopayDeactivateAction>(ManagerPropertiesAutopayDeactivateActionSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApplicationCreatedEvent } from './applicationCreated';
import ManagerApplicationCreatedEventSchema from './applicationCreated.json';

export const isManagerApplicationCreatedEvent = guard<ManagerApplicationCreatedEvent>(ManagerApplicationCreatedEventSchema as any);
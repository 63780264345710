/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksBatchCommittedEvent } from './batchCommitted';
import ManagerBooksBatchCommittedEventSchema from './batchCommitted.json';

export const isManagerBooksBatchCommittedEvent = guard<ManagerBooksBatchCommittedEvent>(ManagerBooksBatchCommittedEventSchema as any);
import { makeVar, useReactiveVar } from '@apollo/client';
import { Cache } from '@aws-amplify/cache';
import { useAuth } from 'context';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useStringErrorNotification } from './useErrorNotifications';

const url = process.env.REACT_APP_SYSTEM_APPSYNC_GRAPHQL ?? '';
const apiKey = process.env.REACT_APP_SYSTEM_API_KEY ?? '';

export type Toggle = {
  id: string;
  accountId: string;
  description: string;
  enabled: boolean;
};

export const toggleState = makeVar<Record<string, Toggle>>({});

const toggleSchema = Yup.object().shape({
  id: Yup.string().required(),
  accountId: Yup.string().required(),
  enabled: Yup.bool().required(),
  description: Yup.string().nullable(),
});

const toggleResponseSchema = Yup.object().shape({
  data: Yup.object()
    .nullable()
    .shape({
      toggle: Yup.object().nullable().shape({
        success: Yup.bool().required(),
        error: Yup.string().nullable(),
        toggle: toggleSchema.nullable(),
      }),
    }),
});

type Nullable<T> = T | null;

type ToggleResponse = {
  data: Nullable<{
    toggle: Nullable<{
      success: boolean;
      error: Nullable<string>;
      toggle: Nullable<Toggle>;
    }>;
  }>;
};

const isToggleResponse = (
  setSchemaError: Dispatch<SetStateAction<string | undefined | null>>,
  json: unknown
): json is ToggleResponse => {
  try {
    toggleResponseSchema.validateSync(json);
    return true;
  } catch (error) {
    console.error('useToggle schema validation errors', {
      error,
    });
    setSchemaError('Error validating configuration response');
    return false;
  }
};

const fetchToggle = (id: string, accountId: string) =>
  fetch(url, {
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-Api-Key': apiKey,
    }),
    method: 'POST',
    body: JSON.stringify({
      operationName: 'GetToggle',
      query: `
        query GetToggle($input: ToggleInput!) {
          toggle(input: $input) {
            success
            error
            toggle {
              id
              accountId
              description
              enabled
            }
          }
        }
      `,
      variables: { input: { id, accountId } },
    }),
  });

const storeToggle = (toggle: Toggle) => {
  const localStorageToggles: Toggle[] = JSON.parse(Cache.getItem('toggles') ?? '[]') as Toggle[];
  Cache.setItem('toggles', JSON.stringify([...localStorageToggles, toggle]));
};

export const useToggle = ({
  id: toggleName,
  defaultState = false,
}: {
  id?: string;
  defaultState?: boolean;
}) => {
  const [toggle, setToggle] = useState<Nullable<Partial<Toggle>>>(null);
  const [setFetchError] = useStringErrorNotification();
  const [setSchemaError] = useStringErrorNotification();
  const [setToggleError] = useStringErrorNotification();
  const { accountId, token } = useAuth();
  const localToggles = useReactiveVar(toggleState);

  useEffect(() => {
    if (!token) {
      Cache.removeItem('toggles');
      toggleState({});
    }
  }, [token]);

  const id = useMemo(
    () =>
      toggleName
        ? toggleName.startsWith('manager-')
          ? toggleName
          : `manager-${toggleName}`
        : undefined,
    [toggleName]
  );

  if (id && !toggle) {
    const localStorageToggles: Toggle[] = JSON.parse(Cache.getItem('toggles') ?? '[]') as Toggle[];
    const localStorageToggle = localStorageToggles.find((storedToggle) => storedToggle.id === id);

    const localToggle = localToggles[id] || localStorageToggle;

    if (localToggle) {
      setToggle(localToggle);
    } else {
      fetchToggle(id, accountId)
        .then(async (response?: Response) => {
          if (response) {
            if (response.ok) {
              const json = await response.json();
              if (isToggleResponse(setSchemaError, json)) {
                setToggleError(json.data?.toggle?.error);
                if (json.data?.toggle?.toggle) {
                  storeToggle(json.data.toggle.toggle);
                  toggleState({ ...localToggles, [id]: json.data.toggle.toggle });
                  setToggle(localToggle);
                }
              }
            } else {
              setFetchError(
                `Error fetching configuration: [${response.status}] ${response.statusText}`
              );
            }
          }
        })
        .catch((error) => {
          console.error('Fetch threw an exception', { error });
          setFetchError('A network error occurred while fetching configuration');
        });
    }
  }

  return toggle?.enabled ?? defaultState;
};

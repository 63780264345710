import { Badge as B, badgeClasses, styled } from '@mui/material';
import {
  AutopayEnrollmentStatus,
  AutopayEnrollmentType,
  useGetAutopayEnrollmentsQuery,
  useGetAutopayQuery,
} from 'api';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { useEffect, useState } from 'react';
import { ensureArray } from 'system';

const Badge = styled(B)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryBackground.main,
  },
}));

const useCountForLevel = () => {
  const [count, setCount] = useState(0);

  const { items: enrollments } = usePaginateAllQuery(useGetAutopayEnrollmentsQuery, {
    getNextToken: (d) => d?.account?.listAutopayEnrollments?.nextToken,
    getItems: (d) => ensureArray(d?.account?.listAutopayEnrollments?.items),
    variables: { filter: { type: AutopayEnrollmentType.Variable } },
  });

  const { data } = useGetAutopayQuery();

  useEffect(
    () =>
      setCount(
        data?.account?.books?.autopay?.activated
          ? (enrollments?.filter(({ status }) =>
              [AutopayEnrollmentStatus.Invited, AutopayEnrollmentStatus.Suspended].includes(status)
            ).length ?? 0)
          : 0
      ),
    [enrollments, data?.account?.books?.autopay?.activated]
  );
  return count;
};

export default function ActionableAutopayPayeeBadge() {
  const count = useCountForLevel();

  return <Badge badgeContent={count} />;
}

import { useTheme } from 'context';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

const env = (process.env.REACT_APP_ENV ?? 'dev').toLowerCase();

export default function Debug({
  hidden,
  alwaysShow,
  ...props
}: ReactJsonViewProps & { hidden?: boolean; alwaysShow?: boolean }) {
  const { darkMode } = useTheme();
  return !alwaysShow && (hidden || env === 'prod') ? (
    <></>
  ) : (
    <ReactJson collapsed theme={darkMode ? 'bright' : 'bright:inverted'} {...props} />
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayEnrollmentNsfEvent } from './enrollmentNsf';
import ManagerAutopayEnrollmentNsfEventSchema from './enrollmentNsf.json';

export const isManagerAutopayEnrollmentNsfEvent = guard<ManagerAutopayEnrollmentNsfEvent>(ManagerAutopayEnrollmentNsfEventSchema as any);
import { ApolloLink } from '@apollo/client';
import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import { CognitoResponse } from '../types';

type Headers = {
  authorization?: string;
};

export const idToken = () => {
  const localUserDetails: CognitoResponse = Cache.getItem('currentUser')
    ? Cache.getItem('currentUser')!
    : null;
  const token = localUserDetails?.signInUserSession?.idToken?.jwtToken ?? '';

  return token;
};

const authLink = new ApolloLink((operation, forward) => {
  const authorization = idToken();
  operation.setContext(({ headers }: { headers: Headers }) => ({
    headers: { ...headers, authorization },
  }));

  return forward(operation);
});

export default authLink;

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerReportsGenerateBooksAction } from './books';
import ManagerReportsGenerateBooksActionSchema from './books.json';

export const isManagerReportsGenerateBooksAction = guard<ManagerReportsGenerateBooksAction>(ManagerReportsGenerateBooksActionSchema as any);
import { Info } from '@mui/icons-material';
import { Stack, TableHead, TableSortLabel, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FilteredColumn } from 'cache';
import { Tooltip } from 'components';
import { MouseEvent } from 'react';
import { Column, GlobalFilter as GlobalFilterType, Order } from '../types';
import FilterColumnByValue from './FilterColumnByValue';
import GlobalFilter from './GlobalFilter';
import { FiltersContainer, TableRow } from './styles';
import TableHeadCell from './TableHeadCell';

type EnhancedTableHeadProps = {
  onRequestSort: (event: MouseEvent, property: string, sortField?: string) => void;
  onFilteredColumnChange: (value: string, toExclude: boolean, field: string) => void;
  onFilteredColumnAllChange: (field: string) => void;
  onFilteredColumnNoneChange: (field: string) => void;
  onGlobalFilterChange: (value: string, toExclude: boolean) => void;
  order: Order;
  orderBy?: string;
  columns: Column[];
  filteredColumns: FilteredColumn[];
  globalFilter?: GlobalFilterType;
  globalFilterExcluded: string[];
};

export default function EnhancedTableHead({
  columns,
  filteredColumns,
  order,
  orderBy,
  onRequestSort,
  onFilteredColumnChange,
  onFilteredColumnAllChange,
  onFilteredColumnNoneChange,
  globalFilter,
  globalFilterExcluded,
  onGlobalFilterChange,
}: EnhancedTableHeadProps) {
  const createSortHandler = (property: string, sortField?: string) => (event: MouseEvent) => {
    onRequestSort(event, property, sortField);
  };

  return (
    <TableHead>
      <TableRow>
        {columns
          .filter(({ hidden }) => !hidden)
          .map((column, i) => {
            const headerElement = (
              <Stack direction="row" alignItems="center" gap={1}>
                {column.headerName}
                {column.headerTooltip && (
                  <Tooltip title={column.headerTooltip}>
                    <Info fontSize="small" color="primary" />
                  </Tooltip>
                )}
              </Stack>
            );

            const filteredColumn = filteredColumns.find(({ field }) => field === column.field);
            return (
              <TableHeadCell
                key={column.field}
                flex={column.flex ?? 1}
                showBorder={i === columns.length - 1}
                sortDirection={Boolean(orderBy) && orderBy === column.field ? order : false}
              >
                <>
                  {!column.disableSort ? (
                    <TableSortLabel
                      active={Boolean(orderBy) && orderBy === column.field}
                      direction={Boolean(orderBy) && orderBy === column.field ? order : Order.ASC}
                      onClick={createSortHandler(column.field, column.sortField)}
                    >
                      {headerElement}
                      {orderBy === column.field && (
                        <Typography sx={visuallyHidden}>
                          {order === Order.DESC ? 'sorted descending' : 'sorted ascending'}
                        </Typography>
                      )}
                    </TableSortLabel>
                  ) : (
                    <>{headerElement}</>
                  )}

                  {column.filterColumnValues && (
                    <FilterColumnByValue
                      {...{
                        column,
                        filteredColumn,
                        onFilteredColumnAllChange,
                        onFilteredColumnNoneChange,
                      }}
                      onValueChange={onFilteredColumnChange}
                    />
                  )}
                </>
              </TableHeadCell>
            );
          })}
        {globalFilter && (
          <FiltersContainer>
            <GlobalFilter
              globalFilter={globalFilter}
              globalFilterExcluded={globalFilterExcluded}
              onFilterSelected={onGlobalFilterChange}
            />
          </FiltersContainer>
        )}
      </TableRow>
    </TableHead>
  );
}

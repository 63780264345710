import { ButtonProps, PopperPlacementType } from '@mui/material';
import { SplitButton } from 'components';
import { FieldValues, UseFormReset, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';

export default function SubmitSplitButton<T extends FieldValues>({
  options,
  className,
  disabled,
  dropDownPlacement = 'bottom-end',
  type = 'submit',
  sx,
  hideProgress,
}: {
  options: {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    title?: string;
    onSelected?: () => void | Promise<void>;
    onClick?: (
      values: T,
      reset: UseFormReset<T>
    ) => Record<string, unknown> | Promise<Record<string, unknown>> | void | Promise<void>;
  }[];
  className?: string;
  disabled?: boolean;
  dropDownPlacement?: PopperPlacementType;
  sx?: ButtonProps['sx'];
  hideProgress?: boolean;
  type?: 'button' | 'submit';
}) {
  const {
    formState: { isSubmitting },
    reset,
    handleSubmit,
  } = useFormContext<T>();

  return (
    <SplitButton
      type={type}
      className={className}
      hideProgress={hideProgress}
      {...spreadIf(sx, { sx })}
      disabled={disabled || isSubmitting}
      {...(dropDownPlacement ? { dropDownPlacement } : {})}
      options={options.map((option) => ({
        ...option,
        loading: isSubmitting || option.loading,
        onClick: async () => {
          await option?.onSelected?.();
          handleSubmit((values) => {
            option.onClick?.(values as T, reset);
          })();
        },
      }))}
    />
  );
}

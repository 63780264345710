/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAvailabilityAllChangedEvent } from './availabilityAllChanged';
import ManagerAvailabilityAllChangedEventSchema from './availabilityAllChanged.json';

export const isManagerAvailabilityAllChangedEvent = guard<ManagerAvailabilityAllChangedEvent>(ManagerAvailabilityAllChangedEventSchema as any);
import { PayeeType } from 'api';

export type JournalImportType = (typeof JournalImportType)[keyof typeof JournalImportType];
export const JournalImportType = {
  OpeningBalances: 'OpeningBalances',
  Transactions: 'Transactions',
} as const;

export type ImportJournalsModalFormRow = {
  amount: number;
  date: string;
  description?: string;

  accountName?: string;
  debitAccountName?: string;
  creditAccountName?: string;
};

export type ImportJournalsModalFormFields = {
  ownerId: string;
  propertyId: string;
  importType: JournalImportType;
  entries: ImportJournalsModalFormRow[];
};

export type ImportJournalsRowModel = {
  amount: number;
  posted: string;
  description?: string;

  glId?: string;
  debitGlId?: string;
  creditGlId?: string;

  payeeId?: string;
  payee?: PayeeType;

  index: number;
};

export type ImportJournalsFormFields = {
  ownerId: string;
  propertyId: string;
  importType: JournalImportType;
  entries: ImportJournalsRowModel[];
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAccountStatAdjustedEvent } from './accountStatAdjusted';
import ManagerAccountStatAdjustedEventSchema from './accountStatAdjusted.json';

export const isManagerAccountStatAdjustedEvent = guard<ManagerAccountStatAdjustedEvent>(ManagerAccountStatAdjustedEventSchema as any);
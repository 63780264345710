import { Box as _Box, styled } from '@mui/material';
import { skipProps } from 'system';
import { COLLAPSED_WIDTH, EXPANDED_WIDTH } from './useAuthFrame';

export const Box = styled(
  _Box,
  skipProps('collapse')
)<{ collapse?: boolean }>(({ theme, collapse }) => ({
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${collapse ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px - 2 * ${theme.spacing(
      4
    )})`,
    transform: `translateX(calc(${collapse ? COLLAPSED_WIDTH : EXPANDED_WIDTH}px + ${theme.spacing(
      4
    )}))`,
    paddingTop: theme.spacing(2.3),
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: theme.palette.background.paper,
  },
}));

import { VerifiedUser } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Tooltip } from './Tooltip';

export const HasProfile = ({ profileId }: { profileId?: string }) => {
  return profileId ? (
    <Box>
      <Tooltip title={'Verified resident profile'}>
        <VerifiedUser color="success" fontSize="small" sx={{ ml: 1 }} />
      </Tooltip>
    </Box>
  ) : (
    <></>
  );
};

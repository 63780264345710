import { Popper, autocompleteClasses, styled } from '@mui/material';

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
}) as unknown as typeof Popper;

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerCampaignClosedEvent } from './campaignClosed';
import ManagerCampaignClosedEventSchema from './campaignClosed.json';

export const isManagerCampaignClosedEvent = guard<ManagerCampaignClosedEvent>(ManagerCampaignClosedEventSchema as any);
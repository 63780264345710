import { ManagerRole } from 'api';

export enum MappedCognitoGroup {
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
}

export enum AuthActions {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  REQUEST_LOGOUT = 'REQUEST_LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR',
  PASSWORD_CREATE_SUCCESS = 'PASSWORD_CREATE_SUCCESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  UPDATE_USER = 'UPDATE_USER',
  LOGIN_ERROR = 'LOGIN_ERROR',
}

export type CognitoResponse = {
  challengeName: string;
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: {
        ['cognito:groups']?: MappedCognitoGroup[];
        ['custom:account']?: string;
        ['custom:tier']?: number;
        sub: string;
      };
    };
  };
  username: string;
};

export type AuthState = {
  userDetails?: CognitoResponse;
  token: string;
  isAuthenticated: boolean;
  loading: boolean;
  errorMessage?: string;
  mustCreatePassword: boolean;
  roles?: ManagerRole[];
  accountId: string;
  tier: number;
  managerId: string;
  includesAnyRole: (...arg: ManagerRole[]) => boolean;
  isBooksAdmin: boolean;
  isBooksAdvanced: boolean;
  isBooksUser: boolean;
  isBooksReporter: boolean;
};

export type AuthAction =
  | {
      type:
        | AuthActions.REQUEST_LOGIN
        | AuthActions.REQUEST_LOGOUT
        | AuthActions.LOGOUT_SUCCESS
        | AuthActions.CLEAR_LOGIN_ERROR
        | AuthActions.PASSWORD_CREATE_SUCCESS;
    }
  | {
      type: AuthActions.LOGIN_SUCCESS | AuthActions.NEW_PASSWORD_REQUIRED;
      payload: CognitoResponse;
    }
  | {
      type: AuthActions.UPDATE_USER;
      payload: { idToken: string };
    }
  | {
      type: AuthActions.LOGIN_ERROR;
      error: string;
    };

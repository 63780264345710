/* eslint-disable react-hooks/exhaustive-deps */
import { S3ProviderListConfig, Storage } from '@aws-amplify/storage';
import { merge } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { insertOrReplace } from 'system';
import { z } from 'zod';

type S3ObjectList = z.infer<typeof s3ObjectListShape>;
const s3ObjectListShape = z.array(
  z
    .object({
      key: z.string(),
      size: z.number(),
      eTag: z.string().optional(),
      lastModified: z.date().optional(),
    })
    .transform(({ key, lastModified, ...rest }) => ({
      key,
      createdZ: DateTime.utc().toISO(),
      ...(lastModified && {
        lastModified,
        createdZ: DateTime.fromJSDate(lastModified).toISO(),
      }),
      ...rest,
    }))
);

export const useGetS3Image = (key?: string) => {
  const [image, setImage] = useState<string | undefined>();
  useEffect(() => {
    const fetchImage = async () => {
      if (key) {
        const img = await Storage.get(key);
        setImage(img.toString());
      } else {
        setImage(undefined);
      }
    };
    fetchImage();
  }, [key]);
  return image;
};

export const useListS3 = (
  prefix?: string,
  config: S3ProviderListConfig & { skip?: boolean } = {
    pageSize: 'ALL',
  }
) => {
  const [objects, setObjects] = useState<S3ObjectList>([]);
  const [loading, setLoading] = useState(false);

  const listObjects = useCallback(async (s3Prefix = prefix) => {
    if (s3Prefix && !config.skip) {
      setLoading(true);
      const objs = await Storage.list(s3Prefix, config);
      const result = s3ObjectListShape.safeParse(objs.results);

      if (result.success) {
        setObjects(result.data.map((obj) => ({ ...obj, key: obj.key.replace(s3Prefix, '') })));
      } else {
        console.error('Failed to parse S3 object list');
        console.error(result.error);
      }

      setLoading(false);
    } else {
      setObjects([]);
    }
  }, []);

  useEffect(() => {
    listObjects(prefix);
  }, [prefix]);

  const exists = useCallback(
    (s3Key: string) => objects.some((obj) => obj.key === s3Key),
    [objects]
  );

  const addObject = useCallback((s3Object: S3ObjectList[number]) => {
    updateObject(s3Object.key, s3Object);
  }, []);

  const updateObject = useCallback((s3Key: string, s3Object: Partial<S3ObjectList[number]>) => {
    setObjects((prev) =>
      insertOrReplace(
        prev,
        (obj) => obj.key === s3Key,
        (obj) => merge(obj, s3Object)
      )
    );
  }, []);

  const removeObject = useCallback((s3Key: string) => {
    setObjects((prev) => prev.filter((obj) => obj.key !== s3Key));
  }, []);

  return {
    loading,
    objects,
    exists,
    addObject,
    listObjects,
    updateObject,
    removeObject,
  };
};

export const useGetS3PublicFile = () => {
  const S3Base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/`;
  const getUrl = (key?: string) => (key ? `${S3Base}${key}` : '');
  return { getUrl };
};

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Abicus({ ...props }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 28 24" {...props}>
      <path
        d="M4.66602 2.66667H7.33268V10.6667H4.66602V2.66667ZM11.3327 2.66667H8.66602V10.6667H11.3327V2.66667ZM4.66602 21.3333H7.33268V13.3333H4.66602V21.3333ZM11.3327 13.3333H8.66602V21.3333H11.3327V18.6667H17.9993V16H11.3327V13.3333ZM0.666016 24H3.33268V0H0.666016V24ZM24.666 0V5.33333H15.3327V2.66667H12.666V10.6667H15.3327V8H24.666V16H21.9993V13.3333H19.3327V21.3333H21.9993V18.6667H24.666V24H27.3327V0H24.666Z"
        fill={props.htmlColor ?? '#C4C4C4'}
      />
    </SvgIcon>
  );
}

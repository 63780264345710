import { ListItemButton, ListItemText, listItemTextClasses, Skeleton, styled } from '@mui/material';
import { Column } from '../types';

const ColumnCell = styled(ListItemText)({
  flex: 1,
  [`& .${listItemTextClasses.primary}`]: {
    fontWeight: 500,
    fontSize: '1em',
  },
});

const Item = styled('div')({
  width: '100%',
  display: 'flex',
});

const Row = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
  display: 'flex',
  flexDirection: 'column',
}));

export default function EnhancedMobileRowPlaceholder({
  key,
  columns,
}: {
  key: string | number;
  columns: Column[];
}) {
  return (
    <Row>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <Item key={`${key} ${column.field}`}>
            <ColumnCell primary={column.headerName} />
            <Skeleton sx={{ flex: 2 }} />
          </Item>
        ))}
    </Row>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import { ValidateOptions } from 'yup/lib/types';
import { ZodType, z } from 'zod';

export type FormProps<T extends FieldValues, Z extends z.ZodTypeAny = never> = {
  children: ReactNode;
  schema?: AnyObjectSchema | Lazy<AnyObjectSchema> | Z;
  defaultValues?: DeepPartial<T>;
  onSubmit?: SubmitHandler<T>;
  className?: string;
  shouldUnregister?: boolean;
  schemaOptions?: ValidateOptions;
  resetOnDefaultValueChange?: boolean;
};

export default function Form<T extends FieldValues, Z extends z.ZodTypeAny = never>({
  children,
  schema,
  defaultValues,
  onSubmit = () => null,
  shouldUnregister,
  schemaOptions,
  resetOnDefaultValueChange: resetOnDefaultChange,
  ...props
}: FormProps<T, Z>) {
  const resolver = schema
    ? schema instanceof ZodType
      ? // TODO: Not sure what's wrong with this, it just started erroring and it seems to work normally
        zodResolver(schema)
      : yupResolver(schema, schemaOptions)
    : undefined;

  const methods = useForm<T>({
    ...(schema && { resolver }),
    mode: 'onChange',
    defaultValues,
    shouldUnregister,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (resetOnDefaultChange && defaultValues) {
      reset(defaultValues);
    }
  }, [resetOnDefaultChange, defaultValues, reset]);

  useEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      console.warn(`Form errors`, methods.formState.errors);
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider<T> {...methods}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(async (values) => {
          try {
            await onSubmit(values);
          } catch (error) {
            console.error(`Failed to submit Form`, { error, values });
          }
        })}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
}

import { LoadingButton } from 'components';
import { LoadingButtonProps } from 'components/LoadingButton';
import { ReactNode } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

type SubmitButtonProps = {
  children: ReactNode;
} & LoadingButtonProps;

export default function SubmitButton<T extends FieldValues>({
  children,
  color = 'primary',
  variant = 'contained',
  fullWidth = true,
  disabled,
  onClick,
  ...props
}: SubmitButtonProps) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useFormContext<T>();

  return (
    <LoadingButton
      color={color}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled || (Object.keys(errors).length > 0 && !isValid)}
      {...props}
      type="submit"
      loading={isSubmitting}
      {...{
        ...(onClick && {
          onClick: (e) => handleSubmit(() => onClick?.(e))(),
        }),
      }}
    >
      {children}
    </LoadingButton>
  );
}

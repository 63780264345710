import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Hidden, TablePaginationProps, TableRowProps } from '@mui/material';
import { EnhancedTableState } from 'cache';
import { useSearchRows } from 'hooks/useSearchRows';
import { isEqual } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { Column, GlobalFilter, Row } from '../types';
import EnhancedDesktopTable from './EnhancedDesktopTable';
import EnhancedMobileTable from './EnhancedMobileTable';
import { getComparator, stableSort } from './utils';

export type EnhancedTableProps = {
  rows: Row[];
  columns: Column[];
  onRowClick?: (id: string) => void;
  desktopRowSx?: TableRowProps['sx'];
  allowPagination?: boolean;
  allowSearch?: boolean;
  allowSort?: boolean;
  allowExport?: boolean;
  className?: string;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode | ((obj: { rows: Row[] }) => JSX.Element);
  footerComponent?: ReactNode;
  rowExpansionComponent?: (props: { row: Row }) => JSX.Element | null;
  renderMobileRow?: (params: { id: string; value: Row }) => ReactNode;
  globalFilter?: GlobalFilter;
  loading?: boolean;
  emptyState?: JSX.Element;
  toolbarTitle?: string;
  rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
  hideBorder?: boolean;
};

export default function EnhancedTable({
  className,
  allowSearch,
  allowSort,
  rows,
  columns,
  reactiveState,
  globalFilter,
  emptyState,
  toolbarTitle,
  desktopRowSx,
  ...props
}: EnhancedTableProps) {
  const state = useReactiveVar(reactiveState);

  const {
    search,
    filteredRowIds,
    orderBy,
    sortField,
    order,
    filteredColumns,
    globalFilterExcluded,
  } = state;

  const { searchedRows } = useSearchRows({ rows, searchTerm: allowSearch ? search : undefined });

  const sortedRows =
    allowSort && orderBy
      ? stableSort(searchedRows, getComparator(order, sortField ?? orderBy))
      : searchedRows;

  const filteredByColumnsRows = sortedRows.filter((row) => {
    const valuesToRemove = filteredColumns.map((filteredColumn) => filteredColumn.excludes).flat();
    return !valuesToRemove.some((valueToRemove) => Object.values(row).includes(valueToRemove));
  });

  const filteredRows =
    globalFilter && globalFilterExcluded.length > 0
      ? filteredByColumnsRows.filter((row) => {
          const filterToTestForTrue = globalFilter.filter(
            (filter) => !globalFilterExcluded.includes(filter.label)
          );
          const results = filterToTestForTrue.map(({ test }) => test(row));
          return results.some((result) => result);
        })
      : filteredByColumnsRows;

  useEffect(() => {
    const currentFilteredRowIds = filteredRows.map((row) => row.id);
    if (!isEqual(filteredRowIds, currentFilteredRowIds)) {
      reactiveState({
        ...state,
        filteredRowIds: currentFilteredRowIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  return !props.loading && rows.length === 0 && emptyState ? (
    emptyState
  ) : (
    <div className={className}>
      <Hidden mdUp>
        <EnhancedMobileTable
          {...props}
          rows={filteredRows}
          {...{ columns, allowSearch, reactiveState }}
        />
      </Hidden>
      <Hidden mdDown>
        <EnhancedDesktopTable
          {...props}
          rows={filteredRows}
          rowSx={desktopRowSx}
          {...{
            columns,
            allowSearch,
            allowSort,
            reactiveState,
            globalFilter,
            toolbarTitle,
          }}
        />
      </Hidden>
    </div>
  );
}

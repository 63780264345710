import { OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';
import { useMemo, useRef } from 'react';
import { ensureArray } from 'system';
import { useErrorNotifications } from './useErrorNotifications';

export type Page<TItem> = {
  edges: Array<{ node: TItem }>;
  totalCount: number;
  pageInfo: {
    endCursor?: string | undefined;
    hasNextPage: boolean;
  };
};

export type RelayPageOptions<
  TData extends Record<string, unknown> | undefined,
  TVariables extends OperationVariables,
  TPage,
> = QueryHookOptions<TData, TVariables> & {
  getPage: (data: QueryResult<TData, TVariables>['data']) => TPage;
};

type RelayPageResult<
  TItem,
  TData,
  TVariables extends OperationVariables,
  TPage extends Page<TItem> | undefined,
> = [Array<TItem>, QueryResult<TData, TVariables> & (TPage | undefined)];

type InferItemType<TPage> = TPage extends Page<infer TItem> ? TItem : never;

export const useRelayPage = <
  TData extends Record<string, unknown> | undefined,
  TVariables extends OperationVariables,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allows the item type to be inferred
  TPage extends Page<any> | undefined = Page<any> | undefined,
>(
  useListData: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  { getPage, ...options }: RelayPageOptions<TData, TVariables, TPage>
): RelayPageResult<InferItemType<TPage>, TData, TVariables, TPage> => {
  const meta = useListData(options);
  useErrorNotifications(meta.error);

  const getPageRef = useRef(getPage);
  getPageRef.current = getPage;

  const page = useMemo(() => getPageRef.current(meta.data), [meta.data]);
  const items = useMemo(() => ensureArray(page?.edges?.map((edge) => edge.node)), [page]);

  return [items, { ...page, ...meta }];
};

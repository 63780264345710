/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksDboPreparedEvent } from './batchDboPrepared';
import ManagerBooksDboPreparedEventSchema from './batchDboPrepared.json';

export const isManagerBooksDboPreparedEvent = guard<ManagerBooksDboPreparedEvent>(ManagerBooksDboPreparedEventSchema as any);
import { RequestFieldsFragment, RequestListFieldsFragment } from 'api';
import { toNumber } from 'lodash';
import { DateTime } from 'luxon';

type ObjectWithName = { name: string };
type ObjectWithDate = { date: DateTime };
type ObjectWithStartISO = { start?: string };
type SortableTenant = { leaseHolder: boolean; name: string };
type SortableKeys<T> = {
  [k in keyof T]: T[k] extends string | number ? k : never;
}[keyof T] extends never
  ? never
  : keyof T;

export const sortByName = (a: ObjectWithName, b: ObjectWithName) => {
  if (!Number.isNaN(toNumber(a.name)) && !Number.isNaN(toNumber(b.name))) {
    return toNumber(a.name) - toNumber(b.name);
  }

  return a.name.localeCompare(b.name);
};

export const sortByAttribute = <T>(attribute: SortableKeys<T>, dir: 'asc' | 'desc' = 'asc') => {
  return (...[a, b]: Parameters<typeof sorter>) => (dir === 'asc' ? sorter(a, b) : sorter(b, a));
  function sorter(a?: Partial<T> | undefined, b?: Partial<T> | undefined) {
    const a_ = a?.[attribute] ?? '';
    const b_ = b?.[attribute] ?? '';

    if (typeof a_ === 'string' && typeof b_ === 'string') {
      if (!Number.isNaN(toNumber(a_)) && !Number.isNaN(toNumber(b_))) {
        return toNumber(a_) - toNumber(b_);
      }

      return a_.localeCompare(b_);
    }

    if (typeof a_ === 'number' && typeof b_ === 'number') {
      return a_ - b_;
    }

    return 0;
  }
};

export const sortByAttributes = <T>(
  sortKeys: (SortableKeys<T> | [SortableKeys<T>, 'asc' | 'desc'])[]
) => {
  return (a: Partial<T> | undefined, b: Partial<T> | undefined) => {
    for (const sortKey of sortKeys) {
      const [key, dir = 'asc'] = Array.isArray(sortKey) ? sortKey : [sortKey];
      const result = sorter(a, b, key as SortableKeys<T>, dir);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };

  function sorter(
    a: Partial<T> | undefined,
    b: Partial<T> | undefined,
    attribute: SortableKeys<T>,
    dir: 'asc' | 'desc' = 'asc'
  ) {
    const a_ = a?.[attribute] ?? '';
    const b_ = b?.[attribute] ?? '';

    if (typeof a_ === 'string' && typeof b_ === 'string') {
      if (!Number.isNaN(toNumber(a_)) && !Number.isNaN(toNumber(b_))) {
        return (dir === 'asc' ? 1 : -1) * (toNumber(a_) - toNumber(b_));
      }

      return (dir === 'asc' ? 1 : -1) * a_.localeCompare(b_);
    }

    if (typeof a_ === 'number' && typeof b_ === 'number') {
      return (dir === 'asc' ? 1 : -1) * (a_ - b_);
    }

    return 0;
  }
};

const alphanumericCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export const alphanumericStringComparator =
  <TKey extends string>(prop: TKey, asc = true) =>
  <TRecord extends Record<TKey, string>>(a: TRecord, b: TRecord) =>
    asc
      ? alphanumericCollator.compare(a[prop], b[prop])
      : alphanumericCollator.compare(b[prop], a[prop]);

export const sortOptionsByText = <T extends { text: string }>(arr: T[]) => {
  return arr.sort((a, b) => a.text.localeCompare(b.text));
};

export const sortDataByLabel = <T extends { label: string }>(arr: T[]) => {
  return arr.sort((a, b) => a.label.localeCompare(b.label));
};

export const newestToOldest = ({ date: a }: ObjectWithDate, { date: b }: ObjectWithDate) =>
  a < b ? 1 : a > b ? -1 : 0;

export const byStart = (
  { start: a = '' }: ObjectWithStartISO,
  { start: b = '' }: ObjectWithStartISO
) => (a > b ? 1 : a < b ? -1 : 0);

export const chronological = (a: DateTime, b: DateTime) => (a > b ? 1 : a < b ? -1 : 0);

export const oldestRequestFirst = (
  a: RequestFieldsFragment | RequestListFieldsFragment,
  b: RequestFieldsFragment | RequestListFieldsFragment
) =>
  !a.fingerprints?.createdZ || !b.fingerprints?.createdZ
    ? 0
    : a.fingerprints.createdZ > b.fingerprints.createdZ
      ? 1
      : a.fingerprints.createdZ < b.fingerprints.createdZ
        ? -1
        : 0;

export const newestRequestFirst = (
  a: RequestFieldsFragment | RequestListFieldsFragment,
  b: RequestFieldsFragment | RequestListFieldsFragment
) =>
  !a.fingerprints?.createdZ || !b.fingerprints?.createdZ
    ? 0
    : a.fingerprints.createdZ < b.fingerprints.createdZ
      ? 1
      : a.fingerprints.createdZ > b.fingerprints.createdZ
        ? -1
        : 0;

export const byAssetName = (
  a: { name: string } | { name: string; property: { name: string } },
  b: { name: string } | { name: string; property: { name: string } }
) =>
  'property' in a && 'property' in b && a.property.name !== b.property.name
    ? sortByName(a.property, b.property)
    : sortByName(a, b);

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksBatchCancelledEvent } from './batchCancelled';
import ManagerBooksBatchCancelledEventSchema from './batchCancelled.json';

export const isManagerBooksBatchCancelledEvent = guard<ManagerBooksBatchCancelledEvent>(ManagerBooksBatchCancelledEventSchema as any);
import { Box, Collapse, InputAdornment, ListItemText } from '@mui/material';
import {
  CheckboxFieldController,
  CurrencyFieldController,
  FullSizePaper,
  PercentFieldController,
  SwitchFieldController,
  TextFieldController,
} from 'components';
import { useMemo, useState } from 'react';
import { useAccountingContext } from '../context';
import FinancialSettingsToolBar from './FinancialSettingsToolBar';

import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { usePageSize } from 'hooks/usePageSize';
import { CustomBillingRatesTable } from './CustomBillingRatesTable';

type RowModel = {
  id: string;
  description: { primary: string; secondary?: string };
  setting: {
    element:
      | typeof TextFieldController
      | typeof CurrencyFieldController
      | typeof SwitchFieldController;
    props?: Record<string, unknown>;
  };
};

export default function MaintenanceBillingRatesForm({
  isCollapsible = true,
}: {
  isCollapsible?: boolean;
}) {
  const { entityIds } = useAccountingContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const accountName = entityIds.account[0].text;

  const rows: RowModel[] = useMemo(
    () => [
      {
        id: 'billingRatesEnabled',
        description: {
          primary: 'Auto billing for maintenance requests',
          secondary: '',
        },
        setting: {
          element: SwitchFieldController,
          props: {
            name: 'billingRates.enabled',
          },
        },
      },
      {
        id: 'labourRate',
        description: {
          primary: 'Labour Rate',
          secondary: 'Hourly rate for operator time on each visit in a request',
        },
        setting: {
          element: CurrencyFieldController,
          props: {
            name: 'billingRates.labourRate',
            variant: 'outlined',
            size: 'small',
          },
        },
      },
      {
        id: 'materialsMarkup',
        description: {
          primary: 'Material Markup',
          secondary: 'Used to add markup to material costs',
        },
        setting: {
          element: PercentFieldController,
          props: {
            name: 'billingRates.materialsMarkup',
            variant: 'outlined',
            size: 'small',
            type: 'number',
          },
        },
      },
      {
        id: 'requireApproval',
        description: {
          primary: 'Require Approval',
          secondary: 'When enabled, maintenace requests will require manual completion',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'billingRates.requireApproval',
            size: 'small',
          },
        },
      },
      {
        id: 'minBillableMinutes',
        description: {
          primary: 'Minimum time billed per visit',
          secondary: 'Used to calculate labour costs as visits are completed',
        },
        setting: {
          element: TextFieldController,
          props: {
            name: 'billingRates.minBillableMinutes',
            size: 'small',
            type: 'number',
            variant: 'outlined',
            InputProps: {
              endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
            },
          },
        },
      },
      {
        id: 'trackRevenue',
        description: {
          primary: `Track maintenance revenue for ${accountName}`,
          secondary: 'Also create a revenue receivable to match the payable expense',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'billingRates.trackRevenue',
            size: 'small',
          },
        },
      },
    ],
    [accountName]
  );

  const columns: Array<GridColDef<RowModel>> = [
    {
      field: 'description',
      flex: 2,
      headerName: 'Item',
      sortable: false,
      renderCell({ value }) {
        return <ListItemText {...value} />;
      },
    },
    {
      field: 'setting',
      headerName: 'Setting',
      flex: 1,
      sortable: false,
      renderCell({ value }) {
        const { element: Element, props } = value ?? {};
        return <Box sx={{ my: 2, width: '100%' }}>{Element ? <Element {...props} /> : <></>}</Box>;
      },
    },
  ];

  const pageConfig = usePageSize({ cacheKey: 'maintenance-billing-mapping', defaultPageSize: 100 });

  return (
    <FullSizePaper sx={{ mb: 2, ...(isCollapsible ? { pt: 2, pb: 1.5 } : { p: 0, border: 0 }) }}>
      {isCollapsible && (
        <FinancialSettingsToolBar
          open={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
          title="Maintenance Billing"
        />
      )}
      <Collapse in={isOpen || !isCollapsible}>
        <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
          <DataGridPro
            autoHeight
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
            disableMultipleRowSelection
            isRowSelectable={() => false}
            {...pageConfig}
            getRowHeight={() => 'auto'}
            rows={rows}
            columns={columns}
            hideFooter={true}
            columnHeaderHeight={0}
            sx={{
              borderBottom: 'none',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
            }}
          />
          <FullSizePaper
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: 1.5,
              borderColor: 'lightDivider',
            }}
          >
            <CustomBillingRatesTable />
          </FullSizePaper>
        </Box>
      </Collapse>
    </FullSizePaper>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayPayeeBatchArchivedEvent } from './payeeBatchArchived';
import ManagerAutopayPayeeBatchArchivedEventSchema from './payeeBatchArchived.json';

export const isManagerAutopayPayeeBatchArchivedEvent = guard<ManagerAutopayPayeeBatchArchivedEvent>(ManagerAutopayPayeeBatchArchivedEventSchema as any);
const asBase64EncodedJson = (value: Record<string, unknown>) => btoa(JSON.stringify(value));

export const createAppSyncAuthorizedWebSocket = (
  getAppSyncAuthorizationInfo: () => {
    host: string;
    Authorization: string;
  }
) => {
  return class extends WebSocket {
    constructor(url: string, protocols = undefined) {
      super(
        `${url}?header=${asBase64EncodedJson(
          getAppSyncAuthorizationInfo()
        )}&payload=${asBase64EncodedJson({})}`,
        protocols
      );
    }

    set onmessage(handler: (event: MessageEvent<unknown>) => unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      super.onmessage = (event) => {
        if (event.data) {
          const data = this._tryParseJsonString(event.data);

          if (data && data.type === 'start_ack') {
            return;
          }
        }

        return handler(event);
      };
    }

    _tryParseJsonString(jsonString: string) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return undefined;
      }
    }
  };
};

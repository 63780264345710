/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPutFactsEvent } from './putFacts';
import ManagerPutFactsEventSchema from './putFacts.json';

export const isManagerPutFactsEvent = guard<ManagerPutFactsEvent>(ManagerPutFactsEventSchema as any);
import { Info } from '@mui/icons-material';
import { Box, Collapse, Typography, alpha } from '@mui/material';
import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { GlAccount, GlAccountFieldsFragment, GlMappingFieldsFragment } from 'api';
import { AutocompleteFieldController, FullSizePaper, GridSearchToolbar, Tooltip } from 'components';
import jsonata from 'jsonata';
import { castArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Option } from 'system';
import { FinancialSettingsFormFields, useGlAccounts, useGlMapping } from '../hooks';
import { formatAccountName } from '../utils';
import FinancialSettingsToolBar from './FinancialSettingsToolBar';

const GlMappingAutocomplete = ({
  id,
  glMapping,
  glAccounts,
  highlight,
}: {
  id: string;
  glMapping: GlMappingFieldsFragment[];
  glAccounts: GlAccountFieldsFragment[];
  highlight: boolean;
}) => {
  const mapping = glMapping.find((m) => m.id === id);
  const expression = jsonata(mapping?.filter ?? '');
  const evaluation = expression.evaluate(glAccounts);

  const filteredAccounts: Option[] = castArray(evaluation).map((glAccount: GlAccount) => ({
    text: formatAccountName(glAccount),
    id: glAccount.id,
  }));

  const { watch, setValue } = useFormContext<FinancialSettingsFormFields>();
  const value = watch(`glAccountMapping.${id}`);

  useEffect(() => {
    const defaultValue = filteredAccounts.find((account) => account.id === value);

    if (!value) {
      setValue(`glAccountMapping.${id}`, defaultValue ? defaultValue.id : filteredAccounts[0].id);
    }
  }, []);

  return (
    <AutocompleteFieldController
      variant="outlined"
      fullWidth
      name={`glAccountMapping.${id}`}
      label={mapping?.name}
      options={filteredAccounts}
      sx={{
        my: 2,
        ...(highlight && {
          backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
        }),
      }}
    />
  );
};

export default function GlAccountMappingForm({
  isCollapsible = true,
}: {
  isCollapsible?: boolean;
}) {
  const { glMapping } = useGlMapping();
  const missingMapping = glMapping.some((mapping) => !mapping.glId);
  const { glAccounts } = useGlAccounts();
  const [isOpen, setIsOpen] = useState<boolean>(missingMapping);

  const hints: { [key: string]: string } = {
    maintenanceRevenue: 'These transactions will not show on resident portal Billing',
  };

  const columns: Array<GridColDef<(typeof glMapping)[number]>> = [
    {
      field: 'glId',
      headerName: 'Account',
      flex: 1.5,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <GlMappingAutocomplete
            id={row.id}
            glMapping={glMapping}
            glAccounts={glAccounts}
            highlight={!row.glId}
          ></GlMappingAutocomplete>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Account Description',
      flex: 2,
      sortable: false,
      renderCell({ value, row }) {
        return (
          <>
            <Typography>{value}</Typography>
            {hints[row.id] && (
              <Tooltip title={hints[row.id]}>
                <Info fontSize="small" color="primary" />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const { errors } = useFormState();
  useEffect(() => {
    if (!isEmpty(errors)) {
      setIsOpen(true);
    }
  }, [errors]);

  return glAccounts.length === 0 ? (
    <></>
  ) : (
    <FullSizePaper sx={{ mb: 2, ...(isCollapsible ? { pt: 2, pb: 1.5 } : { p: 0, border: 0 }) }}>
      {isCollapsible && (
        <FinancialSettingsToolBar
          open={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
          title="Account Mapping"
        />
      )}
      <Collapse in={isOpen || !isCollapsible}>
        <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
          <DataGridPro
            autoHeight
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
            disableMultipleRowSelection
            isRowSelectable={() => false}
            getRowHeight={() => 'auto'}
            rows={glMapping}
            columns={columns}
            slots={{ toolbar: GridSearchToolbar }}
            hideFooter={true}
            sx={{
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
            }}
          />
        </Box>
      </Collapse>
    </FullSizePaper>
  );
}

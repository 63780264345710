/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorRequestImageAddedEvent } from './imageAdded';
import OperatorRequestImageAddedEventSchema from './imageAdded.json';

export const isOperatorRequestImageAddedEvent = guard<OperatorRequestImageAddedEvent>(OperatorRequestImageAddedEventSchema as any);
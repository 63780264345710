import { Grid, GridProps, Skeleton, styled, Typography as T, TypographyProps } from '@mui/material';

const Typography = styled(T)({
  fontWeight: 500,
  wordBreak: 'break-all',
});

type AttributeTextProps = TypographyProps & {
  loading?: boolean;
  item?: boolean;
  itemProps?: GridProps;
};

export default function AttributeText({
  loading,
  className,
  item = false,
  itemProps = {},
  children,
  ...props
}: AttributeTextProps) {
  const text = (
    <Typography className={className} {...props}>
      {loading ? <Skeleton /> : children}
    </Typography>
  );
  return item ? (
    <Grid item className={itemProps.className} {...itemProps}>
      {text}
    </Grid>
  ) : (
    text
  );
}

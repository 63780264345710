import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { UnitHistoryFieldsFragment } from 'api';
import { newestToOldest } from 'system';

export type HistoryItem = {
  isRequest: false;
  name?: string;
  id: string;
  date: DateTime;
};

export type RequestHistoryItem = Omit<HistoryItem, 'isRequest'> & {
  isRequest: true;
  summary: string;
  status: string;
  tenantName: string;
};

export const useRequestHistory = (unit?: UnitHistoryFieldsFragment) => {
  const [items, setItems] = useState<(HistoryItem | RequestHistoryItem)[]>([]);
  useEffect(
    () =>
      unit?.history &&
      setItems(
        unit.history
          .map((item) =>
            item.__typename === 'Request'
              ? {
                  isRequest: true as const,
                  name: item.name ?? '',
                  id: item.id,
                  date: DateTime.fromISO(item.fingerprints?.createdZ ?? ''),
                  summary: item.summary ?? '',
                  status: item.status ?? '',
                  delay: item.delay ?? '',
                  tenantName: item.tenant?.name ?? '',
                }
              : {
                  isRequest: false as const,
                  name: item.name ?? '',
                  id: item.id,
                  date: DateTime.fromISO(item.fingerprints?.createdZ ?? ''),
                }
          )
          .sort(newestToOldest)
      ),
    [unit]
  );

  return { items };
};

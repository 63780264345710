/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyRecurrenceEvent } from './recurrence';
import ManagerResidencyRecurrenceEventSchema from './recurrence.json';

export const isManagerResidencyRecurrenceEvent = guard<ManagerResidencyRecurrenceEvent>(ManagerResidencyRecurrenceEventSchema as any);
import { styled } from '@mui/material';
import * as CustomIcons from 'components/icons';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(-2.7),
}));

export const ImageContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
  width: theme.spacing(8),
  marginRight: theme.spacing(2),
}));

export const Image = styled('img')(({ theme }) => ({
  borderRadius: 5,
  objectFit: 'contain',
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

export const Bubble = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  justifyContent: 'center',
  backgroundColor: theme.palette.primaryBackground.main,
  width: theme.spacing(7),
  height: theme.spacing(7),
  minWidth: theme.spacing(7),
  minHeight: theme.spacing(7),
}));

export const AddPhoto = styled(CustomIcons.AddPhoto)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

let timeout = -1;
let elementCallbacks = new WeakMap<Element, () => void>();
const observer = new ResizeObserver((entries) => {
  window.cancelAnimationFrame(timeout);

  timeout = window.requestAnimationFrame(() =>
    entries.forEach((entry) => elementCallbacks.get(entry.target)?.())
  );
});

const resizeObserver = {
  observe(element: Element, callback: () => void, options?: ResizeObserverOptions) {
    if (element && !elementCallbacks.has(element)) {
      elementCallbacks.set(element, callback);
      observer.observe(element, options);
    }
  },

  unobserve(element?: Element) {
    if (!element) {
      return;
    }

    elementCallbacks.delete(element);
    observer.unobserve(element);
  },

  disconnect() {
    observer.disconnect();
    window.cancelAnimationFrame(timeout);

    timeout = -1;
    elementCallbacks = new WeakMap();
  },
};

export const useResizeObserver = () => {
  return resizeObserver;
};

import { Alert, Stack } from '@mui/material';
import { useAuth, useSiteAlerts } from 'context';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthBar from './AuthBar';
import AuthDrawer from './AuthDrawer';
import { Box } from './AuthFrame.styles';
import { LOGIN_PATH, useAuthFrame } from './useAuthFrame';

export default function AuthFrame() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) navigate(LOGIN_PATH);

  const frameState = useAuthFrame();

  const { alerts } = useSiteAlerts();

  return (
    <>
      <AuthBar handleMobileToggle={frameState.handleMobileToggle} />
      <AuthDrawer {...frameState} />
      <Box collapse={frameState.collapse}>
        <Stack spacing={1} sx={{ mb: 1 }}>
          {alerts.map((props) => (
            <Alert {...props} />
          ))}
        </Stack>
        <Outlet />
      </Box>
    </>
  );
}

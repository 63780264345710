import { Box, Grow, Skeleton, styled } from '@mui/material';
import { Image } from 'api';
import Notes from 'components/Notes';
import { useState } from 'react';
import { NoteType } from 'system';
import { ThumbnailImage } from './ThumbnailImage';

type ImagesProps = {
  withNotes?: boolean;
  loading: boolean;
  id?: string;
  images?: Image[];
  entity: string;
  headerText?: string;
  thumbnailSize?: 'small' | 'normal';
  onDelete?: (imageKey: string) => void;
};

const ThumbnailsContainer = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyItems: 'center',
  flexWrap: 'wrap',
});

const ThumbnailSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

export function Images({
  loading,
  id,
  images,
  entity,
  withNotes = false,
  headerText,
  thumbnailSize = 'normal',
  onDelete,
}: ImagesProps) {
  const [selectedImage, setSelectedImage] = useState<Image>();

  const headerElement =
    headerText &&
    (loading ? <Skeleton variant="text" /> : (images?.length ?? 0) > 0 && <h4>{headerText}</h4>);

  return (
    <>
      <ThumbnailSection>
        {headerElement}
        <ThumbnailsContainer>
          {images?.map((image, i) => (
            <Grow key={image.key} in timeout={(i + 1) * 100}>
              <Box position="relative" onClick={() => setSelectedImage(image)} sx={{ mr: 1 }}>
                <ThumbnailImage
                  showEnlargeIcon
                  active={selectedImage?.key === image.key}
                  imageKey={image.key}
                  baseUrl={`${entity}/${id}`}
                  size={thumbnailSize}
                  onDelete={onDelete}
                />
              </Box>
            </Grow>
          ))}
        </ThumbnailsContainer>
      </ThumbnailSection>

      {selectedImage && withNotes && (
        <Notes
          type={NoteType.IMAGE}
          loading={loading}
          id={id}
          addInput={{ requestId: id, imageKey: selectedImage?.key }}
          removeInput={{ requestId: id, imageKey: selectedImage?.key }}
          notes={images?.find((img) => img.key === selectedImage.key)?.notes}
        />
      )}
    </>
  );
}

import { DraftedJournalFilter, useServerDraftedJournalsQuery } from 'api';
import { useMeta } from 'hooks/useMeta';
import { useRelayPage } from 'hooks/useRelayPage';
import { JOURNALS_PAGE_SIZE, tuple } from 'system';
import { useRefetch } from './useRefetch';

export const useDraftedJournalsPage = ({
  draftReconciliationId,
  filter,
  skip,
  first = JOURNALS_PAGE_SIZE,
}: {
  draftReconciliationId: string;
  filter?: Omit<DraftedJournalFilter, 'draftReconciliationId'>;
  skip?: boolean;
  first?: number;
}) => {
  const [draftJournals, pageMeta] = useRelayPage(useServerDraftedJournalsQuery, {
    skip: skip || !draftReconciliationId,
    getPage: (d) => d?.account?.books?.pageDraftedJournalEntries,
    variables: {
      first,
      filter: { ...filter, draftReconciliationId },
    },
  });

  const { loading } = useMeta(pageMeta);
  const { handleRefresh } = useRefetch(pageMeta);

  return tuple(draftJournals, { ...pageMeta, loading, handleRefresh });
};

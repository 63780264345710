/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEventRemovedEvent } from './eventRemoved';
import ManagerResidencyEventRemovedEventSchema from './eventRemoved.json';

export const isManagerResidencyEventRemovedEvent = guard<ManagerResidencyEventRemovedEvent>(ManagerResidencyEventRemovedEventSchema as any);
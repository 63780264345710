import {
  KeyboardArrowDown as ChevronDownIcon,
  KeyboardArrowUp as ChevronUpIcon,
} from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  marginLeft: -10,
}));

export default function FinancialSettingsToolBar({
  open,
  onClick,
  title,
}: {
  open: boolean;
  onClick: VoidFunction;
  title: string;
}) {
  return (
    <Root>
      <IconButton
        aria-label={`${open ? 'close' : 'open'} ${title}`}
        sx={{ mr: 1 }}
        onClick={onClick}
        size="small"
      >
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </IconButton>
      {title}
    </Root>
  );
}

import { makeVar } from '@apollo/client';
import { Order } from 'components/Table/types';
import { useEffect } from 'react';

export type FilteredColumn = {
  field: string;
  excludes: string[];
};

export type EnhancedTableState = {
  page: number;
  rowsPerPage: number;
  search?: string;
  filteredRowIds: string[];
  order: Order;
  orderBy?: string;
  sortField?: string;
  filteredColumns: FilteredColumn[];
  globalFilterExcluded: string[];
};

const initialEnhancedTableState = (rows = 10) => ({
  filteredRowIds: [],
  page: 0,
  rowsPerPage: rows,
  search: '',
  order: Order.ASC,
  orderBy: undefined,
  sortField: undefined,
  filteredColumns: [],
  globalFilterExcluded: [],
});

const transientTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());

export const useTransientTableState = () => {
  useEffect(() => {
    transientTableState(initialEnhancedTableState());
  }, []);
  return { transientTableState };
};

export const recipientsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const propertyTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const ownerTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const tenantsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState(5));
export const operatorTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const unitTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const commonsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const commercialsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const documentTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const assignmentsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const requestTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const ownerAssetsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const propertySuppliersTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const glAccountsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const payablesTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const receivablesTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const propertyInsurancePoliciesTableState = makeVar<EnhancedTableState>(
  initialEnhancedTableState()
);
export const propertyInsurancePolicyClaimsTableState = makeVar<EnhancedTableState>(
  initialEnhancedTableState()
);
export const buildingUnitTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const leaseExpiringTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const campaignsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const applicationsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const payeeClearablesTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());
export const propertyContactsTableState = makeVar<EnhancedTableState>(initialEnhancedTableState());

import { gql, InMemoryCache } from '@apollo/client';
import {
  isManagerAccountStatsUpdatedEvent,
  isManagerAnnouncementCompletedEvent,
  isManagerApprovalPendingEvent,
  isManagerApprovalSubmittedEvent,
  isManagerAutopayBankAccountAddedEvent,
  isManagerAutopayBankAccountChangedEvent,
  isManagerAutopayBankAccountDeletedEvent,
  isManagerAutopayPayeeBatchArchivedEvent,
  isManagerAutopayPayeeBatchInvitedEvent,
  isManagerAutopayPropertyCreatedEvent,
  isManagerAutopayPropertyDeletedEvent,
  isManagerPropertyUnitsCreatedChangedEvent,
  isManagerRequestConfirmedUpdatedEvent,
  isManagerRequestHistoryEvent,
  isManagerResidencyRefundablesUpdatedEvent,
  isManagerVisitUpdatedEvent,
} from '@propra-manager/registry';
import { isOperatorVisitUpdatedCostsEvent } from '@propra-operator/registry';
import {
  isTenantApplicationSubmittedEvent,
  isTenantAutopayEnrollmentActivatedEvent,
  isTenantAutopayEnrollmentCancelledEvent,
  isTenantAutopayInviteDeclinedEvent,
  isTenantLeaseAcceptedEvent,
} from '@propra-resident/registry';
import {
  guardHandler,
  HandlerMap,
  isSystemAutopaySummaryStatusUpdatedEvent,
} from '@propra-system/registry';
import { AutopayEnrollmentStatus, RequestConnection } from 'api';
import { ensureArray, invalidate } from 'system';

export const cacheHandlers = (cache: InMemoryCache, accountId: string): HandlerMap =>
  [
    guardHandler(isManagerAnnouncementCompletedEvent, ({ detail: { announcementId } }) =>
      Promise.resolve(
        cache.modify({
          id: cache.identify({ id: announcementId, __typename: 'Announcement' }),
          fields: {
            status() {
              return 'COMPLETED';
            },
          },
        })
      )
    ),

    guardHandler(
      isManagerAccountStatsUpdatedEvent,
      ({
        detail: {
          accountId: localAccountId,
          newRequestsWithAction,
          newCampaignApplicationsWithAction,
        },
      }) => {
        return Promise.resolve(
          cache.modify({
            id: cache.identify({ id: localAccountId, __typename: 'Account' }),
            fields: {
              requestsWithAction: () => newRequestsWithAction,
              campaignApplicationsWithAction: () => newCampaignApplicationsWithAction,
            },
          })
        );
      }
    ),

    guardHandler(isTenantApplicationSubmittedEvent, ({ detail: { applicationId, campaignId } }) => {
      cache.modify({
        id: cache.identify({ id: applicationId, __typename: 'Application' }),
        fields: {
          status() {
            return 'SUBMITTED';
          },
        },
      });
      cache.modify({
        id: cache.identify({ id: campaignId, __typename: 'Campaign' }),
        fields: {
          applications: invalidate,
        },
      });

      return Promise.resolve();
    }),

    guardHandler(isTenantLeaseAcceptedEvent, ({ detail: { campaignId } }) => {
      cache.modify({
        id: cache.identify({ id: campaignId, __typename: 'Campaign' }),
        fields: {
          applications: invalidate,
        },
      });

      return Promise.resolve();
    }),

    guardHandler(isManagerRequestConfirmedUpdatedEvent, ({ detail: { requestId, newRequest } }) => {
      cache.evict({ id: cache.identify({ id: requestId, __typename: 'Request' }) });
      cache.gc();

      ensureArray(newRequest.checklist).forEach((item) => {
        cache.writeFragment({
          id: cache.identify({ id: item.id, __typename: 'ChecklistItem' }),
          data: item,
          fragment: gql`
            fragment UpdatedChecklistItem on ChecklistItem {
              id
              name
              completedZ
              order
            }
          `,
        });
      });

      return Promise.resolve();
    }),

    guardHandler(
      isManagerRequestHistoryEvent,
      ({ detail: { requestId, oldStatus, newStatus } }) => {
        const finalStatuses = new Set(['COMPLETED', 'CANCELLED']);

        if (
          oldStatus !== newStatus &&
          finalStatuses.has(newStatus) &&
          !finalStatuses.has(oldStatus ?? 'NEW')
        ) {
          cache.modify({
            id: cache.identify({ id: accountId, __typename: 'Account' }),
            fields: {
              listActiveRequests: (existing: RequestConnection = {}, { readField }) => ({
                ...existing,
                items: [
                  ...ensureArray(existing?.items).filter(
                    (item) => readField('id', item) !== requestId
                  ),
                ],
              }),
              listRecentFinalizedRequests: (existing: RequestConnection = {}) => ({
                ...existing,
                items: [
                  ...ensureArray(existing.items),
                  { __ref: cache.identify({ id: requestId, __typename: 'Request' }) },
                ],
              }),
              listAllFinalizedRequests: (existing: RequestConnection = {}) => ({
                ...existing,
                items: [
                  ...ensureArray(existing.items),
                  { __ref: cache.identify({ id: requestId, __typename: 'Request' }) },
                ],
              }),
            },
          });
        }

        return Promise.resolve(
          cache.modify({
            id: cache.identify({ id: requestId, __typename: 'Request' }),
            fields: {
              status: invalidate,
              history: invalidate,
              notes: invalidate,
            },
          })
        );
      }
    ),

    guardHandler(isManagerVisitUpdatedEvent, ({ detail: { visitId } }) => {
      cache.evict({ id: cache.identify({ id: visitId, __typename: 'Visit' }) });
      cache.gc();
      return Promise.resolve();
    }),

    guardHandler(isOperatorVisitUpdatedCostsEvent, ({ detail: { visitId } }) => {
      cache.evict({ id: cache.identify({ id: visitId, __typename: 'Visit' }) });
      cache.gc();
      return Promise.resolve();
    }),

    guardHandler(
      isManagerPropertyUnitsCreatedChangedEvent,
      ({ detail: { propertyId, oldCreatingUnits, newCreatingUnits } }) => {
        return oldCreatingUnits && !newCreatingUnits
          ? Promise.resolve(
              cache.modify({
                id: cache.identify({ id: propertyId, __typename: 'Property' }),
                fields: {
                  totalUnits: invalidate,
                  units: invalidate,
                },
              })
            )
          : Promise.resolve();
      }
    ),
    guardHandler(isManagerAutopayBankAccountAddedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayProperties: invalidate,
            listAutopayEnrollments: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayBankAccountChangedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayProperties: invalidate,
            listAutopayEnrollments: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayBankAccountDeletedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayProperties: invalidate,
            listAutopayEnrollments: invalidate,
          },
        })
      );
    }),
    guardHandler(isSystemAutopaySummaryStatusUpdatedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopaySummaries: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerApprovalSubmittedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listActiveApprovals: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerApprovalPendingEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listActiveApprovals: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayPropertyCreatedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayProperties: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayPropertyDeletedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayProperties: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayPayeeBatchInvitedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayEnrollments: invalidate,
          },
        })
      );
    }),
    guardHandler(isManagerAutopayPayeeBatchArchivedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.accountId, __typename: 'Account' }),
          fields: {
            listAutopayEnrollments: invalidate,
          },
        })
      );
    }),
    guardHandler(isTenantAutopayEnrollmentActivatedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.enrollmentId, __typename: 'AutopayEnrollment' }),
          fields: {
            status: () => AutopayEnrollmentStatus.Activated,
          },
        })
      );
    }),
    guardHandler(isTenantAutopayEnrollmentCancelledEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.enrollmentId, __typename: 'AutopayEnrollment' }),
          fields: {
            status: () => AutopayEnrollmentStatus.Cancelled,
          },
        })
      );
    }),
    guardHandler(isTenantAutopayInviteDeclinedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.enrollmentId, __typename: 'AutopayEnrollment' }),
          fields: {
            status: () => AutopayEnrollmentStatus.Declined,
          },
        })
      );
    }),
    guardHandler(isManagerResidencyRefundablesUpdatedEvent, ({ detail }) => {
      return Promise.resolve(
        cache.modify({
          id: cache.identify({ id: detail.residencyId, __typename: 'Residency' }),
          fields: {
            refundables: invalidate,
          },
        })
      );
    }),
  ] as HandlerMap;

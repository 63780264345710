/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertyOwnerChangedEvent } from './property';
import ManagerPropertyOwnerChangedEventSchema from './property.json';

export const isManagerPropertyOwnerChangedEvent = guard<ManagerPropertyOwnerChangedEvent>(ManagerPropertyOwnerChangedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerReportsGenerateInspectionUrlAction } from './inspection';
import ManagerReportsGenerateInspectionUrlActionSchema from './inspection.json';

export const isManagerReportsGenerateInspectionUrlAction = guard<ManagerReportsGenerateInspectionUrlAction>(ManagerReportsGenerateInspectionUrlActionSchema as any);
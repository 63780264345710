import { ReactElement, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { SizeMe } from 'react-sizeme';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import FullScreenSpinner from './FullScreenSpinner';

const PDFDocumentWrapper = styled('div')(({ theme }) => ({
  '.react-pdf__Page': {
    marginBottom: theme.spacing(2),
  },
  '.react-pdf__Page__textContent': {
    width: '100% !important',
    height: '100% !important',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    borderRadius: theme.spacing(0.5),
  },
}));

export default function PDFReader({ url, loader }: { url: string; loader?: ReactElement }) {
  const [numPages, setNumPages] = useState(0);
  const file = useMemo(() => ({ url }), [url]);

  return (
    <SizeMe>
      {({ size }) => (
        <PDFDocumentWrapper>
          <Document
            file={file}
            onLoadSuccess={({ numPages: np }: { numPages: number }) => {
              setNumPages(np);
            }}
            renderMode="canvas"
            loading={loader ?? <FullScreenSpinner size={48} />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width ?? 100} />
            ))}
          </Document>
        </PDFDocumentWrapper>
      )}
    </SizeMe>
  );
}

import { z } from 'zod';

/**
 * Wraps a Zod schema to allow null values.
 * @param shape: The Zod schema to wrap.
 * @returns The Zod schema that allows nnd strips ull values.
 **/
export const maybe = <T extends z.ZodTypeAny>(shape: T) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  shape.nullish().transform((x) => x ?? undefined);

import { Reference } from '@apollo/client';
import {
  API,
  AddClearableInput,
  AddJournalEntryInput,
  BalanceType,
  ClearableFieldsFragmentDoc,
  GstInfo,
  JournalEntryFieldsFragment,
  ListClearablesQueryVariables,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  RecurrenceInput,
  useAddClearableMutation,
  useAddJournalEntryMutation,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { useAccountingContext } from 'pages/accounting/context';
import { useFinancialSettings } from 'pages/accounting/hooks';
import { useCallback, useRef } from 'react';
import { ensureArray } from 'system';
import { maybe } from 'system/shapes';
import { z } from 'zod';
import {
  calculateBalancedLines,
  clearablesFilterMatches,
  invalidateSubAccount,
  storeFieldNameArgs,
  updateReconciliationJournalsCache,
} from '../utils';
import { useGlAccounts } from './useGlAccounts';
import { useGlMapping } from './useGlMapping';

export type JELine = {
  id?: string;
  glId: string;
  ownerId: string;
  propertyId: string;
  unitId?: string;
  debit: number;
  credit: number;
  description?: string;
  gstInfo?: GstInfo;
  requestId?: string;
};

export const jeInputLineShape = z.object({
  id: maybe(z.string()),
  glId: z.string(),
  ref: maybe(z.string()),
  batchId: maybe(z.string()),
  clearableId: maybe(z.string()),
  unitId: maybe(z.string()),
  payee: maybe(z.nativeEnum(PayeeType)),
  payeeId: maybe(z.string()),
  debit: maybe(z.number()).default(0),
  credit: maybe(z.number()).default(0),
  description: maybe(z.string()),
  gstInfo: maybe(z.nativeEnum(GstInfo)),
  reconciliationId: maybe(z.string()),
});
export type JEInputLine = z.infer<typeof jeInputLineShape>;

export type JournalEntryFormFields = {
  posted: string;
  ownerId?: string;
  propertyId: string;
  clearableId?: string;
  lines: JEInputLine[];
  noteText?: string;
  recurrence?: RecurrenceInput;
  ref?: string;
};

export const useAddJournalEntries = () => {
  const { sendNotification } = useNotification();
  const { nextJournal, queryLoading } = useFinancialSettings();
  const { currentUser } = useCurrentUser();
  const { glAccounts } = useGlAccounts();
  const { anyClearableGlId } = useGlMapping();
  const { accountId } = useAuth();
  const { ownerFor } = useAccountingContext();
  const [addJournalEntryMutation, { error: jeError }] = useAddJournalEntryMutation();
  const [addClearableMutation, { error: clearableError }] = useAddClearableMutation();
  useAllErrors(jeError, clearableError);

  const addedJournalsRef = useRef<JournalEntryFieldsFragment[] | null>(null);
  const getAddedJournals = useCallback(() => {
    const { current: journals } = addedJournalsRef;
    addedJournalsRef.current = null;

    return journals;
  }, []);

  const addJournalEntries = async (
    values: JournalEntryFormFields,
    filesToUpload?: File[],
    uploadFiles?: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>,
    { notification = true }: { notification?: boolean } = {}
  ) => {
    const { balanced } = calculateBalancedLines(values.lines);

    if (!balanced) {
      if (notification) sendNotification('Journal entry is not balanced', 'error');
      return false;
    }

    const jeInput: AddJournalEntryInput = {
      posted: values.posted,
      lines: values.lines.map(
        ({
          glId,
          unitId,
          debit,
          credit,
          reconciliationId,
          payee,
          payeeId,
          description,
          gstInfo,
        }) => {
          const isDebitAccount =
            glAccounts.find((glAccount) => glAccount.id === glId)?.balanceType ===
            BalanceType.Debit;
          const isDebitAmount = debit !== 0;
          const isCreditAccount =
            glAccounts.find((glAccount) => glAccount.id === glId)?.balanceType ===
            BalanceType.Credit;
          const isCreditAmount = credit !== 0;

          const amount =
            isDebitAccount && isDebitAmount
              ? debit
              : isDebitAccount && isCreditAmount
                ? -credit
                : isCreditAccount && isCreditAmount
                  ? credit
                  : isCreditAccount && isDebitAmount
                    ? -debit
                    : 0;

          return {
            propertyId: values.propertyId,
            ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
            ref: values.ref !== undefined ? String(values.ref) : undefined,
            glId,
            amount,
            description,
            ...(payeeId && { payee, payeeId }),
            ...(gstInfo && { gstInfo }),
            ...(unitId && { unitId }),
            ...(reconciliationId && { reconciliationId }),
          };
        }
      ),
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };

    let newId: string | undefined = '';
    let newJeId: string | undefined = '';

    try {
      if (anyClearableGlId(...values.lines.map(({ glId }) => glId))) {
        const payeeLine = values.lines.find(({ payeeId }) => payeeId);
        const clearableInput: AddClearableInput = {
          ...jeInput,
          due: values.posted,
          payee: payeeLine?.payee ?? PayeeType.Account,
          payeeId: payeeLine?.payeeId ?? '',
        };

        const result = await addClearableMutation({
          variables: { input: clearableInput },
          update(cache, { data }) {
            const id = cache.identify({ accountId, __typename: 'Books' });
            if (data?.addClearable?.success && data.addClearable.clearable) {
              const cachedClearable = data.addClearable.clearable;
              const ref = cache.writeFragment({
                data: data.addClearable.clearable,
                fragment: ClearableFieldsFragmentDoc,
                fragmentName: API.Fragment.ClearableFields,
              });

              cache.modify({
                id,
                fields: {
                  listClearables: (existing = {}, { storeFieldName }) => {
                    const items = ensureArray<Reference[]>(existing.items);
                    const args = storeFieldNameArgs<ListClearablesQueryVariables>(storeFieldName);
                    const matches = clearablesFilterMatches(args.filter, cachedClearable);
                    return matches ? { ...existing, items: [...items, ref] } : existing;
                  },
                  payeeClearables: (existing = { items: [] }, { storeFieldName }) => {
                    const payeeId = cachedClearable.payeeId;
                    return payeeId && storeFieldName.includes(payeeId)
                      ? {
                          ...existing,
                          items: [...existing.items, ref],
                        }
                      : existing;
                  },
                },
              });

              const reconciledJournal = cachedClearable.journalEntries?.find(
                (l) => l.reconciliationId
              );

              if (reconciledJournal) {
                updateReconciliationJournalsCache(cache)(accountId, reconciledJournal);
              }
            }

            if (data?.addClearable?.success && data?.addClearable.recurrence) {
              cache.modify({
                id,
                fields: {
                  recurrences: (existing = []) => [
                    ...existing,
                    cache.writeFragment({
                      data: data.addClearable.recurrence,
                      fragment: RecurrenceFieldsFragmentDoc,
                      fragmentName: API.Fragment.RecurrenceFields,
                    }),
                  ],
                },
              });
            }

            if (data?.addClearable?.success) {
              const invalidator = invalidateSubAccount(cache);
              jeInput.lines.forEach((line) =>
                invalidator({
                  glId: line.glId,
                  ownerId: line.ownerId,
                  propertyId: line.propertyId,
                  posted: jeInput.posted,
                })
              );
            }
          },
        });

        newId = result.data?.addClearable?.clearable?.sourceJournalId;
        newJeId = result.data?.addClearable?.clearable?.sourceJournalEntry.jeId;
        addedJournalsRef.current = result.data?.addClearable.clearable?.journalEntries ?? null;
      } else {
        const result = await addJournalEntryMutation({
          variables: { input: jeInput },
          update(cache, { data }) {
            const id = cache.identify({ accountId, __typename: 'Books' });
            if (data?.addJournalEntry?.success && data?.addJournalEntry.recurrence) {
              cache.modify({
                id,
                fields: {
                  recurrences: (existing = []) => [
                    ...existing,
                    cache.writeFragment({
                      data: data.addJournalEntry.recurrence,
                      fragment: RecurrenceFieldsFragmentDoc,
                      fragmentName: API.Fragment.RecurrenceFields,
                    }),
                  ],
                },
              });
            }

            const reconciledJournal = data?.addJournalEntry.journalEntries?.find(
              (l) => l.reconciliationId
            );

            if (reconciledJournal) {
              updateReconciliationJournalsCache(cache)(accountId, reconciledJournal);
            }

            if (data?.addJournalEntry?.success) {
              const invalidator = invalidateSubAccount(cache);
              jeInput.lines.forEach((line) =>
                invalidator({
                  glId: line.glId,
                  ownerId: line.ownerId,
                  propertyId: line.propertyId,
                  posted: jeInput.posted,
                })
              );
            }
          },
        });

        newId = result.data?.addJournalEntry?.journalIds?.[0];
        newJeId = result.data?.addJournalEntry?.journalEntries?.[0]?.jeId;
        addedJournalsRef.current = result.data?.addJournalEntry.journalEntries ?? null;
      }
      if (notification) sendNotification('Journal entry has been posted', 'success');
    } catch (e) {
      console.error(e);
      if (notification) sendNotification('Validation error, journal entry not posted', 'error');
      return false;
    }

    try {
      if (newJeId && filesToUpload?.[0] && uploadFiles) {
        await uploadFiles({ id: newJeId });
      }
    } catch (e) {
      console.error(e);
      if (notification) sendNotification('There was an error uploading files', 'error');
      return newId;
    }

    return newId;
  };

  return {
    queryLoading,
    nextJournal,
    addJournalEntries,
    getAddedJournals,
  };
};

import clsx from 'clsx';
import { useRequestHeader } from 'hooks/useRequestHeader';
import { Link as RouterLink } from 'react-router-dom';

export default function RequestLink({
  requestId,
  className,
}: {
  requestId: string;
  className?: string;
}) {
  const { request } = useRequestHeader(requestId);
  return (
    <RouterLink to={`/requests/${requestId}`} className={clsx(className)}>
      {request?.name}
    </RouterLink>
  );
}

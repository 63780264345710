import { Box, BoxProps, Typography, Grid as _Grid } from '@mui/material';
import { styled } from '@mui/styles';
import Lottie, { Options } from 'react-lottie';
import defaultAnimationData from '../../components/icons/lotties/loadingBox.json';

const Grid = styled(_Grid)(() => ({
  height: 500,
}));

export default function LoadingBox({
  animationData,
  message = 'Moving in tenants...',
  options,
  sx,
  containerSx,
  absolute,
}: {
  animationData?: unknown;
  message?: string;
  options?: Omit<Options, 'animationData'>;
  sx?: BoxProps['sx'];
  containerSx?: BoxProps['sx'];
  absolute?: boolean;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      ...options?.rendererSettings,
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...options,
    animationData: animationData ?? defaultAnimationData,
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        ...containerSx,
        ...(absolute && {
          zIndex: 100,
          top: 0,
          left: 0,
          width: '100%',
          height: '100% !important',
          position: 'absolute',
          bgcolor: 'rgba(0, 0, 0, 0.15)',
        }),
      }}
    >
      <Box sx={sx}>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Typography variant="subtitle1" align="center" sx={{ mt: 1 }}>
          {message}
        </Typography>
      </Box>
    </Grid>
  );
}

import { FilterPanelPropsOverrides, GridFilterPanelProps, gridClasses } from '@mui/x-data-grid-pro';

export const gridFilterPanelStyles = (): Partial<
  GridFilterPanelProps & FilterPanelPropsOverrides
> => ({
  filterFormProps: {
    columnInputProps: {
      sx: { mt: 'auto' },
    },
    operatorInputProps: {
      sx: { mt: 'auto' },
    },
  },
  sx: {
    [`& .${gridClasses.filterForm}`]: { p: 2 },
    [`& .${gridClasses.filterFormLogicOperatorInput}`]: { mr: 2 },
    [`& .${gridClasses.filterFormColumnInput}`]: { mr: 2, width: 100 },
    [`& .${gridClasses.filterFormOperatorInput}`]: { mr: 2 },
    [`& .${gridClasses.filterFormValueInput}`]: { width: 240 },
  },
});

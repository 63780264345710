import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function BuildSpec({ className, ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 94 93" className={className} {...props}>
      <path
        d="M52.0864 31.7083V19.875H22.4825V73.125H81.6903V31.7083H52.0864ZM34.3241 67.2083H28.4033V61.2917H34.3241V67.2083ZM34.3241 55.375H28.4033V49.4583H34.3241V55.375ZM34.3241 43.5417H28.4033V37.625H34.3241V43.5417ZM34.3241 31.7083H28.4033V25.7917H34.3241V31.7083ZM46.1656 67.2083H40.2449V61.2917H46.1656V67.2083ZM46.1656 55.375H40.2449V49.4583H46.1656V55.375ZM46.1656 43.5417H40.2449V37.625H46.1656V43.5417ZM46.1656 31.7083H40.2449V25.7917H46.1656V31.7083ZM75.7695 67.2083H52.0864V61.2917H58.0072V55.375H52.0864V49.4583H58.0072V43.5417H52.0864V37.625H75.7695V67.2083ZM69.8488 43.5417H63.928V49.4583H69.8488V43.5417ZM69.8488 55.375H63.928V61.2917H69.8488V55.375Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <rect x="6" y="15" width="6" height="59" fill="#24D1AD" />
      <rect x="11" y="15" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="22" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="29" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="36" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="43" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="50" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="57" width="6" height="3" fill="#24D1AD" />
      <rect x="11" y="64" width="6" height="3" fill="#24D1AD" />
      <rect x="6" y="71" width="11" height="3" fill="#24D1AD" />
    </SvgIcon>
  );
}

import { ApolloCache } from '@apollo/client';
import {
  AccountType,
  ActivateBooksInput,
  ActivationGlAccountInput,
  GlAccountFieldsFragment,
  GlAccountFieldsFragmentDoc,
  useActivateBooksMutation,
} from 'api';
import { useAuth } from 'context';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useModalControl } from 'hooks/useModalControl';
import { useNotification } from 'hooks/useNotification';
import { useTaxes } from 'hooks/useTaxes';
import { difference, map, omit } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ensureArray } from 'system';
import { glCategories } from '../categories';

const starterAccounts = [
  {
    id: 1,
    name: 'Trust Bank',
    accountType: AccountType.Asset,
    category: glCategories.bank.id,
  },
  {
    id: 2,
    name: 'Operating Bank',
    accountType: AccountType.Asset,
    category: glCategories.bank.id,
  },
  {
    id: 3,
    name: 'Accounts Receivable',
    accountType: AccountType.Asset,
    category: glCategories.accountsReceivable.id,
  },
  {
    id: 4,
    name: 'Accounts Payable',
    accountType: AccountType.Liability,
    category: glCategories.accountsPayable.id,
  },
  {
    id: 5,
    name: 'Utilities Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 6,
    name: 'Repairs Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 7,
    name: 'Maintenance Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 8,
    name: 'Cleaning Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 9,
    name: 'Management Fees',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 10,
    name: 'Credit Card',
    accountType: AccountType.Liability,
    category: glCategories.creditCard.id,
  },
  {
    id: 11,
    name: 'Insurance Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 12,
    name: 'Rent Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 13,
    name: 'Storage Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 14,
    name: 'Parking Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },

  {
    id: 15,
    name: 'Other Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.otherRevenue.id,
  },
  {
    id: 16,
    name: 'Rent Incentives',
    accountType: AccountType.Revenue,
    category: glCategories.discounts.id,
  },
  {
    id: 17,
    name: 'Deposits Liability',
    accountType: AccountType.Liability,
    category: glCategories.currentLiabilities.id,
  },
  {
    id: 18,
    name: 'Income Summary',
    accountType: AccountType.Equity,
    category: glCategories.distributions.id,
  },
  {
    id: 19,
    name: 'Bank Charges',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 20,
    name: 'Condo Fees Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 21,
    name: 'Condo Fees Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 22,
    name: 'GST/HST Payable',
    accountType: AccountType.Liability,
    category: glCategories.currentLiabilities.id,
  },
  {
    id: 23,
    name: 'Billable Expense Cost',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 24,
    name: 'Billable Expense Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 25,
    name: 'Interest Expense',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 26,
    name: 'Maintenance Contra',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 27,
    name: 'Maintenance Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 28,
    name: 'Management Fee Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
  {
    id: 29,
    name: 'Opening Balance',
    accountType: AccountType.Equity,
    category: glCategories.contributions.id,
  },
  {
    id: 30,
    name: 'Bad Debt',
    accountType: AccountType.Expense,
    category: glCategories.operatingExpenses.id,
  },
  {
    id: 31,
    name: 'Owner Contributions',
    accountType: AccountType.Equity,
    category: glCategories.contributions.id,
  },
  {
    id: 32,
    name: 'Damages Revenue',
    accountType: AccountType.Revenue,
    category: glCategories.operatingRevenue.id,
  },
];

const pstStarterAccount = {
  name: 'PST Payable',
  accountType: AccountType.Liability,
  category: glCategories.currentLiabilities.id,
};

export const useActivateGlAccounts = () => {
  const { sendNotification } = useNotification();
  const navigate = useNavigate();
  const [activateBooksMutation, { error: activationError, loading: activationLoading }] =
    useActivateBooksMutation();
  useErrorNotifications(activationError);
  const [glAccounts, setGlAccounts] =
    useState<(ActivationGlAccountInput & { id: number })[]>(starterAccounts);
  const [handleShowModal, handleHideModal, isModalOpen, glAccountToEdit] = useModalControl<
    ActivationGlAccountInput & { id: number }
  >();
  const [handleShowWarningModal, handleHideWarningModal, isWarningModalOpen] = useModalControl();
  const { accountId } = useAuth();
  const { accountAppliesPst } = useTaxes();

  useEffect(() => {
    if (accountAppliesPst) {
      setGlAccounts((accounts) => [...accounts, { ...pstStarterAccount, id: accounts.length + 1 }]);
    } else {
      setGlAccounts((accounts) =>
        accounts.filter((glAccount) => glAccount.name !== pstStarterAccount.name)
      );
    }
  }, [accountAppliesPst]);

  const addGlAccount = (values: ActivationGlAccountInput) => {
    setGlAccounts([
      ...glAccounts,
      {
        id: glAccounts.length + 1,
        ...values,
      },
    ]);
    handleHideModal();
  };

  const deleteGlAccount = (id: number) => {
    const accounts = glAccounts.filter((glAccount) => glAccount.id !== id);
    setGlAccounts(
      accounts.map((account, index) => ({
        ...account,
        id: index,
      }))
    );
  };

  const showEditGlAccount = (id: number) => {
    handleShowModal(glAccounts.find((glAccount) => glAccount.id === id));
  };

  const editGlAccount = (values: ActivationGlAccountInput) => {
    setGlAccounts(
      glAccounts.map((glAccount) => {
        if (glAccount.id === glAccountToEdit?.id) {
          return {
            ...glAccount,
            ...values,
          };
        }
        return glAccount;
      })
    );
    handleHideModal();
  };

  const activateBooks = async () => {
    const input: ActivateBooksInput = {
      glAccounts: glAccounts.map((glAccount) => omit(glAccount, ['id'])),
    };

    const cacheGlAccount = (cache: ApolloCache<unknown>) => (glAccount: GlAccountFieldsFragment) =>
      cache.writeFragment({ data: glAccount, fragment: GlAccountFieldsFragmentDoc });

    try {
      await activateBooksMutation({
        variables: {
          input,
        },
        update(cache, { data }) {
          if (data?.activateBooks.books?.glAccounts) {
            cache.modify({
              id: cache.identify({ id: accountId, __typename: 'Account' }),
              fields: {
                books: () => ({
                  accountId,
                  __typename: 'Books',
                  glAccounts: ensureArray(data?.activateBooks.books?.glAccounts).map(
                    cacheGlAccount(cache)
                  ),
                }),
              },
            });
          }
        },
      });
      handleHideWarningModal();
      sendNotification('New chart of account has been created!', 'success');
      setTimeout(() => {
        navigate('/accounting/financial-settings-setup');
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  };

  const validationErrors = () => {
    const missingAccountTypes = difference(
      Object.values(AccountType),
      map(glAccounts, 'accountType')
    );

    const errors = [
      ...(glAccounts.length < 2 ? ['Minimum accounts of 2 required'] : []),
      ...(glAccounts.length > 49 ? ['Maximum 49 accounts at activation'] : []),
      ...missingAccountTypes.map((accountType) => `Must have at least one ${accountType} account`),
    ];

    return errors;
  };

  return {
    glAccounts,
    validationErrors,
    addGlAccount,
    deleteGlAccount,
    handleShowModal,
    handleHideModal,
    isModalOpen,
    showEditGlAccount,
    glAccountToEdit,
    editGlAccount,
    activateBooks,
    handleShowWarningModal,
    handleHideWarningModal,
    isWarningModalOpen,
    activationLoading,
  };
};

import { ReactiveVar } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { tenantsGridState } from 'cache';
import CopyToClipboard from 'components/CopyToClipboard';
import { CSSLinearProgress } from 'components/CSSLinearProgress';
import { ButtonContainer, CancelButton } from 'components/FormController/FormModalStyles';
import { GridSearchToolbar } from 'components/GridToolbar';
import { HasProfile } from 'components/HasProfile';
import { ItemList } from 'components/ItemList';
import { TenantRowModel } from 'hooks/useAccountTenants';
import { useGridState } from 'hooks/useGridState';
import { usePageSize } from 'hooks/usePageSize';
import { compact, keyBy, sortBy } from 'lodash';
import { Bubble } from 'pages/tenants/TenantsTable.styles';
import { useMemo } from 'react';
import { avatarInitials, ensureArray, formatPhone } from 'system';

type TenantsTableProps<TRowModel extends TenantRowModel> = {
  onSubmit?: (values: TRowModel[]) => void;
  onClose?: () => void;
  submitting?: boolean;
  tenants: TRowModel[];
  loading?: boolean;
  onRowClick?: (record: TRowModel) => void;
  selectedTenants?: GridRowSelectionModel;
  onSelectionChanged?: (selection: GridRowSelectionModel, rows: TRowModel[]) => void;

  gridState?: ReactiveVar<GridInitialStatePro>;
  extraColumns?: OrderableGridColumns<TRowModel>;
} & Omit<DataGridProProps, 'onRowClick' | 'loading' | 'rows' | 'columns'>;

type OrderableGridColumns<
  TModel extends GridValidRowModel,
  TGridColumns extends Array<GridColDef<TModel>> = Array<GridColDef<TModel>>,
> = TGridColumns extends Array<infer T> ? (T & { columnOrder?: number })[] : never;

export const TenantTable = <TRowModel extends TenantRowModel = TenantRowModel>({
  onSubmit,
  onClose,
  submitting,
  tenants,
  loading,
  selectedTenants,
  onSelectionChanged,
  extraColumns,
  onRowClick,
  gridState = tenantsGridState,
  ...gridProps
}: TenantsTableProps<TRowModel>) => {
  const { initialState, apiRef } = useGridState(gridState);

  const allowSelection = Boolean(onSelectionChanged);

  const tenantsById = useMemo(() => keyBy(tenants, 'id'), [tenants]);

  const columns = useMemo<OrderableGridColumns<TRowModel>>(
    () =>
      sortBy(
        compact([
          {
            columnOrder: 1,
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            renderCell({ value, row }) {
              return (
                <Stack direction="row" alignItems="center">
                  <Bubble>{avatarInitials(value)}</Bubble>
                  <Box>
                    <Typography fontWeight={500} variant="body2">
                      {value}
                    </Typography>
                    <HasProfile profileId={row.profileId} />
                    {row?.enabled === false && (
                      <Typography variant="caption" color="error.main">
                        Deactivated
                      </Typography>
                    )}
                  </Box>
                </Stack>
              );
            },
          },
          {
            columnOrder: 3,
            field: 'emails',
            headerName: 'Email',
            flex: 1.8,
            valueGetter: ({ value }: { value?: string[] }) => value?.join(', '),
            renderCell({ value = '' }: { value?: string }) {
              return (
                <Stack flexDirection="row" flexWrap="wrap" gap={0.5} alignItems="center">
                  <ItemList
                    max={1}
                    items={value?.split(',')}
                    renderItem={(email) => <CopyToClipboard textToCopy={email} />}
                  />
                </Stack>
              );
            },
          },
          {
            columnOrder: 5,
            field: 'phones',
            flex: 1,
            headerName: 'Phone Number',
            valueGetter: ({ value }: { value?: string[] }) => value?.map(formatPhone)?.join(', '),
            renderCell({ value = '' }: { value?: string }) {
              return (
                <Stack flexDirection="row" flexWrap="wrap" gap={0.5} alignItems="center">
                  <ItemList
                    max={1}
                    items={value?.split(',')}
                    renderItem={(phone) => <CopyToClipboard textToCopy={phone} />}
                  />
                </Stack>
              );
            },
          },
          allowSelection && {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            type: 'boolean',
            width: 90,
            columnOrder: 7,
            valueFormatter: ({ id }) => Boolean(id && selectedTenants?.includes(id)),
            renderHeader: () => 'Selected',
          },
          ...ensureArray(extraColumns),
        ]),
        (x, i) => x.columnOrder ?? i + 1
      ),
    [allowSelection, extraColumns, selectedTenants]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ height: 500 }}>
        <DataGridPro
          disableColumnReorder
          disableColumnPinning
          rowSelectionModel={selectedTenants}
          onRowSelectionModelChange={(newSelectionModel) => {
            onSelectionChanged?.(
              newSelectionModel,
              compact(newSelectionModel.map((id) => tenantsById[id]))
            );
          }}
          {...usePageSize()}
          {...gridProps}
          apiRef={apiRef}
          initialState={initialState}
          checkboxSelection={allowSelection}
          disableRowSelectionOnClick={!allowSelection}
          onRowClick={onRowClick && (({ row }) => onRowClick(row))}
          density="comfortable"
          sx={{ '& .MuiDataGrid-row': onRowClick ? { cursor: 'pointer' } : {} }}
          loading={loading}
          rows={tenants}
          columns={columns}
          slots={{
            toolbar: GridSearchToolbar,
            ...(tenants.length && loading && { LoadingOverlay: CSSLinearProgress }),
            ...gridProps.slots,
          }}
        />
      </Box>

      {(onSubmit || onClose) && (
        <ButtonContainer>
          {onClose && (
            <CancelButton onClick={onClose} loading={submitting}>
              Cancel
            </CancelButton>
          )}

          {onSubmit && (
            <LoadingButton
              loading={submitting}
              disabled={!selectedTenants?.[0]}
              type="submit"
              onClick={async () => {
                await onSubmit(compact(selectedTenants?.map((id) => tenantsById[id])));
                onClose?.();
              }}
            >
              Save
            </LoadingButton>
          )}
        </ButtonContainer>
      )}
    </Box>
  );
};

import { styled, TableRow as _TableRow, TableRowProps } from '@mui/material';
import { spreadIf } from 'system';
import { Column, Row } from '../types';
import EnhancedCell from './EnhancedCell';

type EnhancedRowProps = {
  row: Row;
  onRowClick?: (id: string) => void;
  columns: Column[];
  loading?: boolean;
  sx?: TableRowProps['sx'];
};

const TableRow = styled(_TableRow)(({ theme, onClick }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 80,
  ...spreadIf(onClick, { cursor: 'pointer' }),
}));

export default function EnhancedRow({ row, onRowClick, columns, loading, sx }: EnhancedRowProps) {
  return (
    <TableRow hover onClick={onRowClick ? () => onRowClick(row.id) : undefined} sx={sx}>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <EnhancedCell key={`${row.id}-${column.field}`} {...{ column, row, loading }} />
        ))}
    </TableRow>
  );
}

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl as FC,
  Skeleton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { PropertyListFieldsFragment } from 'api';
import { FullSizePaper as FSP } from 'components';
import { usePropertySelection } from 'hooks/usePropertySelection';
import { compact, find, sortBy } from 'lodash';
import { ensureArray } from 'system';

const FormControl = styled(FC)({
  width: '80%',
});

const FullSizePaper = styled(FSP)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 4),
  },
}));

const Option = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ActionButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(2),
    alignItems: 'center',
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '1em',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8em',
  },
}));

type PropertySelectionProps = {
  loading?: boolean;
  properties: PropertyListFieldsFragment[];
  selected: string[] | null;
  onChange: (propertyIds: string[]) => void;
};

export default function PropertySelection({
  loading,
  properties,
  selected,
  onChange,
}: PropertySelectionProps) {
  const { isAllSelected, handleChange, toggleIsSelectAll } = usePropertySelection({
    properties,
    selected,
    onChange,
  });
  const selectionLabel = isAllSelected ? 'Unselect All' : 'Select All';

  return (
    <FullSizePaper>
      <FormControl>
        <Autocomplete
          multiple
          loading={loading}
          limitTags={3}
          options={sortBy(properties, ['key', 'name'])}
          onChange={handleChange}
          value={compact(ensureArray(selected).map((id) => find(properties, { id })))}
          disableCloseOnSelect
          getOptionLabel={(property) => property?.name ?? ''}
          renderOption={(props, property) => (
            <li {...props} key={property?.id}>
              <Option key={property?.id}>
                <Checkbox
                  checked={selected?.includes(property?.id ?? '')}
                  color="primary"
                  size="small"
                />
                <Typography>{property?.name}</Typography>
              </Option>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={selected?.length === 0}
              helperText={selected?.length === 0 ? 'At least one property must be selected' : ''}
            />
          )}
        />
      </FormControl>

      {loading ? (
        <Skeleton width={100} />
      ) : (
        <ActionButton onClick={toggleIsSelectAll}>
          <Text>{selectionLabel}</Text>
        </ActionButton>
      )}
    </FullSizePaper>
  );
}

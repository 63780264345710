import { Box, BoxProps } from '@mui/material';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { PropsWithChildren, useEffect, useRef } from 'react';

export type AutoSizeBoxProps = PropsWithChildren<
  {
    bottomPadding?: number;
    minHeight?: number;
    onHeightChange?: (height: number) => unknown;
  } & Omit<BoxProps, 'ref'>
>;

export const AutoSizeBox = ({
  children,
  minHeight = 450,
  bottomPadding = 0,
  sx,
  onHeightChange,
  ...boxProps
}: AutoSizeBoxProps) => {
  const box = useRef<HTMLDivElement>();
  const { height, offsetTop } = useWindowDimensions(box);

  useEffect(() => {
    onHeightChange?.(Math.max(height - offsetTop - 60 - bottomPadding, minHeight));
  }, [bottomPadding, height, minHeight, offsetTop, onHeightChange]);

  useEffect(() => {
    return () => {
      box.current = undefined;
    };
  }, []);

  return (
    <Box
      {...boxProps}
      ref={box}
      sx={{
        ...sx,
        height: Math.max(height - offsetTop - 60 - bottomPadding, minHeight),
        minHeight,
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

import { useGetProvinceTaxDetailsLazyQuery } from 'api';
import { useCallback } from 'react';
import { ensureArray } from 'system';

export const useGetProvinceTaxDetails = () => {
  const [getTaxDetailsByProvince] = useGetProvinceTaxDetailsLazyQuery();

  const getTaxDetails = useCallback(
    async ({ province, taxExempted }: { province?: string; taxExempted?: boolean }) => {
      const { data } = await getTaxDetailsByProvince({
        variables: { input: { province, taxExempted } },
      });

      return ensureArray(data?.getProvinceTaxDetails?.taxDetails);
    },
    [getTaxDetailsByProvince]
  );

  return { getTaxDetails };
};

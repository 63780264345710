/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitUpdatedCostsEvent } from './updatedCosts';
import OperatorVisitUpdatedCostsEventSchema from './updatedCosts.json';

export const isOperatorVisitUpdatedCostsEvent = guard<OperatorVisitUpdatedCostsEvent>(OperatorVisitUpdatedCostsEventSchema as any);
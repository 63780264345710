/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOperatorRecalcEvent } from './operatorRecalc';
import ManagerOperatorRecalcEventSchema from './operatorRecalc.json';

export const isManagerOperatorRecalcEvent = guard<ManagerOperatorRecalcEvent>(ManagerOperatorRecalcEventSchema as any);
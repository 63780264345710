import { simpleHash } from '@propra-system/util/simpleHash';
import { ManagerRole, useTeamUnitLazyQuery, useTeamsAdminQuery } from 'api';
import { useAuth } from 'context';
import { compact, groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';
import { useErrorNotifications } from './useErrorNotifications';

export const useTeams = (props?: { skip?: boolean }) => {
  const { managerId, includesAnyRole } = useAuth();
  const { data, loading, error } = useTeamsAdminQuery({ skip: props?.skip });
  const managerTeams = useMemo(
    () => ensureArray(data?.account?.teams).filter((t) => t.managerIds?.includes(managerId)),
    [data?.account?.teams, managerId]
  );
  const isAdmin = includesAnyRole(ManagerRole.Admin, ManagerRole.BooksAdmin);
  const filterVisibility = !isAdmin && (loading || managerTeams.length > 0);

  useErrorNotifications(error);
  const managerPropertyIds = useMemo(
    () => new Set(managerTeams.flatMap((t) => t.propertyIds)),
    [managerTeams]
  );

  const propertyUnitIds = useMemo(() => {
    const allTeams = compact(managerTeams.flatMap((t) => t.propertyUnitIds));

    return new Map(
      Object.entries(groupBy(allTeams, 'propertyId')).map(([propertyId, unitIds]) => [
        propertyId,
        new Set(compact(unitIds.map((u) => u.unitId))),
      ])
    );
  }, [managerTeams]);

  const propertyOccupancies = useMemo(
    () =>
      [...managerPropertyIds].reduce(
        (result, propertyId) => ({
          ...result,
          [propertyId]: new Set(
            managerTeams
              .filter((t) => t.propertyIds.includes(propertyId))
              .flatMap((t) => t.occupancies)
          ),
        }),
        {} as Record<string, Set<string>>
      ),
    [managerTeams, managerPropertyIds]
  );

  const [teamUnit] = useTeamUnitLazyQuery();

  const isPropertyVisible = useCallback(
    (propertyId: string) =>
      !filterVisibility || (loading ? undefined : managerPropertyIds.has(propertyId)),
    [loading, managerPropertyIds, filterVisibility]
  );

  const isUnitIdVisible = useCallback(
    async (unitId: string) => {
      if (!filterVisibility) return true;

      const response = await teamUnit({ variables: { unitId } });
      const propertyId = response.data?.unit?.propertyId;
      if (!propertyId) return undefined;

      if ((propertyUnitIds.get(propertyId)?.size ?? 0) > 0) {
        return propertyUnitIds.get(propertyId)?.has(unitId);
      }

      const occupancy = response.data?.unit?.occupancy ?? 'rental';
      return Boolean(propertyOccupancies[propertyId]?.has(occupancy));
    },
    [filterVisibility, teamUnit, propertyUnitIds, propertyOccupancies]
  );

  const isOccupancyVisible = useCallback(
    ({
      id,
      propertyId,
      occupancy = 'rental',
    }: {
      id?: string;
      propertyId?: string;
      occupancy?: string;
    }) => {
      return (
        !filterVisibility ||
        (propertyId && !loading
          ? id && propertyUnitIds.get(propertyId)?.size
            ? propertyUnitIds.get(propertyId)?.has(id)
            : Boolean(propertyOccupancies[propertyId]?.has(occupancy))
          : undefined)
      );
    },
    [filterVisibility, loading, propertyUnitIds, propertyOccupancies]
  );

  const isOwnerVisible = useCallback(
    ({
      propertyIds,
      unitHeaders,
    }: {
      propertyIds?: Array<string>;
      unitHeaders?: Array<{
        id: string;
        propertyId: string;
        occupancy?: string;
      }>;
    }) =>
      !filterVisibility ||
      (ensureArray(propertyIds).length === 0 && ensureArray(unitHeaders).length === 0) ||
      ensureArray(propertyIds).some(isPropertyVisible) ||
      ensureArray(unitHeaders).some((header) => {
        if ((propertyUnitIds.get(header.propertyId)?.size ?? 0) > 0) {
          return propertyUnitIds.get(header.propertyId)?.has(header.id);
        }
        return isOccupancyVisible(header);
      }),
    [filterVisibility, isOccupancyVisible, isPropertyVisible, propertyUnitIds]
  );

  const isTenantVisible = useCallback(
    ({
      allResidencies,
    }: {
      allResidencies?: Array<{
        unit?: { id?: string; propertyId?: string; occupancy?: string };
      }>;
    }) =>
      !filterVisibility ||
      (allResidencies?.length ?? 0) === 0 ||
      ensureArray(allResidencies).some(({ unit }) => {
        if (!unit || !unit.propertyId || !unit.id) return false;
        if ((propertyUnitIds.get(unit.propertyId)?.size ?? 0) > 0) {
          return propertyUnitIds.get(unit.propertyId)?.has(unit.id);
        }
        return isOccupancyVisible(unit);
      }),
    [filterVisibility, isOccupancyVisible, propertyUnitIds]
  );

  const hash = useMemo(
    () =>
      filterVisibility
        ? simpleHash(
            managerTeams
              .map((team) => team.id)
              .sort()
              .join()
          )
        : undefined,
    [filterVisibility, managerTeams]
  );

  const withTeamHash = useCallback((s: string) => (hash ? `${s}:${hash}` : s), [hash]);

  return {
    isPropertyVisible,
    isUnitIdVisible,
    isOccupancyVisible,
    withTeamHash,
    isOwnerVisible,
    isTenantVisible,
    filterVisibility,
    managerPropertyIds,
  };
};

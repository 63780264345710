import { ClearableConnection, useUpdateClearableMutation } from 'api';
import { useAuth } from 'context';
import { useNotification } from 'hooks/useNotification';
import { keyBy } from 'lodash';
import { has } from 'lodash/fp';
import { useCallback } from 'react';
import { isClearablesFilter } from 'system';
import { clearablesFilterMatches, fromCreditDebitToAmount, storeFieldNameArgs } from '../utils';
import { JournalEntryFormFields } from './useAddJournalEntries';
import { useGlAccounts } from './useGlAccounts';

export type EditClearableFields = Omit<JournalEntryFormFields, 'noteText'> & {
  due: string;
  useEnrollment: boolean;
  enrollmentId?: string;
  availableEnrollmentId?: string;
  autopayCollectionsEnabled?: boolean;
};

export const useEditClearable = ({ clearableId }: { clearableId: string }) => {
  const { accountId } = useAuth();
  const { findGlAccount } = useGlAccounts();
  const { sendNotification } = useNotification();
  const [editClearableMutation, { loading }] = useUpdateClearableMutation();

  const editClearable = useCallback(
    async ({
      posted,
      due,
      ref,
      propertyId,
      lines,
      ownerId = 'invalid',
      useEnrollment,
      enrollmentId,
      availableEnrollmentId,
      autopayCollectionsEnabled,
    }: EditClearableFields) => {
      try {
        const { payee, payeeId } = lines.find(has('payeeId')) || {};

        await editClearableMutation({
          variables: {
            input: {
              id: clearableId,
              due,
              posted,
              payee,
              payeeId,
              enrollmentId: useEnrollment ? (enrollmentId ?? availableEnrollmentId) : '',
              autopayCollectionsEnabled: autopayCollectionsEnabled ?? true,
              lines: lines.map(({ debit, credit, ...line }) => ({
                ...line,
                ref,
                ownerId,
                propertyId,
                amount: fromCreditDebitToAmount({ debit, credit }, findGlAccount(line.glId)),
              })),
            },
          },
          update(cache, { data }) {
            if (data?.updateClearable?.success && data.updateClearable.clearable) {
              const clearable = data.updateClearable.clearable;
              const journalsById = keyBy(clearable.journalEntries, 'id');

              Object.entries(journalsById).forEach(([journalId]) => {
                cache.evict({ id: cache.identify({ id: journalId, __typename: 'JournalEntry' }) });

                cache.modify({
                  id: cache.identify({ __typename: 'Books', accountId }),
                  fields: {
                    countClearables: (_, { DELETE }) => DELETE,
                    listClearables(
                      connection: ClearableConnection,
                      { storeFieldName, readField, toReference }
                    ) {
                      const filterArgs = storeFieldNameArgs(storeFieldName);
                      const { filter } = isClearablesFilter(filterArgs)
                        ? filterArgs
                        : { filter: undefined };
                      const oldClearable = connection.items?.find(
                        (c) => readField('id', c) === clearableId
                      );

                      const items = clearablesFilterMatches(filter, clearable)
                        ? connection.items?.map((c) =>
                            readField('id', c) === clearableId ? toReference(clearable) : c
                          )
                        : oldClearable && !clearablesFilterMatches(filter, clearable)
                          ? connection.items?.filter((c) => readField('id', c) !== clearableId)
                          : connection.items;

                      return { ...connection, items };
                    },
                  },
                });
              });

              cache.gc();
            }
          },
        });
      } catch (e) {
        sendNotification(e instanceof Error ? e.message : JSON.stringify(e), 'error');
        throw e;
      }
    },
    [editClearableMutation, clearableId, findGlAccount, accountId, sendNotification]
  );

  return { loading, editClearable };
};

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { List, Paper as P, styled } from '@mui/material';
import { EnhancedTableState } from 'cache';
import { ReactNode } from 'react';
import { skipProps, spreadIf } from 'system';
import { Column, Row } from '../types';
import EnhancedMobileRow from './EnhancedMobileRow';
import EnhancedMobileRowPlaceholder from './EnhancedMobileRowPlaceholder';
import EnhancedTableToolbar from './EnhancedTableToolbar';

const Root = styled('div')({
  width: '100%',
});

const Paper = styled(
  P,
  skipProps('hideBorder')
)<{ hideBorder?: boolean }>(({ theme, hideBorder }) => ({
  width: '100%',
  border: 0,
  ...spreadIf(!hideBorder, {
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderBottom: 'none',
  }),
}));

export type EnhancedMobileTableProps = {
  rows: Row[];
  columns: Column[];
  onRowClick?: (id: string) => void;
  allowSearch?: boolean;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode | ((obj: { rows: Row[] }) => JSX.Element);
  footerComponent?: ReactNode;
  renderMobileRow?: (params: { id: string; value: Row }) => ReactNode;
  loading?: boolean;
  hideBorder?: boolean;
};

export default function EnhancedMobileTable({
  rows,
  columns,
  onRowClick,
  allowSearch = false,
  reactiveState,
  toolbarComponent,
  footerComponent,
  renderMobileRow,
  loading,
  hideBorder,
}: EnhancedMobileTableProps) {
  const state = useReactiveVar(reactiveState);
  const { search } = state;

  return (
    <Root>
      <Paper elevation={0} hideBorder={hideBorder}>
        {Boolean(allowSearch || toolbarComponent) && (
          <EnhancedTableToolbar
            {...{
              allowSearch,
              search,
              updateSearch: (searchText: string) =>
                reactiveState({ ...state, search: searchText, page: 0 }),
              toolbarComponent,
              rows,
            }}
          />
        )}
        {loading ? (
          <List sx={{ p: 0 }}>
            {[1, 2, 3].map((i) => (
              <EnhancedMobileRowPlaceholder key={i} columns={columns} />
            ))}
          </List>
        ) : (
          <List sx={{ p: 0 }}>
            {rows.map((row) => (
              <EnhancedMobileRow key={row.id} {...{ row, onRowClick, columns, renderMobileRow }} />
            ))}
          </List>
        )}
        {footerComponent && <div>{footerComponent}</div>}
      </Paper>
    </Root>
  );
}

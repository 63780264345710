import { ReactNode, createContext, useContext, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const Idle = createContext<boolean>(false);
export const useIdle = () => useContext(Idle);

export const IdleProvider = ({ children }: { children: ReactNode }) => {
  const [isIdle, setIsIdle] = useState(false);

  useIdleTimer({
    onIdle: () => setIsIdle(true),
    onActive: () => setIsIdle(false),
  });

  return <Idle.Provider value={isIdle}>{children}</Idle.Provider>;
};

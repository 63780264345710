import { Box, Divider as MaterialUIDivider } from '@mui/material';
import { Children } from 'system';

export default function Divider({ children }: Children) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-around">
      <MaterialUIDivider sx={{ flexGrow: 1 }} />
      {children}
      <MaterialUIDivider sx={{ flexGrow: 1 }} />
    </Box>
  );
}

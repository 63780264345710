import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export default function File({ ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon width="94" viewBox="0 0 94 94" {...props}>
      <path
        d="M39.166 78.334V58.7507H23.4993V15.6673H70.4994V47.0007H78.3327V15.6673C78.3327 11.359 74.8077 7.83398 70.4994 7.83398H23.4993C19.191 7.83398 15.666 11.359 15.666 15.6673V62.6673L39.166 86.1673V78.334Z"
        fill={theme.palette.tertiary.main}
      />
      <path d="M62.6654 23.5H31.332V31.3333H62.6654V23.5Z" fill={theme.palette.tertiary.main} />
      <path d="M62.6654 39.168H31.332V47.0013H62.6654V39.168Z" fill={theme.palette.tertiary.main} />
    </SvgIcon>
  );
}

import { Grid, GridProps, Skeleton, Typography, TypographyProps } from '@mui/material';

type BodyTextProps = TypographyProps & {
  loading?: boolean;
  item?: boolean;
  itemProps?: GridProps;
};

export default function BodyText({
  loading = false,
  item = false,
  itemProps = {},
  children,
  ...props
}: BodyTextProps) {
  const text = (
    <Typography sx={{ overflow: 'hidden' }} {...props}>
      {loading ? <Skeleton /> : children}
    </Typography>
  );
  return item ? (
    <Grid item sx={{ overflow: 'hidden' }} {...itemProps}>
      {text}
    </Grid>
  ) : (
    text
  );
}

import { OpenSearchTaskResult, useGetOpenSearchTaskQuery } from 'api';
import { useMeta } from 'hooks/useMeta';
import { useEffect, useMemo, useState } from 'react';
import { safeRound, safeSum } from 'system';

const getBatchProgress = (
  result?: OpenSearchTaskResult
): {
  done?: boolean;
  progress: number;
  batchId?: string;
  updatedZ?: string;
} => {
  if (!result) {
    return { progress: 0, done: false };
  }

  const { task, completed: done } = result;
  const processed = safeSum(task.status.created, task.status.updated, task.status.deleted);
  const progress = safeRound((processed / Math.max(task.status.total, 1)) * 100);

  return {
    done,
    progress,
    batchId: task.id,
  };
};

export const useOpenSearchTask = ({
  id = '',
  untilCompleted,
  pollInterval = 5000,
  skip = false,
}: {
  id?: string;
  pollInterval?: number;
  untilCompleted?: boolean;
  skip?: boolean;
}) => {
  const [shouldPoll, setShouldPoll] = useState(untilCompleted);
  useEffect(() => {
    setShouldPoll(untilCompleted);
  }, [untilCompleted]);

  const { data, ...meta } = useGetOpenSearchTaskQuery({
    variables: { id },
    skip: !id || skip,
    notifyOnNetworkStatusChange: true,
  });
  const { loading } = useMeta(meta);

  useEffect(() => {
    if (shouldPoll && !data?.opensearch?.task?.completed) {
      meta.startPolling(pollInterval);
    } else {
      meta.stopPolling();
    }
  }, [data?.opensearch?.task?.completed, meta, pollInterval, shouldPoll]);

  const taskProgress = useMemo(
    () => (skip ? { progress: 100, done: true } : getBatchProgress(data?.opensearch?.task)),
    [data?.opensearch?.task, skip]
  );

  return {
    task: data?.opensearch?.task,
    loading,

    stopPolling: meta.stopPolling,
    startPolling: meta.startPolling,
    taskProgress,
  };
};

import { gql, Reference, useApolloClient } from '@apollo/client';
import {
  isManagerApprovalPendingEvent,
  isManagerBooksBatchFailedError,
  isManagerBooksBatchPreparedEvent,
  isManagerBooksClearableAddedEvent,
  isManagerBooksClearableUpdatedEvent,
  isManagerBooksDboPostedEvent,
  isManagerBooksJournalClearableUpdatedEvent,
  isManagerBooksJournalDeletedEvent,
  isManagerBooksJournalPostedEvent,
  isManagerBooksJournalUpdatedEvent,
  isManagerBooksJournalVoidedEvent,
  isManagerBooksReconciliationFailedEvent,
  isManagerBooksReconciliationPreparedEvent,
  isManagerBooksReconciliationStartedEvent,
  isManagerBooksReportUpdatedEvent,
} from '@propra-manager/registry';
import { guardHandler, HandlerMap } from '@propra-system/registry';
import {
  GlAccountCombinedSubAccountArgs,
  JournalPostedDetailFragment,
  JournalPostedDetailFragmentDoc,
  PayeeType,
  ReconciliationFieldsFragmentDoc,
  useGetReconciliationLazyQuery,
} from 'api';
import { useAuth, useBalanceCache, useEvents } from 'context';
import equal from 'fast-deep-equal';
import { compact, uniq, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { ensureArray, graphqlNull, insertOrReplace, invalidate, safeSum } from 'system';
import {
  balanceAffected,
  invalidateSubAccount,
  storeFieldNameArgs,
  subAccountMatches,
  updateReconciliationJournalsCache,
} from '../utils';
import { useClearableCache } from './cache';
import { useGlAccounts } from './useGlAccounts';

type AccountingEventsEnabled =
  | boolean
  | ('reconciliation' | 'journal' | 'batch' | 'reports' | 'approval')[];

type CombinedSubAccountCache =
  | { balance?: number; journalEntries?: { items?: Reference[] } }
  | undefined;

const isEnabledFor =
  (enabled?: AccountingEventsEnabled) =>
  (value: Exclude<AccountingEventsEnabled, boolean>[number]) =>
    Array.isArray(enabled) ? enabled.includes(value) : !!enabled;

export const useAccountingEvents = (enable?: AccountingEventsEnabled) => {
  const { addHandlerMap, removeHandlerMap } = useEvents();
  const { isBalanceSheetAccount } = useGlAccounts();
  const client = useApolloClient();
  const { cache } = client;
  const { accountId } = useAuth();
  const cachedBooks = cache.identify({ accountId, __typename: 'Books' });

  const { updateClearableCache } = useClearableCache();
  const [getReconciliation] = useGetReconciliationLazyQuery();

  const invalidateJournalsCache = useMemo(() => invalidateSubAccount(cache), [cache]);
  const cacheReconciliationJournals = useMemo(
    () => updateReconciliationJournalsCache(cache),
    [cache]
  );

  const { enqueueBalanceUpdate, onBalanceUpdated, dequeueBalanceUpdate } = useBalanceCache();

  useEffect(() => {
    const update = dequeueBalanceUpdate();
    if (update) {
      const { input: updateSubAccount } = storeFieldNameArgs<GlAccountCombinedSubAccountArgs>(
        update.storeFieldName
      );

      if (updateSubAccount) {
        cache.modify({
          id: cache.identify({ id: update.glId, __typename: 'GLAccount' }),
          fields: {
            combinedSubAccount: (existing: CombinedSubAccountCache, { storeFieldName }) => {
              return storeFieldName === update.storeFieldName
                ? {
                    ...existing,
                    balance: safeSum(existing?.balance ?? 0, update.adjustmentAmount),
                  }
                : existing;
            },
          },
        });
      } else {
        console.error({ message: 'Error parsing sub account details for cache update', update });
      }

      onBalanceUpdated(update);
    }
  }, [enqueueBalanceUpdate, onBalanceUpdated, dequeueBalanceUpdate, cache]);

  const removeJournalFromSubAccounts = (event: {
    id: string;
    journalId: string;
    posted: string;
    glId: string;
    ownerId: string;
    propertyId: string;
    amount: number;
  }) => {
    cache.modify({
      id: cache.identify({ id: event.glId, __typename: 'GLAccount' }),
      fields: {
        combinedSubAccount: (existing: CombinedSubAccountCache, { storeFieldName, readField }) => {
          const args = storeFieldNameArgs<GlAccountCombinedSubAccountArgs>(storeFieldName);
          if (
            isBalanceSheetAccount(event.glId) &&
            args.input &&
            balanceAffected(args.input, event)
          ) {
            enqueueBalanceUpdate({
              id: [event.id, storeFieldName].join(),
              glId: event.glId,
              storeFieldName,
              adjustmentAmount: -event.amount,
            });
          }

          if (args.input && subAccountMatches(args.input, event)) {
            cache.evict({
              id: cache.identify({ id: event.journalId, __typename: 'JournalEntry' }),
            });
            cache.gc();

            return {
              ...existing,
              journalEntries: {
                ...existing?.journalEntries,
                items: ensureArray(existing?.journalEntries?.items).filter(
                  (ref) => readField('id', ref) !== event.journalId
                ),
              },
            };
          }
          return existing;
        },
      },
    });
  };

  const addJournalToSubAccounts = (event: JournalPostedDetailFragment & { journalId: string }) => {
    cache.modify({
      id: cache.identify({ id: event.glId, __typename: 'GLAccount' }),
      fields: {
        combinedSubAccount: (existing: CombinedSubAccountCache, { storeFieldName }) => {
          const args = storeFieldNameArgs<GlAccountCombinedSubAccountArgs>(storeFieldName);
          if (
            isBalanceSheetAccount(event.glId) &&
            args.input &&
            balanceAffected(args.input, event)
          ) {
            enqueueBalanceUpdate({
              id: [event.id, storeFieldName].join(),
              glId: event.glId,
              storeFieldName,
              adjustmentAmount: event.amount,
            });
          }

          if (args.input && subAccountMatches(args.input, event)) {
            client.writeFragment({
              id: cache.identify({
                id: event.journalId,
                __typename: 'JournalEntry',
              }),
              data: {
                __typename: 'JournalEntry',
                id: event.journalId,
                journalId: event.journalId,
                posted: event.posted,
                glId: event.glId,
                ownerId: event.ownerId,
                propertyId: event.propertyId,
                amount: event.amount,
                lines: event.lines.map((l) => ({
                  __typename: 'JournalEntryLine',
                  id: l.id,
                  glId: l.glId,
                  ownerId: l.ownerId,
                  propertyId: l.propertyId,
                  unitId: l.unitId ?? graphqlNull,
                  amount: l.amount,
                  description: l.description,
                  ref: String(l.ref),
                  clearableId: l.clearableId ?? graphqlNull,
                  requestId: l.requestId ?? graphqlNull,
                  payee: l.payee as PayeeType,
                  payeeId: l.payeeId,
                })),
                jeId: event.jeId ?? '',
                journalIds: event.journalIds ?? graphqlNull,
                clearableId: event.clearableId ?? graphqlNull,
                unitId: event.unitId ?? graphqlNull,
                description: event.description ?? graphqlNull,
                ref:
                  event.ref !== undefined && event.ref !== null ? String(event.ref) : graphqlNull,
              },
              fragment: JournalPostedDetailFragmentDoc,
            });
            const ref = {
              __ref: cache.identify({ id: event.journalId, __typename: 'JournalEntry' }),
            };

            return {
              ...existing,
              journalEntries: {
                ...existing?.journalEntries,
                items: uniqBy([ref, ...ensureArray(existing?.journalEntries?.items)], '__ref'),
              },
            };
          }
          return existing;
        },
      },
    });
  };

  useEffect(
    () => {
      const isEnabled = isEnabledFor(enable);

      const journalEvents = [
        guardHandler(isManagerBooksJournalClearableUpdatedEvent, async ({ detail }) => {
          const { newClearableId, oldClearableId } = detail;
          updateClearableCache(uniq(compact([newClearableId, oldClearableId])));
        }),
        guardHandler(isManagerBooksClearableAddedEvent, async ({ detail: { clearable } }) => {
          updateClearableCache([clearable.id]);
        }),
        guardHandler(isManagerBooksClearableUpdatedEvent, async ({ detail: { newClearable } }) => {
          updateClearableCache([newClearable.id]);
        }),
        guardHandler(isManagerBooksJournalUpdatedEvent, async ({ id: eventId = '', detail }) => {
          const id = cache.identify({ id: detail.journalId, __typename: 'JournalEntry' });
          cache.modify({
            id,
            fields: {
              posted: () => detail.posted,
              amount: () => detail.amount,
              ...(detail.clearableId && { clearableId: () => detail.clearableId }),
              glId: () => detail.glId,
              ownerId: () => detail.ownerId,
              propertyId: () => detail.propertyId,
              ...(detail.unitId && { unitId: () => detail.unitId }),
              ...(detail.reconciliationId && { reconciliationId: () => detail.reconciliationId }),
              ...(detail.description && { description: () => detail.description }),
              lines: () => detail.lines,
              ...(detail.notes && { notes: () => detail.notes }),
              ...(detail.ref && { ref: () => detail.ref }),
              journalIds: () => detail.journalIds,
            },
          });

          const newJournal = {
            id: `new-${eventId}`,
            journalId: detail.journalId,
            posted: detail.posted,
            glId: detail.glId,
            ownerId: detail.ownerId,
            propertyId: detail.propertyId,
            amount: detail.amount,
            lines: detail.lines.map((l) => ({
              id: l.id,
              glId: l.glId,
              ownerId: l.ownerId,
              propertyId: l.propertyId,
              unitId: l.unitId ?? graphqlNull,
              amount: l.amount,
              description: l.description,
              ref: String(l.ref),
              clearableId: l.clearableId ?? graphqlNull,
              requestId: l.requestId ?? graphqlNull,
              payee: l.payee as PayeeType,
              payeeId: l.payeeId,
            })),
            jeId: detail.jeId ?? '',
            journalIds: detail.journalIds ?? graphqlNull,
            clearableId: detail.clearableId ?? graphqlNull,
            unitId: detail.unitId ?? graphqlNull,
            description: detail.description ?? graphqlNull,
            ref: detail.ref !== undefined && detail.ref !== null ? String(detail.ref) : graphqlNull,
          };

          const oldJournal = {
            id: `old-${eventId}`,
            journalId: detail.journalId,
            posted: detail.posted,
            glId: detail.oldGlId,
            ownerId: detail.oldOwnerId,
            propertyId: detail.oldPropertyId,
            amount: detail.oldAmount,
            lines: detail.oldLines.map((l) => ({
              id: l.id,
              glId: l.glId,
              ownerId: l.ownerId,
              propertyId: l.propertyId,
              unitId: l.unitId ?? graphqlNull,
              amount: l.amount,
              description: l.description,
              ref: String(l.ref),
              clearableId: l.clearableId ?? graphqlNull,
              requestId: l.requestId ?? graphqlNull,
              payee: l.payee as PayeeType,
              payeeId: l.payeeId,
            })),
            jeId: detail.oldJeId ?? '',
            journalIds: detail.oldJournalIds ?? graphqlNull,
            clearableId: detail.oldClearableId ?? graphqlNull,
            unitId: detail.oldUnitId ?? graphqlNull,
            description: detail.oldDescription ?? graphqlNull,
            ref:
              detail.oldRef !== undefined && detail.oldRef !== null
                ? String(detail.oldRef)
                : graphqlNull,
          };

          if (
            !equal(
              {
                glId: newJournal.glId,
                ownerId: newJournal.ownerId,
                propertyId: newJournal.propertyId,
                amount: newJournal.amount,
              },
              {
                glId: oldJournal.glId,
                ownerId: oldJournal.ownerId,
                propertyId: oldJournal.propertyId,
                amount: oldJournal.amount,
              }
            )
          ) {
            removeJournalFromSubAccounts(oldJournal);
            addJournalToSubAccounts(newJournal);
          }

          cacheReconciliationJournals(accountId, { id: detail.journalId, ...detail });
        }),
      ];

      const defaultEvents = [
        guardHandler(isManagerBooksJournalDeletedEvent, async ({ id = '', detail }) => {
          detail.clearableId && updateClearableCache([detail.clearableId]);

          removeJournalFromSubAccounts({ ...detail, id });
        }),
        guardHandler(isManagerBooksJournalVoidedEvent, async ({ detail }) => {
          detail.clearableId && updateClearableCache([detail.clearableId]);
        }),
        guardHandler(isManagerBooksJournalPostedEvent, async ({ id = '', detail }) => {
          addJournalToSubAccounts({
            id,
            journalId: detail.journalId,
            jeId: detail.jeId ?? '',
            journalIds: detail.journalIds ?? [],
            glId: detail.glId,
            propertyId: detail.propertyId,
            ownerId: detail.ownerId,
            posted: detail.posted,
            amount: detail.amount,
            lines: detail.lines.map((l) => ({
              id: l.id,
              glId: l.glId,
              ownerId: l.ownerId,
              propertyId: l.propertyId,
              unitId: l.unitId ?? graphqlNull,
              amount: l.amount,
              description: l.description ?? graphqlNull,
              ref: l.ref !== undefined && l.ref !== null ? String(l.ref) : graphqlNull,
              clearableId: l.clearableId ?? graphqlNull,
              requestId: l.requestId ?? graphqlNull,
              payee: (l.payee ?? graphqlNull) as PayeeType | undefined,
              payeeId: l.payeeId ?? graphqlNull,
            })),
          });

          cacheReconciliationJournals(accountId, { id: detail.journalId, ...detail });
        }),
      ];

      const batchEvents = [
        guardHandler(isManagerBooksBatchPreparedEvent, async ({ detail: { batchId } }) => {
          cache.modify({
            id: cache.identify({ id: batchId, __typename: 'Batch' }),
            fields: {
              status: () => 'PREPARED',
            },
          });
        }),
        guardHandler(
          isManagerBooksBatchFailedError,
          async ({ detail: { batchId, errorMessage } }) => {
            cache.modify({
              id: cache.identify({ id: batchId, __typename: 'Batch' }),
              fields: {
                status: () => 'FAILED',
                errorMessage: () => errorMessage,
              },
            });
          }
        ),
        guardHandler(isManagerBooksDboPostedEvent, async ({ detail }) => {
          cache.modify({
            id: cache.identify({ id: detail.batchId, __typename: 'Batch' }),
            fields: {
              status: () => 'POSTED',
            },
          });
        }),
      ];

      const reconciliationEvents = [
        guardHandler(
          isManagerBooksReconciliationStartedEvent,
          async ({ detail: { reconciliationId } }) => {
            const { data } = await getReconciliation({ variables: { id: reconciliationId } });

            const ref = cache.writeFragment({
              data: { ...data?.reconciliation, status: 'STARTED' },
              fragment: ReconciliationFieldsFragmentDoc,
              fragmentName: 'ReconciliationFields',
            });

            cache.modify({
              id: cachedBooks,
              fields: {
                reconciliations: (existing: Reference[] | undefined = [], { readField }) =>
                  insertOrReplace(
                    existing,
                    (reference) => readField('id', reference) === reconciliationId,
                    ref
                  ),
              },
            });
          }
        ),
        guardHandler(
          isManagerBooksReconciliationPreparedEvent,
          async ({ detail: { reconciliationId } }) => {
            cache.modify({
              id: cache.identify({ id: reconciliationId, __typename: 'Reconciliation' }),
              fields: {
                status: () => 'PREPARED',
              },
            });
          }
        ),
        guardHandler(
          isManagerBooksReconciliationFailedEvent,
          async ({ detail: { reconciliationId, errorMessage } }) => {
            cache.modify({
              id: cache.identify({ id: reconciliationId, __typename: 'Reconciliation' }),
              fields: {
                status: () => 'FAILED',
                errorMessage: () => errorMessage,
              },
            });
          }
        ),
      ];

      const reportEvents = [
        guardHandler(isManagerBooksReportUpdatedEvent, async () => {
          cache.modify({
            id: cachedBooks,
            fields: {
              bundledReports: invalidate,
            },
          });
        }),
      ];

      const approvalEvents = [
        guardHandler(isManagerApprovalPendingEvent, async ({ detail }) => {
          const { approvalId: id, status } = detail;
          const ref = cache.writeFragment({
            data: { __typename: 'Approval', id, accountId, status },
            fragment: gql`
              fragment ApprovalFragment on Approval {
                id
                accountId
                status
              }
            `,
          });
          cache.modify({
            id: cache.identify({ id: accountId, __typename: 'Account' }),
            fields: {
              listActiveApprovals: (existing = {}) => {
                const { items = [] } = existing;
                return {
                  ...existing,
                  items: [...new Set([...items, ref])],
                };
              },
            },
          });
        }),
      ];

      const activeHandlerMap = <HandlerMap>[
        ...defaultEvents,
        ...(isEnabled('batch') ? batchEvents : []),
        ...(isEnabled('journal') ? journalEvents : []),
        ...(isEnabled('reconciliation') ? reconciliationEvents : []),
        ...(isEnabled('reports') ? reportEvents : []),
        ...(isEnabled('approval') ? approvalEvents : []),
      ];

      const inactiveHandlerMap = <HandlerMap>[
        ...(!isEnabled('batch') ? batchEvents : []),
        ...(!isEnabled('journal') ? journalEvents : []),
        ...(!isEnabled('reconciliation') ? reconciliationEvents : []),
        ...(!isEnabled('reports') ? reportEvents : []),
        ...(!isEnabled('approval') ? approvalEvents : []),
      ];

      addHandlerMap(activeHandlerMap);
      removeHandlerMap(inactiveHandlerMap);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache, cachedBooks, enable, invalidateJournalsCache]
  );
};

import { Box, Skeleton, Theme, useTheme } from '@mui/material';
import { Chip, ChipProps } from 'components';
import { colours } from 'system';

const color = (theme: Theme, status: string) =>
  ({
    DISABLED: colours.white,
    DEACTIVATED: colours.white,
    DELETED: colours.white,
    CANCELLED: colours.white,
    COMPLETED: colours.nero,
    ENABLED: colours.nero,
    'NEEDS ATTENTION': theme.palette.background.default,
    'NEW VISIT REQUESTED': theme.palette.background.default,
    COMMERCIAL: theme.palette.warning.main,
    INACTIVE: theme.palette.background.default,
    SUBMITTED: theme.palette.common.white,
    APPROVAL: theme.palette.common.white,
    APPROVED: theme.palette.common.white,
    DECLINED: theme.palette.common.black,
    FAILED: theme.palette.common.white,
    INVITED: theme.palette.common.white,
    SUSPENDED: theme.palette.common.white,
    NSF: theme.palette.common.white,
    INCOMPLETE: theme.palette.warning.main,
    ACTIVATED: theme.palette.common.white,
    NOT_INVITED: theme.palette.common.black,
  })[status] ?? theme.palette.text.primary;

const backgroundColor = (theme: Theme, status: string) =>
  ({
    COMPLETED: colours.shamrock,
    ENABLED: colours.shamrock,
    DISABLED: colours.mahogany,
    DEACTIVATED: colours.mahogany,
    DELETED: colours.mahogany,
    CANCELLED: colours.mahogany,
    'NEEDS ATTENTION': theme.palette.primary.main,
    'NEW VISIT REQUESTED': theme.palette.quaternary.main,
    ACTIVE: colours.shamrock,
    INACTIVE: theme.palette.primary.main,
    CLOSED: colours.gainsboro,
    PAUSED: colours.gainsboro,
    SUBMITTED: theme.palette.primary.main,
    APPROVAL: theme.palette.primary.main,
    APPROVED: theme.palette.success.main,
    DECLINED: theme.palette.action.disabledBackground,
    FAILED: theme.palette.error.main,
    INVITED: theme.palette.primary.main,
    SUSPENDED: theme.palette.error.main,
    NSF: theme.palette.error.main,
    ACTIVATED: theme.palette.secondary.main,
    NOT_INVITED: theme.palette.action.disabledBackground,
  })[status] ?? 'transparent';

const borderColor = (theme: Theme, status: string) =>
  ({
    DEFAULT: theme.palette.text.secondary,
    CONFIRMED: theme.palette.primary.main,
    SCHEDULED: theme.palette.primary.main,
    COMPLETED: colours.shamrock,
    DISABLED: colours.mahogany,
    DEACTIVATED: colours.mahogany,
    ENABLED: colours.shamrock,
    EMPTY: theme.palette.text.secondary,
    LEASED: theme.palette.primary.main,
    COMMERCIAL: theme.palette.warning.main,
    ADMIN: theme.palette.primary.main,
    INSPECTOR: theme.palette.quaternary.main,
    'BOOKS ADMIN': colours.shamrock,
    'BOOKS ADVANCED': colours.shamrock,
    'BOOKS USER': colours.shamrock,
    'BOOKS REPORTER': colours.shamrock,
    USER: theme.palette.text.secondary,
    CANCELLED: colours.mahogany,
    STARTED: colours.shamrock,
    MISSED: theme.palette.warning.main,
    OVERDUE: colours.mahogany,
    DELAYED: theme.palette.warning.main,
    SUBMITTED: theme.palette.primary.main,
    APPROVAL: theme.palette.primary.main,
    'NEEDS ATTENTION': theme.palette.primary.main,
    'NEW VISIT REQUESTED': theme.palette.quaternary.main,
    PRINTED: theme.palette.primary.main,
    REFUND: theme.palette.primary.main,
    VOID: theme.palette.error.main,
    FAILED: theme.palette.error.main,
    RECONCILED: theme.palette.primary.main,
    ACTIVE: colours.shamrock,
    INACTIVE: theme.palette.primary.main,
    CLOSED: colours.gainsboro,
    PAUSED: colours.gainsboro,
    APPROVED: theme.palette.success.main,
    DECLINED: 'transparent',
    NOT_INVITED: 'transparent',
    INVITED: theme.palette.primary.main,
    SUSPENDED: theme.palette.error.main,
    NSF: theme.palette.error.main,
    INCOMPLETE: theme.palette.warning.main,
    ACTIVATED: theme.palette.secondary.main,
  })[status] ?? theme.palette.action.disabledBackground;

export type StatusChipProps = {
  status?: string;
  loading?: boolean;
};

export default function StatusChip({
  status = 'DEFAULT',
  loading = false,
  ...props
}: StatusChipProps & Omit<ChipProps, 'label'>) {
  const theme = useTheme();

  return loading ? (
    <Skeleton />
  ) : (
    <Box sx={{ width: 'fitContent' }}>
      <Chip
        label={status.replaceAll('_', ' ')}
        textColor={color(theme, status)}
        backgroundColor={backgroundColor(theme, status)}
        borderColor={borderColor(theme, status)}
        className={props.className}
        {...props}
      />
    </Box>
  );
}

import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import { LoadingButton, Tooltip } from 'components';
import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';

export default function SplitButton({
  options,
  className,
  disabled,
  dropDownPlacement = 'bottom',
  type = 'button',
  variant = 'contained',
  sx,
  hideProgress,
  defaultSelectedIndex,
  freezeIndex,
}: {
  options: ({
    label: ReactNode;
    onClick: () => void | Promise<void>;
    onSelected?: () => void | Promise<void>;
    loading?: boolean;
  } & Omit<ButtonProps, 'onClick' | 'label' | 'loading'>)[];
  className?: string;
  disabled?: boolean;
  dropDownPlacement?: PopperPlacementType;
  sx?: ButtonProps['sx'];
  variant?: ButtonProps['variant'];
  hideProgress?: boolean;
  defaultSelectedIndex?: number;
  type?: 'button' | 'submit';
  freezeIndex?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(defaultSelectedIndex ?? 0);
    // intentional unused dependency, making sure we reset the selected index of the # of options changes
  }, [options.length, defaultSelectedIndex]);

  const handleClick = () => {
    options[selectedIndex].onClick();
  };

  const handleMenuItemClick = async (
    _event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    index: number
  ) => {
    if (!freezeIndex) {
      setSelectedIndex(index);
    }

    setOpen(false);

    await options[index].onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: globalThis.MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return options.length === 0 ? (
    <></>
  ) : options.length === 1 ? (
    <LoadingButton
      type={type}
      onClick={handleClick}
      style={{ height: 37 }}
      loading={options[0].loading}
      sx={sx}
      className={className}
      variant={variant}
      disabled={disabled}
      hideProgress={hideProgress}
    >
      {options[0].label}
    </LoadingButton>
  ) : (
    <>
      <ButtonGroup
        disabled={disabled}
        variant={variant}
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <LoadingButton
          type={type}
          style={{ height: 37 }}
          sx={sx}
          className={className}
          hideProgress={hideProgress}
          disabledTooltip={
            options[selectedIndex].disabled && options[selectedIndex].title
              ? options[selectedIndex].title
              : undefined
          }
          {...{ ...options[selectedIndex] }}
          onClick={handleClick}
        >
          {options[selectedIndex].label}
        </LoadingButton>
        <Button
          size="small"
          type="button"
          disabled={disabled}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={dropDownPlacement}
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  component="div"
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  {options.map((option, index) => {
                    const menuItem = (
                      <MenuItem
                        type={type}
                        component="button"
                        title={option.title}
                        disabled={option.disabled}
                        key={
                          option.label &&
                          typeof option.label !== 'object' &&
                          typeof option.label !== 'boolean'
                            ? option.label
                            : `submit_option_${index}`
                        }
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        sx={{ display: 'flex', gap: 1 }}
                      >
                        {option.startIcon}
                        <Typography noWrap component="span">
                          {option.label}
                        </Typography>
                        {option.endIcon}
                      </MenuItem>
                    );
                    return option.disabled && option.title ? (
                      <Tooltip title={option.title}>{menuItem}</Tooltip>
                    ) : (
                      menuItem
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

import { ChipProps } from '@mui/material';
import { Tooltip } from 'components';
import StatusChip, { StatusChipProps } from './StatusChip';

export default function TooltipStatusChip(props: StatusChipProps & ChipProps) {
  const { status = 'DEFAULT' } = props;

  const tooltipMessageLookup: Record<string, JSX.Element> = {
    SUBMITTED: <span>This request needs an assigned operator</span>,
    CONFIRMED: <span>An operator has been assigned this request</span>,
    'NEEDS ATTENTION': <span>This appointment has a change request</span>,
    'NEW VISIT REQUESTED': <span>The operator has requested a new visit</span>,
    STARTED: <span>The operator started working on this request</span>,
    COMPLETED: <span>The operator has completed this request</span>,
    MISSED: <span>The operator missed the scheduled appointment time</span>,
    UNFINISHED: <span>The operator has unfinished work on this request</span>,
    DELAYED: <span>The operator is behind schedule</span>,
    OVERDUE: <span>This request is overdue for scheduling</span>,
    CANCELLED: <span>This request has been cancelled</span>,
    PAUSED: <span>This request has been placed on hold</span>,
  };

  return (
    <Tooltip title={tooltipMessageLookup[status] ?? ''}>
      <StatusChip {...props} />
    </Tooltip>
  );
}

import { ApolloLink, HttpLink } from '@apollo/client';

export const createAppSyncHttpLink = ({
  appSyncApiUrl,
  getJwtToken,
}: {
  appSyncApiUrl: string;
  getJwtToken: () => string;
}) => {
  const httpLink = new HttpLink({ uri: appSyncApiUrl });
  const authorizationHeaderLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers, ...rest }: { headers: Headers }) => ({
      ...rest,
      headers: {
        ...headers,
        Authorization: getJwtToken(),
      },
    }));

    return forward(operation);
  });

  return ApolloLink.from([authorizationHeaderLink, httpLink]);
};

import { Skeleton, styled, TableCell as TC } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { skipProps } from 'system';
import { Column, Row } from '../types';

type EnhancedCellProps = {
  row: Row;
  column: Column;
  loading?: boolean;
};
const TableCell = styled(
  TC,
  skipProps('flex')
)<{ flex: CSSProperties['flex'] }>(({ theme, flex }) => ({
  alignSelf: 'center',
  padding: theme.spacing(2),
  border: 'none',
  flex,
}));

export default function EnhancedCell({ column, row, loading }: EnhancedCellProps) {
  return (
    <TableCell sx={column.sx} flex={column.flex ?? 1} className={column.className}>
      {loading ? (
        <Skeleton />
      ) : column.renderCell ? (
        column.renderCell({ id: row.id, value: row[column.field], row })
      ) : (
        row[column.field]
      )}
    </TableCell>
  );
}

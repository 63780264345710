import { ReactNode } from 'react';
import { Column, Row } from '../types';
import { ColumnCell, Item, ListItemButton, MobileRow, RowCell } from './EnhancedMobileRow.styles';

type EnhancedMobileRowProps = {
  row: Row;
  onRowClick?: (id: string) => void;
  columns: Column[];
  renderMobileRow?: (params: { id: string; value: Row }) => ReactNode;
};

export default function EnhancedMobileRow({
  row,
  onRowClick,
  columns,
  renderMobileRow,
}: EnhancedMobileRowProps) {
  return renderMobileRow ? (
    <MobileRow onClick={onRowClick && (() => onRowClick(row.id))}>
      {renderMobileRow({ id: row.id, value: row })}
    </MobileRow>
  ) : (
    <ListItemButton onClick={onRowClick && (() => onRowClick(row.id))}>
      {columns
        .filter(({ hidden }) => !hidden)
        .map((column) => (
          <Item key={`${column.field} ${row.id}`}>
            <ColumnCell primary={column.headerName} />

            <RowCell
              primary={
                column.renderCell
                  ? column.renderCell({ id: row.id, value: row[column.field], row })
                  : row[column.field]
              }
            />
          </Item>
        ))}
    </ListItemButton>
  );
}

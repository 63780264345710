import { SwitchProps } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SwitchField } from '../Fields/SwitchField';

export default function SwitchFieldController({
  name,
  label,
  onChange,
  ...props
}: SwitchProps & { name: string; label?: ReactNode }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <SwitchField
          {...field}
          checked={field.value ?? props.defaultChecked ?? false}
          onChange={(event, checked) => {
            setValue(name, !field.value, { shouldValidate: true });
            onChange?.(event, checked);
          }}
          color="primary"
          label={label ?? ''}
          {...props}
        />
      )}
    />
  );
}

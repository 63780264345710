/* eslint-disable @typescript-eslint/no-explicit-any */

import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { Box, BoxProps } from '@mui/material';
import { useTheme } from 'context';
import { createCustomEqual, deepEqual } from 'fast-equals';
import * as React from 'react';
import {
  Children,
  EffectCallback,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';

export type GoogleMapProps = Omit<BoxProps, 'onClick' | 'onChange'> & {
  lat?: number;
  lng?: number;
  draggableMarker?: boolean;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onChange?: (position: google.maps.LatLng) => void;
};

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

const darkModeStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
];

export const GoogleMap = ({
  lat = 51,
  lng = -114,
  draggableMarker,
  onClick,
  onChange,
  ...props
}: GoogleMapProps) => {
  const { darkMode } = useTheme();

  return (
    <Box {...props}>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}
        render={render}
        libraries={['places']}
      >
        <Map
          center={{ lat, lng }}
          zoom={15}
          style={{ flexGrow: '1', height: '100%' }}
          styles={darkMode ? darkModeStyles : undefined}
          onClick={onClick}
        >
          <Marker
            position={{ lat, lng }}
            draggable={draggableMarker}
            crossOnDrag
            onChange={onChange}
          />
        </Map>
      </Wrapper>
    </Box>
  );
};
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map = ({ onClick, onIdle, children, style, ...options }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement<{ map?: google.maps.Map }>(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = ({
  onChange,
  ...options
}: google.maps.MarkerOptions & { onChange?: (e: google.maps.LatLng) => void }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (marker) {
      ['dragend'].forEach((eventName) => google.maps.event.clearListeners(marker, eventName));

      if (onChange) {
        marker.addListener('dragend', () => {
          const position = marker.getPosition();
          if (position) onChange(position);
        });
      }
    }
  }, [marker, onChange]);

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual({
  createInternalComparator: () => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    return deepEqual(a, b);
  },
});

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const useDeepCompareEffectForMaps = (callback: EffectCallback, dependencies: any[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

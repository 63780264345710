/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyTerminatedAction } from './terminatedAction';
import ManagerResidencyTerminatedActionSchema from './terminatedAction.json';

export const isManagerResidencyTerminatedAction = guard<ManagerResidencyTerminatedAction>(ManagerResidencyTerminatedActionSchema as any);
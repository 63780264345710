import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Option } from 'system';
import { useAccountingContext } from '../context';
import { usePropertyOwners } from './usePropertyOwners';

export const useSortOwners = () => {
  const { entityIds, unitById, propertyById } = useAccountingContext();
  const { booksOwners } = usePropertyOwners({ unitById, propertyById });

  const [sortedOwners, setSortedOwners] = useState<Option[]>([]);

  useEffect(() => {
    const sortedOptions = booksOwners
      .filter((owner) => owner.isPropertyOwner || owner.isUnitOwner)
      .map((owner) => ({
        id: owner.id,
        text: owner.name,
        subText: owner.isPropertyOwner ? 'Property Owner' : 'Unit Owner',
      }));
    const newSortedOwners = entityIds.account?.[0]
      ? [
          {
            id: entityIds.account[0].id,
            text: entityIds.account[0].text,
            subText: 'Administrative',
          },
        ]
      : [];
    setSortedOwners(newSortedOwners.concat(sortBy(sortedOptions, 'subText')));
  }, [booksOwners, entityIds.account]);

  return {
    sortedOwners,
  };
};

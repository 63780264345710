import {
  API,
  DraftReconciliationInput,
  DraftReconciliationMutation,
  Reconciliation,
  ReconciliationFieldsFragmentDoc,
  ReconciliationStatus,
  useDraftReconciliationMutation,
} from 'api';
import { useAuth } from 'context';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { NullableAllDefined, graphqlNull } from 'system';
import { z } from 'zod';

const toGraphqlDraftReconciliation = <
  TRec extends Omit<Reconciliation, 'id' | 'status' | 'scope'>,
>({
  configuration,
  statementDate,
  statementBalance,
}: TRec): NullableAllDefined<DraftReconciliationMutation> => ({
  __typename: 'Mutation',
  draftReconciliation: {
    success: true,
    error: graphqlNull,
    __typename: 'DraftReconciliationPayload',
    reconciliation: {
      __typename: 'Reconciliation',
      current: true,
      configuration,
      statementDate,
      statementBalance,
      name: graphqlNull,
      glAccount: graphqlNull,
      reindexToken: graphqlNull,
      errorMessage: graphqlNull,
      lastReconciliationId: graphqlNull,
      lastStatementBalance: graphqlNull,
      id: 'optimistic-reconciliation-id',
      status: ReconciliationStatus.Draft,
      completedZ: graphqlNull,
      documentKey: graphqlNull,
      booksBatchId: graphqlNull,
      scope: {
        __typename: 'ReconciliationScope',
        glId: 'optimistic-gl-id',
        propertyOwnerIds: graphqlNull,
      },
    },
  },
});

export const draftReconciliationFormShape = z.object({
  configuration: z.string().min(0),
  statementDate: z.string(),
  statementBalance: z.number().default(0),
});

export type DraftReconciliationFormFields = z.infer<typeof draftReconciliationFormShape>;

export const useDraftReconciliation = () => {
  const { sendNotification } = useNotification();
  const { accountId } = useAuth();
  const [draftReconciliationMutation, draftReconciliationMeta] = useDraftReconciliationMutation();
  const { loading } = useMeta(draftReconciliationMeta);

  const draftReconciliation = async (values: DraftReconciliationFormFields) => {
    const input: DraftReconciliationInput = {
      configuration: values.configuration,
      statementDate: values.statementDate,
      statementBalance: values.statementBalance,
    };

    try {
      const result = await draftReconciliationMutation({
        variables: { input },
        optimisticResponse: ({ input: { configuration, statementBalance, statementDate } }) =>
          toGraphqlDraftReconciliation({ configuration, statementBalance, statementDate }),
        update(cache, { data }) {
          if (data?.draftReconciliation?.reconciliation) {
            const { reconciliation } = data.draftReconciliation;

            cache.modify({
              id: cache.identify({ accountId, __typename: 'Books' }),
              fields: {
                reconciliations: (existing = []) => [
                  ...existing,
                  cache.writeFragment({
                    data: reconciliation,
                    fragment: ReconciliationFieldsFragmentDoc,
                    fragmentName: API.Fragment.ReconciliationFields,
                  }),
                ],
              },
            });

            cache.modify({
              id: cache.identify({
                id: reconciliation.lastReconciliationId,
                __typename: 'Reconciliation',
              }),
              fields: { current: () => false },
            });
          }
        },
      });

      return result?.data?.draftReconciliation?.reconciliation?.id;
    } catch (e) {
      sendNotification('Something went wrong. Try again', 'error');
      return false;
    }
  };

  return {
    draftReconciliation,
    loading,
  };
};

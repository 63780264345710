import * as Icons from '@mui/icons-material';
import { ManagerRole } from 'api';
import { CustomIcons } from 'components';
import { ReactElement } from 'react';
import { spreadListIf } from 'system';
import ActionableApplicationsBadge from '../ActionableApplicationsBadge';
import ActionableAutopayPayeeBadge from '../ActionableAutopayPayeeBadge';
import ActionableRequestsBadge from '../ActionableRequestsBadge';

export type AuthNavItem = {
  to?: string;
  onClick?: VoidFunction;
  icon?: React.ComponentType;
  label: string;
  section?: string;
  badge?: ReactElement;
  showFullSizeIcon: boolean;
  restrictions?: ManagerRole[];
  toggleId?: string;
  requiresProperties?: boolean;
  children?: AuthNavItem[];
};

export const authNav = ({
  logoutHandler,
  isBooksActive,
  hasResidentialUnits,
  hasCommercialUnits,
}: {
  logoutHandler: VoidFunction;
  isBooksActive: boolean;
  hasResidentialUnits: boolean;
  hasCommercialUnits: boolean;
}): AuthNavItem[] => [
  {
    to: '/',
    icon: Icons.Dashboard,
    label: 'Dashboard',
    showFullSizeIcon: true,
    requiresProperties: true,
    children: [
      ...spreadListIf(hasResidentialUnits, [
        {
          to: '/residencies/availability/residential',
          label: 'Residential',
          showFullSizeIcon: false,
        },
      ]),
      ...spreadListIf(hasCommercialUnits, [
        {
          to: '/residencies/availability/commercial',
          label: 'Commercial',
          showFullSizeIcon: false,
        },
      ]),
      ...spreadListIf(isBooksActive, [
        {
          to: '/collections',
          label: 'Collections',
          showFullSizeIcon: false,
        },
      ]),
    ],
  },
  {
    to: '/requests',
    icon: Icons.Notifications,
    label: 'Requests',
    badge: <ActionableRequestsBadge />,
    showFullSizeIcon: true,
    requiresProperties: true,
  },
  {
    to: '/properties',
    icon: Icons.Apartment,
    label: 'Properties',
    showFullSizeIcon: true,
  },
  {
    to: '/suppliers',
    icon: Icons.LocalShipping,
    label: 'Suppliers',
    showFullSizeIcon: true,
  },
  {
    to: '/operators',
    icon: Icons.EmojiPeople,
    label: 'Operators',
    showFullSizeIcon: true,
  },
  {
    to: '/tenants',
    icon: Icons.Home,
    label: 'Tenants',
    showFullSizeIcon: true,
  },
  {
    to: '/owners',
    icon: Icons.VpnKey,
    label: 'Owners',
    showFullSizeIcon: true,
  },
  {
    to: '/announcements',
    icon: Icons.Campaign,
    label: 'Announcements',
    showFullSizeIcon: true,
    requiresProperties: true,
  },
  {
    to: '/campaigns',
    icon: Icons.Flag,
    label: 'Campaigns',
    showFullSizeIcon: true,
    badge: <ActionableApplicationsBadge />,
    requiresProperties: true,
  },
  {
    to: '/accounting',
    icon: Icons.AttachMoney,
    label: 'Accounting',
    showFullSizeIcon: true,
    restrictions: [
      ManagerRole.BooksAdmin,
      ManagerRole.BooksAdvanced,
      ManagerRole.BooksUser,
      ManagerRole.BooksReporter,
    ],
    requiresProperties: true,
  },
  ...(isBooksActive
    ? [
        {
          to: '/autopay',
          icon: Icons.CreditCard,
          label: 'Autopay',
          showFullSizeIcon: true,
          toggleId: 'manager-autopay',
          restrictions: [
            ManagerRole.Admin,
            ManagerRole.BooksAdmin,
            ManagerRole.BooksAdvanced,
            ManagerRole.BooksUser,
            ManagerRole.User,
          ],
          requiresProperties: true,
          badge: <ActionableAutopayPayeeBadge />,
        },
      ]
    : []),
  {
    to: '/account',
    icon: Icons.Person,
    label: 'Account',
    section: 'Settings',
    showFullSizeIcon: false,
  },
  {
    to: '/templates',
    icon: Icons.Description,
    label: 'Smart Templates',
    section: 'Settings',
    showFullSizeIcon: false,
    restrictions: [ManagerRole.Admin],
  },
  {
    onClick: logoutHandler,
    icon: CustomIcons.Logout,
    label: 'Logout',
    section: 'Settings',
    showFullSizeIcon: false,
  },
];

import { usePersistentState } from './usePersistentState';

type UsePageSizeOptions = {
  cacheKey?: string;
  rowsPerPageOptions?: number[];
  defaultPageSize?: number;
};

const defaultOptions = {
  cacheKey: 'pageSize',
  rowsPerPageOptions: [10, 100, 1000],
  defaultPageSize: 100,
};

export const usePageSize = (options: UsePageSizeOptions = {}) => {
  const { cacheKey, rowsPerPageOptions, defaultPageSize } = { ...defaultOptions, ...options };
  const [pageSize, setPageSize] = usePersistentState(cacheKey, defaultPageSize, {
    validatorFn: (cached) => typeof cached === 'number' && rowsPerPageOptions.includes(cached),
  });

  return { onPageSizeChange: setPageSize, pagination: true, pageSize, rowsPerPageOptions };
};

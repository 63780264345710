import { Box, Stack } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

export type GridSearchToolbarProps = {
  top?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  primaryAction?: ReactNode;
  toolbarSx?: GridToolbarContainerProps['sx'];
};

export const GridSearchToolbar = ({
  left = <GridToolbarQuickFilter />,
  right,
  primaryAction,
  top,
  toolbarSx,
}: GridSearchToolbarProps) => {
  return (
    <Stack gap={0.5} sx={{ '@media print': { display: 'none' } }}>
      {top && <Box display="flex">{top}</Box>}
      <GridToolbarContainer
        sx={{ justifyContent: 'space-between', alignItems: 'center', ...toolbarSx }}
      >
        <Box display="flex">{left}</Box>

        {(right || primaryAction) && (
          <Box display="flex" gap={1} alignItems="center">
            {right}
            {primaryAction}
          </Box>
        )}
      </GridToolbarContainer>
    </Stack>
  );
};

import { useListAllTenantsWithResidencyQuery } from 'api';
import { useMemo } from 'react';
import { useMeta } from './useMeta';
import { usePaginateAllQuery } from './usePaginateAllQuery';
import { useTeams } from './useTeams';

export const useTenants = () => {
  const { items, ...tenantResidencyMeta } = usePaginateAllQuery(
    useListAllTenantsWithResidencyQuery,
    {
      getNextToken: (d) => d?.account?.listTenants?.nextToken,
      getItems: (d) => d?.account?.listTenants?.items,
    }
  );

  const { loading, working } = useMeta(tenantResidencyMeta);

  const { isTenantVisible } = useTeams();
  const tenants = useMemo(() => items.filter(isTenantVisible), [isTenantVisible, items]);

  return { tenants, loading, working };
};

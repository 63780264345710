import { Collapse, TableCell } from '@mui/material';
import { FC } from 'react';
import { Row } from '../types';
import { TableRow } from './styles';

type ExpandableRowProps = {
  row: Row;
  onExpand?: (id: unknown) => void;
  onCollapse?: VoidFunction;
  expanded?: boolean;
  expansionComponent?: FC<{ row: Row }>;
};

export default function ExpandableRow({
  row,
  expanded,
  expansionComponent: ExpansionComponent,
}: ExpandableRowProps) {
  return ExpansionComponent ? (
    <TableRow sx={{ border: 'none' }}>
      <TableCell colSpan={99} sx={{ p: 0, flexGrow: 1 }}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ExpansionComponent row={row} />
        </Collapse>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
}

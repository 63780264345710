import { Box, Link, LinkProps } from '@mui/material';
import clsx from 'clsx';

type UnitAddressLineProps = {
  unit?: {
    id?: string;
    name?: string;
    property?: {
      id?: string;
      name?: string;
      address?: {
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
    building?: {
      id?: string;
      name?: string;
      address?: {
        street?: string;
        city?: string;
        province?: string;
        postal?: string;
      };
    };
  };
  link?: boolean;
  cityProv?: boolean;
  postal?: boolean;
  full?: boolean;
  className?: string;
  sx?: LinkProps['sx'];
};

export default function UnitAddressLine({
  link = false,
  unit,
  postal = false,
  cityProv = false,
  full = false,
  className,
  sx,
}: UnitAddressLineProps) {
  const buildingName = unit?.building?.name ?? unit?.property?.name;
  const address = unit?.building?.address ?? unit?.property?.address;

  const text = [
    unit?.name,
    buildingName,
    address?.street,
    (cityProv || full) && address?.city,
    (cityProv || full) && address?.province,
    (postal || full) && address?.postal,
  ]
    .filter(Boolean)
    .join(', ');

  return link ? (
    <Link
      href={`/properties/${unit?.property?.id}/units/${unit?.id}/residency`}
      className={clsx(className)}
      sx={sx}
    >
      {text}
    </Link>
  ) : (
    <Box sx={sx} className={clsx(className)}>
      {text}
    </Box>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerFutureLeaseAddedEvent } from './futureAdded';
import ManagerFutureLeaseAddedEventSchema from './futureAdded.json';

export const isManagerFutureLeaseAddedEvent = guard<ManagerFutureLeaseAddedEvent>(ManagerFutureLeaseAddedEventSchema as any);
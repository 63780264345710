/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksJournalUnitUpdatedEvent } from './jeUnitUpdated';
import ManagerBooksJournalUnitUpdatedEventSchema from './jeUnitUpdated.json';

export const isManagerBooksJournalUnitUpdatedEvent = guard<ManagerBooksJournalUnitUpdatedEvent>(ManagerBooksJournalUnitUpdatedEventSchema as any);
import { Box, Collapse, ListItemText, Typography } from '@mui/material';
import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { ChequePlacement } from 'api';
import {
  CheckboxField,
  CheckboxFieldController,
  DropdownFieldController,
  FullSizePaper,
  ItemList,
} from 'components';
import { useFees } from 'hooks/useFees';
import { usePageSize } from 'hooks/usePageSize';
import { capitalize, identity, orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAccountingContext } from '../context';
import FinancialSettingsToolBar from './FinancialSettingsToolBar';

type RowModel = {
  id: string;
  description: { primary: string; secondary?: string };
  setting: {
    element: typeof CheckboxFieldController | typeof DropdownFieldController;
    props?: Record<string, unknown>;
  };
};

export default function AccountingTasksForm({ isCollapsible = true }: { isCollapsible?: boolean }) {
  const { entityIds } = useAccountingContext();
  const accountName = entityIds.account[0].text;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { feeOptions } = useFees();
  const { watch, setValue } = useFormContext();
  const billableRevenueFeeIds: string[] = watch('billableRevenueFeeIds');

  const managementFeesRows: RowModel[] = useMemo(
    () => [
      {
        id: 'trackManagementFeeRevenue',
        description: {
          primary: `Track management fee revenue for ${accountName}`,
          secondary: 'Also create a revenue receivable to match the payable expense',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'trackManagementFeeRevenue',
            size: 'small',
          },
        },
      },
    ],
    [accountName]
  );

  const printChequesRow: RowModel[] = useMemo(
    () => [
      {
        id: 'chequePlacement',
        description: {
          primary: 'Default cheque placement',
        },
        setting: {
          element: DropdownFieldController,
          props: {
            name: 'chequePlacement',
            size: 'small',
            variant: 'outlined',
            sx: { minWidth: '180px' },
            data: orderBy(Object.values(ChequePlacement), identity, 'desc').map((value) => ({
              label: capitalize(value),
              value,
            })),
          },
        },
      },
    ],
    []
  );

  const incomePayoutRows: RowModel[] = useMemo(
    () => [
      {
        id: 'excludePayables',
        description: {
          primary: 'Omit Payables from Payout',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'incomePayoutOptions.excludePayables',
            size: 'small',
          },
        },
      },
      {
        id: 'excludePrepayments',
        description: {
          primary: 'Hold prepaid receivables',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'incomePayoutOptions.excludePrepayments',
            size: 'small',
          },
        },
      },
      {
        id: 'includeOutstandingPayables',
        description: {
          primary: 'Hold payables outstanding as of the posting date',
        },
        setting: {
          element: CheckboxFieldController,
          props: {
            name: 'incomePayoutOptions.includeOutstandingPayables',
            size: 'small',
          },
        },
      },
    ],
    []
  );

  const columns: Array<GridColDef<RowModel>> = [
    {
      field: 'description',
      flex: 2,
      headerName: 'Item',
      sortable: false,
      renderCell({ value }) {
        return <ListItemText {...value} />;
      },
    },
    {
      field: 'setting',
      headerName: 'Setting',
      sortable: false,
      flex: 1,
      renderCell({ value }) {
        const { element: Element, props } = value ?? {};
        return <Box sx={{ my: 2, width: '100%' }}>{Element ? <Element {...props} /> : <></>}</Box>;
      },
    },
  ];

  const pageConfig = usePageSize({ cacheKey: 'maintenance-billing-mapping', defaultPageSize: 100 });

  return (
    <FullSizePaper sx={{ mb: 2, ...(isCollapsible ? { pt: 2, pb: 1.5 } : { p: 0, border: 0 }) }}>
      {isCollapsible && (
        <FinancialSettingsToolBar
          open={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
          title="Accounting Tasks"
        />
      )}
      <Collapse in={isOpen || !isCollapsible}>
        <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
          <Typography variant="h6" fontWeight={400} marginBottom={1}>
            Management Fees
          </Typography>
          <DataGridPro
            autoHeight
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
            disableMultipleRowSelection
            isRowSelectable={() => false}
            {...pageConfig}
            getRowHeight={() => 'auto'}
            rows={managementFeesRows}
            columns={columns}
            hideFooter={true}
            columnHeaderHeight={0}
            sx={{
              borderBottom: 'none',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
            }}
          />
          <FullSizePaper
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: 1.5,
              borderColor: 'lightDivider',
            }}
          >
            <ListItemText>
              Calculate percent of revenue management fees on base rent or unit fee plus the
              following monthly fees
            </ListItemText>
            <ItemList
              items={feeOptions}
              renderItem={(item) => (
                <CheckboxField
                  label={item.text}
                  checked={billableRevenueFeeIds.includes(item.id)}
                  onChange={(_, checked) => {
                    setValue(
                      'billableRevenueFeeIds',
                      checked
                        ? [...new Set(billableRevenueFeeIds.concat(item.id))]
                        : billableRevenueFeeIds.filter((feeId) => feeId !== item.id)
                    );
                  }}
                />
              )}
            />
          </FullSizePaper>

          <Box sx={{ my: 2 }}>
            <Typography variant="h6" fontWeight={400} marginBottom={1}>
              Print Cheques
            </Typography>
            <DataGridPro
              autoHeight
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnPinning
              disableColumnMenu
              disableMultipleRowSelection
              isRowSelectable={() => false}
              {...pageConfig}
              getRowHeight={() => 'auto'}
              rows={printChequesRow}
              columns={columns}
              hideFooter={true}
              columnHeaderHeight={0}
              sx={{
                [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
                [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
              }}
            />
          </Box>

          <Box sx={{ my: 2 }}>
            <Typography variant="h6" fontWeight={400} marginBottom={1}>
              Income Payout Options
            </Typography>
            <DataGridPro
              autoHeight
              disableColumnReorder
              disableRowSelectionOnClick
              disableColumnPinning
              disableColumnMenu
              disableMultipleRowSelection
              isRowSelectable={() => false}
              {...pageConfig}
              getRowHeight={() => 'auto'}
              rows={incomePayoutRows}
              columns={columns}
              hideFooter={true}
              columnHeaderHeight={0}
              sx={{
                [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
                [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
              }}
            />
          </Box>
        </Box>
      </Collapse>
    </FullSizePaper>
  );
}

import { OwnerListFieldsFragment, useGetOwnersQuery } from 'api';
import { useEffect, useState } from 'react';
import { emptyArray } from 'system';
import { useMeta } from './useMeta';
import { usePaginateAllQuery } from './usePaginateAllQuery';
import { useTeams } from './useTeams';

export const useAccountOwners = () => {
  const { items, ...meta } = usePaginateAllQuery(useGetOwnersQuery, {
    getNextToken: (d) => d?.account?.listOwners?.nextToken,
    getItems: (d) => d.account?.listOwners?.items,
  });

  const { loading, working } = useMeta(meta);

  const { isOwnerVisible } = useTeams();

  const [owners, setOwners] = useState<OwnerListFieldsFragment[]>(emptyArray);
  useEffect(() => {
    setOwners(items.filter(isOwnerVisible));
  }, [isOwnerVisible, items]);

  return { owners, loading, working };
};

import { ErrorTemplate } from 'components';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }
  goBackAndRefresh = (): void => {
    location.reload();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).context.router.goBack();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorTemplate
          title="Pardon our dust!"
          subtitle="We've made some updates, please refresh the page to continue."
          buttonText="REFRESH"
          onClick={this.goBackAndRefresh}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

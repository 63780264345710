import { ErrorMessage } from '@hookform/error-message';
import { Alert, Box, Skeleton, Stack, Typography } from '@mui/material';
import {
  GridFooterContainer,
  GridPagination,
  GridRowId,
  GridToolbarContainer,
  GridValidRowModel,
  gridFilteredSortedRowEntriesSelector,
  gridPageSizeSelector,
  gridSortedRowIdsSelector,
  selectedGridRowsCountSelector,
  selectedGridRowsSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { compact, constant } from 'lodash';
import plur from 'plur';
import { FieldErrors } from 'react-hook-form';
import { ensureArray, formatCurrency, safeSum } from 'system';

export type GridFooterProps<TModel extends GridValidRowModel> = {
  total: number;
  totalLabel?: string;
  rowCount: number;
  loading?: boolean;
  names?: string[];
  errors?: string | FieldErrors;
  getRowTotal: (model: TModel) => number;
};

export const GridFooter = <TModel extends GridValidRowModel>({
  total,
  totalLabel,
  rowCount,
  loading,
  getRowTotal,
  errors,
  names,
}: GridFooterProps<TModel>) => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const gridRowCount =
    useGridSelector(apiRef, gridSortedRowIdsSelector).length || rootProps.rowCount;
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const selectionModel = useGridSelector(apiRef, selectedGridRowsSelector) as Map<
    GridRowId,
    TModel
  >;
  const selectedTotal =
    selectionModel.size > 0
      ? safeSum(compact([...selectionModel.values()]).map(getRowTotal ?? constant(0)))
      : 0;

  const filteredRows = compact(gridFilteredSortedRowEntriesSelector(apiRef));
  const filteredTotal =
    filteredRows.length > 0
      ? safeSum(filteredRows.map(({ model }) => model as TModel).map(getRowTotal ?? constant(0)))
      : 0;

  const effectiveRowCount = (rowCount || gridRowCount) ?? 0;
  const toolbarSx = { display: 'flex', justifyContent: 'space-between', minHeight: 52 } as const;

  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const hideFooterRowCount = rootProps.hideFooterRowCount;
  const hideFooterSelectedRowCount = rootProps.hideFooterSelectedRowCount;
  const hidePaginationControls = rootProps.hideFooterPagination || pageSize > effectiveRowCount;

  return (
    <GridFooterContainer sx={{ flexDirection: 'column', alignItems: 'normal' }}>
      {errors && (
        <GridToolbarContainer>
          {typeof errors === 'string' ? (
            <Alert severity="error">{errors}</Alert>
          ) : (
            ensureArray(names).flatMap((name) => (
              <ErrorMessage
                key={name}
                name={name}
                errors={errors}
                render={({ message }) =>
                  message && (
                    <Alert severity="error" sx={{ whiteSpace: 'pre-wrap', flexGrow: 1 }}>
                      {message}
                    </Alert>
                  )
                }
              />
            ))
          )}
        </GridToolbarContainer>
      )}

      {(!hideFooterRowCount || !hidePaginationControls) && (
        <GridToolbarContainer
          sx={{
            ...toolbarSx,
            justifyContent: hidePaginationControls ? 'flex-end' : 'space-between',
          }}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <Box marginX={2}>
              {effectiveRowCount} {plur('row', effectiveRowCount)}
              {Boolean(selectedRowCount) && <>, {selectedRowCount} selected</>}
            </Box>
          )}

          {!hidePaginationControls && <GridPagination />}
        </GridToolbarContainer>
      )}

      {loading ? (
        <Skeleton />
      ) : (filteredTotal || total || selectedTotal) && !hideFooterSelectedRowCount ? (
        <GridToolbarContainer sx={toolbarSx}>
          <Stack direction="row" gap={2} marginX={2}>
            {filteredTotal && filteredTotal !== total && (
              <Typography fontSize="inherit" marginRight={2}>
                {`Filtered ${totalLabel ?? 'Total'} ${formatCurrency(filteredTotal, {
                  negativeStyle: 'cr',
                })} `}
              </Typography>
            )}

            <Typography fontSize="inherit">
              {`${totalLabel ?? 'Total'} ${formatCurrency(total, { negativeStyle: 'cr' })}`}
            </Typography>

            {Boolean(selectedTotal) && (
              <Typography fontSize="inherit">{`, ${formatCurrency(selectedTotal, {
                negativeStyle: 'cr',
              })} selected`}</Typography>
            )}
          </Stack>
        </GridToolbarContainer>
      ) : (
        <></>
      )}
    </GridFooterContainer>
  );
};

import { GlAccountFieldsFragment } from 'api';
import { capitalize } from 'lodash';
import { Path, useController } from 'react-hook-form';
import { formatAccountName } from '../../pages/accounting/utils';
import {
  GLAccountAutocompleteField,
  GLAccountAutocompleteFieldProps,
} from './GLAccountAutocompleteField';

export const accountOption = (glAccount: GlAccountFieldsFragment) => ({
  id: glAccount.id,
  text: formatAccountName(glAccount),
  subText: capitalize(glAccount?.accountType),
});

export function GLAccountAutocompleteController<T = Record<string, unknown>>({
  name,
  defaultValue,
  ...autocompleteFields
}: { name: Path<T> } & Omit<GLAccountAutocompleteFieldProps, 'onChange' | 'error' | 'value'>) {
  const { field, fieldState } = useController({
    name,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue: defaultValue as any,
  });

  return (
    <GLAccountAutocompleteField
      {...autocompleteFields}
      value={field.value}
      error={fieldState.error}
      onChange={(value) => {
        field.onChange(value);
      }}
    />
  );
}

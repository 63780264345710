import { TextFieldProps } from '@mui/material';
import { Path, PathValue, useController } from 'react-hook-form';
import { AutocompleteField, AutocompleteFieldProps } from '../Fields';

type AutocompleteFieldControllerProps<
  TId extends string,
  TFields extends { [x: string]: TId } = { [x: string]: TId },
  TFieldName extends Path<TFields> = Path<TFields>,
> = Omit<AutocompleteFieldProps<TId>, 'name' | 'defaultValue'> & {
  name: TFieldName;
  reset?: number;
  defaultValue?: PathValue<TFields, TFieldName>;
  onValueChange?: (id: TId, optionalId?: string) => void;
  onInputChange?: (_: unknown, query: string, reason: string) => void;
  manualSort?: boolean;
};

export default function AutocompleteFieldController<
  TId extends string = string,
  TFields extends { [x: string]: TId } = { [x: string]: TId },
  TFieldName extends Path<TFields> = Path<TFields>,
>({
  name,
  disabled,
  onValueChange,
  groupBy,
  defaultValue,
  ...props
}: Omit<
  TextFieldProps,
  'onBlur' | 'onChange' | 'onFocus' | 'defaultValue' | 'autoComplete' | 'error' | 'name'
> &
  AutocompleteFieldControllerProps<TId, TFields, TFieldName>) {
  const { field, fieldState } = useController<TFields, TFieldName>({ name, defaultValue });

  return (
    <AutocompleteField<TId>
      {...{
        ...props,
      }}
      disabled={disabled}
      groupBy={groupBy}
      value={field.value as TId}
      error={fieldState.error}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          field.onChange(newValue);
          return;
        }

        field.onChange(newValue?.id ?? '', { shouldValidate: true });
        onValueChange?.(newValue?.id ?? ('' as TId));
      }}
    />
  );
}

import { SvgIcon, SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { colours } from 'system';

export default function AddPerson({ ...props }: SvgIconProps) {
  const { darkMode } = useTheme();
  return (
    <SvgIcon viewBox="0 0 94 94" {...props}>
      <path
        d="M39.166 78.3336V58.7503H23.4993V15.667H70.4994V47.0003H78.3327V15.667C78.3327 11.3586 74.8077 7.83362 70.4994 7.83362H23.4993C19.191 7.83362 15.666 11.3586 15.666 15.667V62.667L39.166 86.167V78.3336Z"
        fill={darkMode ? colours.whiteSmoke : colours.black}
      />
      <path
        d="M62.6654 23.5H31.332V31.3333H62.6654V23.5Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <path
        d="M62.6654 39.1672H31.332V47.0005H62.6654V39.1672Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <path
        d="M60.7097 69.7158C65.4097 69.7158 68.9347 66.1908 68.9347 61.4908C68.9347 56.7908 65.4097 53.2658 60.7097 53.2658C56.0097 53.2658 52.4847 56.7908 52.4847 61.4908C52.4847 66.1908 56.4014 69.7158 60.7097 69.7158ZM60.7097 57.5741C63.0597 57.5741 64.6264 59.5325 64.6264 61.4908C64.6264 63.8408 62.668 65.4075 60.7097 65.4075C58.7514 65.4075 56.793 63.4491 56.793 61.4908C56.793 59.5325 58.3597 57.5741 60.7097 57.5741ZM73.6347 75.1992C70.1097 73.6325 65.8014 72.0658 60.7097 72.0658C55.618 72.0658 50.918 73.6325 47.7847 75.1992C45.8264 76.3742 44.6514 78.3325 44.6514 80.6825V86.1658H77.1597V80.2908C77.1597 78.3325 75.9847 75.9825 73.6347 75.1992ZM72.8514 82.2491H48.1764V80.6825C48.1764 79.8992 48.568 79.1158 49.3514 78.7241C51.7014 77.5491 55.618 75.9825 60.7097 75.9825C65.8014 75.9825 69.3264 77.5491 72.068 78.7241C72.8514 79.1158 73.243 79.8992 73.243 80.6825V82.2491H72.8514Z"
        fill={darkMode ? colours.lightSlateBlue : colours.purpleHeart}
      />
      <path
        d="M83.4275 57.5742V63.8409H89.6941V67.7576H83.4275V74.0242H79.5108V67.7576H73.2441V63.8409H79.5108V57.5742H83.4275Z"
        fill="#24D1AD"
      />
    </SvgIcon>
  );
}

import { useLayoutEffect, useState } from 'react';
import { ifTruthy } from 'system';
import { useResizeObserver } from './useResizeObserver';

export const useWindowDimensions = (ref?: React.MutableRefObject<HTMLDivElement | undefined>) => {
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  const [offsetTop, setOffsetTop] = useState(0);
  const resizeObserver = useResizeObserver();

  useLayoutEffect(() => {
    const current = ref?.current;

    if (!current) {
      return;
    }

    setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
    setOffsetTop(ifTruthy(ref?.current?.offsetTop));

    resizeObserver.observe(
      current,
      () => {
        setWindowDimensions({ height: window.innerHeight, width: window.innerWidth });
        setOffsetTop(ifTruthy(ref?.current?.offsetTop));
      },
      { box: 'border-box' }
    );

    return () => {
      resizeObserver.unobserve(current);
    };
  }, [ref, resizeObserver]);

  return { ...windowDimensions, offsetTop };
};

import { RequestFieldsFragment, RequestListFieldsFragment } from 'api';
import { RequestDelay, RequestStatus } from './types';

const omitNonSearchFields = (key: string, value: unknown) => key !== 'id' && value;

export const filterByValue = <T = unknown>(arr: Array<T>, value: string) => {
  return value === ''
    ? arr
    : arr.filter((data) =>
        value
          .toLowerCase()
          .split(' ')
          .every(
            (valstr) =>
              JSON.stringify(data, omitNonSearchFields)
                .toLowerCase()
                .indexOf(valstr.replace(/[^-.\w\d'’]/gi, '')) !== -1
          )
      );
};

export const requestWithStatus =
  (status: RequestStatus, delay: RequestDelay | null) =>
  (request: RequestFieldsFragment | RequestListFieldsFragment) =>
    request.status === status && request.delay === delay;

export const requestOtherStatus = (request: RequestFieldsFragment | RequestListFieldsFragment) =>
  request.status !== RequestStatus.SUBMITTED && request.status !== RequestStatus.CONFIRMED;

export const omitDeleted = ({ deletedZ }: { deletedZ?: string }) => !deletedZ;

export const imageKeyMatchesDomain =
  (domain: string) =>
  <T extends { key?: string }>({ key }: T) =>
    key?.split('/')[0] === domain ||
    (key?.split('/')[0] === 'visits' && key?.split('/')[2] === domain);

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAccountFactsAddedEvent } from './accountPutFacts';
import ManagerAccountFactsAddedEventSchema from './accountPutFacts.json';

export const isManagerAccountFactsAddedEvent = guard<ManagerAccountFactsAddedEvent>(ManagerAccountFactsAddedEventSchema as any);
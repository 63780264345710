import * as graphqlPrinter from 'graphql/language/printer';

export const createAppSyncGraphQLOperationAdapter = (
  getAppSyncAuthorizationInfo: () => {
    host: string;
    Authorization: string;
  }
) => ({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applyMiddleware: async (options: any, next: any) => {
    options.data = JSON.stringify({
      query:
        typeof options.query === 'string' ? options.query : graphqlPrinter.print(options.query),
      variables: options.variables,
    });

    options.extensions = { authorization: await getAppSyncAuthorizationInfo() };

    delete options.operationName;
    delete options.variables;

    next();
  },
});

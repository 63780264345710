/**
 * A simple, insecure hash function that returns a fixed-length hash of a string.
 * @param str Any string
 * @returns An insecure, fixed-length hash of the string
 */
export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash &= hash; // Convert to 32bit integer
  }
  return (hash >>> 0).toString(36);
};

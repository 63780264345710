import { compact } from 'lodash';
import { Occupancy } from 'system';
import { capitalize, safeRound, safeSum } from './general';

export const netUnitEffect = (unit?: {
  condoFee?: number;
  condoFeeGst?: number;
  condoUnitFees?: { amount?: number | undefined }[];
}) =>
  safeRound(
    safeSum(compact([unit?.condoFee, ...(unit?.condoUnitFees?.map((f) => f.amount) ?? [])])) *
      safeSum(1, unit?.condoFeeGst ?? 0)
  );

export const calculateUnitGst = (unit?: {
  condoFee?: number;
  condoFeeGst?: number;
  condoUnitFees?: { amount?: number | undefined }[];
}) =>
  safeRound(
    safeSum(compact([unit?.condoFee, ...(unit?.condoUnitFees?.map((f) => f.amount) ?? [])])) *
      (unit?.condoFeeGst ?? 0)
  );

export const unitTypes: { label: string; value: Occupancy }[] = [
  { label: 'Rental', value: 'rental' },
  { label: 'Common area', value: 'common' },
  { label: 'Owner-occupied', value: 'owner' },
  { label: 'Owner-occupied (commercial)', value: 'commercialOwner' },
  { label: 'Commercial Rental', value: 'commercial' },
];

export const toUnitTypeLabel = (type: Occupancy | string) =>
  unitTypes.find((t) => t.value === type)?.label ?? capitalize(type);

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorOperatorDisabledEvent } from './disabled';
import OperatorOperatorDisabledEventSchema from './disabled.json';

export const isOperatorOperatorDisabledEvent = guard<OperatorOperatorDisabledEvent>(OperatorOperatorDisabledEventSchema as any);
import gPhoneNumber, { PhoneNumberFormat } from 'google-libphonenumber';
const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

export const validPhone = (value: string) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'CA');
    const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
    return (
      phoneUtil.isPossibleNumber(phoneNumber) &&
      phoneUtil.isValidNumberForRegion(phoneNumber, regionCode)
    );
  } catch (error) {
    return false;
  }
};

export const nullableValidPhone = (value?: string | null) => (value ? validPhone(value) : true);

export const formatPhone = (phone?: string) => {
  if (!phone) return '';

  try {
    const parsedPhone = phone.startsWith('+')
      ? phoneUtil.parseAndKeepRawInput(phone)
      : phoneUtil.parseAndKeepRawInput(phone, 'CA');
    return phone.startsWith('+')
      ? phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL)
      : phoneUtil.format(parsedPhone, PhoneNumberFormat.NATIONAL);
  } catch (error) {
    console.error('Error formatting phone number:', error);
    return phone;
  }
};

export const callablePhone = (phone: string) => {
  return phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phone, 'CA'),
    gPhoneNumber.PhoneNumberFormat.E164
  );
};

export const textablePhone = (phone: string) => callablePhone(phone).replace(/\D/g, '');

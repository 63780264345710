import { Box as _Box, LinearProgress as _LinearProgress, styled } from '@mui/material';
import { Form as _Form, FullSizePaper as _FullSizePaper } from 'components';

export const Form = styled(_Form)({
  display: 'flex',
  flexDirection: 'column',
}) as typeof _Form;

export const LinearProgress = styled(_LinearProgress)({
  width: '60%',
});

export const LinearProgressContainer = styled(_Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
}));

export const IconContainer = styled('div')(({ theme }) => ({
  '& *': {
    borderRadius: '50%',
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryBackground.main,
  },
}));

export const FullSizePaper = styled(_FullSizePaper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4),
  maxWidth: 1600,
}));

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEndsSoonEvent } from './endsSoon';
import ManagerResidencyEndsSoonEventSchema from './endsSoon.json';

export const isManagerResidencyEndsSoonEvent = guard<ManagerResidencyEndsSoonEvent>(ManagerResidencyEndsSoonEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayBankAccountChangedEvent } from './bankAccountChanged';
import ManagerAutopayBankAccountChangedEventSchema from './bankAccountChanged.json';

export const isManagerAutopayBankAccountChangedEvent = guard<ManagerAutopayBankAccountChangedEvent>(ManagerAutopayBankAccountChangedEventSchema as any);
import { Grid, styled, Typography } from '@mui/material';
import { skipProps } from 'system';

export const Line = styled(
  Grid,
  skipProps('matchPadding')
)<{ matchPadding?: boolean }>(({ theme, matchPadding }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
  '&--marginless': {
    margin: 0,
  },
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  padding: matchPadding ? theme.spacing(0.5, 2) : theme.spacing(2),
}));

export const ListText = styled(Typography)({
  fontSize: '16px',
  lineHeight: '20px',
}) as typeof Typography;

export const Title = styled(Typography)({
  fontWeight: 400,
  fontSize: '24px',
  fontFamily: 'Poppins',
});

export const Subtitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '20px',
});

export const GridBox = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
  padding: theme.spacing(1, 2),
}));

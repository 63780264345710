import { ClientOptions, SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 as uuid4 } from 'uuid';

export class UUIDOperationIdSubscriptionClient extends SubscriptionClient {
  constructor(
    url: string,
    options?: ClientOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webSocketImpl?: any,
    webSocketProtocols?: string | string[]
  ) {
    super(url, options, webSocketImpl, webSocketProtocols);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.generateOperationId = () => uuid4();
  }
}

import { ApolloError, DocumentNode } from '@apollo/client';
import { useAuth } from 'context';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ensureArray, ifDifferent, tuple } from 'system';

const apiUrl = process.env.REACT_APP_APPSYNC_GRAPHQL ?? '';
export const useManualPaginateAllQuery = <
  TData extends Record<string, unknown>,
  TVariables extends { [K in TNextParam]?: string },
  TItems extends Array<unknown> | undefined = Array<unknown> | undefined,
  TNextParam extends string = 'nextToken',
>({
  skip,
  getItems,
  getNextToken,
  operationName,
  variables: inputVars,
  query,
  tokenFieldName = 'nextToken' as TNextParam,
}: {
  skip?: boolean;
  query: DocumentNode;
  operationName: string;
  variables: TVariables;
  getItems: (d: TData) => TItems | undefined;
  getNextToken: (d: TData) => string | undefined;
  tokenFieldName?: TNextParam;
}) => {
  const { token: authToken } = useAuth();

  const [variables, setVariables] = useState(inputVars);
  useEffect(() => {
    setVariables(ifDifferent(inputVars));
  }, [inputVars]);

  const getPageResultRef = useRef(getItems);
  useEffect(() => {
    getPageResultRef.current = getItems;
  }, [getItems]);

  const getNextTokenRef = useRef(getNextToken);
  useEffect(() => {
    getNextTokenRef.current = getNextToken;
  }, [getNextToken]);

  const [done, setDone] = useState(skip ? undefined : false);
  const [loading, setLoading] = useState(!skip);
  const [pages, setPages] = useState<{ data: TData }[]>([]);
  const [error, setError] = useState<Error | ApolloError>();

  const items = useMemo(
    () => pages.flatMap(({ data }) => ensureArray(getPageResultRef.current(data))) as TItems,
    [pages]
  );

  const controllerRef = useRef<AbortController>(new AbortController());

  const walkPages = useCallback(
    async function ({ nextToken = '', reset = false }) {
      if (skip) {
        return;
      }

      controllerRef.current.abort();
      controllerRef.current = new AbortController();

      if (reset) {
        setDone(false);
        setLoading(true);
        setPages([]);
        setError(undefined);
      }

      try {
        const resp = await fetch(apiUrl, {
          signal: controllerRef.current.signal,
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: authToken,
          }),
          method: 'POST',
          body: JSON.stringify({
            operationName,
            variables: {
              ...variables,
              ...(nextToken && { [tokenFieldName]: nextToken }),
            },
            query: query.loc?.source.body,
          }),
        });

        const result: { data: TData } = await resp.json();
        const token = getNextTokenRef.current(result.data);
        setLoading(false);

        if (result.data) {
          setPages((curr) => [...curr, result]);
          token && (await walkPages({ nextToken: token }));
        }

        if (!token) {
          setDone(true);
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.name === 'AbortError') {
            console.warn(e);
          } else {
            setError(e);
          }
        }
      }
    },
    [authToken, operationName, query.loc?.source.body, skip, variables]
  );

  const fetchAllPages = useCallback(() => {
    void walkPages({ reset: true });

    return () => {
      controllerRef.current.abort();
    };
  }, [walkPages]);

  useEffect(() => fetchAllPages(), [fetchAllPages]);

  return tuple(items, { loading, pages, error, done, refetch: fetchAllPages });
};

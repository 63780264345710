import type { KebabCaseToCamelCase } from '@propra-system/util/src/kebabCaseToCamelCase';
import { kebabCaseToCamelCase } from '@propra-system/util/src/kebabCaseToCamelCase';
import { z } from 'zod';

export const eventBase = <TDetailSchema extends z.ZodTypeAny, TDetailType extends string>(
  name: TDetailType,
  detailSchema: TDetailSchema,
  source = 'system',
  extendedSchema = z.object({})
) =>
  z
    .object({
      id: z
        .string()
        .optional()
        .transform((val) => val ?? String(+new Date())),
      source: z.literal(source),
      'detail-type': z.literal(name),
      detail: detailSchema,
    })
    .merge(extendedSchema)
    .describe(name);

export const eventFactory = <TDetailSchema extends z.ZodTypeAny, TDetailType extends string>(
  name: TDetailType,
  detailSchema: TDetailSchema
): EventFactoryResponse<TDetailSchema, TDetailType> => {
  const event = eventBase(name, detailSchema);
  const key = kebabCaseToCamelCase(name);

  return {
    [key]: event,
    [`${key}DetailType`]: name,
    [`${key}Detail`]: detailSchema,
  } as EventFactoryResponse<TDetailSchema, TDetailType>;
};

type EventFactoryResponse<TDetailSchema extends z.ZodTypeAny, TDetailType extends string> = Record<
  KebabCaseToCamelCase<TDetailType>,
  ReturnType<typeof eventBase<TDetailSchema, TDetailType>>
> &
  Record<`${KebabCaseToCamelCase<TDetailType>}DetailType`, TDetailType> &
  Record<`${KebabCaseToCamelCase<TDetailType>}Detail`, TDetailSchema>;

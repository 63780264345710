import { datadogRum } from '@datadog/browser-rum';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

const environmentID = process.env.REACT_APP_FLAGSMITH_ENV_KEY;

export function Flagsmith({ children }: { children: React.ReactElement[] | React.ReactElement }) {
  return environmentID ? (
    <FlagsmithProvider
      options={{
        environmentID,
        datadogRum: { client: datadogRum, trackTraits: true },
      }}
      flagsmith={flagsmith}
    >
      {children}
    </FlagsmithProvider>
  ) : (
    <>{children}</>
  );
}

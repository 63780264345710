/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitImageAddedEvent } from './imageAdded';
import OperatorVisitImageAddedEventSchema from './imageAdded.json';

export const isOperatorVisitImageAddedEvent = guard<OperatorVisitImageAddedEvent>(OperatorVisitImageAddedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseTenantRemovedEvent } from './tenantRemoved';
import ManagerLeaseTenantRemovedEventSchema from './tenantRemoved.json';

export const isManagerLeaseTenantRemovedEvent = guard<ManagerLeaseTenantRemovedEvent>(ManagerLeaseTenantRemovedEventSchema as any);
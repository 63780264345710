import { Paper as _Paper, Stack, styled, Typography, TypographyProps } from '@mui/material';
import { skipProps } from 'system';

export const Paper = styled(
  _Paper,
  skipProps('noBorderRadius')
)<{ noBorderRadius?: boolean }>(({ theme, noBorderRadius }) => ({
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.divider}`,
  borderBottomLeftRadius: 3,
  borderBottomRightRadius: 3,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  ...(noBorderRadius && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
}));

export const TabLabel = styled(
  Typography,
  skipProps('active')
)<{ active?: boolean }>(({ theme, active }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(5),
  ...(active && {
    color: theme.palette.text.primary,
  }),
}));

export const TabIcon = styled(
  'span',
  skipProps('active')
)<{ active?: boolean }>(({ theme, active }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));

export const TabStrip = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const Title = styled(Typography)<TypographyProps<React.ElementType>>(({ theme }) => ({
  fontSize: 22,
  fontWeight: 'bold',
  marginRight: theme.spacing(4),
}));

export const Header = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(-1),
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 3,
  borderTop: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  minHeight: 45,
}));

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Popover,
  styled,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FilteredColumn } from 'cache';
import { Column, CustomIcons } from 'components';
import { useAnchorEl } from 'hooks/useAnchorEl';
import { ensureArray } from 'system';

type FilterColumnByValueProps = {
  column: Column;
  filteredColumn?: FilteredColumn;
  onValueChange: (value: string, toExclude: boolean, field: string) => void;
  onFilteredColumnAllChange: (field: string) => void;
  onFilteredColumnNoneChange: (field: string) => void;
};

const iconSx = {
  width: 18,
  height: 18,
};

const Title = styled(Typography)<TypographyProps<React.ElementType>>(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontWeight: 500,
  textAlign: 'center',
  paddingTop: theme.spacing(2),
}));

export default function FilterColumnByValue({
  column,
  filteredColumn,
  onValueChange,
  onFilteredColumnAllChange,
  onFilteredColumnNoneChange,
}: FilterColumnByValueProps) {
  const { anchorEl, clearAnchorEl, handleAnchorElClick } = useAnchorEl();

  const values = ensureArray(column.filterColumnValues);
  const excludedValues = ensureArray(filteredColumn?.excludes);
  const hasSelection = excludedValues.length > 0;

  return (
    <>
      <Tooltip title="Show/Hide Value">
        <IconButton sx={{ p: 0 }} onClick={handleAnchorElClick} size="large">
          {hasSelection ? (
            <CustomIcons.FilterAltFilled sx={iconSx} />
          ) : (
            <CustomIcons.FilterAltOutlined sx={iconSx} />
          )}
        </IconButton>
      </Tooltip>
      <Popover open={Boolean(anchorEl)} {...{ anchorEl }} onClose={clearAnchorEl}>
        <Title component="h3" variant="overline">
          Show / Hide Values
        </Title>
        <List sx={{ pr: 2, pb: 2 }}>
          <ListItem>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  checked={!hasSelection}
                  onChange={() =>
                    hasSelection
                      ? onFilteredColumnAllChange(column.field)
                      : onFilteredColumnNoneChange(column.field)
                  }
                  color="primary"
                  size="small"
                />
              }
              label={hasSelection ? 'SELECT ALL' : 'CLEAR ALL'}
            />
          </ListItem>
          {values.map((value) => {
            const isExcluded = excludedValues.includes(value);
            return (
              <ListItem key={value}>
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      checked={!isExcluded}
                      onChange={() => onValueChange(value, isExcluded, column.field)}
                      color="primary"
                      size="small"
                    />
                  }
                  label={value.toUpperCase()}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}

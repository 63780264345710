import { Card, CardActionArea, Grid } from '@mui/material';
import { AttributeText, BodyText } from 'components';
import TooltipStatusChip from 'components/TooltipStatusChip';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { computeRequestStatusLabel } from 'system';
import { RequestHistoryItem } from './hooks/useRequestHistory';

export default function RequestHistoryCard({
  loading,
  item,
}: {
  loading: boolean;
  item: RequestHistoryItem;
}) {
  return (
    <Card variant="outlined">
      <CardActionArea component={Link} to={`/requests/${item.id}`} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs container direction="column" justifyContent="space-between">
            <BodyText item loading={loading}>
              {item.summary}
            </BodyText>
            <AttributeText item loading={loading}>
              {item.isRequest && 'Case # '}
              {item.name}
            </AttributeText>
          </Grid>
          <Grid item xs container alignItems="flex-end" direction="column" spacing={1}>
            <Grid item>
              <TooltipStatusChip
                loading={loading}
                status={computeRequestStatusLabel(item)}
                size="small"
              ></TooltipStatusChip>
            </Grid>
            <BodyText item loading={loading}>
              {item.date.toLocaleString(DateTime.DATE_MED)}
            </BodyText>
            <AttributeText item loading={loading}>
              {item.tenantName}
            </AttributeText>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

import { styled, TableCell, TableRow as TR } from '@mui/material';

export const TableRow = styled(TR)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
}));

export const FiltersContainer = styled(TableCell)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

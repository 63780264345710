import { CssBaseline, StyledEngineProvider, Theme } from '@mui/material';
import {
  AuthProvider,
  EventsProvider,
  Flagsmith,
  IdleProvider,
  SiteAlertsProvider,
  SiteOptionsProvider,
  ThemeProvider,
} from 'context';
import { Navigation } from 'navigation';
import { SnackbarProvider } from 'notistack';
import Session from './Session';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <Flagsmith>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <CssBaseline enableColorScheme />
          <IdleProvider>
            <SnackbarProvider>
              <AuthProvider>
                <EventsProvider>
                  <Session>
                    <SiteAlertsProvider>
                      <SiteOptionsProvider>
                        <Navigation />
                      </SiteOptionsProvider>
                    </SiteAlertsProvider>
                  </Session>
                </EventsProvider>
              </AuthProvider>
            </SnackbarProvider>
          </IdleProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Flagsmith>
  );
}

export default App;

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApprovalDeclinedEvent } from './declined';
import ManagerApprovalDeclinedEventSchema from './declined.json';

export const isManagerApprovalDeclinedEvent = guard<ManagerApprovalDeclinedEvent>(ManagerApprovalDeclinedEventSchema as any);
import { Badge as B, badgeClasses, styled } from '@mui/material';
import { useGetActionableRequestsForAccountQuery } from 'api';
import { useEffect, useState } from 'react';

const Badge = styled(B)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryBackground.main,
  },
}));

const useCountForLevel = () => {
  const [count, setCount] = useState(0);
  const { data, error } = useGetActionableRequestsForAccountQuery();
  useEffect(() => setCount(data?.account?.requestsWithAction ?? 0), [data]);
  useEffect(() => error && console.error('Error getting actionable request count', error), [error]);
  return count;
};

export default function ActionableRequestsBadge() {
  const count = useCountForLevel();

  return <Badge badgeContent={count} />;
}

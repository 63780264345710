import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

type AddressLineProps = {
  data: {
    id?: string;
    name?: string;
    address?: {
      suite?: string;
      street?: string;
      city?: string;
      province?: string;
      postal?: string;
    };
  };
  name?: boolean;
  link?: boolean;
  cityProv?: boolean;
  postal?: boolean;
  full?: boolean;
  className?: string;
};

export default function AddressLine({
  name = true,
  link = false,
  data,
  postal = false,
  cityProv = false,
  full = false,
  className,
}: AddressLineProps) {
  const text = [
    name && data.name,
    data.address?.suite,
    data.address?.street,
    (cityProv || full) && data.address?.city,
    (cityProv || full) && data.address?.province,
    (postal || full) && data.address?.postal,
  ]
    .filter(Boolean)
    .join(', ');
  return link ? (
    <RouterLink to={`/properties/${data?.id}`} className={clsx(className)}>
      {text}
    </RouterLink>
  ) : (
    <div className={clsx(className)}>{text}</div>
  );
}

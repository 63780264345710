import { Box, BoxProps, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Chip, ChipProps } from './Chip';
import { GridBox, Line, ListText, Subtitle, Title } from './CustomizedList.styles';

type CustomizedListProps = {
  title?: string | ReactNode;
  items?: {
    label: string;
    badges?: { label: string; color?: ChipProps['color']; tooltip?: string }[];
    value: ReactNode;
    button?: ReactNode;
    hasMenu?: boolean;
  }[];
  button?: ReactNode;
  emptyMessage?: string | ReactNode;
  subtitle?: {
    first?: string;
    second?: string;
  };
  sx?: BoxProps['sx'];
};

export default function CustomizedList({
  title,
  items,
  button,
  emptyMessage,
  subtitle,
  sx,
}: CustomizedListProps) {
  return (
    <Box sx={{ mb: 2, ...sx }}>
      <GridBox container>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Title>{title}</Title>
          {button}
        </Grid>
      </GridBox>
      {subtitle && (
        <GridBox container>
          <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
            <Subtitle>{subtitle.first}</Subtitle>
            <Subtitle>{subtitle.second}</Subtitle>
          </Grid>
        </GridBox>
      )}
      <Grid container>
        {items?.[0]
          ? items.map((item) => (
              <Line item xs={12} key={item.label} matchPadding={Boolean(item.hasMenu)}>
                <ListText>
                  {item.label}

                  <Stack gap={1} direction="row" marginTop={1}>
                    {item.badges?.map((badge, i) => (
                      <Chip
                        key={`${i}_${badge.label}`}
                        label={badge.label}
                        tooltip={badge.tooltip}
                        color={badge.color ?? 'primary'}
                      />
                    ))}
                  </Stack>
                </ListText>
                <Box display="flex" alignItems="center">
                  {item.button}
                  <ListText component="span">{item.value}</ListText>
                </Box>
              </Line>
            ))
          : emptyMessage && (
              <Line item xs={12}>
                {typeof emptyMessage === 'string' ? (
                  <ListText variant="caption">{emptyMessage}</ListText>
                ) : (
                  emptyMessage
                )}
              </Line>
            )}
      </Grid>
    </Box>
  );
}

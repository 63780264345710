/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAnnouncementPublishedEvent } from './published';
import ManagerAnnouncementPublishedEventSchema from './published.json';

export const isManagerAnnouncementPublishedEvent = guard<ManagerAnnouncementPublishedEvent>(ManagerAnnouncementPublishedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertyUnitsCreatedChangedEvent } from './bulkUnitsCreatedChanged';
import ManagerPropertyUnitsCreatedChangedEventSchema from './bulkUnitsCreatedChanged.json';

export const isManagerPropertyUnitsCreatedChangedEvent = guard<ManagerPropertyUnitsCreatedChangedEvent>(ManagerPropertyUnitsCreatedChangedEventSchema as any);
import { AutopayEnrollmentStatus } from 'api';
import { capitalize, TestedOption } from 'system';
import { PayeeRowModel } from './types';

const excludedStatuses: (AutopayEnrollmentStatus | string)[] = [AutopayEnrollmentStatus.Archived];

export const StatusOptions: TestedOption<PayeeRowModel>[] = [
  'INCOMPLETE',
  ...Object.values(AutopayEnrollmentStatus),
]
  .filter((status) => !excludedStatuses.includes(status))
  .map((status) => ({
    text: capitalize(status),
    id: status,
    test: (row: PayeeRowModel) => row.status === status,
  }));

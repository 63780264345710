import {
  useDeleteJournalEntryMutation,
  useGetJournalEntriesForJeLazyQuery,
  useGetJournalEntryLazyQuery,
} from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useModalControl } from 'hooks/useModalControl';
import { useNotification } from 'hooks/useNotification';
import { compact, map, uniq } from 'lodash';
import { useClearableCache } from './cache';

export const useDeleteJournal = () => {
  const { sendNotification } = useNotification();
  const [showConfirmModal, hideConfirmModal, confirmModalOpen, journalIdToDelete] =
    useModalControl<string>();

  const [deleteJournalMutation, { loading, error }] = useDeleteJournalEntryMutation();
  useAllErrors(error);

  const [getJE] = useGetJournalEntriesForJeLazyQuery();
  const [getJournal] = useGetJournalEntryLazyQuery();
  const { deleteClearablesCache } = useClearableCache();

  const deleteJournal = async (
    id: string,
    { notification = true }: { notification?: boolean } = {}
  ) => {
    try {
      const { data: journalData } = await getJournal({ variables: { id } });
      if (journalData?.journalEntry) {
        const { data: jeData } = await getJE({
          variables: { jeId: journalData?.journalEntry?.jeId },
        });

        const journalIds = compact(uniq(map(jeData?.account?.books?.journalEntriesForJE, 'id')));

        const clearableIds = compact(
          uniq(map(jeData?.account?.books?.journalEntriesForJE, 'clearableId'))
        );

        await deleteJournalMutation({
          variables: { id },
          async update(cache, { data }) {
            if (data?.deleteJournalEntry?.success) {
              journalIds.forEach((journalId) =>
                cache.evict({ id: cache.identify({ id: journalId, __typename: 'JournalEntry' }) })
              );

              deleteClearablesCache(clearableIds, { gc: false });
              cache.gc();
            }
          },
        });
        if (notification) sendNotification('Entry deleted', 'success');
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return {
    showConfirmModal,
    hideConfirmModal,
    confirmModalOpen,
    journalIdToDelete,
    deleteJournal,
    loading,
  };
};

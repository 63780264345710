import {
  API,
  useAddReconciliationConfigMutation,
  useDeleteReconciliationConfigMutation,
  useListReconciliationConfigsQuery,
  useUpdateReconciliationConfigMutation,
} from 'api';
import { useAuth } from 'context';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { useCallback } from 'react';
import { stripNonData } from 'system';
import { array, object, string } from 'yup';

export type ReconciliationConfigFormFields = {
  id?: string;
  glId: string;
  name: string;
  bankId?: string;
  propertyOwnerIds?: string[];
};

export type UpdateReconciliationConfigFormFields = {
  id: string;
} & Partial<ReconciliationConfigFormFields>;

export const useReconciliationConfig = (skip?: boolean) => {
  const { isBooksUser } = useAuth();

  const { items: reconciliationConfigs, ...meta } = usePaginateAllQuery(
    useListReconciliationConfigsQuery,
    {
      skip: skip || !isBooksUser,
      getNextToken: (data) => data?.account?.books?.listReconciliationConfigs?.nextToken,
      getItems: (data) => data?.account?.books?.listReconciliationConfigs?.items,
    }
  );

  const { loading } = useMeta(meta);

  const findConfig = useCallback(
    (id?: string) => reconciliationConfigs.find((cfg) => cfg.id === id),
    [reconciliationConfigs]
  );

  return {
    loading,
    reconciliationConfigs,
    findConfig,
  };
};

export const addReconciliationSchema = object().shape({
  id: string(),
  glId: string().required('Please select a GL Account'),
  name: string().required('Please enter a name for this reconciliation group'),
  propertyOwnerIds: array().of(string()).nullable(true),
});

export const useAddReconciliationConfig = () => {
  const { sendNotification } = useNotification();
  const [addReconciliationMutation, { error, loading }] = useAddReconciliationConfigMutation();
  useErrorNotifications(error);

  const addReconciliationConfig = useCallback(
    async (values: ReconciliationConfigFormFields) => {
      try {
        const response = await addReconciliationMutation({
          variables: { input: stripNonData(values) },
          refetchQueries: [API.Query.GetReconciliations],
        });
        sendNotification('Group has been created successfully', 'success');
        return response.data?.addReconciliationConfig?.reconciliationConfig?.id;
      } catch (e) {
        console.warn('Failed to add reconciliation config', e);
        sendNotification('Something went wrong. Try again', 'error');
        return false;
      }
    },
    [addReconciliationMutation, sendNotification]
  );

  return {
    loading,
    addReconciliationConfig,
  };
};

export const updateReconciliationSchema = addReconciliationSchema.shape({
  id: string().required(),
});

export const useUpdateReconciliationConfig = () => {
  const { sendNotification } = useNotification();
  const [updateReconciliationMutation, { error, loading }] =
    useUpdateReconciliationConfigMutation();
  useErrorNotifications(error);

  const updateReconciliationConfig = useCallback(
    async (values: UpdateReconciliationConfigFormFields) => {
      try {
        const response = await updateReconciliationMutation({
          variables: { input: stripNonData(values) },
          refetchQueries: [API.Query.GetReconciliations],
        });

        sendNotification('Group has been updated successfully', 'success');
        return response.data?.updateReconciliationConfig?.reconciliationConfig?.id;
      } catch (e) {
        console.warn('Failed to update reconciliation config', e);
        sendNotification('Something went wrong. Try again', 'error');
        return false;
      }
    },
    [sendNotification, updateReconciliationMutation]
  );

  return {
    loading,
    updateReconciliationConfig,
  };
};

export const useDeleteReconciliationConfig = () => {
  const { sendNotification } = useNotification();
  const [deleteReconciliationMutation, { error, loading }] =
    useDeleteReconciliationConfigMutation();
  useErrorNotifications(error);

  const deleteReconciliationConfig = useCallback(
    async (id: string) => {
      try {
        await deleteReconciliationMutation({
          variables: { input: { id } },
          refetchQueries: [API.Query.GetReconciliations],
        });
        sendNotification('Group has been deleted successfully', 'success');
        return true;
      } catch (e) {
        console.warn('Failed to delete reconciliation config', e);
        sendNotification('Something went wrong. Try again', 'error');
        return false;
      }
    },
    [deleteReconciliationMutation, sendNotification]
  );

  return {
    loading,
    deleteReconciliationConfig,
  };
};

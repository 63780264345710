import { styled, Typography } from '@mui/material';

export const Name = styled(Typography)({
  fontWeight: 500,
  fontSize: '1rem',
});

export const Tenant = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
}));

export const Bubble = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primaryBackground.main,
  color: theme.palette.primary.main,
  minWidth: theme.spacing(5),
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2em',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(2),
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: '0.8em',
  },
}));

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayBankAccountAddedEvent } from './bankAccountAdded';
import ManagerAutopayBankAccountAddedEventSchema from './bankAccountAdded.json';

export const isManagerAutopayBankAccountAddedEvent = guard<ManagerAutopayBankAccountAddedEvent>(ManagerAutopayBankAccountAddedEventSchema as any);
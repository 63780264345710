import { JournalEntryFieldsFragment, useVoidJournalEntryMutation } from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useModalControl } from 'hooks/useModalControl';

export const useVoid = () => {
  const [voidJournalEntryMutation, { loading, error }] = useVoidJournalEntryMutation();
  useAllErrors(error);

  const [showVoidModal, hideVoidModal, voidModalOpen, journalToVoid] =
    useModalControl<JournalEntryFieldsFragment>();

  const voidJournal = async ({ jeId }: { jeId: string }) => {
    await voidJournalEntryMutation({ variables: { input: { jeId } } });
  };

  return {
    showVoidModal,
    hideVoidModal,
    voidModalOpen,
    journalToVoid,
    voidJournal,
    loading,
  };
};

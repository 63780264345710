import { Skeleton, styled, Typography } from '@mui/material';
import { FullSizePaper } from 'components';

const StatPaper = styled(FullSizePaper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(1, 2),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 4),
    marginTop: 0,
  },
}));

const Label = styled(Typography)({
  fontSize: 13,
  fontWeight: 500,
  flex: 1,
  letterSpacing: 0.15,
});

const Content = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: 0.15,
});

type StatProps = {
  label: string;
  content: string;
  loading?: boolean;
};

export default function Stat({ label, content, loading }: StatProps) {
  return (
    <StatPaper>
      <Label>{label}</Label>
      <Content>{loading ? <Skeleton variant="text" height={36} width="50%" /> : content}</Content>
    </StatPaper>
  );
}

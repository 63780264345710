import {
  Box,
  Paper as _Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell as _TableCell,
  TableContainer,
  TableFooter,
  TableRow as _MaterialTableRow,
} from '@mui/material';
import { skipProps, spreadIf } from 'system';
import { AdaptiveTableProps } from '../types';
import TableHead from './TableHead';
import TableRow from './TableRow';
import TableToolbar from './TableToolbar';

const Paper = styled(
  _Paper,
  skipProps('marginless', 'hideBorder')
)<{ marginless?: boolean; hideBorder?: boolean }>(({ marginless, hideBorder, theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  border: hideBorder ? 0 : `1px solid ${theme.palette.divider}`,
  borderBottom: 'none',
  ...spreadIf(marginless, {
    margin: 0,
  }),
}));

const MaterialTableRow = styled(
  _MaterialTableRow,
  skipProps('hideBorder')
)<{ hideBorder?: boolean }>(({ hideBorder, theme }) => ({
  height: theme.spacing(6.625),
  borderBottom: hideBorder ? 0 : `1px solid ${theme.palette.divider}`,
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
  alignSelf: 'center',
  padding: theme.spacing(2),
  border: 'none',
}));

export default function DesktopTable({
  rows,
  columns,
  onRowClick,
  loading,
  error,
  toolbarComponent,
  footerComponent,
  header = 'always',
  cellPadding,
  hasMargins = true,
  condensed,
  sx,
  headerRowSx,
  hideBorder,
}: AdaptiveTableProps) {
  const showHeader = header === 'always' || header === 'desktopOnly';

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Paper marginless={!hasMargins} hideBorder={hideBorder} elevation={0}>
        <TableContainer>
          {toolbarComponent && (
            <TableToolbar
              toolbarComponent={
                typeof toolbarComponent === 'function'
                  ? toolbarComponent({ rows })
                  : toolbarComponent
              }
            />
          )}
          <Table aria-labelledby="tableTitle" size="medium" aria-label="adaptive table">
            {showHeader && (
              <TableHead sx={headerRowSx} columns={columns.filter(({ hidden }) => !hidden)} />
            )}
            <TableBody>
              {loading && (
                <MaterialTableRow hideBorder={hideBorder}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </MaterialTableRow>
              )}
              {!loading && error && (
                <MaterialTableRow hideBorder={hideBorder}>
                  <TableCell>{JSON.stringify(error)}</TableCell>
                </MaterialTableRow>
              )}
              {!loading &&
                !error &&
                rows.map((row, id) => (
                  <TableRow
                    key={id}
                    {...{
                      row,
                      onRowClick,
                      condensed,
                      hideBorder,
                    }}
                    columns={columns.filter(({ hidden }) => !hidden)}
                    cellPadding={cellPadding}
                  />
                ))}
            </TableBody>
            {footerComponent && (
              <TableFooter>
                <tr>
                  <td colSpan={99}>{footerComponent}</td>
                </tr>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

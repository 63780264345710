import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Dispatch } from 'react';
import { logout, updateUser } from '../actions';
import { AuthAction } from '../types';

type Action = () => void;
let isRefreshing = false;
let pendingRequests: Action[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Action) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const isDev = process.env.NODE_ENV === 'development';

const errorLink = (dispatch: Dispatch<AuthAction>) =>
  onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.message) {
          case 'Token has expired.':
            if (!isRefreshing) {
              setIsRefreshing(true);

              return fromPromise(
                updateUser(dispatch).catch(() => {
                  resolvePendingRequests();
                  setIsRefreshing(false);

                  logout(dispatch);

                  return forward(operation);
                })
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>((resolve) => {
                  addPendingRequest(() => resolve());
                })
              ).flatMap(() => {
                return forward(operation);
              });
            }
          case err.message.startsWith('Duplicated operation') && err.message && !isDev:
            console.log(err.message);
            if (response) response.errors = undefined;
            break;
          case err.message.startsWith('Cannot return null for non-nullable type:') &&
            err.message &&
            !isDev:
            console.log(err.message);
            if (response) response.errors = undefined;
            break;
        }
      }
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
      logout(dispatch);
    }
  });

export default errorLink;

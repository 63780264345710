import {
  DraftedJournalFilter,
  useServerDraftedJournalsQuery,
  useServerReconciliationJournalsQuery,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { useCallback, useEffect, useState } from 'react';
import { safeSum } from 'system';
import { ReconciliationStats, useReconciliation } from './useReconciliation';
import { useRefetch } from './useRefetch';

export const useReconciliationStats = ({
  reconciliation,
  filter,
  skip,
}: {
  reconciliation?: ReturnType<typeof useReconciliation>['reconciliation'];
  filter?: DraftedJournalFilter;
  skip?: boolean;
}) => {
  const [reconciliationStats, setReconciliationStats] = useState<ReconciliationStats>({
    statementBalance: 0,
    previousBalance: 0,
    endingBalance: 0,
    accountBalance: 0,
    difference: 0,

    totalCleared: 0,
    clearedDepositsCount: 0,
    totalDepositsCleared: 0,
    clearedWithdrawalsCount: 0,
    totalWithdrawalsCleared: 0,

    totalUncleared: 0,
    unclearedDepositsCount: 0,
    totalDepositsUncleared: 0,
    unclearedWithdrawalsCount: 0,
    totalWithdrawalsUncleared: 0,
  });

  const glId = reconciliation?.scope?.glId ?? '';
  const reconciliationId = reconciliation?.id ?? '';
  const draftReconciliationId = reconciliation?.status === 'DRAFT' ? (reconciliationId ?? '') : '';

  const { data: draftData, ...draftMeta } = useServerDraftedJournalsQuery({
    skip: skip || !draftReconciliationId,
    variables: {
      first: 0,
      filter: { ...filter, draftReconciliationId },
    },
  });

  const { data: searchData, ...searchMeta } = useServerReconciliationJournalsQuery({
    skip,
    variables: {
      first: 0,
      filter: {
        ...filter,
        glId,
        reconciliationId,
        statementDate: reconciliation?.statementDate,
        propertyOwnerIds: reconciliation?.scope?.propertyOwnerIds,
      },
    },
  });

  const { loading } = useMeta(draftMeta, searchMeta);

  const { handleRefresh: refreshMeta } = useRefetch(draftMeta);
  const { handleRefresh: refreshSearch } = useRefetch(searchMeta);
  const handleRefresh = useCallback(async () => {
    await refreshSearch();
    await refreshMeta();
  }, [refreshMeta, refreshSearch]);

  const journalPage = searchData?.account?.books?.pageJournalEntriesForReconciliation;
  const draftedPage = draftData?.account?.books?.pageDraftedJournalEntries;

  useEffect(() => {
    const statementBalance = reconciliation?.statementBalance ?? 0;
    const previousBalance = reconciliation?.lastStatementBalance ?? 0;

    const totalWithdrawalsCleared =
      draftedPage?.totalWithdrawalsCleared ?? journalPage?.totalWithdrawalsCleared ?? 0;

    const totalDepositsCleared =
      draftedPage?.totalDepositsCleared ?? journalPage?.totalDepositsCleared ?? 0;

    const totalCleared = safeSum(totalWithdrawalsCleared, totalDepositsCleared);
    const endingBalance = safeSum(previousBalance, totalCleared);
    const difference = safeSum(statementBalance, -endingBalance);

    const totalDepositsUncleared =
      (journalPage?.totalDeposits ?? 0) -
      (draftedPage?.totalDepositsCleared ?? journalPage?.totalDepositsCleared ?? 0);

    const totalWithdrawalsUncleared =
      (journalPage?.totalWithdrawals ?? 0) -
      (draftedPage?.totalWithdrawalsCleared ?? journalPage?.totalWithdrawalsCleared ?? 0);

    const totalUncleared = safeSum(totalDepositsUncleared, totalWithdrawalsUncleared);
    const accountBalance = safeSum(endingBalance, totalUncleared);

    setReconciliationStats((stats) => ({
      ...stats,
      statementBalance,
      previousBalance,

      totalCleared,
      clearedDepositsCount:
        draftedPage?.clearedDepositsCount ?? journalPage?.clearedDepositsCount ?? 0,
      totalDepositsCleared,
      clearedWithdrawalsCount:
        draftedPage?.clearedWithdrawalsCount ?? journalPage?.clearedWithdrawalsCount ?? 0,
      totalWithdrawalsCleared,

      totalUncleared,
      unclearedDepositsCount:
        draftedPage?.unclearedDepositsCount ?? journalPage?.unclearedDepositsCount ?? 0,
      totalDepositsUncleared,
      unclearedWithdrawalsCount:
        draftedPage?.unclearedWithdrawalsCount ?? journalPage?.unclearedWithdrawalsCount ?? 0,
      totalWithdrawalsUncleared,

      endingBalance,
      difference,
      accountBalance,
    }));
  }, [reconciliation, draftedPage, journalPage]);

  return {
    reconciliationStats,
    loading,
    handleRefresh,
  };
};

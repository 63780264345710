import { WebSocketLink } from '@apollo/client/link/ws';
import { createAppSyncAuthorizedWebSocket } from './appSyncAuthorizedWebSocket';
import { createAppSyncGraphQLOperationAdapter } from './appSyncGraphQLOperationAdapter';
import { cacheWithAsyncRefresh } from './asyncUtils';
import { UUIDOperationIdSubscriptionClient } from './UUIDOperationIdSubscriptionClient';

const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000;

export type StatusChangeHandler = (
  status: 'connecting' | 'connected' | 'disconnected' | 'error' | 'reconnecting' | 'reconnected'
) => void;

export const createAppSyncSubscriptionWebsocketLink = ({
  appSyncApiUrl,
  getJwtToken,
  onStatusChange,
}: {
  appSyncApiUrl: string;
  getJwtToken: () => string;
  onStatusChange?: StatusChangeHandler;
}) => {
  const appSyncApiHost = new URL(appSyncApiUrl).host;
  const getAppSyncAuthorizationInfo = () => ({
    host: appSyncApiHost,
    Authorization: getJwtToken(),
  });

  const realtimeUrl = (host: string) => {
    const url = `wss://${host.replace('appsync-api', 'appsync-realtime-api')}/graphql`;
    return url.includes('amazonaws.com') ? url : `${url}/realtime`;
  };

  const subClient = new UUIDOperationIdSubscriptionClient(
    realtimeUrl(appSyncApiHost),
    { timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS, reconnect: true, lazy: true },
    // We want to avoid expired authorization information being used but SubscriptionClient synchronously
    // instantiates websockets (on connection/reconnection) so the best we can do is schedule an async refresh
    // and suffer failed connection attempts until a fresh token has been retrieved
    createAppSyncAuthorizedWebSocket(cacheWithAsyncRefresh(getAppSyncAuthorizationInfo))
  ).use([createAppSyncGraphQLOperationAdapter(getAppSyncAuthorizationInfo)]);

  onStatusChange && subClient.onDisconnected(() => onStatusChange('disconnected'));
  onStatusChange && subClient.onConnected(() => onStatusChange('connected'));
  onStatusChange && subClient.onConnecting(() => onStatusChange('connecting'));
  onStatusChange && subClient.onError(() => onStatusChange('error'));
  onStatusChange && subClient.onReconnected(() => onStatusChange('reconnected'));
  onStatusChange && subClient.onReconnecting(() => onStatusChange('reconnecting'));

  return new WebSocketLink(subClient);
};

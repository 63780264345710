import { ButtonProps, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { skipProps } from 'system';
import _LoadingButton from './LoadingButton';

type BackButtonProps = {
  buttonText?: string;
  loading?: boolean;
  sx?: ButtonProps['sx'];
};

export const LoadingButton = styled(
  _LoadingButton,
  skipProps('noMargin')
)(({ theme }) => ({
  fontSize: 14,
  marginRight: 3,
  background: theme.palette.background.paper,
}));

export default function BackButton({ buttonText = 'Back', loading = false, sx }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <LoadingButton
      loading={loading}
      color="primary"
      variant="outlined"
      onClick={() => navigate(-1)}
      sx={sx}
    >
      {buttonText}
    </LoadingButton>
  );
}

import { Close } from '@mui/icons-material';
import { IconButton } from 'components';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

const closeSnackbarActionFor =
  (closeSnackbar: ReturnType<typeof useSnackbar>['closeSnackbar']) => (key: SnackbarKey) => (
    <IconButton onClick={() => closeSnackbar(key)}>
      <Close />
    </IconButton>
  );

export const useNotification = ({ shouldNotify = true }: { shouldNotify?: boolean } = {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendNotification = useCallback(
    (message: string, type?: VariantType) => {
      if (type === 'error') {
        console.error(message);
      }

      shouldNotify &&
        enqueueSnackbar((message as unknown as Error).message || message, {
          variant: type ?? 'default',
          autoHideDuration: 5000,
          action: closeSnackbarActionFor(closeSnackbar),
        });
    },
    [closeSnackbar, enqueueSnackbar, shouldNotify]
  );

  return {
    sendNotification,
  };
};

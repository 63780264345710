import { PropertyListFieldsFragment } from 'api';
import { compact, isEmpty, map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { emptyArray } from 'system';

export const usePropertySelection = ({
  properties,
  selected,
  onChange,
}: {
  properties: PropertyListFieldsFragment[];
  selected: string[] | null;
  onChange: (propertyIds: string[]) => void;
}) => {
  const handleChange = (_: unknown, newSelection: (PropertyListFieldsFragment | undefined)[]) => {
    onChange(compact(map(newSelection, 'id')));
  };
  useEffect(() => {
    if (selected === null && !isEmpty(properties)) {
      onChange(map(properties, 'id'));
    }
  }, [selected, properties, onChange]);

  const toggleIsSelectAll = () => {
    if (isAllSelected) {
      onChange(emptyArray);
    } else {
      onChange(map(properties, 'id'));
    }
  };

  const isAllSelected = useMemo(
    () => selected?.length === properties.length,
    [selected, properties]
  );

  return {
    isAllSelected,
    handleChange,
    toggleIsSelectAll,
  };
};

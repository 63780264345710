import { ApolloCache } from '@apollo/client';
import {
  AccountType,
  CreateGlAccountInput,
  GlAccountFieldsFragment,
  GlAccountFieldsFragmentDoc,
  UpdateGlAccountInput,
  useCreateGlAccountMutation,
  useUpdateGlAccountMutation,
} from 'api';
import { GlobalFilter, Row } from 'components';
import { useAuth } from 'context';
import { useMeta } from 'hooks/useMeta';
import { useModalControl } from 'hooks/useModalControl';
import { useNotification } from 'hooks/useNotification';
import { useCallback } from 'react';
import { ensureArray } from 'system';
import { GLCategory } from '../categories';
import { useBooks } from './useBooks';

export type GlAccountFormFields = {
  key?: string;
  name: string;
  accountType: AccountType;
  category: GLCategory;
};

export const glAccountsGlobalFilter: GlobalFilter = [
  {
    label: 'Assets',
    test: (row: Row) => row.accountType === AccountType.Asset,
  },
  {
    label: 'Liabilities',
    test: (row: Row) => row.accountType === AccountType.Liability,
  },
  {
    label: 'Equity',
    test: (row: Row) => row.accountType === AccountType.Equity,
  },
  {
    label: 'Expense',
    test: (row: Row) => row.accountType === AccountType.Expense,
  },
  {
    label: 'Revenue',
    test: (row: Row) => row.accountType === AccountType.Revenue,
  },
];

export const useGlAccounts = () => {
  const { accountId } = useAuth();
  const { sendNotification } = useNotification();

  const { books, ...booksMeta } = useBooks();
  const glAccounts = ensureArray(books?.glAccounts);

  const [createGlAccountMutation, createMeta] = useCreateGlAccountMutation();
  const [updateGlAccountMutation, updateMeta] = useUpdateGlAccountMutation();
  const { loading } = useMeta(booksMeta, createMeta, updateMeta);

  const [handleShowModal, handleHideModal, isModalOpen, glAccountToEdit] =
    useModalControl<GlAccountFieldsFragment>();

  const cacheGlAccount = (cache: ApolloCache<unknown>) => (glAccount: GlAccountFieldsFragment) =>
    cache.writeFragment({ data: glAccount, fragment: GlAccountFieldsFragmentDoc });

  const addGlAccount = async (values: GlAccountFormFields) => {
    const input: CreateGlAccountInput = {
      key: values.key,
      name: values.name,
      accountType: values.accountType,
      category: values.category,
    };

    try {
      await createGlAccountMutation({
        variables: {
          input,
        },
        update(cache, result) {
          const glAccount = result.data?.createGLAccount?.glAccount;
          if (glAccount) {
            cache.modify({
              id: cache.identify({ accountId, __typename: 'Books' }),
              fields: {
                glAccounts(existing = []) {
                  return [...existing, cacheGlAccount(cache)(glAccount)];
                },
              },
            });
          }
        },
      });
      handleHideModal();
      sendNotification(`${input.name} has been added!`, 'success');
    } catch (e) {
      console.error(e);
    }
  };

  const showEditGlAccount = (id: string) => {
    handleShowModal(glAccounts.find((glAccount) => glAccount.id === id));
  };

  const editGlAccount = async (values: GlAccountFormFields) => {
    const input: UpdateGlAccountInput = {
      id: glAccountToEdit?.id ?? '',
      key: values.key,
      name: values.name,
      category: values.category,
      accountType: values.accountType,
    };

    try {
      await updateGlAccountMutation({
        variables: {
          input,
        },
      });
      handleHideModal();
      sendNotification(`${input.name} has been updated!`, 'success');
    } catch (e) {
      console.error(e);
    }
  };

  const onValidateUniqueKeyName = (initialKey?: string, value?: string) => {
    return (
      !value ||
      !glAccounts.map((glAccount) => glAccount.key).includes(value) ||
      initialKey === value
    );
  };

  const findGlAccount = useCallback(
    (glId?: string) => glAccounts.find(({ id }) => id === glId),
    [glAccounts]
  );

  const isBalanceSheetAccount = useCallback(
    (glId?: string) => {
      const type = findGlAccount(glId)?.accountType;
      return type && [AccountType.Asset, AccountType.Liability, AccountType.Equity].includes(type);
    },
    [findGlAccount]
  );

  const searchGlIdByName = useCallback(
    (name?: string) => glAccounts?.find((glAccount) => glAccount.name === name)?.id,
    [glAccounts]
  );

  const glAccountName = useCallback((glId?: string) => findGlAccount(glId)?.name, [findGlAccount]);

  return {
    loading,
    glAccounts,
    findGlAccount,
    isBalanceSheetAccount,
    glAccountName,
    searchGlIdByName,
    addGlAccount,
    handleShowModal,
    handleHideModal,
    isModalOpen,
    showEditGlAccount,
    glAccountToEdit,
    editGlAccount,
    onValidateUniqueKeyName,
    submitLoading: createMeta.loading || updateMeta.loading,
  };
};

import { Alert, Grid, InputAdornment, TextField } from '@mui/material';
import { Note } from 'api';
import { AttributeLabel, BodyText, LoadingButton } from 'components';
import { orderBy } from 'lodash';
import React from 'react';
import { NoteType } from 'system';
import NoteCard from './NoteCard';
import { useNotes } from './hooks/useNotes';

type NotesProps = {
  loading: boolean;
  notes?: Note[];
  id?: string;
  type: NoteType;
  addInput?: Record<string, unknown>;
  removeInput?: Record<string, unknown>;
  disabled?: boolean;
};

export default function Notes({
  id,
  loading,
  notes,
  type,
  addInput,
  removeInput,
  disabled,
}: NotesProps) {
  const { text, setText, handleAdd, addLoading, deleteLoading, handleDelete } = useNotes(
    type,
    id,
    addInput,
    removeInput
  );

  const sortedNotes = orderBy(notes, 'createdZ', 'desc');
  const operatorNotes = sortedNotes.filter((note) => note.source === 'operator');
  const managerNotes = sortedNotes.filter((note) => !note.source || note.source === 'manager');
  const residentNotes = sortedNotes.filter((note) => note.source === 'resident');

  const noteSections = [
    { label: 'Manager Notes', notes: managerNotes },
    { label: 'Operator Notes', notes: operatorNotes },
    { label: 'Resident Notes', notes: residentNotes },
  ];

  return (
    <Grid>
      {!disabled && (
        <>
          <Grid item>
            <AttributeLabel>Add a note</AttributeLabel>
          </Grid>
          <Grid item sx={{ my: 2 }}>
            <TextField
              multiline
              fullWidth
              value={text}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
              variant="outlined"
              disabled={addLoading}
              onKeyDown={(e) => ((e.metaKey || e.ctrlKey) && e.key === 'Enter' ? handleAdd() : {})}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ flex: 'end' }}>
                    <LoadingButton
                      color="primary"
                      disabled={!text}
                      onClick={handleAdd}
                      loading={addLoading}
                    >
                      Post
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      )}

      {loading ? (
        <Grid item sx={{ mt: 2 }}>
          <BodyText loading={loading}>loading...</BodyText>
        </Grid>
      ) : notes?.[0] ? (
        <>
          {noteSections.map(({ label, notes: sectionNotes }) => (
            <Grid key={label} item sx={{ mt: 2 }}>
              {sectionNotes.length > 0 && (
                <Grid item>
                  <AttributeLabel>{label}</AttributeLabel>
                </Grid>
              )}
              {sectionNotes.map((note) => (
                <Grid item key={note.id} sx={{ mt: 2 }}>
                  <NoteCard
                    {...{
                      loading,
                      note,
                      handleDelete,
                      deleteLoading,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </>
      ) : (
        <Alert severity="info">There are no notes to show</Alert>
      )}
    </Grid>
  );
}

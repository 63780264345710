/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerTenantProfileAssociatedEvent } from './profileAssociated';
import ManagerTenantProfileAssociatedEventSchema from './profileAssociated.json';

export const isManagerTenantProfileAssociatedEvent = guard<ManagerTenantProfileAssociatedEvent>(ManagerTenantProfileAssociatedEventSchema as any);
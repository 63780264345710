/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApplicationLeaseSignedEvent } from './applicationLeaseSigned';
import ManagerApplicationLeaseSignedEventSchema from './applicationLeaseSigned.json';

export const isManagerApplicationLeaseSignedEvent = guard<ManagerApplicationLeaseSignedEvent>(ManagerApplicationLeaseSignedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseTenantAddedEvent } from './tenantAdded';
import ManagerLeaseTenantAddedEventSchema from './tenantAdded.json';

export const isManagerLeaseTenantAddedEvent = guard<ManagerLeaseTenantAddedEvent>(ManagerLeaseTenantAddedEventSchema as any);
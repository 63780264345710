/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksClearableUnitUpdatedEvent } from './clearableUnitUpdated';
import ManagerBooksClearableUnitUpdatedEventSchema from './clearableUnitUpdated.json';

export const isManagerBooksClearableUnitUpdatedEvent = guard<ManagerBooksClearableUnitUpdatedEvent>(ManagerBooksClearableUnitUpdatedEventSchema as any);
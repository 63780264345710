import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { useTheme as usePropraTheme } from 'context';
import { colours } from 'system';

export default function Props({ disabled, ...props }: SvgIconProps & { disabled?: boolean }) {
  const theme = useTheme();
  const { darkMode } = usePropraTheme();

  const mainColor = disabled ? theme.palette.text.disabled : theme.palette.primary.main;
  const stopColor = disabled
    ? theme.palette.text.disabled
    : darkMode
      ? colours.purpleHeart
      : colours.lightSlateBlue;

  return (
    <SvgIcon width="16" viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
          fill="white"
        />
        <rect width="16" height="16" fill={mainColor} />
        <path
          d="M16.0016 15.9998V8.785L10.6681 4.44426H7.26071L6.00145 3.40723L4.74219 4.44426V12.6665L5.80897 13.5331L8.90527 15.9998H16.0016Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M8.21976 3.40723C7.46203 3.40723 6.74525 3.65455 6.17183 4.08737V3.63394C6.17183 3.51028 6.06944 3.40723 5.94656 3.40723H4.75876C4.73828 3.40723 4.73828 3.40723 4.73828 3.42784V12.6819C4.73828 12.6819 4.73828 12.6819 4.75876 12.6819H6.17183C6.17183 12.6819 6.17183 12.6819 6.17183 12.6613V9.73463C6.74525 10.1674 7.46203 10.4148 8.21976 10.4148C10.1448 10.4148 11.7012 8.84838 11.7012 6.911C11.7012 4.97362 10.1448 3.40723 8.21976 3.40723ZM8.21976 8.97204C7.0934 8.97204 6.17183 8.04457 6.17183 6.911C6.17183 5.77743 7.0934 4.84996 8.21976 4.84996C9.34612 4.84996 10.2677 5.77743 10.2677 6.911C10.2677 8.04457 9.34612 8.97204 8.21976 8.97204Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="8.5349"
          y1="7.46648"
          x2="15.0682"
          y2="12.6665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={stopColor} />
          <stop offset="1" stopColor={stopColor} stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

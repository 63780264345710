/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayPropertyDeletedEvent } from './propertyDeleted';
import ManagerAutopayPropertyDeletedEventSchema from './propertyDeleted.json';

export const isManagerAutopayPropertyDeletedEvent = guard<ManagerAutopayPropertyDeletedEvent>(ManagerAutopayPropertyDeletedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerRequestRecurrenceUpdatedEvent } from './recurrenceUpdated';
import ManagerRequestRecurrenceUpdatedEventSchema from './recurrenceUpdated.json';

export const isManagerRequestRecurrenceUpdatedEvent = guard<ManagerRequestRecurrenceUpdatedEvent>(ManagerRequestRecurrenceUpdatedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReportUpdatedEvent } from './reportUpdated';
import ManagerBooksReportUpdatedEventSchema from './reportUpdated.json';

export const isManagerBooksReportUpdatedEvent = guard<ManagerBooksReportUpdatedEvent>(ManagerBooksReportUpdatedEventSchema as any);
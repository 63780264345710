/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerReportsGenerateLeaseInspectionUrlAction } from './leaseInspection';
import ManagerReportsGenerateLeaseInspectionUrlActionSchema from './leaseInspection.json';

export const isManagerReportsGenerateLeaseInspectionUrlAction = guard<ManagerReportsGenerateLeaseInspectionUrlAction>(ManagerReportsGenerateLeaseInspectionUrlActionSchema as any);
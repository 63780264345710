import { Skeleton, SkeletonProps, Typography, TypographyProps } from '@mui/material';

export const LoadingTypography = ({
  loading,
  children,
  skeletonProps,
  ...props
}: TypographyProps & { loading?: boolean; skeletonProps?: SkeletonProps }) => (
  <Typography {...props}>
    {loading ? <Skeleton {...skeletonProps} variant="text" sx={{ width: '15ch' }} /> : children}
  </Typography>
);

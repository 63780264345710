import { AppBar as _AppBar, styled, Toolbar as _Toolbar } from '@mui/material';

export const AppBar = styled(_AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(0),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const Toolbar = styled(_Toolbar)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(2.5),
}));

export const Spacer = styled('div')({ flexGrow: 1 });

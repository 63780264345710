import { BrowserStorageCache } from '@aws-amplify/cache';
import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import { OpenInNew } from '@mui/icons-material';
import {
  alpha,
  Box,
  createTheme,
  LinkProps,
  ThemeProvider as MaterialThemeProvider,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { LicenseInfo } from '@mui/x-license-pro';
import { usePrevious } from 'hooks/usePrevious';
import { ClearableRowModel } from 'pages/accounting/hooks';
import { JournalEntryRowModel } from 'pages/accounting/hooks/useSubAccount';
import { forwardRef, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { FieldErrors, FieldName } from 'react-hook-form';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Children, colours } from 'system';
import { ThemeContext } from './useTheme';

LicenseInfo.setLicenseKey(
  '985eba06af6ae217db236fe08162589cTz05OTQzOCxFPTE3NTk3NTk5NTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
);

declare module '@mui/x-data-grid-pro' {
  interface LoadingOverlayPropsOverrides {
    // Subaccount table
    variant?: string;
    value?: number;
    valueBuffer?: number;
  }
  interface NoRowsOverlayPropsOverrides {
    // Empty state
    onConfirm?: () => void;
  }
  interface FooterPropsOverrides {
    // Error footer
    loading?: boolean;
    hideCounts?: boolean;
    errors?: string | FieldErrors | undefined;
    names?: Array<FieldName<FieldValuesFromFieldErrors<FieldErrors>>>;

    // Selected footer
    selectionModel?: GridRowSelectionModel;
    rows?: JournalEntryRowModel[];

    // Clearable table footer
    total?: number;
    totalLabel?: string;
    selectedTotal?: number;
    selectedCount?: number;
    hideTotals?: boolean;
    rowCount?: number;
    getRowTotal: (model: ClearableRowModel) => number;
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    ink: TypeText;
    tertiary: PaletteColor;
    quaternary: PaletteColor;
    quinary: PaletteColor;
    primaryBackground: PaletteColor;
    secondaryBackground: PaletteColor;
    tertiaryBackground: PaletteColor;
    inputBackground: string;
    lightDivider: string;

    units: {
      leased: PaletteColor;
      commercial: PaletteColor;
      owner: PaletteColor;
      common: PaletteColor;
      empty: PaletteColor;
    };
  }
  export interface PaletteOptions {
    ink?: Partial<TypeText>;
    tertiary?: PaletteColorOptions;
    quaternary?: PaletteColorOptions;
    quinary?: PaletteColorOptions;
    primaryBackground?: PaletteColorOptions;
    secondaryBackground?: PaletteColorOptions;
    tertiaryBackground?: PaletteColorOptions;
    inputBackground: string;
    lightDivider: string;

    units: {
      leased: PaletteColorOptions;
      commercial: PaletteColorOptions;
      owner: PaletteColorOptions;
      common: PaletteColorOptions;
      empty: PaletteColorOptions;
    };
  }

  export interface TypeBackground {
    translucentPaper: string;
  }
}

declare module '@mui/material/Button' {
  export interface ButtonPropsVariantOverrides {
    cancel: true;
    muted: true;
    dropdown: true;
  }
}
declare module '@mui/material/ButtonGroup' {
  export interface ButtonGroupPropsVariantOverrides {
    cancel: true;
    muted: true;
    dropdown: true;
  }
}
declare module '@mui/material/Link' {
  export interface LinkOwnProps {
    href: RouterLinkProps['to'];
    state?: RouterLinkProps['state'];
  }
}

type ThemeMode = 'light' | 'dark';

const useDarkModeDefaults = () => {
  const [theme, setTheme] = useState<ThemeMode>('light');
  const liveSystemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const currentSystemDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches;
  const previousSystemDarkMode = usePrevious(currentSystemDarkMode);

  useEffect(() => {
    const storedTheme = BrowserStorageCache.getItem('theme') as ThemeMode | null;
    setTheme(storedTheme !== null ? storedTheme : currentSystemDarkMode ? 'dark' : 'light');
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMode = (mode: ThemeMode) => {
    BrowserStorageCache.setItem('theme', mode);
    setTheme(mode);
  };

  const toggleDarkMode = () => setMode(theme === 'light' ? 'dark' : 'light');

  useEffect(() => {
    if (
      currentSystemDarkMode !== undefined &&
      previousSystemDarkMode !== undefined &&
      previousSystemDarkMode !== currentSystemDarkMode
    ) {
      setMode(currentSystemDarkMode ? 'dark' : 'light');
    }
  }, [liveSystemDarkMode, currentSystemDarkMode, previousSystemDarkMode]);

  return { darkMode: theme === 'dark', toggleDarkMode };
};

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<{
    href: RouterLinkProps['to'];
    state?: RouterLinkProps['state'];
    target?: string;
    rel?: string;
  }>
>((props, ref) => {
  const { href, children, rel: relProp, ...other } = props;
  // Map href (MUI) -> to (react-router)
  const newTab = other.target === '_blank' || other.target === '_top';
  const rel = relProp ?? (newTab ? 'noopener noreferrer' : undefined);
  return typeof href === 'string' && href.startsWith('http') ? (
    <a ref={ref} href={href} rel={rel} {...other}>
      <Stack direction="row" spacing={1} display="flex" alignItems="center">
        <Box display="inline-block">{children}</Box>
        {newTab && <OpenInNew fontSize="inherit" />}
      </Stack>
    </a>
  ) : (
    <RouterLink ref={ref} to={href} children={children} {...other} />
  );
});

export default function ThemeProvider({ children }: Children) {
  const { darkMode, toggleDarkMode } = useDarkModeDefaults();
  const themeContext = { toggleDarkMode, darkMode };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          text: {
            secondary: colours.nobel,
          },
          ink: {
            primary: darkMode ? colours.whiteSmoke : colours.black,
            secondary: colours.nobel,
          },
          primary: {
            light: colours.lightSlateBlue,
            main: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
          },
          secondary: {
            main: colours.shamrock,
          },
          tertiary: {
            main: darkMode ? colours.whiteSmoke : colours.black,
          },
          quaternary: {
            main: darkMode ? colours.darkGoldenrod : colours.orange,
          },
          quinary: {
            main: darkMode ? colours.cornflowerBlue : colours.lightSkyBlue,
          },
          units: {
            leased: {
              main: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
              dark: colours.persianIndigo,
              contrastText: darkMode ? colours.persianIndigo : colours.whiteSmoke,
              light: colours.white,
            },
            commercial: {
              light: colours.waterLeaf,
              main: colours.shamrock,
              dark: colours.elfGreen,
              contrastText: darkMode ? colours.whiteSmoke : colours.elfGreen,
            },
            owner: {
              main: darkMode ? colours.darkGoldenrod : colours.orange,
              dark: colours.bakersChocolate,
              light: colours.white,
            },
            common: {
              main: darkMode ? colours.cornflowerBlue : colours.lightSkyBlue,
              dark: colours.sanMarino,
              contrastText: darkMode ? colours.whiteSmoke : colours.sanMarino,
            },
            empty: {
              main: darkMode ? colours.black : colours.whisper,
              dark: darkMode ? colours.blackMarlin : colours.nobel,
              contrastText: darkMode ? colours.whiteSmoke : colours.black,
            },
          },
          info: {
            light: colours.purpleHeart,
            main: colours.lightSlateBlue,
            dark: colours.lightSlateBlue,
          },
          success: {
            light: colours.waterLeaf,
            main: colours.shamrock,
            dark: colours.elfGreen,
          },
          warning: {
            light: colours.moccasin,
            main: colours.orange,
            dark: colours.darkGoldenrod,
          },
          error: {
            main: colours.mahogany,
          },
          background: {
            default: darkMode ? colours.black : colours.whiteSmoke,
            paper: darkMode ? colours.nero : colours.white,
            translucentPaper: darkMode ? alpha(colours.nero, 0.9) : alpha(colours.white, 0.9),
          },
          primaryBackground: {
            main: darkMode ? colours.cherryPie : colours.magnolia,
          },
          secondaryBackground: {
            main: colours.waterLeaf,
          },
          tertiaryBackground: {
            main: darkMode ? colours.blackMarlin : colours.silver,
          },
          divider: darkMode ? colours.blackMarlin : colours.silver,
          lightDivider: darkMode ? colours.black : colours.whiteSmoke,
          inputBackground: darkMode ? alpha('#FFFFFF', 0.09) : alpha('#000000', 0.06),
        },
        components: {
          MuiAvatar: {
            styleOverrides: {
              root: {
                backgroundColor: darkMode ? colours.cherryPie : colours.magnolia,
                color: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
              },
            },
          },
          MuiButton: {
            variants: [
              {
                props: { variant: 'cancel' },
                style: {
                  color: colours.nobel,
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
              {
                props: { variant: 'muted' },
                style: {
                  color: colours.nobel,
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
              {
                props: { variant: 'dropdown' },
                style: {
                  border: '1px solid',
                  borderColor: colours.nobel,
                  padding: '8.5x 14px',
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
            ],
          },
          MuiButtonGroup: {
            variants: [
              {
                props: { variant: 'cancel' },
                style: {
                  color: colours.nobel,
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
              {
                props: { variant: 'muted' },
                style: {
                  color: colours.nobel,
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
              {
                props: { variant: 'dropdown' },
                style: {
                  border: '1px solid',
                  borderColor: colours.nobel,
                  padding: '8.5x 14px',
                  '&:active': {
                    backgroundColor: colours.whisper,
                  },
                },
              },
            ],
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                '&:visited': {
                  color: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
                },
              },
            },
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiTableContainer: {
            defaultProps: {
              sx: {
                overflow: 'inherit',
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                wordBreak: 'break-word',
              },
            },
          },
        },
        typography: {
          fontFamily: 'Work Sans',
          ...Object.fromEntries(
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'overline'].map((variant) => [
              variant,
              { fontFamily: 'Poppins' },
            ])
          ),
        },
      }),
    [darkMode]
  );

  return (
    <MaterialThemeProvider theme={theme}>
      <ThemeContext.Provider value={themeContext}>{children}</ThemeContext.Provider>
    </MaterialThemeProvider>
  );
}

import { DeleteForever } from '@mui/icons-material';
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Dialog, Link } from '@mui/material';
import { useGetS3Image } from 'hooks/useS3Storage';
import { ReactEventHandler, useCallback, useState } from 'react';
import { prefixFilename } from 'system';
import { iconLinkSx, OptimizedImage, Overlay, thumbnailStyles } from './ThumbnailImage.styles';
import { ImageType } from './types';

const MAX_RETRIES = 2;
const RETRY_DELAY = 800;

const ThumbnailImage = ({
  showEnlargeIcon,
  active,
  baseUrl,
  alt,
  imageKey,
  size = 'normal',
  onDelete,
  downloadOriginal,
}: {
  active?: boolean;
  showEnlargeIcon?: boolean;
  imageKey?: string;
  alt?: string;
  baseUrl?: string;
  size?: 'small' | 'normal' | 'medium';
  onDelete?: (imageKey: string) => void;
  downloadOriginal?: boolean;
}) => {
  const thumbnailKey = `${baseUrl ? `${baseUrl}/` : ''}${prefixFilename({
    filename: imageKey,
    prefix: ImageType.thumbnail,
  })}`;
  const optimizedKey = `${baseUrl ? `${baseUrl}/` : ''}${prefixFilename({
    filename: imageKey,
    prefix: ImageType.optimized,
  })}`;
  const thumbnailUrl = useGetS3Image(thumbnailKey);
  const optimizedUrl = useGetS3Image(optimizedKey);
  const originalUrl = useGetS3Image(downloadOriginal ? imageKey : '');
  const [open, setOpen] = useState(false);

  const [hover, setHover] = useState(false);
  const [retries, setRetries] = useState(0);
  const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
    ({ currentTarget }) => {
      setTimeout(() => {
        if (retries > MAX_RETRIES - 1) {
          currentTarget.onerror = null;
        }
        currentTarget.src = thumbnailUrl ?? 'http://invalid';
        setRetries((current) => current + 1);
      }, RETRY_DELAY);
    },
    [thumbnailUrl, retries]
  );

  return thumbnailUrl ? (
    <div>
      <img
        alt={alt}
        src={thumbnailUrl}
        onError={handleError}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={thumbnailStyles({ size, active, hover })}
      />
      {showEnlargeIcon && active && (
        <>
          <Overlay>
            <AspectRatioTwoToneIcon sx={iconLinkSx} onClick={() => setOpen(true)} />

            <Link
              sx={iconLinkSx}
              href={downloadOriginal ? originalUrl : optimizedUrl}
              download={downloadOriginal ? imageKey : optimizedKey}
              target="_blank"
              component="a"
            >
              <GetAppIcon />
            </Link>

            {onDelete && imageKey && (
              <DeleteForever sx={iconLinkSx} onClick={() => onDelete(imageKey)} />
            )}
          </Overlay>

          <Dialog open={open} onClose={() => setOpen(false)}>
            <OptimizedImage alt={alt} src={optimizedUrl} />
          </Dialog>
        </>
      )}
    </div>
  ) : (
    <BrokenImageIcon sx={thumbnailStyles({ size, active })}></BrokenImageIcon>
  );
};

export { ThumbnailImage };

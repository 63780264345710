import { ReactiveVar, useReactiveVar } from '@apollo/client';
import {
  Box,
  Paper as P,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination as TP,
  TablePaginationProps,
  TableRowProps,
} from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/styles';
import { guard } from '@propra-system/registry';
import { EnhancedTableState } from 'cache';
import { useModalControl } from 'hooks/useModalControl';
import { ChangeEvent, Fragment, MouseEvent, ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { ReactElementType } from 'react-window';
import { download, ensureArray, skipProps, spreadIf } from 'system';
import { Column, GlobalFilter, Order, Row } from '../types';
import EnhancedRow from './EnhancedRow';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import ExpandableRow from './ExpandableRow';
import { ExpandCollapseButton } from './ExpandCollapseButton';
import SimpleTableHead from './SimpleTableHead';
import {
  generateFilteredColumnsForColumn,
  generateFilteredColumnsForValue,
  generateGlobalFilterExcluded,
} from './utils';

const Root = styled(Box)({
  width: '100%',
});

const Paper = styled(
  P,
  skipProps('showPagination', 'hideBorder')
)<{ showPagination: boolean; hideBorder?: boolean }>(({ theme, showPagination, hideBorder }) => ({
  border: 0,
  width: '100%',
  marginBottom: theme.spacing(2),
  ...spreadIf(!hideBorder, {
    border: '1px solid',
    borderColor: theme.palette.divider,
  }),
  ...spreadIf(showPagination && !hideBorder, {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  }),
}));

const TablePagination = styled(
  TP,
  skipProps('showBorder')
)<{ showBorder?: boolean; component?: ReactElementType }>(({ theme, showBorder }) => ({
  ...spreadIf(showBorder, {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  }),
}));

const hasNameProp = guard<{ props: { name: string } }>({
  type: 'object',
  required: ['props'],
  properties: {
    props: { type: 'object', required: ['name'], properties: { name: { type: 'string' } } },
  },
});

export type EnhancedDesktopTableProps = {
  rows: Row[];
  columns: Column[];
  onRowClick?: (id: string) => void;
  rowSx?: TableRowProps['sx'];
  allowPagination?: boolean;
  allowSearch?: boolean;
  allowSort?: boolean;
  allowExport?: boolean;
  reactiveState: ReactiveVar<EnhancedTableState>;
  toolbarComponent?: ReactNode | ((obj: { rows: Row[] }) => JSX.Element);
  footerComponent?: ReactNode;
  rowExpansionComponent?: (props: { row: Row }) => JSX.Element | null;
  globalFilter?: GlobalFilter;
  loading?: boolean;
  toolbarTitle?: string;
  rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
  hideBorder?: boolean;
};

export default function EnhancedDesktopTable({
  rows,
  columns: allColumns,
  onRowClick,
  rowSx,
  allowPagination = false,
  allowSearch = false,
  allowSort = false,
  allowExport = false,
  reactiveState,
  toolbarComponent,
  toolbarTitle,
  footerComponent,
  rowExpansionComponent: expansionComponent,
  globalFilter,
  loading,
  rowsPerPageOptions = [5, 10, 20, 30, 40, 50],
  hideBorder,
}: EnhancedDesktopTableProps) {
  const state = useReactiveVar(reactiveState);
  const [handleExpand, handleCollapse, rowExpanded, expandedRowId] = useModalControl<Row['id']>();
  const { page, rowsPerPage, search, order, orderBy, filteredColumns, globalFilterExcluded } =
    state;

  const columns = useMemo<Column[]>(
    () => [
      ...(expansionComponent
        ? [
            {
              flex: '0 72px',
              headerName: '',
              hideCsv: true,
              disableSort: true,
              field: 'toggle-row-expansion',
              renderCell: ({ id, row }: { id: string; row?: Row }) =>
                expansionComponent({ row }) ? (
                  <ExpandCollapseButton
                    {...{
                      onCollapse: handleCollapse,
                      onExpand: () => handleExpand(id),
                      expanded: rowExpanded && expandedRowId === id,
                    }}
                  />
                ) : (
                  <></>
                ),
            },
          ]
        : []),
      ...allColumns.map((c) => ({ ...c, hidden: c.hideDesktop || c.hidden })),
    ],
    [allColumns, expansionComponent, handleExpand, handleCollapse, rowExpanded, expandedRowId]
  );

  const themeInstance = useTheme();
  const toPlainText = (node: ReactNode) => {
    const divContainer = document.createElement('div');

    ReactDOM.render(<ThemeProvider theme={themeInstance}>{node}</ThemeProvider>, divContainer);

    return divContainer.textContent || divContainer.innerText || '';
  };

  const formContext = useFormContext();
  const renderCsvCell = ({ row, column }: { row: Row; column: Column }) => {
    const props = { id: row.id, value: row[column.field], row };
    const renderCell =
      column?.renderCsvCell ?? column?.renderCell ?? (() => <>{row[column.field]}</>);

    const node = renderCell(props);
    const value = hasNameProp(node) ? (formContext.getValues(node.props.name) ?? node) : node;

    return toPlainText(value);
  };

  const onRequestSort = (_event: MouseEvent, property: string, sortField?: string) => {
    reactiveState({
      ...state,
      order: orderBy === property && order === Order.ASC ? Order.DESC : Order.ASC,
      orderBy: property,
      sortField,
      page: 0,
    });
  };

  const exportCsv = () => {
    download(
      new Blob(
        [
          [
            columns
              .filter((c) => !c.hideCsv)
              .map(({ headerName }) => `"${headerName}"`)
              .join(','),
            ...rows.map((row) =>
              columns
                .filter((c) => !c.hideCsv)
                .map((column) => renderCsvCell({ row, column }))
                .map((v) => (typeof v === 'number' ? v : `"${v}"`))
            ),
          ].join('\n'),
        ],
        { type: 'text/csv' }
      )
    );
  };

  return (
    <Root>
      <Paper showPagination={Boolean(allowPagination)} hideBorder={hideBorder} elevation={0}>
        <TableContainer>
          {Boolean(allowSearch || toolbarComponent) && (
            <EnhancedTableToolbar
              {...{
                allowSearch,
                toolbarTitle,
                search,
                updateSearch: (newSearch: string) =>
                  reactiveState({ ...state, search: newSearch, page: 0 }),
                toolbarComponent,
                rows,
                onExportCsv: allowExport ? exportCsv : undefined,
              }}
            />
          )}
          {allowPagination && (
            <TablePagination
              showBorder
              count={rows.length}
              component="div"
              {...{
                rowsPerPageOptions,
                rowsPerPage,
                page,
                onPageChange: (_event: unknown, newPage: number) => {
                  reactiveState({ ...state, page: newPage });
                },
                onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => {
                  reactiveState({
                    ...state,
                    page: 0,
                    rowsPerPage: parseInt(event.target.value, 10),
                  });
                },
              }}
            />
          )}
          <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            {allowSort ? (
              <EnhancedTableHead
                {...{
                  columns,
                  filteredColumns,
                  order,
                  orderBy,
                  onRequestSort,
                  onFilteredColumnAllChange: (field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForColumn(field, filteredColumns, []),
                    });
                  },
                  onFilteredColumnNoneChange: (field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForColumn(
                        field,
                        filteredColumns,
                        ensureArray(
                          columns
                            .filter(({ hidden }) => !hidden)
                            .find((column) => column.field === field)?.filterColumnValues
                        )
                      ),
                    });
                  },
                  onFilteredColumnChange: (value: string, toExclude: boolean, field: string) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      filteredColumns: generateFilteredColumnsForValue(
                        value,
                        toExclude,
                        field,
                        filteredColumns
                      ),
                    });
                  },
                  globalFilter,
                  globalFilterExcluded,
                  onGlobalFilterChange: (value: string, toExclude: boolean) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      globalFilterExcluded: generateGlobalFilterExcluded(
                        value,
                        toExclude,
                        globalFilterExcluded
                      ),
                    });
                  },
                }}
              />
            ) : (
              <SimpleTableHead
                {...{
                  columns,
                  globalFilter,
                  globalFilterExcluded,
                  onGlobalFilterChange: (value: string, toExclude: boolean) => {
                    reactiveState({
                      ...state,
                      page: 0,
                      globalFilterExcluded: generateGlobalFilterExcluded(
                        value,
                        toExclude,
                        globalFilterExcluded
                      ),
                    });
                  },
                }}
              />
            )}
            <TableBody>
              {(loading ? [{ id: 1 }, { id: 2 }, { id: 3 }] : rows)
                .slice(
                  allowPagination ? page * rowsPerPage : 0,
                  allowPagination ? page * rowsPerPage + rowsPerPage : rows.length
                )
                .map((row) => {
                  const showExpansionComponent = Boolean(expansionComponent?.({ row }));

                  return (
                    <Fragment key={row.id}>
                      <EnhancedRow {...{ row, onRowClick, columns, loading }} sx={rowSx} />
                      {showExpansionComponent && (
                        <ExpandableRow
                          row={row}
                          expansionComponent={expansionComponent}
                          expanded={rowExpanded && expandedRowId === row.id}
                        />
                      )}
                    </Fragment>
                  );
                })}
            </TableBody>

            {footerComponent && (
              <TableFooter>
                <tr>
                  <td colSpan={99}>{footerComponent}</td>
                </tr>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        {allowPagination && (
          <TablePagination
            count={rows.length}
            component="div"
            {...{
              rowsPerPageOptions,
              rowsPerPage,
              page,
              onPageChange: (_event: unknown, newPage: number) => {
                reactiveState({ ...state, page: newPage });
              },
              onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => {
                reactiveState({ ...state, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
              },
            }}
          />
        )}
      </Paper>
    </Root>
  );
}

import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerApprovalDeclinedEvent } = eventFactory(
  'manager-approval-declined-event',
  z.object({
    accountId: z.string(),
    approvalId: z.string(),
    status: z.string(),
    request: z.record(z.unknown()),
    type: maybe(z.string()),
    approverDomain: z.string(),
    declinedSub: z.string(),
    declinedZ: maybe(z.string()),
    declinedComment: maybe(z.string()),
    requestedSub: z.string(),
  })
);

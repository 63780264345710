/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayInvitePayeeAction } from './invitePayee';
import ManagerAutopayInvitePayeeActionSchema from './invitePayee.json';

export const isManagerAutopayInvitePayeeAction = guard<ManagerAutopayInvitePayeeAction>(ManagerAutopayInvitePayeeActionSchema as any);
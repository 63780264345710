export type { KebabCaseToCamelCase } from '@propra-system/types';

/**
 * Converts a kebab-case string to camelCase.
 *
 * @param {string} str - The kebab-case string to convert.
 * @returns {string} The camelCase version of the input string.
 */
export const kebabCaseToCamelCase = (str: string) =>
  !str.includes('-') || str === ''
    ? str
    : str
        .replace(/^-/, '')
        .replace(/-$/, '')
        .replace(/-+([^-])/g, (_, letter: string) => letter.toUpperCase());

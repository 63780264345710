import { Link, LinkProps } from '@mui/material';
import { Link as LinkComponent } from 'react-router-dom';

/**
 * @deprecated('Use `Link` from `@mui/material` instead.')
 **/
export default function RouterLink({
  to,
  ...props
}: Omit<LinkProps, 'component'> & { to: string }) {
  return <Link component={LinkComponent} to={to} {...props} />;
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksClearableUpdatedEvent } from './clearableUpdated';
import ManagerBooksClearableUpdatedEventSchema from './clearableUpdated.json';

export const isManagerBooksClearableUpdatedEvent = guard<ManagerBooksClearableUpdatedEvent>(ManagerBooksClearableUpdatedEventSchema as any);
import { useListAllUnitsQuery } from 'api';
import { useMeta } from './useMeta';
import { usePaginateAllQuery } from './usePaginateAllQuery';

export const useAccountUnits = () => {
  const { items: units, ...meta } = usePaginateAllQuery(useListAllUnitsQuery, {
    getNextToken: (d) => d?.account?.listUnits?.nextToken,
    getItems: (d) => d.account?.listUnits?.items,
  });

  const { loading, working } = useMeta(meta);

  return { units, loading, working };
};

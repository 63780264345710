import {
  Address,
  BooksFieldType,
  FractionInput,
  MoveOutReason,
  OwnerListFieldsFragment,
  PayeeType,
  PropertyHeader,
  PropertyListFieldsFragment,
  UnitFieldsFragment,
  UnitHeader,
  useGetAccountQuery,
  useGetOperatorsQuery,
  useListSuppliersQuery,
} from 'api';
import { useAccountOwners } from 'hooks/useAccountOwners';
import { useAccountProperties } from 'hooks/useAccountProperties';
import { useAccountUnits } from 'hooks/useAccountUnits';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { useTeams } from 'hooks/useTeams';
import { useTenants } from 'hooks/useTenants';
import {
  Dictionary,
  compact,
  filter,
  find,
  first,
  groupBy,
  keyBy,
  mapValues,
  orderBy,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import { isCurrent, isFuture, isPast } from 'pages/properties/property/units/unit/residency/util';
import {
  FC,
  Key,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { emptyArray, ensureArray, formatAddress, parseJSON, spreadIf } from 'system';
import { useBooks } from '../hooks/useBooks';
import { usePropertyOwners } from '../hooks/usePropertyOwners';

type UnitLookup = Dictionary<{
  id: string;
  unit: {
    id: string;
    name: string;
    sqft?: number;
    propertyId: string | undefined;
  };
  property: {
    __typename?: 'Property' | undefined;
  } & PropertyListFieldsFragment;
}>;

type PropertyLookup = Dictionary<
  {
    __typename?: 'Property' | undefined;
  } & PropertyListFieldsFragment
>;

type EntityType = 'account' | 'owner' | 'operator' | 'property' | 'tenant' | 'unit' | 'supplier';
export type EntityOption = {
  text: string;
  id: string;
  address?: string;
  addressObject?: Address;
  propertyIds?: string[];
};

export type OwnerEntityOption = EntityOption & {
  email?: string;
  unitHeaders?: UnitHeader[];
};

export type TenantEntityOption = EntityOption & {
  enabled?: boolean;
  emails?: string[];
  residencyHeaders?: {
    residencyId: string;
    unitId: string;
    propertyId: string;
    startZ: string;
    endZ?: string;
    payeeFrac?: FractionInput;
    moveOutReason?: MoveOutReason;
  }[];
};

export type UnitEntityOption = EntityOption & {
  unitText: string;
  ownerId?: string;
  propertyId?: string;
};

type EntityIds = Omit<Record<EntityType, EntityOption[]>, 'unit' | 'tenant' | 'owner'> & {
  unit: UnitEntityOption[];
  tenant: TenantEntityOption[];
  owner: OwnerEntityOption[];
};

export type AccountingContextType = {
  loading: boolean;
  working: boolean;
  entityIds: EntityIds;
  unitByLeaseId: UnitLookup;
  propertyById: PropertyLookup;
  unitById: Dictionary<
    Pick<UnitFieldsFragment, 'id' | 'name' | 'sqft' | 'propertyId' | 'occupancy' | 'buildingId'>
  >;
  propertyName: (arg: { propertyId?: string }) => string | undefined;
  propertyAddress: (arg: { propertyId: string }) => Address | undefined;
  ownerFor: (ownedAssetId?: string) => EntityOption;
  unitName: (arg: { unitId?: string }) => string | undefined;
  ownerName: (arg: { ownerId: string }) => string | undefined;
  payeeName: (arg: { payee?: PayeeType; payeeId?: string }) => string | undefined;
  payeeLink: (arg: { payee?: PayeeType | string; payeeId?: string }) => string | undefined;
  payeeAddress: (arg: {
    element: FC<{ children: ReactNode; key: Key; last?: boolean }>;
    payee?: PayeeType;
    payeeId?: string;
  }) => JSX.Element;
  entityName: (
    arg: { propertyId: string } | { propertyId?: string; unitId: string }
  ) => string | undefined;
  sortableValue: (arg: { type: BooksFieldType; value: string }) => string | number | undefined;
  getPropertyOwnerId: ReturnType<typeof usePropertyOwners>['getPropertyOwnerId'];
  unitsOwnedInProperty: ReturnType<typeof usePropertyOwners>['unitsOwnedInProperty'];
  booksOwners: ReturnType<typeof usePropertyOwners>['booksOwners'];
  accountAsBooksOwner: ReturnType<typeof usePropertyOwners>['booksOwners'][0] | undefined;
  sortedEntityIds: <TType extends EntityType = EntityType>(
    entityType: TType,
    propertyIds?: string[]
  ) => EntityIds[TType];
  ownersByPropertyId: Dictionary<string[]>;
};

const AccountingContext = createContext<AccountingContextType | undefined>(undefined);

export const ownersToOptions = (
  owners: Pick<
    OwnerListFieldsFragment,
    | 'id'
    | 'enabled'
    | 'name'
    | 'email'
    | 'address'
    | 'propertyHeaders'
    | 'propertyIds'
    | 'unitHeaders'
    | 'unitIds'
  >[] = [],
  options: { includeDisabled?: boolean } = {}
): OwnerEntityOption[] =>
  (options?.includeDisabled ? owners : filter(owners, 'enabled')).map((owner) => {
    const totalAssets = (owner.unitIds?.length ?? 0) + (owner.propertyIds?.length ?? 0);
    const firstPropertyName = owner.propertyHeaders?.[0]?.name;
    const firstUnitName = `${owner.unitHeaders?.[0]?.propertyName} - ${owner.unitHeaders?.[0]?.name}`;
    const firstSubText = firstPropertyName ?? firstUnitName;

    return {
      text: owner.name,
      id: owner.id,
      email: owner.email,
      address: formatAddress(owner.address),
      addressObject: owner.address,
      ...spreadIf(totalAssets, {
        subText: `${firstSubText} ${totalAssets > 1 ? `\nand ${totalAssets - 1} more` : ''}`,
      }),
      propertyIds: owner.propertyIds,
      unitHeaders: owner.unitHeaders,
    };
  });

export const useAccountingContext = () => {
  const context = useContext(AccountingContext);
  if (context === undefined) {
    throw new Error('useAccountingContext must be used within an AccountingProvider');
  }

  return context;
};

const initialState: AccountingContextType = {
  loading: true,
  working: true,
  entityIds: {
    account: emptyArray,
    owner: emptyArray,
    operator: emptyArray,
    property: emptyArray,
    tenant: emptyArray,
    unit: emptyArray,
    supplier: emptyArray,
  },
  propertyName: () => undefined,
  propertyAddress: () => undefined,
  unitById: {},
  ownerFor: () => ({ id: '', text: '' }),
  unitName: () => undefined,
  ownerName: () => undefined,
  payeeName: () => undefined,
  payeeLink: () => undefined,
  payeeAddress: () => <></>,
  entityName: () => undefined,
  sortableValue: () => undefined,
  getPropertyOwnerId: () => undefined,
  unitsOwnedInProperty: () => emptyArray,
  booksOwners: emptyArray,
  accountAsBooksOwner: undefined,
  unitByLeaseId: {},
  ownersByPropertyId: {},
  propertyById: {},
  sortedEntityIds: () => emptyArray,
};
export const AccountingProvider = ({ children }: { children: ReactNode }) => {
  const accountResponse = useGetAccountQuery();
  const { isPropertyVisible } = useTeams();

  const ownersResponse = useAccountOwners();
  const operatorsResponse = useGetOperatorsQuery();
  const suppliersResponse = usePaginateAllQuery(useListSuppliersQuery, {
    getNextToken: (d) => d?.account?.listSuppliers?.nextToken,
    getItems: (d) => d.account?.listSuppliers?.items,
  });

  const tenantsResponse = useTenants();
  const { books } = useBooks();
  const propertiesResponse = useAccountProperties({
    includeDisabled: books?.includeDisabledProperties,
  });
  const unitsResponse = useAccountUnits();

  const unitById = useMemo(
    () => keyBy(ensureArray(unitsResponse.units), 'id'),
    [unitsResponse.units]
  );

  const propertyById = useMemo(
    () => keyBy(propertiesResponse.properties, 'id'),
    [propertiesResponse.properties]
  );

  const {
    loading: propertyOwnersResponseLoading,
    working: propertyOwnersResponseWorking,
    booksOwners,
    getPropertyOwnerId,
    unitsOwnedInProperty,
  } = usePropertyOwners({ unitById, propertyById });

  const [accountAsBooksOwner, setAccountAsBooksOwner] = useState<(typeof booksOwners)[0]>();

  const responseErrors = [accountResponse.error, operatorsResponse.error, suppliersResponse.error];

  useAllErrors(...responseErrors);

  const propertyByUnitId = useMemo(
    () =>
      keyBy(
        ensureArray(unitsResponse.units).map((unit) => ({
          id: unit.id,
          unit: { id: unit.id, name: unit.name, propertyId: unit.propertyId },
          property: propertyById[unit.propertyId ?? ''],
        })),
        'id'
      ),
    [propertyById, unitsResponse.units]
  );

  const unitByLeaseId = useMemo(() => {
    const units = ensureArray(unitsResponse.units);
    const lookup = units.flatMap((unit) => {
      const { current, future, history } = groupBy(unit.allResidencies, (r) =>
        isCurrent(r) ? 'current' : isFuture(r) ? 'future' : 'history'
      );

      const residencyIds = compact([
        ...ensureArray(current),
        ...ensureArray(future),
        ...ensureArray(history),
      ]).map((r) => r.id);

      return residencyIds.map((id) => ({
        id,
        unit: { id: unit.id, name: unit.name, propertyId: unit.propertyId, sqft: unit.sqft },
        property: propertyById[unit.propertyId ?? ''],
      }));
    });

    return keyBy(lookup, 'id');
  }, [propertyById, unitsResponse.units]);

  const [entityIds, setEntityIds] = useState<EntityIds>(initialState.entityIds);
  const [loading, setLoading] = useState(false);

  useEffect(
    () =>
      setLoading(
        [
          accountResponse.loading,
          ownersResponse.loading,
          operatorsResponse.loading,
          propertiesResponse.loading,
          suppliersResponse.loading,
          tenantsResponse.loading,
          unitsResponse.loading,
          propertyOwnersResponseLoading,
        ].some(Boolean)
      ),
    [
      accountResponse.loading,
      operatorsResponse.loading,
      ownersResponse.loading,
      propertiesResponse.loading,
      propertyOwnersResponseLoading,
      suppliersResponse.loading,
      tenantsResponse.loading,
      unitsResponse.loading,
    ]
  );

  const [working, setWorking] = useState(false);
  useEffect(
    () =>
      setWorking(
        [
          ownersResponse.working,
          propertiesResponse.working,
          tenantsResponse.working,
          unitsResponse.working,
          propertyOwnersResponseWorking,
        ].some(Boolean)
      ),
    [
      ownersResponse.working,
      propertiesResponse.working,
      propertyOwnersResponseWorking,
      tenantsResponse.working,
      unitsResponse.working,
    ]
  );

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      account: [
        {
          text: accountResponse.data?.account?.name ?? '',
          id: accountResponse.data?.account?.id ?? '',
          address: formatAddress(accountResponse.data?.account?.address),
          addressObject: accountResponse.data?.account?.address,
          propertyIds: ensureArray(propertiesResponse.properties)
            .filter((p) => p.ownerId === accountResponse.data?.account?.id || !p.ownerId)
            .map((p) => p.id),
        },
      ],
    }));
  }, [accountResponse.data?.account, propertiesResponse.properties]);

  useEffect(() => {
    if (accountResponse.data?.account && propertiesResponse.properties) {
      const propertiesMap = new Map<string, PropertyHeader>(
        propertiesResponse.properties
          .filter((p) => !p.ownerId)
          .map(({ id, name, imageKey, address }) => [
            id,
            {
              id,
              name,
              imageKey,
              address,
            },
          ])
      );
      const propertyIds = Array.from(propertiesMap.keys());
      const propertyHeaders = Array.from(propertiesMap.values());
      const { id, name, address } = accountResponse.data.account;
      setAccountAsBooksOwner({
        id,
        address,
        allPropertyIds: propertyIds,
        currentPropertyIds: propertyIds,
        enabled: true,
        isPropertyOwner: true,
        isUnitOwner: false,
        name,
        propertyHeaders,
        propertyIds,
        unitHeaders: [],
        unitIds: [],
        propertyOptions: [
          ...propertiesResponse.properties
            .filter((p) => isPropertyVisible(p.id))
            .map((property) => ({
              id: `${id}#${property.id}`,
              ownerId: id,
              ownerName: name,
              propertyId: property.id,
              propertyName: property.name,
              enabled: true,
              occupancy: undefined,
            })),
          {
            id: `${id}#${id}`,
            ownerId: id,
            ownerName: name,
            propertyId: id,
            propertyName: name,
            enabled: true,
            occupancy: undefined,
          },
        ],
      });
    }
  }, [accountResponse.data?.account, isPropertyVisible, propertiesResponse.properties]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      owner: ownersToOptions(ownersResponse.owners),
    }));
  }, [ownersResponse.owners]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      operator: ensureArray(operatorsResponse.data?.account?.operators).map((operator) => ({
        text: operator.name,
        id: operator.id,
        address: operator.location,
      })),
    }));
  }, [operatorsResponse.data?.account?.operators]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      property: ensureArray(propertiesResponse.properties).map((property) => ({
        text: `${property.name}${property.key ? ` (${property.key})` : ''}`,
        id: property.id,
        address: formatAddress(property.address),
        addressObject: property.address,
      })),
    }));
  }, [propertiesResponse.properties]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      tenant: ensureArray(tenantsResponse.tenants).map((tenant) => {
        const residencyHeaders = sortBy(
          tenant.allResidencies,
          ({ startZ, endZ, moveOutReason }) => {
            const order = [
              isCurrent({ startZ, endZ }),
              isFuture({ startZ }),
              isPast({ endZ }),
              moveOutReason === MoveOutReason.Terminated,
            ].indexOf(true);

            return order === -1 ? undefined : order;
          }
        );

        const lookupData = unitByLeaseId[first(compact(residencyHeaders))?.id ?? ''];

        return {
          text: tenant.name ?? 'Tenant',
          id: tenant.id,
          emails: tenant.emails,
          ...(residencyHeaders && {
            residencyHeaders: residencyHeaders.map(
              ({ id, unitId, propertyId, startZ, endZ, moveOutReason, residents = [] }) => ({
                residencyId: id,
                unitId,
                propertyId,
                startZ,
                endZ,
                moveOutReason,
                payeeFrac: residents.find((r) => r.residentId === tenant.id)?.payeeFrac,
              })
            ),
          }),
          enabled: tenant.enabled ?? true,
          ...spreadIf(lookupData, {
            subText: compact([lookupData?.property?.name, lookupData?.unit.name]).join(' - '),
            address: formatAddress(lookupData?.property?.address),
            addressObject: lookupData?.property?.address,
            propertyIds: [lookupData?.property?.id],
          }),
        };
      }),
    }));
  }, [tenantsResponse.tenants, unitByLeaseId]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      supplier: ensureArray(suppliersResponse.items).map((supplier) => ({
        text: supplier.name,
        id: supplier.id,
        address: supplier.address ? formatAddress(supplier.address) : supplier.location,
        addressObject: supplier.address,
      })),
    }));
  }, [suppliersResponse.items]);

  useEffect(() => {
    setEntityIds((prev) => ({
      ...prev,
      unit: ensureArray(unitsResponse.units).map((unit) => {
        const { property } = propertyByUnitId[unit.id] ?? {};
        const building = find(property?.buildings, { id: unit.buildingId });
        return {
          text: building
            ? `${property?.name} (${building.name}) - ${unit.name}`
            : `${property?.name} - ${unit.name}`,
          id: unit.id,
          unitText: unit.name,
          propertyId: property?.id,
          ownerId: unit.ownerId ?? property?.ownerId ?? prev.account[0]?.id,
          address: formatAddress({ ...property?.address, suite: unit.name }),
          addressObject: { ...property?.address, suite: unit.name },
        };
      }),
    }));
  }, [propertyByUnitId, unitsResponse.units]);

  const accountName = useCallback(
    (accountId?: string) =>
      accountId === entityIds.account?.[0]?.id ? entityIds.account?.[0]?.text : undefined,
    [entityIds.account]
  );

  const propertyName = useCallback(
    ({ propertyId }: { propertyId?: string }) =>
      accountName(propertyId) ??
      ensureArray(propertiesResponse.properties).find(({ id }) => id === propertyId)?.name,
    [accountName, propertiesResponse.properties]
  );

  const propertyAddress = useCallback(
    ({ propertyId }: { propertyId?: string }) =>
      (entityIds.account?.[0].id === propertyId
        ? entityIds.account?.[0].addressObject
        : undefined) ??
      ensureArray(propertiesResponse.properties).find(({ id }) => id === propertyId)?.address,
    [entityIds.account, propertiesResponse.properties]
  );

  const unitName = useCallback(
    ({ unitId }: { unitId?: string }) => entityIds.unit.find(({ id }) => id === unitId)?.text,
    [entityIds.unit]
  );

  const ownerName = useCallback(
    ({ ownerId }: { ownerId: string }) =>
      accountName(ownerId) ?? entityIds.owner.find(({ id }) => id === ownerId)?.text,
    [accountName, entityIds.owner]
  );

  const entityName = useCallback(
    (props: { propertyId: string } | { propertyId?: string; unitId: string }) =>
      'unitId' in props && props.unitId && props.propertyId !== entityIds.account[0]?.id
        ? entityIds.unit.find(({ id }) => id === props.unitId)?.text
        : propertyName(props),
    [entityIds.account, entityIds.unit, propertyName]
  );

  const payeeName = useCallback(
    ({ payee, payeeId }: { payee?: PayeeType; payeeId?: string }) =>
      payee && entityIds[payee]?.find(({ id }) => id === payeeId)?.text,
    [entityIds]
  );

  const payeeLink = useCallback(
    ({ payee, payeeId }: { payee?: PayeeType | string; payeeId?: string }) =>
      payee === PayeeType.Account
        ? '/account'
        : payee === PayeeType.Owner
          ? `/owners/${payeeId}`
          : payee === PayeeType.Operator
            ? `/operators/${payeeId}`
            : payee === PayeeType.Tenant
              ? `/tenants/${payeeId}`
              : payee === PayeeType.Supplier
                ? `/suppliers/${payeeId}`
                : undefined,
    []
  );

  const payeeAddressObject = useCallback(
    ({ payee, payeeId }: { payee?: PayeeType; payeeId?: string }) =>
      payee && entityIds[payee]?.find(({ id }) => id === payeeId)?.addressObject,
    [entityIds]
  );

  const payeeAddress = useCallback(
    ({
      element,
      payee,
      payeeId,
    }: {
      element: FC<{ children: ReactNode; key: Key; last?: boolean }>;
      payee?: PayeeType;
      payeeId?: string;
    }) => {
      const address = payeeAddressObject({ payee, payeeId });

      const lines = address
        ? filter(
            [
              `${address?.suite ? address.suite + ', ' : ''}${address?.street ?? ''}`,
              address?.city ? `${address.city}, ${address?.province ?? ''}` : '',
              address?.postal,
            ],
            (s) => `${s}`.trim()
          )
        : [];

      return (
        <>
          {lines.map((parts, index) =>
            element({ children: parts as ReactNode, key: index, last: index + 1 === lines.length })
          )}
        </>
      );
    },
    [payeeAddressObject]
  );

  const sortableValue = useCallback(
    ({ type, value }: { type: BooksFieldType; value: string }) =>
      type === 'money'
        ? parseFloat(value)
        : type === 'property'
          ? entityName({ propertyId: value })
          : type === 'owner'
            ? ownerName({ ownerId: value })
            : type === 'unit'
              ? entityName({ unitId: value })
              : type === 'payee'
                ? payeeName(parseJSON(value))
                : value,
    [entityName, ownerName, payeeName]
  );

  const sortedEntityIds = <TType extends EntityType = EntityType>(
    entityType: TType,
    propertyIds?: string[]
  ): EntityIds[TType] => {
    if (!propertyIds?.length) return entityIds[entityType];

    const sortedOptions = orderBy(
      entityIds[entityType],
      [
        (option) =>
          propertyIds.some((propertyId) => ensureArray(option.propertyIds).includes(propertyId)),
        'text',
      ],
      ['desc', 'asc']
    );

    return uniqBy(sortedOptions, 'id') as EntityIds[TType];
  };

  /**
   * The logic here - for later moving to API pipeline resolver
   *
   * Given an id string which may be a unit id or a property id
   * Return the id and name of the owner
   *
   * If the id is for a unit, and that unit has an ownerId, use that ownerId
   * If the id is for a unit with no ownerId, check if the property has an ownerId
   *   If the property has no ownerId, then the owner is the account itself, return the account id and name
   *
   * If the id is for a property, and that property has an ownerId, use that ownerId
   * If the id is for a property with no ownerId, then the owner is the account itself, return the account id and name
   *
   * If the id is neither unit nor property, just fallback to returning the account id and name
   */
  const ownerFor = useCallback(
    (ownedAssetId = '') =>
      (propertyById[ownedAssetId]
        ? propertyById[ownedAssetId].ownerId
          ? find(entityIds.owner, { id: propertyById[ownedAssetId].ownerId })
          : entityIds.account?.[0]
        : unitById[ownedAssetId]?.ownerId
          ? find(entityIds.owner, { id: unitById[ownedAssetId].ownerId })
          : propertyById[unitById[ownedAssetId]?.propertyId ?? '']?.ownerId
            ? find(entityIds.owner, {
                id: propertyById[unitById[ownedAssetId]?.propertyId ?? ''].ownerId,
              })
            : undefined) ?? entityIds.account?.[0],
    [entityIds.account, entityIds.owner, propertyById, unitById]
  );

  const ownersByPropertyId = useMemo(() => {
    const properties = ensureArray(propertiesResponse.properties);
    const units = ensureArray(unitsResponse.units);

    const lookup = properties
      .map(({ id: propertyId }) => ({ ownerId: ownerFor(propertyId)?.id, propertyId }))
      .concat(units.map(({ id, propertyId = '' }) => ({ ownerId: ownerFor(id)?.id, propertyId })))
      .filter(
        ({ ownerId }) =>
          booksOwners.some((o) => o.id === ownerId) || ownerId === entityIds.account[0]?.id
      );

    return mapValues(groupBy(lookup, 'propertyId'), (options) =>
      uniq(options.map((o) => o.ownerId))
    );
  }, [
    booksOwners,
    entityIds.account,
    ownerFor,
    propertiesResponse.properties,
    unitsResponse.units,
  ]);

  return (
    <AccountingContext.Provider
      value={{
        loading,
        working,
        entityIds,
        propertyName,
        propertyAddress,
        unitName,
        ownerName,
        payeeName,
        payeeLink,
        payeeAddress,
        entityName,
        sortableValue,
        getPropertyOwnerId,
        unitsOwnedInProperty,
        booksOwners,
        accountAsBooksOwner,
        unitByLeaseId,
        propertyById,
        ownersByPropertyId,
        sortedEntityIds,
        ownerFor,

        unitById,
      }}
    >
      {children}
    </AccountingContext.Provider>
  );
};

import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function CardTitle({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Typography className={className} component="h2" variant="h6" textTransform="uppercase">
      {children}
    </Typography>
  );
}

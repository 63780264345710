export type GLCategory = DisplayGLCategory | 'undepositedFunds';
export type DisplayGLCategory = keyof typeof glCategoryBase;
type GLCategories = {
  [Key in keyof typeof glCategoryBase]: (typeof glCategoryBase)[Key] & { id: Key };
};

const glCategoryBase = {
  accountsReceivable: {
    accountType: 'asset',
    text: 'Accounts Receivable',
  },
  bank: {
    accountType: 'asset',
    text: 'Bank',
  },
  currentAssets: {
    accountType: 'asset',
    text: 'Current Assets',
  },
  longTermAssets: {
    accountType: 'asset',
    text: 'Long Term Assets',
  },
  accumulatedDepreciation: {
    accountType: 'asset',
    text: 'Accumulated Depreciation',
  },
  buildings: {
    accountType: 'asset',
    text: 'Buildings',
  },
  investments: {
    accountType: 'asset',
    text: 'Investments',
  },
  accountsPayable: {
    accountType: 'liability',
    text: 'Accounts Payable',
  },
  creditCard: {
    accountType: 'liability',
    text: 'Credit Card',
  },
  currentLiabilities: {
    accountType: 'liability',
    text: 'Current Liabilities',
  },
  longTermLiabilities: {
    accountType: 'liability',
    text: 'Long Term Liabilities',
  },
  payrollLiabilities: {
    accountType: 'liability',
    text: 'Payroll Liabilities',
  },
  operatingExpenses: {
    accountType: 'expense',
    text: 'Operating Expenses',
  },
  depreciation: {
    accountType: 'expense',
    text: 'Depreciation',
  },
  gainLoss: {
    accountType: 'expense',
    text: 'Gain / Loss',
  },
  otherExpenses: {
    accountType: 'expense',
    text: 'Other Miscellaneous Expenses',
  },
  payrollExpenses: {
    accountType: 'expense',
    text: 'Payroll Expenses',
  },
  operatingRevenue: {
    accountType: 'revenue',
    text: 'Operating Revenue',
  },
  otherRevenue: {
    accountType: 'revenue',
    text: 'Other Revenue',
  },
  discounts: {
    accountType: 'revenue',
    text: 'Discounts',
  },
  interestEarned: {
    accountType: 'revenue',
    text: 'Interest Earned',
  },
  distributions: {
    accountType: 'equity',
    text: 'Distributions',
  },
  contributions: {
    accountType: 'equity',
    text: 'Contributions',
  },
  retainedEarnings: {
    accountType: 'equity',
    text: 'Retained Earnings',
  },
  equityCapitalReserve: {
    accountType: 'equity',
    text: 'Capital Reserve',
  },
  revenueCapitalReserve: {
    accountType: 'revenue',
    text: 'Capital Reserve',
  },
  expenseCapitalReserve: {
    accountType: 'expense',
    text: 'Capital Reserve',
  },
  assetCapitalReserve: {
    accountType: 'asset',
    text: 'Capital Reserve',
  },
  liabilityCapitalReserve: {
    accountType: 'liability',
    text: 'Capital Reserve',
  },
  openingBalance: {
    accountType: 'equity',
    text: 'Opening Balance',
  },
};

export const glCategories = Object.entries(glCategoryBase).reduce(
  (result, [id, detail]) => ({
    ...result,
    [id]: { ...detail, id },
  }),
  {} as GLCategories
);

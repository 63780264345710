import { QueryResult } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

export const useRefetch = ({ refetch, called }: Pick<QueryResult, 'refetch' | 'called'>) => {
  const [refetching, setRefetching] = useState(false);
  useEffect(() => {
    if (called) {
      refetch({ fetchPolicy: 'cache-and-network' });
    }
  }, [called, refetch]);

  const handleRefresh = useCallback(async () => {
    setRefetching(true);
    await Promise.resolve(refetch()).finally(() => setRefetching(false));
    setRefetching(false);
  }, [refetch]);

  return { handleRefresh, refetching };
};

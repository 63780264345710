import {
  PresetFieldsFragment,
  UpdatePresetGlMappingInput,
  UpdatePresetRecurrenceInput,
  useUpdatePresetGlMappingMutation,
  useUpdatePresetRecurrenceMutation,
} from 'api';
import { useLoading } from 'hooks/useLoading';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { usePayloadNotification } from 'hooks/usePayloadNotification';
import { range } from 'lodash';
import { useEffect, useState } from 'react';
import { UpdateGlMappingFormFields } from './useFinancialSettings';
import { usePresets } from './usePresets';

export type PresetRecurrenceFormFields = {
  enabled: boolean;
  rrule: string;
  postedOffset: number;
};

const cycleOptions = range(1, 29).map((d) => ({
  label: `${d}`,
  value: `RRULE:FREQ=MONTHLY;BYMONTHDAY=${d}`,
}));

export const usePreset = ({ presetId }: { presetId?: string }) => {
  const { presets, ...presetsMeta } = usePresets();
  const { sendNotification } = useNotification();
  const [preset, setPreset] = useState<PresetFieldsFragment>();
  const [updatePresetGlMappingMutation, updatePresetMeta] = useUpdatePresetGlMappingMutation();
  const [updatePresetRecurrenceMutation, recurrenceMeta] = useUpdatePresetRecurrenceMutation();

  const { loading } = useMeta(presetsMeta, updatePresetMeta, recurrenceMeta);
  const updateLoading = useLoading(updatePresetMeta.loading, recurrenceMeta.loading);

  const { payloadNotification } = usePayloadNotification();

  useEffect(() => {
    setPreset(presets.find(({ id }) => id === presetId));
  }, [presetId, presets]);

  const updatePresetGlMapping = async (values: UpdateGlMappingFormFields) => {
    if (!presetId) {
      console.error('No preset ID was found to update.');
      sendNotification('No preset ID was found to update.', 'error');
      return;
    }

    const input: UpdatePresetGlMappingInput = {
      id: presetId ?? '',
      glMapping: Object.keys(values.glAccountMapping).map((key) => ({
        id: key,
        glId: values.glAccountMapping[key],
      })),
    };

    try {
      await updatePresetGlMappingMutation({
        variables: {
          input,
        },
      });
    } catch (e) {
      console.error(e);
      sendNotification('Error updating configuration.', 'error');
    }
  };

  const updatePresetRecurrence = async (values: PresetRecurrenceFormFields) => {
    if (!presetId) {
      console.error('No preset ID was found to update.');
      sendNotification('No preset ID was found to update.', 'error');
      return false;
    }

    const { enabled, rrule, postedOffset, ...rest } = values;

    const input: UpdatePresetRecurrenceInput = {
      id: presetId,
      enabled,
      rrule,
      postedOffset,
      parameters: JSON.stringify(rest),
    };

    try {
      const { data } = await updatePresetRecurrenceMutation({ variables: { input } });
      payloadNotification({
        payload: data?.updatePresetRecurrence,
        success: 'Configuration updated',
        error: "Error updating configuration'",
      });
      return data?.updatePresetRecurrence.success;
    } catch (e) {
      console.error(e);
      sendNotification('Error updating configuration.', 'error');
    }
    return false;
  };

  return {
    preset,
    updatePresetGlMapping,
    updatePresetRecurrence,
    loading,
    updateLoading,
    cycleOptions,
  };
};

import { useApolloClient } from '@apollo/client';
import { useAuth } from 'context';
import { useCallback } from 'react';
import { invalidate } from 'system';

export const useClearableCache = () => {
  // Reading/Writing cache using the client directly ensures cache is updated promptly
  const client = useApolloClient();
  const { cache } = client;

  const { accountId } = useAuth();
  const booksCacheId = cache.identify({ accountId, __typename: 'Books' });

  const invalidateClearablesCache = useCallback(
    (clearableIds: string[], { gc = true } = {}) => {
      console.debug(`deleting clearables from cache`, { clearableIds });

      cache.modify({ id: booksCacheId, fields: { pageClearables: invalidate } });

      clearableIds.forEach((clearableId) => {
        cache.evict({ id: cache.identify({ id: clearableId, __typename: 'Clearable' }) });
      });

      if (gc) {
        cache.gc();
      }
    },
    [booksCacheId, cache]
  );

  return {
    updateClearableCache: invalidateClearablesCache,
    deleteClearablesCache: invalidateClearablesCache,
  };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseInspectionCompletedEvent } from './inspectionCompleted';
import ManagerLeaseInspectionCompletedEventSchema from './inspectionCompleted.json';

export const isManagerLeaseInspectionCompletedEvent = guard<ManagerLeaseInspectionCompletedEvent>(ManagerLeaseInspectionCompletedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksPresetRecurrenceEvent } from './presetRecurrence';
import ManagerBooksPresetRecurrenceEventSchema from './presetRecurrence.json';

export const isManagerBooksPresetRecurrenceEvent = guard<ManagerBooksPresetRecurrenceEvent>(ManagerBooksPresetRecurrenceEventSchema as any);
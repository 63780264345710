/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayCollectionTypeAddedEvent } from './collectionTypeAdded';
import ManagerAutopayCollectionTypeAddedEventSchema from './collectionTypeAdded.json';

export const isManagerAutopayCollectionTypeAddedEvent = guard<ManagerAutopayCollectionTypeAddedEvent>(ManagerAutopayCollectionTypeAddedEventSchema as any);
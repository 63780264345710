import { styled, Toolbar as T } from '@mui/material';
import { ReactNode } from 'react';

type TableToolbarProps = {
  toolbarComponent: ReactNode;
};

const Toolbar = styled(T)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
}));

export default function TableToolbar({ toolbarComponent }: TableToolbarProps) {
  return <Toolbar>{toolbarComponent}</Toolbar>;
}

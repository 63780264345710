import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  ButtonProps,
  Collapse,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { IconButton } from 'components';
import { useAuth } from 'context';
import { useToggle } from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItemButton } from './NavListItem.styles';
import { AuthNavItem } from './authNav';

type NavListItemProps = AuthNavItem & {
  short: boolean;
  disabled?: boolean;
  sx?: ButtonProps['sx'];
  isActive?: boolean;
  isSubItem?: boolean;
};

const NavButton = ({
  icon: Icon,
  onClick,
  label,
  badge,
  showFullSizeIcon,
  short,
  disabled,
  sx,
  isActive,
  isSubItem,
}: Omit<NavListItemProps, 'to' | 'toggleId' | 'restrictions'>) => {
  return (
    <ListItemButton isActive={isActive} disabled={disabled} sx={sx} onClick={() => onClick?.()}>
      {Icon && (showFullSizeIcon || short) && (
        <Tooltip title={label} aria-label={label}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Icon />
          </ListItemIcon>
        </Tooltip>
      )}

      {isSubItem && !(showFullSizeIcon || short) && <ListItemIcon sx={{ minWidth: 40 }} />}

      {!short && (
        <>
          <ListItemText primary={label} sx={{ m: 0 }} />
          {badge && badge}
        </>
      )}
    </ListItemButton>
  );
};

export default function NavListItem({ to, restrictions, toggleId, ...props }: NavListItemProps) {
  const { roles } = useAuth();
  const isAuthorized = restrictions ? roles && restrictions.some((r) => roles.includes(r)) : true;
  const toggleEnabled = useToggle({ id: toggleId });
  const enabled = toggleId ? toggleEnabled : true;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.short && setOpen(false);
  }, [props.short]);

  return (
    <>
      {isAuthorized && enabled ? (
        <>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Box width="100%">
              {to ? (
                <NavLink to={to} style={{ textDecoration: 'none' }} end={to === '/'}>
                  {({ isActive }) => <NavButton {...props} isActive={isActive} />}
                </NavLink>
              ) : (
                <NavButton {...props} />
              )}
            </Box>
            {props.children && (
              <IconButton
                aria-label="menu-toggle"
                size="small"
                onClick={() => setOpen(!open)}
                sx={{ marginRight: 1 }}
              >
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </Stack>

          {props.children && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {props.children?.map((child) =>
                child.to ? (
                  <NavLink to={child.to} style={{ textDecoration: 'none' }} end={child.to === '/'}>
                    {({ isActive }) => (
                      <NavButton
                        {...child}
                        sx={{ color: 'text.secondary' }}
                        short={props.short}
                        disabled={props.disabled}
                        isActive={isActive}
                        isSubItem
                      />
                    )}
                  </NavLink>
                ) : (
                  <NavButton
                    {...child}
                    short={props.short}
                    sx={{ color: 'text.secondary' }}
                    disabled={props.disabled}
                    isSubItem
                  />
                )
              )}
            </Collapse>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

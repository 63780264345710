import { useEffect } from 'react';
import { Badge as _Badge, styled } from '@mui/material';
import { useGetActionableCampaignApplicationsQuery } from 'api';

export const Badge = styled(_Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primaryBackground.main,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

const useCountForLevel = () => {
  const { data, error } = useGetActionableCampaignApplicationsQuery();

  useEffect(
    () => error && console.error('Error getting actionable campaign applications count', error),
    [error]
  );
  return data?.account?.campaignApplicationsWithAction ?? 0;
};

export default function ActionableApplicationsBadge() {
  const count = useCountForLevel();
  return <Badge badgeContent={count} />;
}

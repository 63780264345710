import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextFieldProps,
} from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

export default function PasswordFieldController<T extends FieldValues>({
  name,
  variant,
  helperText,
  className,
  label,
}: TextFieldProps & { name: Path<T> }) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...field }, fieldState }) => (
        <FormControl variant={variant ?? 'filled'} fullWidth className={clsx(className)}>
          <InputLabel>{label || 'Password'}</InputLabel>
          <FilledInput
            fullWidth
            type={showPassword ? 'text' : 'password'}
            {...field}
            value={value ?? ''}
            error={Boolean(fieldState.error?.message)}
            inputProps={{ 'data-dd-privacy': 'mask-user-input' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {fieldState.error?.message && (
            <FormHelperText error>{fieldState.error.message}</FormHelperText>
          )}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

import {
  useGetOwnerDocumentsQuery,
  usePropertyDocumentsQuery,
  useRenameOwnerDocumentMutation,
  useRenamePropertyDocumentMutation,
  useRenameRequestDocumentMutation,
  useRenameResidencyDocumentMutation,
  useRenameTenantDocumentMutation,
  useRenameUnitDocumentMutation,
  useRequestDocumentsQuery,
  useResidencyDocumentsQuery,
  useTenantDocumentsQuery,
  useUnitDocumentsQuery,
} from 'api';
import { DocumentType } from 'system';
import { UseGetDocumentsProp, UseRenameDocumentProp } from './types';
import { useBuildingDocuments } from './useBuildingDocuments';
import { useDocumentsWithType, UseDocumentsWithTypeProps } from './useDocumentsWithType';

export const useDocuments = <TDocumentType extends DocumentType>({
  type,
  source,
  extraProps,
}: Pick<UseDocumentsWithTypeProps<TDocumentType>, 'type' | 'source'> & {
  extraProps?: Record<string, unknown>;
}) => {
  if (type === DocumentType.BUILDING) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useBuildingDocuments({
      buildingId: source?.id ?? 'invalid',
      propertyId: (extraProps?.['propertyId'] as string) ?? 'invalid',
    });
  }

  const [useDocumentsQuery, useRenameDocumentMutation] = (
    type === DocumentType.PROPERTY
      ? [usePropertyDocumentsQuery, useRenamePropertyDocumentMutation]
      : type === DocumentType.OWNER
        ? [useGetOwnerDocumentsQuery, useRenameOwnerDocumentMutation]
        : type === DocumentType.UNIT
          ? [useUnitDocumentsQuery, useRenameUnitDocumentMutation]
          : type === DocumentType.RESIDENCY
            ? [useResidencyDocumentsQuery, useRenameResidencyDocumentMutation]
            : type === DocumentType.REQUEST
              ? [useRequestDocumentsQuery, useRenameRequestDocumentMutation]
              : type === DocumentType.TENANT
                ? [useTenantDocumentsQuery, useRenameTenantDocumentMutation]
                : [undefined, undefined]
  ) as [UseGetDocumentsProp<TDocumentType>?, UseRenameDocumentProp<TDocumentType>?];

  if (!useDocumentsQuery || !useRenameDocumentMutation) {
    throw new Error(`Unknown document type: "${type}"`);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useDocumentsWithType<TDocumentType>({
    type,
    source,
    useDocumentsQuery,
    useRenameDocumentMutation,
  });
};

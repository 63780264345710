/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReconciliationCompletedEvent } from './completed';
import ManagerBooksReconciliationCompletedEventSchema from './completed.json';

export const isManagerBooksReconciliationCompletedEvent = guard<ManagerBooksReconciliationCompletedEvent>(ManagerBooksReconciliationCompletedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitNoteRemovedEvent } from './noteRemoved';
import OperatorVisitNoteRemovedEventSchema from './noteRemoved.json';

export const isOperatorVisitNoteRemovedEvent = guard<OperatorVisitNoteRemovedEvent>(OperatorVisitNoteRemovedEventSchema as any);
import { Button, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useGetS3Image } from 'hooks/useS3Storage';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';
import {
  AddPhoto,
  Bubble,
  Container,
  Image,
  ImageContainer,
} from './ImageUploadFieldController.styles';

type ImagePreview = {
  url: string;
  name: string;
};

export default function ImageUploadFieldController({
  name,
  icon,
  preview,
  previewUrl,
  onDelete,
  helperText,
  className,
  ...props
}: TextFieldProps & {
  name: string;
  preview?: string;
  previewUrl?: string;
  icon?: ReactNode;
  onDelete?: VoidFunction;
  helperText?: string;
}) {
  const { control, watch } = useFormContext();
  const imagePreviewUrlFromKey = useGetS3Image(preview);
  const imagePreview = previewUrl ?? imagePreviewUrlFromKey;
  const [image, setImage] = useState<ImagePreview>({
    url: '',
    name: '',
  });
  const imageFieldValue = watch('image');

  useEffect(() => {
    if (imageFieldValue) {
      setImage({
        url: URL.createObjectURL(imageFieldValue),
        name: imageFieldValue.name,
      });
    }
  }, [imageFieldValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Container>
          {image.url !== '' || imagePreview ? (
            <ImageContainer>
              <Image alt={image.name} src={image.url || imagePreview} />

              {onDelete && preview && (
                <Button
                  color="primary"
                  component="span"
                  onClick={onDelete}
                  size="small"
                  sx={{ fontSize: 11 }}
                >
                  Remove
                </Button>
              )}
            </ImageContainer>
          ) : (
            <Bubble>{icon ? icon : <AddPhoto />}</Bubble>
          )}
          <TextField
            type="text"
            variant="filled"
            fullWidth
            {...props}
            sx={spreadIf(Boolean(fieldState.error?.message ?? helperText), { mb: 1 })}
            className={className}
            value={image.name}
            {...{
              error: Boolean(fieldState.error?.message),
              helperText: fieldState.error?.message ?? helperText,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor={`real-image-input-${name}`}>
                    <Button color="primary" component="span">
                      Upload
                    </Button>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`real-image-input-${name}`}
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                setImage({
                  url: URL.createObjectURL(event.target.files[0]),
                  name: event.target.files[0].name,
                });
                field.onChange(event.target.files[0]);
              }
            }}
          />
        </Container>
      )}
    />
  );
}

import { styled, Typography } from '@mui/material';
import { MoveOutReason, useResidencyExpiringThresholdsQuery } from 'api';
import { DateTime } from 'luxon';
import { isTerminated } from 'pages/properties/property/units/unit/residency/util';
import { rrulestr } from 'rrule';
import { formatDate, skipProps } from 'system';

const SOON_DAYS_THRESHOLD_DEFAULT = 60;

const Text = styled(
  Typography,
  skipProps('kind')
)<{ kind?: 'warning' | 'error' }>(({ theme, kind }) => ({
  fontSize: '1em',
  color: kind ? theme.palette[kind].main : undefined,
}));

type ResidencyExpiringProps = {
  startZ?: string;
  endZ?: string;
  moveOutReason?: MoveOutReason;
  renewZ?: string;
  rrule?: string;
  terms?: string[];
};

const maybeRenewalDate = (rrule?: string) => {
  if (!rrule) return;
  let dt: DateTime | undefined;
  try {
    const nextRenewal = rrulestr(rrule).after(new Date());
    if (!nextRenewal) return;
    dt = DateTime.fromJSDate(nextRenewal);
    if (dt.isValid) return dt;
  } catch (e) {
    // no op
  }
};

export default function ResidencyExpiring({
  startZ,
  endZ,
  moveOutReason,
  rrule,
  renewZ,
  terms,
}: ResidencyExpiringProps) {
  const { data } = useResidencyExpiringThresholdsQuery();

  const endDate = DateTime.fromISO(endZ ?? '').minus(1);
  const startDate = DateTime.fromISO(startZ ?? '');
  const today = DateTime.local().startOf('day');
  const isFuture = startDate > today;
  const isExpired = endDate <= today;

  const closestTermDate =
    terms
      ?.filter((term) => DateTime.fromISO(term) <= today)
      .sort()
      .reverse()[0] ?? '';
  const nextTerm = terms?.[terms?.indexOf(closestTermDate) + 1];

  const renews =
    isFuture || isExpired
      ? undefined
      : renewZ
        ? DateTime.fromISO(renewZ)
        : nextTerm
          ? DateTime.fromISO(nextTerm)
          : maybeRenewalDate(rrule);

  const isMonthToMonth = startDate.isValid && !endDate.isValid && !renews?.isValid;
  const isEmptyUnit = !startDate.isValid && !endDate.isValid;
  const terminated = isTerminated({ moveOutReason });

  const untilEnd = today.until(renews ?? endDate);
  const soonDays = data?.account?.settings?.soon_days_threshold || SOON_DAYS_THRESHOLD_DEFAULT;
  const isSoon = untilEnd.length('days') <= soonDays;

  return renews?.isValid ? (
    <>
      <Text
        fontWeight="bold"
        kind={isSoon ? 'warning' : isExpired || terminated ? 'error' : undefined}
      >
        Renews {renews.toRelativeCalendar()}
      </Text>
      <Text>{renews.toLocaleString(DateTime.DATE_FULL)}</Text>
    </>
  ) : isMonthToMonth && !isFuture ? (
    <>Month to Month lease</>
  ) : isEmptyUnit ? (
    <>Empty Unit</>
  ) : (
    <>
      <Text
        fontWeight="bold"
        kind={isSoon ? 'warning' : isExpired || terminated ? 'error' : undefined}
      >
        {terminated
          ? 'Terminated '
          : isExpired
            ? 'Expired '
            : isFuture
              ? 'Will start '
              : 'Expires '}
        {isFuture ? startDate.toRelativeCalendar() : endDate.toRelativeCalendar()}
      </Text>

      <Text>{formatDate(isFuture ? startDate : endDate)}</Text>
    </>
  );
}

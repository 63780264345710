/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApplicationCampaignChangedEvent } from './applicationCampaignChanged';
import ManagerApplicationCampaignChangedEventSchema from './applicationCampaignChanged.json';

export const isManagerApplicationCampaignChangedEvent = guard<ManagerApplicationCampaignChangedEvent>(ManagerApplicationCampaignChangedEventSchema as any);
import { createContext, useContext } from 'react';
import { AuthState } from './types';

export const AuthContext = createContext<AuthState | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AppProvider');
  }

  return context;
};

export default useAuth;

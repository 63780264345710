import {
  CommitReconciliationMutation,
  ReconciliationStatus,
  useCommitReconciliationMutation,
  useGetReconciliationQuery,
} from 'api';
import { useMeta } from 'hooks/useMeta';
import { usePayloadNotification } from 'hooks/usePayloadNotification';
import { useCallback } from 'react';
import { NullableAllDefined, graphqlNull } from 'system';

const toGraphqlCommitReconciliation = ({
  id,
}: {
  id: string;
}): NullableAllDefined<CommitReconciliationMutation> => ({
  __typename: 'Mutation',
  completeReconciliation: {
    success: true,
    error: graphqlNull,
    __typename: 'ReconciliationPayload',
    reconciliation: {
      __typename: 'Reconciliation',
      id,
      status: ReconciliationStatus.Committed,
    },
  },
});

enum Notifications {
  COMMITTED = 'Reconciliation Committed',
  UNKNOWN_ERROR = 'Could not commit your reconciliation, please try again later.',
}

export const useCommitReconciliation = ({ reconciliationId }: { reconciliationId: string }) => {
  const { promiseNotification } = usePayloadNotification();

  const [commitReconciliationMutation, commitMeta] = useCommitReconciliationMutation();
  const { data, ...getMeta } = useGetReconciliationQuery({
    variables: { id: reconciliationId },
    skip: !reconciliationId,
  });
  const { loading } = useMeta(getMeta, commitMeta);
  const glAccountName = data?.reconciliation?.glAccount?.name;

  const commitReconciliation = useCallback(
    () =>
      promiseNotification({
        promise: commitReconciliationMutation({
          variables: { id: reconciliationId },
          optimisticResponse: toGraphqlCommitReconciliation({ id: reconciliationId }),
        }),
        getPayload: (d) => d?.completeReconciliation,
        successMessage: `${glAccountName} ${Notifications.COMMITTED}`,
        defaultErrorMessage: Notifications.UNKNOWN_ERROR,
      }),
    [commitReconciliationMutation, glAccountName, promiseNotification, reconciliationId]
  );

  return {
    commitReconciliation,
    loading,
  };
};

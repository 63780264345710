import { eventFactory } from '@propra-system/shape-handler';
import { z } from 'zod';

export const {
  managerAutopayPaymentsPrototypeCompletedEvent,
  managerAutopayPaymentsPrototypeCompletedEventDetail,
} = eventFactory(
  'manager-autopay-payments-prototype-completed-event',
  z.object({
    id: z.string(),
    accountId: z.string(),
    batchId: z.string(),
    completedZ: z.string().datetime(),
    payees: z
      .object({
        payeeClearingAmounts: z
          .object({
            clearableId: z.string(),
            amount: z.number(),
          })
          .array(),
      })
      .array(),
  })
);

import { ReactNode, createContext, useContext, useState } from 'react';
import { noOp } from 'system';

const defaultSiteOptions = {
  residencyData: true,
};

type SiteOptionsContextType = {
  options: typeof defaultSiteOptions;
  enable: (key: keyof typeof defaultSiteOptions) => void;
  disable: (key: keyof typeof defaultSiteOptions) => void;
};

const SiteOptions = createContext<SiteOptionsContextType>({
  options: defaultSiteOptions,
  enable: noOp,
  disable: noOp,
});
export const useSiteOptions = (): [
  SiteOptionsContextType['options'],
  Omit<SiteOptionsContextType, 'options'>,
] => {
  const { options, ...controls } = useContext(SiteOptions);
  return [options, controls];
};

export const SiteOptionsProvider = ({ children }: { children: ReactNode }) => {
  const [options, setOptions] = useState(defaultSiteOptions);
  const value: SiteOptionsContextType = {
    options,
    enable: (key: keyof typeof defaultSiteOptions) => {
      setOptions((prev) => ({ ...prev, [key]: true }));
    },
    disable: (key: keyof typeof defaultSiteOptions) => {
      setOptions((prev) => ({ ...prev, [key]: false }));
    },
  };

  // const { addAlert, removeAlert } = useSiteAlerts();

  // useEffect(() => {
  //   const handleAlert = options.residencyData ? removeAlert : addAlert;
  //   handleAlert({
  //     key: 'residencyData',
  //     severity: 'info',
  //     children: 'Using legacy data structure for leases.',
  //   });
  // }, [addAlert, options.residencyData, removeAlert]);

  return <SiteOptions.Provider value={value}>{children}</SiteOptions.Provider>;
};

import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { emptyArray } from 'system';
import { useDboBatchEvents } from '../hooks/useDboBatchEvents';

type BatchListener = {
  id: string;
  label: string;
  path?: string;
  total?: number;
  kind?: 'dbo' | 'books';
} & Pick<Parameters<typeof useDboBatchEvents>[0], 'predicate'>;

export type BatchContextType = {
  batchListeners: BatchListener[];
  addBatchListener: (arg: BatchListener) => void;
  removeBatchListener: (id: string) => void;
  updateBatchListener: (id: string, updates: Partial<BatchListener>) => void;
};

const initialState: BatchContextType = {
  batchListeners: emptyArray,
  addBatchListener: () => undefined,
  updateBatchListener: () => undefined,
  removeBatchListener: () => undefined,
};

const BatchContext = createContext<BatchContextType>(initialState);

export const useBatchContext = () => {
  const context = useContext(BatchContext);
  if (!context) {
    throw new Error('useBatchContext must be used within an BatchProvider');
  }

  return context;
};

export const BatchProvider = ({ children }: { children: ReactNode }) => {
  const [batchListeners, setBatchListeners] = useState<BatchListener[]>([]);

  const addBatchListener = useCallback((listener: BatchListener) => {
    setBatchListeners((listeners) =>
      listeners.some(({ id }) => id === listener.id)
        ? listeners.map((l) => (l.id === listener.id ? listener : l))
        : [...listeners, listener]
    );
  }, []);

  const updateBatchListener = useCallback(
    (id: string, updates: Partial<BatchListener>) =>
      setBatchListeners((listeners) =>
        listeners.map((l) => (l.id === id ? { ...l, ...updates } : l))
      ),
    []
  );

  const removeBatchListener = useCallback(
    (listenerId: string) =>
      setBatchListeners((listeners) => listeners.filter(({ id }) => id !== listenerId)),
    []
  );

  return (
    <BatchContext.Provider
      value={{
        batchListeners,
        addBatchListener,
        updateBatchListener,
        removeBatchListener,
      }}
    >
      {children}
    </BatchContext.Provider>
  );
};

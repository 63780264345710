import {
  autocompleteClasses,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  Box,
  styled,
} from '@mui/material';
import { sum } from 'lodash';
import { createContext, forwardRef, HTMLAttributes, ReactNode, useContext } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Option } from 'system';

const MAX_ITEMS = 10;
const ITEM_PADDING_VERTICAL = 12;
const CONTAINER_PADDING_VERTICAL = 0;

export type ItemData = [
  renderProps: HTMLAttributes<HTMLLIElement>,
  option: Option,
  state: AutocompleteRenderOptionState,
];

export type ItemGroupData = Omit<AutocompleteRenderGroupParams, 'children'> & {
  children?: ItemData[];
};

export const renderVirtualGroup = (params: AutocompleteRenderGroupParams) =>
  params as unknown as ReactNode;

export const renderVirtualOption = (...params: ItemData) => params as ReactNode;

const OuterElement = styled(Box)(({ theme }) => ({
  [`& ${autocompleteClasses.listbox}`]: {
    padding: theme.spacing(`${CONTAINER_PADDING_VERTICAL}px`, 0),
  },
}));
const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => <OuterElement ref={ref} {...props} {...useContext(OuterElementContext)} />
);

export const listboxComponentFactory = ({
  renderOption,
  getItemHeight,
}: {
  getItemHeight: (item: ItemData | ItemGroupData) => number;
  renderOption: (_index: number, data: ItemData | ItemGroupData) => JSX.Element;
}) =>
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(
    { children, ...outerProps },
    ref
  ) {
    // Necessary hack, we're hi-jacking the render to give us the items for us to virtualize
    // flattens groups so all elements are inlined, and we can properly calc estimated heights
    const itemData = (children as (ItemData | ItemGroupData)[]).flatMap((item) => [
      item,
      ...(hasGroup(item) ? (item.children ?? []) : []),
    ]);

    // estimating height for initial render assuming {count} items shown
    const height =
      sum(itemData.slice(0, MAX_ITEMS).map(getItemHeight)) + 2 * CONTAINER_PADDING_VERTICAL;

    return (
      <Box ref={ref}>
        <OuterElementContext.Provider value={outerProps}>
          <Virtuoso
            style={{ height }}
            data={itemData}
            itemContent={renderOption}
            totalCount={itemData.length}
            components={{ Scroller: OuterElementType }}
            overscan={height}
          ></Virtuoso>
        </OuterElementContext.Provider>
      </Box>
    );
  });

export const spacingToNumber = (themeSize: string) => Number(themeSize.slice(0, -2));

export const hasGroup = (item: ItemData | ItemGroupData): item is ItemGroupData =>
  Object.prototype.hasOwnProperty.call(item, 'group');

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerFlinksGetaccountResponseEvent } from './getaccount';
import ManagerFlinksGetaccountResponseEventSchema from './getaccount.json';

export const isManagerFlinksGetaccountResponseEvent = guard<ManagerFlinksGetaccountResponseEvent>(ManagerFlinksGetaccountResponseEventSchema as any);
import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';
import { CurrencyField, CurrencyFieldProps } from '../Fields/CurrencyField';

export default function CurrencyFieldController({
  name,
  onChange,
  error,
  helperText,
  onValueChange,
  type: _type,
  showClear = false,
  InputProps,
  ...props
}: Omit<CurrencyFieldProps, 'value'> & { name: string }) {
  const { control, setValue, resetField } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => (
        <CurrencyField
          {...field}
          onChange={onChange}
          onValueChange={(vals) => {
            setValue(name, vals.floatValue, { shouldValidate: true });
            onValueChange?.(vals);
          }}
          InputProps={{
            ref,
            ...InputProps,
            ...spreadIf(showClear, {
              endAdornment: (
                <IconButton
                  sx={{ visibility: field.value ? 'visible' : 'hidden' }}
                  onClick={() => {
                    resetField(name);
                    setValue(name, 0);
                  }}
                >
                  <Clear />
                </IconButton>
              ),
            }),
          }}
          error={Boolean(fieldState.error?.message ?? error)}
          helperText={fieldState.error?.message ?? helperText}
          {...props}
        />
      )}
    />
  );
}

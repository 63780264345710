import { GlAccountFieldsFragment } from 'api';
import { useMeta } from 'hooks/useMeta';
import { find } from 'lodash';
import { GLCategory } from 'pages/accounting/categories';
import { useCallback, useEffect, useState } from 'react';
import { ensureArray } from 'system';
import { useBooks } from './useBooks';
import { useGlAccounts } from './useGlAccounts';

interface CategoryGlAccounts {
  (category: GLCategory): GlAccountFieldsFragment[];
  (categoryArray: GLCategory[]): GlAccountFieldsFragment[];
}

export type ChargeMapping = {
  id: string;
  name: string;
  chargeId: string;
  glId: string;
  amount?: number;
  limit?: number;
  description?: string;
};

export const useChargeMapping = () => {
  const { books, account, ...booksMeta } = useBooks();
  const { glAccounts, ...glAccountsMeta } = useGlAccounts();
  const [chargeMapping, setChargeMapping] = useState<ChargeMapping[]>([]);

  const { loading } = useMeta(booksMeta, glAccountsMeta);

  useEffect(() => {
    const charges = ensureArray(account?.charges);
    const mapping = ensureArray(books?.chargeMapping);

    setChargeMapping(
      charges.map(({ id, limit, default: amount, name }) => ({
        id,
        chargeId: id,
        name,
        amount,
        limit,
        glId: find(mapping, { chargeId: id })?.glId ?? '',
        description: name,
      }))
    );
  }, [account?.charges, books?.chargeMapping, setChargeMapping]);

  const mappedGlId = useCallback(
    (chargeId: string) => find(chargeMapping, { chargeId })?.glId,
    [chargeMapping]
  );

  const mappedGlAccount = useCallback(
    (chargeId: string) => find(glAccounts, { id: find(chargeMapping, { chargeId })?.glId }),
    [glAccounts, chargeMapping]
  );

  const categoryGlAccounts: CategoryGlAccounts = useCallback(
    (category: GLCategory | GLCategory[]) =>
      glAccounts.filter((gl) =>
        Array.isArray(category)
          ? gl.category && category.includes(gl.category as GLCategory)
          : category === gl.category
      ),
    [glAccounts]
  );

  return {
    loading,
    chargeMapping,
    mappedGlId,
    mappedGlAccount,
    categoryGlAccounts,
  };
};

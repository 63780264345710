/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEffectUpdatedEvent } from './effectUpdated';
import ManagerResidencyEffectUpdatedEventSchema from './effectUpdated.json';

export const isManagerResidencyEffectUpdatedEvent = guard<ManagerResidencyEffectUpdatedEvent>(ManagerResidencyEffectUpdatedEventSchema as any);
import { ManagerRole } from 'api';
import { FullScreenSpinner } from 'components';
import { useAuth } from 'context';
import { DefaultFrame } from 'navigation/frames';
import AuthFrame from 'navigation/frames/AuthFrame';
import lazyload from 'navigation/lazyload';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

const Reset = lazyload(() => import('./reset'));
const Login = lazyload(() => import('./login'));

const NotFound = lazyload(() => import('./notfound'));

const Account = lazyload(() => import('./account'));
const Announcements = lazyload(() => import('./announcements'));
const Accounting = lazyload(() => import('./accounting'));
const Campaigns = lazyload(() => import('./campaigns'));
const Collections = lazyload(() => import('./collections'));
const Dashboard = lazyload(() => import('./dashboard'));
const Operators = lazyload(() => import('./operators'));
const Owners = lazyload(() => import('./owners'));
const Properties = lazyload(() => import('./properties'));
const Requests = lazyload(() => import('./requests'));
const Suppliers = lazyload(() => import('./suppliers'));
const Templates = lazyload(() => import('./templates'));
const Tenants = lazyload(() => import('./tenants'));
const Autopay = lazyload(() => import('./autopay'));
const Residencies = lazyload(() => import('./residencies'));

export const AppRoutes = () => {
  const { roles, isAuthenticated, loading } = useAuth();
  const { pathname } = useLocation();
  const includesAnyRole = (...restrictions: ManagerRole[]) =>
    roles && restrictions.some((r) => roles.includes(r));

  return (
    <Routes>
      <Route
        path="/reset"
        element={
          <DefaultFrame>
            <Reset />
          </DefaultFrame>
        }
      />
      <Route
        path="/login"
        element={
          <DefaultFrame>
            <Login />
          </DefaultFrame>
        }
      />

      {loading ? (
        <Route path="/*" element={<FullScreenSpinner />} />
      ) : !isAuthenticated ? (
        <Route path="/*" element={<Navigate to={'/login'} state={{ from: { pathname } }} />} />
      ) : (
        <Route path="/*" element={<AuthFrame />}>
          <Route index element={<Dashboard />} />

          <Route path="account/*" element={<Account />} />
          <Route path="announcements/*" element={<Announcements />} />
          <Route path="collections/*" element={<Collections />} />
          <Route
            path="leases/*"
            element={<Navigate to={pathname.replace('leases', 'residencies')} replace />}
          />
          <Route path="operators/*" element={<Operators />} />
          <Route path="owners/*" element={<Owners />} />
          <Route path="properties/*" element={<Properties />} />
          <Route path="requests/*" element={<Requests />} />
          <Route path="suppliers/*" element={<Suppliers />} />
          <Route path="templates/*" element={<Templates />} />
          <Route path="tenants/*" element={<Tenants />} />
          <Route path="campaigns/*" element={<Campaigns />} />
          <Route path="residencies/*" element={<Residencies />} />

          {includesAnyRole(
            ManagerRole.BooksAdmin,
            ManagerRole.BooksAdvanced,
            ManagerRole.BooksUser,
            ManagerRole.BooksReporter
          ) && <Route path="accounting/*" element={<Accounting />} />}

          {includesAnyRole(
            ManagerRole.Admin,
            ManagerRole.BooksAdmin,
            ManagerRole.BooksAdvanced,
            ManagerRole.BooksUser,
            ManagerRole.User
          ) && <Route path="autopay/*" element={<Autopay />} />}

          <Route path="*" element={<NotFound />} />
        </Route>
      )}
    </Routes>
  );
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitAssignedEvent } from './assigned';
import OperatorVisitAssignedEventSchema from './assigned.json';

export const isOperatorVisitAssignedEvent = guard<OperatorVisitAssignedEvent>(OperatorVisitAssignedEventSchema as any);
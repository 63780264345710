/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAnnouncementCompletedEvent } from './completed';
import ManagerAnnouncementCompletedEventSchema from './completed.json';

export const isManagerAnnouncementCompletedEvent = guard<ManagerAnnouncementCompletedEvent>(ManagerAnnouncementCompletedEventSchema as any);
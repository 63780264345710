import { ErrorMessage } from '@hookform/error-message';
import {
  Autocomplete,
  CircularProgress,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  Grid,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { get } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Option } from 'system';

const Icon = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.secondary,
  margin: theme.spacing(1, 1, 0, 1),
}));

type OptionHandler = (options: Option[]) => void;

type SearchBarProps = {
  multiple?: boolean;
  options: Option[];
  onSelectValue?: OptionHandler;
  onRemoveValue?: OptionHandler;
  onCreateOption?: OptionHandler;
  onBlur?: OptionHandler;
  onClear?: OptionHandler;
  disableOption?: (option: Option) => boolean;
  filterOptions?: (options: Option[], params: FilterOptionsState<Option>) => Option[];
  getOptionLabel?: (option: Option) => string;
  onSearch: (searchTerm: string) => void;
  icon?: ReactNode;
  loading?: boolean;
  className?: string;
  helperText?: string;
  placeholder?: string;
  searchFieldName?: string;
  clearOnBlur?: boolean;
  startAdornment?: JSX.Element;
};

export default function AutocompleteSearchFieldController({
  disabled = false,
  variant = 'outlined',
  label = '',
  name,
  className,
  options = [],
  onSelectValue,
  onRemoveValue,
  onCreateOption,
  onBlur,
  onClear,
  disableOption,
  filterOptions,
  getOptionLabel = (option) => option?.text ?? '',
  onSearch,
  icon,
  helperText,
  placeholder,
  multiple = false,
  searchFieldName,
  loading,
  startAdornment,
}: TextFieldProps & SearchBarProps & { name: string; loading?: boolean }) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [search, setSearch] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSearch(search), [search]);
  useEffect(() => setFilteredOptions(options), [options]);

  type Handler = (selected: Option[]) => void;
  const handlerMap: Record<string, Handler | undefined> = {
    selectOption: onSelectValue,
    removeOption: onRemoveValue,
    createOption: onCreateOption,
    blur: onBlur,
    clear: onClear,
  };

  const onChange = (_event: unknown, selected: unknown | unknown[], reason: string) =>
    typeof reason === 'string' &&
    typeof handlerMap[reason] === 'function' &&
    (!multiple || Array.isArray(selected)) &&
    (handlerMap[reason] as Handler)((multiple ? selected : [selected]) as Option[]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl variant={variant} fullWidth className={clsx(className)}>
          <Autocomplete
            freeSolo
            clearOnBlur
            disabled={disabled}
            autoHighlight
            multiple={multiple}
            defaultValue={
              field.value?.length
                ? field.value.map((fieldValue: string) => ({
                    id: '',
                    text: fieldValue,
                    subText: fieldValue,
                  }))
                : []
            }
            onInputChange={(_event, searchTerm, reason) => {
              if (reason === 'input') {
                setSearch(searchTerm);
              } else if (reason === 'reset') {
                field.onChange(_event, searchTerm, reason);
                setValue(name, []);
              }
            }}
            options={filteredOptions}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : getOptionLabel(option)
            }
            getOptionDisabled={disableOption}
            onBlur={field.onBlur}
            onChange={onChange}
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  {icon && <Icon item>{icon}</Icon>}
                  <Grid item>
                    <Typography variant="body1">{option?.text}</Typography>
                    {option?.subText && (
                      <Typography variant="body2" color="textSecondary">
                        {option.subText}
                      </Typography>
                    )}
                    {option?.disabled && option?.disabledMessage && (
                      <Typography variant="caption" color="error">
                        {option.disabledMessage}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={'filled'}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  ...(startAdornment ? { startAdornment } : {}),
                }}
                {...{
                  ...(searchFieldName && {
                    onChange: (evt) => {
                      setValue(searchFieldName, evt.target.value, { shouldValidate: true });
                    },
                    onKeyDown: (e) => {
                      if (e.key === 'Enter' && get(errors, searchFieldName)) {
                        e.stopPropagation();
                      }
                    },
                  }),
                }}
              />
            )}
          />

          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
          />

          {searchFieldName && (
            <ErrorMessage
              errors={errors}
              name={searchFieldName}
              render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
            />
          )}

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

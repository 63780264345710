import { ApolloError } from '@apollo/client';
import { TableCellProps, TableProps, TableRowProps } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { ReactNode } from 'react';

export type SxProps = TableCellProps['sx'];
export type AdaptiveColumn = {
  field: string;
  headerName: string;
  flex?: CSSProperties['flex'];
  sx?: TableRowProps['sx'];
  headerSx?: TableCellProps['sx'];
  minWidth?: number;
  headerMargin?: number;
  hidden?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCell?: (params: { id: string; value: any; row: Row }) => ReactNode;
};

export type AdaptiveTableProps = {
  rows: Row[];
  columns: AdaptiveColumn[];
  onRowClick?: (id: string, row: Row) => void;
  loading?: boolean;
  error?: string | ApolloError;
  toolbarComponent?: ReactNode | ((obj: { rows: Row[] }) => JSX.Element);
  footerComponent?: ReactNode;
  sx?: TableProps['sx'];
  header?: 'none' | 'mobileOnly' | 'desktopOnly' | 'always';
  cellPadding?: number;
  hasMargins?: boolean;
  condensed?: boolean;
  headerRowSx?: TableCellProps['sx'];
  hideBorder?: boolean;
  rowSx?: TableRowProps['sx'];
};

export type Row = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type Column = {
  field: string;
  sortField?: string;
  headerName: ReactNode;
  headerTooltip?: string;
  flex?: CSSProperties['flex'];
  className?: string;
  sx?: TableCellProps['sx'];
  filterColumnValues?: string[];
  renderCsvCell?: (params: { id: string; value: Row; row?: Row }) => ReactNode;
  renderCell?: (params: { id: string; value: Row; row?: Row }) => ReactNode;
  hideCsv?: boolean;
  hideDesktop?: boolean;
  hideMobile?: boolean;
  disableSort?: boolean;
  hidden?: boolean;
};

export type GlobalFilter<TRow = Row> = { label: string; test: (row: TRow) => boolean }[];

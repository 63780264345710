import { useEffect, useState } from 'react';
import { ifDifferent } from 'system';

/** @deprecated use useDebouncedValue instead */
export const useDebounce = <TValue = unknown>(value: TValue, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 300);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

export const useDebouncedValue = <TValue = unknown>(
  value: TValue,
  options?: { delay?: number }
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    setDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(ifDifferent(value));
      setDebouncing(false);
    }, options?.delay ?? 300);

    return () => {
      clearTimeout(handler);
    };
  }, [options?.delay, value]);

  return [debouncedValue, debouncing] satisfies [TValue, boolean];
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyRecurrenceUpdatedEvent } from './recurrenceUpdated';
import ManagerResidencyRecurrenceUpdatedEventSchema from './recurrenceUpdated.json';

export const isManagerResidencyRecurrenceUpdatedEvent = guard<ManagerResidencyRecurrenceUpdatedEvent>(ManagerResidencyRecurrenceUpdatedEventSchema as any);
import { FileCopy } from '@mui/icons-material';
import { Avatar, Chip, Stack, Typography } from '@mui/material';

export type DocumentCellProps = {
  name: string;
  subtext?: string;
  size?: string;
};

export function DocumentCell({ name, size, subtext }: DocumentCellProps) {
  return (
    <Stack gap={1} direction="row" alignItems="center">
      <Avatar>
        <FileCopy />
      </Avatar>

      <Stack alignItems="flex-start">
        <Typography variant="body1">{name.split('/').reverse()[0]}</Typography>
        {size && (
          <Typography variant="subtitle2" color="textSecondary">
            {size}
          </Typography>
        )}
        {subtext && (
          <Typography variant="subtitle2" color="textSecondary">
            {subtext}
          </Typography>
        )}
        {name.startsWith('shared-') || name.includes('/shared-') || name.includes('shared/') ? (
          <Chip label="Shared" />
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEventAddedEvent } from './eventAdded';
import ManagerResidencyEventAddedEventSchema from './eventAdded.json';

export const isManagerResidencyEventAddedEvent = guard<ManagerResidencyEventAddedEvent>(ManagerResidencyEventAddedEventSchema as any);
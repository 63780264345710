import { Storage } from '@aws-amplify/storage';
import { Document } from 'api';
import { useAuth } from 'context';
import { useNotification } from 'hooks/useNotification';
import {
  isUploadError,
  isUploadSuccess,
  joinFileNames,
  UploadError,
  UploadSuccess,
} from 'hooks/useUploadFiles';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { downloadFileS3 } from 'system';

const bucket = process.env.REACT_APP_DOCUMENT_BUCKET ?? '';

export type UploadFilesFunction = (props: { id: string; resetOnSuccess?: boolean }) => Promise<{
  successfulUploads: UploadSuccess[];
  failedUploads: UploadError[];
}>;

export const useAccountingUploadFiles = () => {
  const [filesToUpload, setFilesToUpdate] = useState<File[]>([]);
  const { sendNotification } = useNotification();
  const { accountId } = useAuth();

  const removeUploadFile = (file: File) =>
    setFilesToUpdate(filesToUpload.filter(({ name }) => name !== file.name));

  const addUploadFiles = (files: File[]) =>
    setFilesToUpdate(uniqBy([...filesToUpload, ...files], 'name'));

  const uploadFiles: UploadFilesFunction = async ({ id, resetOnSuccess = true }) => {
    const results = await Promise.all(
      filesToUpload.map((file) =>
        Storage.put(`books/accounts/${accountId}/journalEntries/${id}/${file.name}`, file, {
          bucket,
        })
          .then((res) => ({ ...res, file }))
          .catch((e) => ({ file, error: e }))
      )
    );

    const failedUploads = results.filter(isUploadError);
    const successfulUploads = results.filter(isUploadSuccess);

    if (failedUploads.length) {
      sendNotification(`Error uploading ${joinFileNames(failedUploads)}`, 'error');
      console.error(`Error uploading ${failedUploads.length} files`, { failedUploads });
    }

    if (successfulUploads.length) {
      sendNotification(`Successfully uploaded ${joinFileNames(successfulUploads)}`, 'success');
      console.info(`Successfully uploaded ${successfulUploads.length} files`, {
        successfulUploads,
      });
    }

    if (failedUploads.length) {
      setFilesToUpdate(failedUploads.map(({ file }) => file));
    } else if (resetOnSuccess) {
      setFilesToUpdate([]);
    }

    return { successfulUploads, failedUploads };
  };

  return {
    addUploadFiles,
    filesToUpload,
    removeUploadFile,
    uploadFiles,
  };
};

export const useAccountingFiles = (journalEntryId: string) => {
  const { accountId } = useAuth();
  const [refresh, setRefresh] = useState(0);
  const { sendNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Document[]>([]);

  useEffect(() => {
    async function fetchList() {
      try {
        setLoading(true);
        const list = await Storage.list(
          `books/accounts/${accountId}/journalEntries/${journalEntryId}`,
          { bucket, pageSize: 1000 }
        );
        const allFiles = await Promise.all(
          list.results.map(async ({ key, size, lastModified }) => {
            return {
              name: key?.substring(key?.lastIndexOf('/') + 1),
              key,
              size,
              createdZ: DateTime.fromJSDate(lastModified ?? new Date()).toString(),
            };
          })
        );
        setFiles(allFiles as Document[]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error listing files', { error });
      }
    }

    if (journalEntryId !== '') {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalEntryId, refresh]);

  const downloadFile = async (key: string) => {
    if (!journalEntryId) {
      return;
    }
    await downloadFileS3(key, bucket);
  };

  const deleteFile = async (key: string) => {
    if (!journalEntryId) {
      return;
    }

    try {
      await Storage.remove(key, { bucket });
      sendNotification(`Successfully deleted '${key}'`, 'success');
      setRefresh(refresh + 1);
    } catch (error: unknown) {
      console.error('Error removing file', { error });
    }
  };

  return {
    deleteFile,
    downloadFile,
    files,
    loading,
    refresh: () => setRefresh(refresh + 1),
  };
};

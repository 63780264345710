import { Stack } from '@mui/material';
import { Form, LoadingButton, Modal } from 'components';
import { FormProps } from 'components/FormController/Form';
import { ModalProps } from 'components/Modal';
import { useEffect } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { ButtonContainer, CancelButton } from './FormModalStyles';

export type ModalFormProps<T extends FieldValues, Z extends z.ZodTypeAny> = {
  disabled?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  modalProps: Omit<ModalProps, 'onSubmit' | 'actions' | 'children'>;
  formProps: Omit<FormProps<T, Z>, 'children'>;
  children: JSX.Element | JSX.Element[];
  afterSubmit?: (success?: boolean) => void;
};

export const FormModalButtons = <
  T extends FieldValues = Record<string, unknown>,
  Z extends z.ZodTypeAny = never,
>({
  onSubmit,
  onClose,
  disabled,
  defaultValues,
  cancelLabel = 'Cancel',
  submitLabel = 'Save',
  afterSubmit,
}: Pick<FormProps<T, Z>, 'defaultValues' | 'onSubmit'> &
  Pick<ModalProps, 'onClose'> &
  Pick<ModalFormProps<T, Z>, 'submitLabel' | 'cancelLabel' | 'afterSubmit' | 'disabled'>) => {
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useFormContext<T>();

  useEffect(() => {
    if (!getValues() && defaultValues) {
      reset(defaultValues);
    }
  });

  const onAfterSubmit =
    afterSubmit ??
    ((success?: boolean) => {
      if (success !== false) {
        onClose();
        reset();
      }
    });

  return (
    <ButtonContainer>
      <CancelButton onClick={onClose} disabled={isSubmitting || disabled} noPrimary={!onSubmit}>
        {cancelLabel}
      </CancelButton>

      {onSubmit && (
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={disabled}
          loading={isSubmitting}
          onClick={handleSubmit(async (values) => {
            const result = await onSubmit?.(values);
            onAfterSubmit(result);
          })}
        >
          {submitLabel}
        </LoadingButton>
      )}
    </ButtonContainer>
  );
};

export default function FormModal<
  T extends FieldValues = Record<string, unknown>,
  Z extends z.ZodTypeAny = never,
>({ modalProps, formProps, children, ...labelProps }: ModalFormProps<T, Z>) {
  return (
    <Modal {...modalProps} sx={{ p: 2 }}>
      <Form<T, Z> {...formProps}>
        <Stack spacing={2}>{children}</Stack>
        <FormModalButtons<T> {...{ ...formProps, ...modalProps, ...labelProps }} />
      </Form>
    </Modal>
  );
}

import { Skeleton, Stack, StackProps, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

const Value = styled('span')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 500,
});

const Field = styled(Typography)({
  flexShrink: 0,
  fontSize: '12px',
  lineHeight: '24px',
  fontWeight: 500,
  textTransform: 'uppercase',
});

export default function FieldValuePair({
  field,
  value,
  inverted,
  hideUndefined,
  direction,
  loading,
  gap,
}: {
  field: string;
  value?: string | ReactNode | null;
  inverted?: boolean;
  hideUndefined?: boolean;
  gap?: StackProps['spacing'];
  direction?: StackProps['direction'];
  loading?: boolean;
}) {
  return hideUndefined && !value ? (
    <></>
  ) : inverted ? (
    <Stack direction={direction} gap={gap}>
      <Value>{field}</Value>
      {loading ? (
        <Skeleton>
          <Field />
        </Skeleton>
      ) : (
        <Field color="textSecondary">{value}</Field>
      )}
    </Stack>
  ) : (
    <Stack direction={direction} gap={gap}>
      <Field color="textSecondary">{field}</Field>
      {loading ? <Skeleton variant="text" sx={{ flex: 1 }} /> : <Value>{value}</Value>}
    </Stack>
  );
}

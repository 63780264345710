import { MutationFunctionOptions, useReactiveVar } from '@apollo/client';
import {
  GetBatchPrototypesQuery,
  InitiateBooksBatchInput,
  JePrototype,
  JePrototypeField,
  useCancelBooksBatchMutation,
  useCommitBooksBatchMutation,
  useGetBatchQuery,
  useInitiateBooksBatchMutation,
  useToggleBatchPrototypesMutation,
  useUpdateBatchPrototypeMutation,
} from 'api';
import { draftBatchState } from 'cache';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { chunk } from 'lodash';
import { Duration } from 'luxon';
import { useBatchContext } from 'pages/accounting/context';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NonNullableField, graphqlNull } from 'system';
import { useDboBatchEvents } from '../useDboBatchEvents';
import { usePollQueryUntil } from '../usePollQueryUntil';

export type InitiateBatchFormFields = {
  posted: string;
  propertyIds: string[];
  [key: string]: unknown;
};

export type PostBatchFormFields = {
  posted: string;
  prototypes: JePrototype[] | undefined;
};

export type BatchPrototypeRowModel = {
  id: string;
  fields?: Omit<JePrototypeField, '__typename'>[];
  [field: string]: unknown;
};

export type BatchFields = Omit<NonNullable<GetBatchPrototypesQuery['batch']>, '__typename'>;
export type BatchPrototypeFields = Omit<
  NonNullableField<BatchFields, 'listPrototypes.items.0'>,
  '__typename'
>;

export const useInitiateBooksBatch = ({ presetId }: { presetId: string }) => {
  const navigate = useNavigate();
  const { sendNotification } = useNotification();
  const [initiateBatchMutation, meta] = useInitiateBooksBatchMutation();
  const { loading } = useMeta(meta);

  const initiateBooksBatch = useCallback(
    async ({ propertyIds, posted, ...rest }: InitiateBatchFormFields) => {
      const input: InitiateBooksBatchInput = {
        posted,
        presetId,
        propertyIds,
        parameters: JSON.stringify(rest),
      };

      try {
        const { data } = await initiateBatchMutation({ variables: { input } });

        if (!data?.initiateBooksBatch.success) {
          throw new Error(data?.initiateBooksBatch.error ?? 'Failed to initiate batch');
        }

        navigate(`/accounting/tasks/${data?.initiateBooksBatch.batch?.id}`);
        return data?.initiateBooksBatch?.batch?.id;
      } catch (e) {
        console.error('failed to initiate batch', e);
        sendNotification(e instanceof Error ? e.message : 'Error creating batch', 'error');
      }
    },
    [initiateBatchMutation, navigate, presetId, sendNotification]
  );

  return { loading, initiateBooksBatch };
};

export const useCancelBooksBatch = (batchId?: string) => {
  const { sendNotification } = useNotification();
  const [cancelBooksBatchMutation, meta] = useCancelBooksBatchMutation();
  const { loading } = useMeta(meta);

  const cancelBooksBatch = useCallback(
    async (
      id: string = batchId ?? '',
      options?: Omit<MutationFunctionOptions, 'variables' | 'optimisticResponse'>
    ) => {
      if (!id) throw new Error('Batch id not found');

      try {
        await cancelBooksBatchMutation({ variables: { id }, ...options });
        return true;
      } catch (e) {
        console.error('Failed to cancel batch record', e);
        sendNotification('Error cancelling batch', 'error');
        return false;
      }
    },
    [batchId, cancelBooksBatchMutation, sendNotification]
  );

  return { loading, cancelBooksBatch };
};

export const useCommitBooksBatch = (batchId?: string) => {
  const { addBatchListener } = useBatchContext();

  const { sendNotification } = useNotification();
  const [commitBooksBatchMutation, meta] = useCommitBooksBatchMutation();
  const { loading } = useMeta(meta);

  const commitBooksBatch = useCallback(
    async (
      id: string = batchId ?? '',
      options?: Omit<MutationFunctionOptions, 'variables' | 'optimisticResponse'>
    ) => {
      if (!id) throw new Error('Batch id not found');

      try {
        await commitBooksBatchMutation({
          ...options,
          variables: { id },
          update(cache, { data }) {
            if (data?.commitBooksBatch?.success && data?.commitBooksBatch.batch) {
              addBatchListener({
                id: `commit-${id}`,
                label: data?.commitBooksBatch.batch.name ?? 'task',
                predicate: ({ booksBatchId }) => booksBatchId === id,
              });
            }
          },
        });

        return true;
      } catch (e) {
        console.error('Failed to commit batch record', e);
        sendNotification('Error commitling batch', 'error');
        return false;
      }
    },
    [batchId, commitBooksBatchMutation, addBatchListener, sendNotification]
  );

  return { loading, commitBooksBatch };
};

const toDisplayedField = (field: JePrototypeField) => ({ ...field });
const fromDisplayedField = ({ id, value, groupId }: ReturnType<typeof toDisplayedField>) => ({
  id,
  groupId,
  value: typeof value === 'object' ? JSON.stringify(value) : value,
});

export const useUpdateBatchPrototype = () => {
  const { sendNotification } = useNotification();
  const [updatePrototypeMutation, updatePrototypeMeta] = useUpdateBatchPrototypeMutation();
  const [togglePrototypesMutation, togglePrototypesMeta] = useToggleBatchPrototypesMutation();
  const { loading } = useMeta(updatePrototypeMeta, togglePrototypesMeta);

  const updatePrototype = useCallback(
    async (
      { id, omit = false, fields }: BatchPrototypeFields,
      options?: Omit<MutationFunctionOptions, 'variables' | 'optimisticResponse'>
    ) => {
      try {
        await updatePrototypeMutation({
          ...options,
          variables: {
            input: {
              id,
              omit,
              fields: fields?.map(fromDisplayedField),
            },
          },
          optimisticResponse: {
            updateBatchPrototype: {
              __typename: 'BatchPrototypePayload',
              success: true,
              error: null as unknown as undefined,
              prototype: {
                __typename: 'JEPrototype',
                id,
                omit,
                fields: fields?.map((f) => ({
                  ...f,
                  ...fromDisplayedField(f),
                  __typename: 'JEPrototypeField',
                })),
              },
            },
          },
        });
      } catch (e) {
        console.error('Failed to update prototype record', e);
        sendNotification('Error updating batch', 'error');
      }
    },
    [sendNotification, updatePrototypeMutation]
  );

  const togglePrototypes = useCallback(
    async (
      { ids: prototypeIds, batchId, omit }: { ids: string[]; batchId: string; omit?: boolean },
      options?: Omit<MutationFunctionOptions, 'variables' | 'optimisticResponse'>
    ) => {
      for (const ids of chunk(prototypeIds, 1000)) {
        await togglePrototypesMutation({
          ...options,
          variables: { input: { ids, omit: omit ?? false } },
          optimisticResponse: {
            toggleBatchPrototypes: {
              __typename: 'JEPrototypeKeysPayload',
              success: true,
              error: graphqlNull,
              prototypeKeys: ids.map((id) => ({ id, batchId, __typename: 'JEPrototypeKey' })),
            },
          },
          update(cache, { data }) {
            if (data?.toggleBatchPrototypes.success) {
              ids.forEach((id) => {
                cache.modify({
                  id: cache.identify({ id, __typename: 'JEPrototype' }),
                  fields: {
                    omit: () => omit,
                  },
                });
              });
            }
          },
        });
      }
    },
    [togglePrototypesMutation]
  );

  return { updatePrototype, togglePrototypes, loading };
};

export const useBooksBatch = ({ batchId }: { batchId: string }) => {
  const { sendNotification } = useNotification();
  const predicate = useCallback(
    (d: { booksBatchId?: string }) => d.booksBatchId === batchId,
    [batchId]
  );

  const { batchProgress, isBatchStale } = useDboBatchEvents({
    predicate,
    staleTimeout: Duration.fromObject({ seconds: 15 }),
  });

  const [isPrepared, { data: headerData, ...headerMeta }] = usePollQueryUntil(useGetBatchQuery, {
    pollInterval: 5000,
    variables: { batchId },
    pollUntil: (query) => query?.batch?.status === 'PREPARED',
    shouldPoll: (query) =>
      !['PREPARED', 'FAILED'].includes(query?.batch?.status ?? '') &&
      (isBatchStale || batchProgress.done !== false),
  });

  useEffect(() => {
    if (batchProgress.done !== true && !isPrepared && isBatchStale) {
      sendNotification(`Checking for updates`, 'info');
    }
  }, [batchProgress.done, isBatchStale, isPrepared, sendNotification]);

  const batch = headerData?.batch;
  const failed = batch?.status === 'FAILED';

  return {
    batch,
    loading: useMeta(headerMeta).loading,
    batchProgress,
    failed,
    errorMessage: failed
      ? (batch?.errorMessage ??
        `Failed to prepare ${batch.name ?? 'task'}. Please try again later or contact support`)
      : '',
    working:
      !failed && (!['PREPARED', 'POSTED'].includes(batch?.status ?? '') || headerMeta.loading),
  };
};

export const useStoredDraftBatch = ({ draftBatchId }: { draftBatchId: string }) => {
  const draftBatch = useReactiveVar(draftBatchState);

  return {
    draftBatch: draftBatch?.id === draftBatchId ? draftBatch : undefined,
  };
};

import { Place } from 'hooks/useGoogleAutocompletePredictions';
import { get } from 'lodash';
import { Country, Provinces, TimezonesByCountry, UnionToIntersection } from 'system/types';

export const geography = {
  CA: {
    code: 'CA',
    name: 'Canada',
    timezone: 'America/Edmonton',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      AB: { code: 'AB', timezone: 'America/Edmonton', name: 'Alberta' },
      BC: { code: 'BC', timezone: 'America/Vancouver', name: 'British Columbia' },
      MB: { code: 'MB', timezone: 'America/Winnipeg', name: 'Manitoba' },
      NB: { code: 'NB', timezone: 'America/Halifax', name: 'New Brunswick' },
      NL: { code: 'NL', timezone: 'America/St_Johns', name: 'Newfoundland and Labrador' },
      NS: { code: 'NS', timezone: 'America/Halifax', name: 'Nova Scotia' },
      NT: { code: 'NT', timezone: 'America/Edmonton', name: 'Northwest Territories' },
      NU: { code: 'NU', timezone: 'America/Winnipeg', name: 'Nunavut' },
      ON: { code: 'ON', timezone: 'America/Toronto', name: 'Ontario' },
      PE: { code: 'PE', timezone: 'America/Halifax', name: 'Prince Edward Island' },
      QC: { code: 'QC', timezone: 'America/Toronto', name: 'Quebec' },
      SK: { code: 'SK', timezone: 'America/Regina', name: 'Saskatchewan' },
      YT: { code: 'YT', timezone: 'America/Edmonton', name: 'Yukon}' },
    },
  },
  PA: {
    code: 'PA',
    name: 'Panama',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    timezone: 'America/Panama',
    provinces: {
      '01': { code: '01', name: 'Bocas del Toro', timezone: undefined },
      '02': { code: '02', name: 'Cocle', timezone: undefined },
      '03': { code: '03', name: 'Colon', timezone: undefined },
      '04': { code: '04', name: 'Chiriqui', timezone: undefined },
      '05': { code: '05', name: 'Darien', timezone: undefined },
      '06': { code: '06', name: 'Herrera', timezone: undefined },
      '07': { code: '07', name: 'Los Santos', timezone: undefined },
      '08': { code: '08', name: 'Panama', timezone: undefined },
      '09': { code: '09', name: 'Veraguas', timezone: undefined },
      '10': { code: '10', name: 'West Panama', timezone: undefined },
      EM: { code: 'EM', name: 'Embera', timezone: undefined },
      KY: { code: 'KY', name: 'Guna Yala', timezone: undefined },
      NB: { code: 'NB', name: 'Ngabe-Bugle', timezone: undefined },
      NT: { code: 'NT', name: 'Naso Tjer Di', timezone: undefined },
    },
  },
  US: {
    code: 'US',
    name: 'United States',
    timezone: 'America/Chicago',
    provinceLabel: 'State',
    postalLabel: 'Zip',
    provinces: {
      AL: { timezone: 'America/Chicago', code: 'AL', name: 'Alabama' },
      AK: { timezone: 'America/Anchorage', code: 'AK', name: 'Alaska' },
      AZ: { timezone: 'America/Phoenix', code: 'AZ', name: 'Arizona' },
      AR: { timezone: 'America/Chicago', code: 'AR', name: 'Arkansas' },
      CA: { timezone: 'America/Los_Angeles', code: 'CA', name: 'California' },
      CO: { timezone: 'America/Denver', code: 'CO', name: 'Colorado' },
      CT: { timezone: 'America/New_York', code: 'CT', name: 'Connecticut' },
      DE: { timezone: 'America/New_York', code: 'DE', name: 'Delaware' },
      FL: { timezone: 'America/New_York', code: 'FL', name: 'Florida' },
      GA: { timezone: 'America/New_York', code: 'GA', name: 'Georgia' },
      HI: { timezone: 'Pacific/Honolulu', code: 'HI', name: 'Hawaii' },
      ID: { timezone: 'America/Denver', code: 'ID', name: 'Idaho' },
      IL: { timezone: 'America/Chicago', code: 'IL', name: 'Illinois' },
      IN: { timezone: 'America/Indianapolis', code: 'IN', name: 'Indiana' },
      IA: { timezone: 'America/Chicago', code: 'IA', name: 'Iowa' },
      KS: { timezone: 'America/Chicago', code: 'KS', name: 'Kansas' },
      KY: { timezone: 'America/New_York', code: 'KY', name: 'Kentucky' },
      LA: { timezone: 'America/Chicago', code: 'LA', name: 'Louisiana' },
      ME: { timezone: 'America/New_York', code: 'ME', name: 'Maine' },
      MD: { timezone: 'America/New_York', code: 'MD', name: 'Maryland' },
      MA: { timezone: 'America/New_York', code: 'MA', name: 'Massachusetts' },
      MI: { timezone: 'America/New_York', code: 'MI', name: 'Michigan' },
      MN: { timezone: 'America/Chicago', code: 'MN', name: 'Minnesota' },
      MS: { timezone: 'America/Chicago', code: 'MS', name: 'Mississippi' },
      MO: { timezone: 'America/Chicago', code: 'MO', name: 'Missouri' },
      MT: { timezone: 'America/Denver', code: 'MT', name: 'Montana' },
      NE: { timezone: 'America/Chicago', code: 'NE', name: 'Nebraska' },
      NV: { timezone: 'America/Los_Angeles', code: 'NV', name: 'Nevada' },
      NH: { timezone: 'America/New_York', code: 'NH', name: 'New Hampshire' },
      NJ: { timezone: 'America/New_York', code: 'NJ', name: 'New Jersey' },
      NM: { timezone: 'America/Denver', code: 'NM', name: 'New Mexico' },
      NY: { timezone: 'America/New_York', code: 'NY', name: 'New York' },
      NC: { timezone: 'America/New_York', code: 'NC', name: 'North Carolina' },
      ND: { timezone: 'America/Chicago', code: 'ND', name: 'North Dakota' },
      OH: { timezone: 'America/New_York', code: 'OH', name: 'Ohio' },
      OK: { timezone: 'America/Chicago', code: 'OK', name: 'Oklahoma' },
      OR: { timezone: 'America/Los_Angeles', code: 'OR', name: 'Oregon' },
      PA: { timezone: 'America/New_York', code: 'PA', name: 'Pennsylvania' },
      RI: { timezone: 'America/New_York', code: 'RI', name: 'Rhode Island' },
      SC: { timezone: 'America/New_York', code: 'SC', name: 'South Carolina' },
      SD: { timezone: 'America/Chicago', code: 'SD', name: 'South Dakota' },
      TN: { timezone: 'America/Chicago', code: 'TN', name: 'Tennessee' },
      TX: { timezone: 'America/Chicago', code: 'TX', name: 'Texas' },
      UT: { timezone: 'America/Denver', code: 'UT', name: 'Utah' },
      VT: { timezone: 'America/New_York', code: 'VT', name: 'Vermont' },
      VA: { timezone: 'America/New_York', code: 'VA', name: 'Virginia' },
      WA: { timezone: 'America/Los_Angeles', code: 'WA', name: 'Washington' },
      WV: { timezone: 'America/New_York', code: 'WV', name: 'West Virginia' },
      WI: { timezone: 'America/Chicago', code: 'WI', name: 'Wisconsin' },
      WY: { timezone: 'America/Denver', code: 'WY', name: 'Wyoming' },
    },
  },
};

export const validCountry = (country: unknown): country is Country =>
  typeof country === 'string' && Object.keys(geography).includes(country);

export const getTimezone = (country: Country, province = '') => {
  type FlattenedTimezones = UnionToIntersection<(typeof TimezonesByCountry)[typeof country]>;

  const timezones = TimezonesByCountry[country];

  const prov = Object.keys(timezones).includes(province)
    ? (province as keyof FlattenedTimezones)
    : undefined;

  return prov ? (get(timezones as FlattenedTimezones, [prov]) ?? null) : null;
};

export const provinceLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'State' : 'Province';

export const postalCodeLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'Zip Code' : 'Postal Code';

const ProvinceNames = Object.fromEntries(
  Object.entries({
    ...geography.PA.provinces,
    ...geography.US.provinces,
    ...geography.CA.provinces,
  }).map(([k, { name }]) => [k, name])
);

export const provinceName = (province?: string) =>
  province ? ProvinceNames[province.toUpperCase()] : (province ?? province);

export const DEFAULT_GST = 0.05;

export const getCondoLabels = (province: keyof typeof Provinces | string | undefined) =>
  province === Provinces.BC
    ? {
        condo: 'strata',
        unitFactor: 'unit entitlement',
      }
    : {
        condo: 'condo',
        unitFactor: 'unit factor',
      };

export const placeToAddress = (place?: Place, country: Country = Country.CA) => {
  const timezone = getTimezone(country, place?.province);

  return {
    ...(place?.streetName && { street: place.streetName }),
    ...(place?.city && { city: place.city }),
    ...(place?.postalCode && { postal: place.postalCode }),
    ...(place?.lat && { lat: place.lat }),
    ...(place?.lng && { lng: place.lng }),
    ...(place?.province && { province: place.province }),
    ...(timezone ? { timezone } : {}),
    ...(country && { country }),
  };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorUnseenTasksUpdatedEvent } from './unseenTaskUpdated';
import OperatorUnseenTasksUpdatedEventSchema from './unseenTaskUpdated.json';

export const isOperatorUnseenTasksUpdatedEvent = guard<OperatorUnseenTasksUpdatedEvent>(OperatorUnseenTasksUpdatedEventSchema as any);
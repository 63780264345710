import { SignatureInput, useGetCurrentUserQuery, useUpdateManagerMutation } from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useLoading } from 'hooks/useLoading';

export const useCurrentUser = () => {
  const { data, loading: userLoading, error: apolloError, refetch } = useGetCurrentUserQuery();

  const [updateManagerMutation, { error: updateManagerError, loading: loadingUpdateManager }] =
    useUpdateManagerMutation();

  useAllErrors(apolloError, updateManagerError);

  const updateSignature = async (signature: SignatureInput) => {
    if (!data?.manager?.id) {
      return;
    }

    const result = await updateManagerMutation({
      variables: {
        id: data.manager.id,
        input: {
          signature,
        },
      },
    });

    return result.data?.updateManager?.signature;
  };

  const loading = useLoading(userLoading, loadingUpdateManager);

  return { loading, currentUser: data?.manager, updateSignature, refetch };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayCollectionTypeRemovedEvent } from './collectionTypeRemoved';
import ManagerAutopayCollectionTypeRemovedEventSchema from './collectionTypeRemoved.json';

export const isManagerAutopayCollectionTypeRemovedEvent = guard<ManagerAutopayCollectionTypeRemovedEvent>(ManagerAutopayCollectionTypeRemovedEventSchema as any);
import { Box, Button, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { skipProps, spreadIf } from 'system';

export type ActionCellProps = {
  id: string;
  icon: ReactNode;
  name: string;
  onClick: (id: string) => void;
  disabled?: boolean;
  float?: boolean;
};

const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const ActionButton = styled(
  Button,
  skipProps('float')
)<{ float: boolean }>(({ theme, float }) => ({
  display: 'flex',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(2),
    alignItems: 'center',
  },
  ...spreadIf(float, {
    float: 'right',
    [theme.breakpoints.down('lg')]: {
      float: 'none',
    },
  }),
}));

const Text = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '1em',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8em',
  },
}));

export default function ActionsCell({
  actions,
  stopPropagation = false,
}: {
  actions: ActionCellProps[];
  stopPropagation?: boolean;
}) {
  return (
    <Container>
      {actions.map(({ id, icon, name, onClick, disabled = false, float = false }) => (
        <ActionButton
          key={`action-${name}-${id}`}
          float={float}
          startIcon={icon}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
            onClick(id);
          }}
          disabled={disabled}
        >
          <Text>{name}</Text>
        </ActionButton>
      ))}
    </Container>
  );
}

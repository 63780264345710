/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOperatorUpdatedEvent } from './updated';
import ManagerOperatorUpdatedEventSchema from './updated.json';

export const isManagerOperatorUpdatedEvent = guard<ManagerOperatorUpdatedEvent>(ManagerOperatorUpdatedEventSchema as any);
import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { CSSProperties } from '@mui/styles';

type StyledTableHeadCellProps = {
  flex: CSSProperties['flex'];
  children: ReactNode;
  minwidth?: number;
  margin?: number;
  className?: string;
};

export const StyledTableHeadCell = styled(TableCell)<StyledTableHeadCellProps>(
  ({ flex, minwidth, margin, theme }) => ({
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    border: 'none',
    margin: theme.spacing(2, 1, 2, margin ?? 2),
    flex,
    minwidth,
    align: 'left',
    padding: '0px',
  })
);

import { Reference, useApolloClient } from '@apollo/client';
import { Document, DocumentFieldsFragmentDoc } from 'api';
import { useAuth } from 'context';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { compact, map, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import plur from 'plur';
import { useCallback, useEffect, useState } from 'react';
import { DocumentType, ensureArray, S3KeyFolder } from 'system';
import { IDKey, UseGetDocumentsProp, UseRenameDocumentProp } from './types';

export type UseDocumentsWithTypeProps<TDocumentType extends DocumentType> = {
  readonly type: TDocumentType;
  source?: { id?: string; __typename?: string };
  useDocumentsQuery: UseGetDocumentsProp<TDocumentType>;
  useRenameDocumentMutation: UseRenameDocumentProp<TDocumentType>;
};

export const useDocumentsWithType = <TDocumentType extends DocumentType>({
  type,
  source: { id = '', __typename } = {},
  useDocumentsQuery,
  useRenameDocumentMutation,
}: UseDocumentsWithTypeProps<TDocumentType>) => {
  const client = useApolloClient();
  const cache = client.cache;
  const [docError, setDocError] = useState('');
  const { accountId } = useAuth();
  const { data, loading, error: queryError } = useDocumentsQuery({ variables: { id } });
  const [renameDocumentMutation, { error: renameError }] = useRenameDocumentMutation({});
  const [documents, setDocuments] = useState<Document[]>([]);
  const fullS3Key = useCallback(
    (key: string) => `accounts/${accountId}/${S3KeyFolder[type]}/${id}/${key}`,
    [accountId, id, type]
  );

  useAllErrors(docError, queryError, renameError);

  useEffect(() => {
    setDocuments(ensureArray(data?.[type]?.documents));
  }, [data, type]);

  const removeDocument = (key: string) => {
    const docRef = cache.identify({ key, __typename: 'Document' });
    cache.evict({ id: docRef });
    cache.gc();
  };

  const refreshDocumentKey = (document: Document, newKey: string) => {
    const oldCacheId = cache.identify(document);
    cache.evict({ id: oldCacheId });

    cache.modify({
      id: cache.identify({ id, __typename }),
      fields: {
        documents: (existing?: Reference[]) =>
          map(existing, (ref) =>
            ref.__ref === oldCacheId
              ? cache.writeFragment({
                  data: {
                    ...document,
                    typename: document.typename ?? null,
                    name: document.name,
                    size: document.size,
                    key: newKey,
                  },
                  fragment: DocumentFieldsFragmentDoc,
                })
              : ref
          ),
      },
    });

    cache.gc();
  };

  const addDocuments = async (uploadedFiles?: { localKey: string; file: File }[]) => {
    uploadedFiles?.forEach(({ localKey, file }) => {
      client.writeFragment({
        data: {
          __typename: 'Document',
          key: localKey,
          name: null,
          size: file.size,
          typename: null,
          createdZ: DateTime.utc().toISO(),
        },
        fragment: DocumentFieldsFragmentDoc,
      });
      const newRef = { __ref: cache.identify({ key: localKey, __typename: 'Document' }) };

      cache.modify({
        id: cache.identify({ id, __typename }),
        fields: {
          documents: (existing?: Reference[]) =>
            uniqBy([...ensureArray(existing), newRef], '__ref'),
        },
      });
    });
  };

  const renameDocument = async ({
    document: { key },
    values: { name },
  }: {
    document: Document;
    values: { name: string };
  }) => {
    if (!id) {
      setDocError('Must have a valid id in source property');
      console.error(`'id' missing in ${type} source`);
      return;
    }

    const sourceKey = {
      [`${type}Id`]: id,
    } as IDKey<TDocumentType>;

    renameDocumentMutation({
      variables: { input: { ...sourceKey, key, name } },
    });
  };

  const validateFiles = (files: File[]) => {
    const dup = compact(files.map(({ name }) => documents.find(({ key }) => key === name)));

    if (dup.length > 0) {
      const dupKeys = dup.map(({ key }) => key);
      setDocError(`Duplicate ${plur('file', dup.length)}: ${JSON.stringify(dupKeys)}`);
    }

    return dup.length === 0;
  };

  return {
    documents,
    loading,
    fullS3Key,
    addDocuments,
    validateFiles,
    removeDocument,
    refreshDocumentKey,
    renameDocument,
  };
};

import { TextField, TextFieldProps } from '@mui/material';
import { toNumber } from 'lodash';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { spreadIf } from 'system';

const numberFormatSx: TextFieldProps['sx'] = {
  ['& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button']:
    {
      display: 'none',
    },
};

export default function TextFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  variant,
  helperText,
  className,
  sx,
  ...props
}: TextFieldProps & { name: Path<T> }) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          variant={variant ?? 'filled'}
          fullWidth
          {...props}
          {...field}
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(
              props.type === 'number' ? (e.target.value ? toNumber(e.target.value) : e) : e
            );
            props.onChange?.(e);
          }}
          inputProps={{
            autoComplete: 'off',
            ...props.inputProps,
            inputMode: props.type === 'number' ? 'numeric' : 'text',
          }}
          InputProps={{ ...props.InputProps }}
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message || helperText}
          className={className}
          sx={{ ...numberFormatSx, ...spreadIf(sx) }}
        />
      )}
    />
  );
}

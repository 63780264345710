import { ViewList, ViewModule } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SearchBar } from 'components';
import { PersistentState, usePersistentState } from 'hooks/usePersistentState';
import { ReactNode } from 'react';
import { GridContainer, ListContainer, Title } from './ListGrid.styles';

export default function ListGrid<T>({
  items,
  renderListItem,
  renderGridItem,
  onSearchEntered,
}: {
  items: T[];
  renderListItem: (item: T) => ReactNode;
  renderGridItem: (item: T) => ReactNode;
  onSearchEntered: (searchTerm: string) => void;
}) {
  const [view, setView] = usePersistentState<'grid' | 'list'>(PersistentState.listGrid, 'list');

  return (
    <>
      <Title>
        <SearchBar placeholder="Search" initialSearch="" onSearchEntered={onSearchEntered} />
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={(_: React.MouseEvent<HTMLElement>, nextView: string) =>
            setView(nextView === 'list' ? 'list' : 'grid')
          }
        >
          <ToggleButton value="list" aria-label="list">
            <ViewList fontSize="small" color={view === 'list' ? 'primary' : 'action'} />
          </ToggleButton>
          <ToggleButton value="grid" aria-label="grid">
            <ViewModule fontSize="small" color={view === 'grid' ? 'primary' : 'action'} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Title>
      {view === 'grid' && (
        <GridContainer>
          {items.map((item, index) => (
            <div key={index}>{renderGridItem(item)}</div>
          ))}
        </GridContainer>
      )}
      {view === 'list' && (
        <ListContainer>
          {items.map((item, index) => (
            <div key={index}>{renderListItem(item)}</div>
          ))}
        </ListContainer>
      )}
    </>
  );
}

import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { ContactRelationship } from 'api';
import AttributeText from 'components/AttributeText';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { EmergencyContactFields, ensureArray } from 'system';
import DropdownFieldController from './DropdownFieldController';
import TextFieldController from './TextFieldController';

type EmergencyContactFieldsValues = FieldValues & {
  emergencyContacts?: EmergencyContactFields[];
};

export default function EmergencyContactsFieldsController() {
  const { control } = useFormContext<EmergencyContactFieldsValues>();
  const { remove, append, fields } = useFieldArray<EmergencyContactFieldsValues>({
    control,
    name: 'emergencyContacts',
  });

  return (
    <Stack gap={1} alignItems="flex-start">
      <AttributeText>Emergency Contact</AttributeText>

      <Stack direction="row" spacing={1} justifyContent="space-between" width="100%">
        <Button variant="text" onClick={() => append([{ name: '', phone: '' }])} size="small">
          Add Emergency Contact
        </Button>
        {fields.length ? (
          <Button variant="text" onClick={() => remove()} size="small">
            Remove All
          </Button>
        ) : (
          <></>
        )}
      </Stack>

      <Box sx={{ width: '100%' }}>
        {ensureArray(fields).map((_contact, index) => (
          <Stack direction="row" spacing={1} key={index} marginBottom={1}>
            <TextFieldController name={`emergencyContacts.${index}.name`} label="Contact Name" />
            <DropdownFieldController
              name={`emergencyContacts.${index}.relationship`}
              type="text"
              fullWidth
              label="Relationship"
              data={Object.values(ContactRelationship).map((r) => ({ label: r, value: r }))}
            />
            <TextFieldController name={`emergencyContacts.${index}.phone`} label="Phone" />
            <IconButton aria-label="delete" size="small" onClick={() => remove(0)}>
              <Close fontSize="inherit" />
            </IconButton>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}

import {
  styled,
  TableRow as _MaterialTableRow,
  TableRowProps as MaterialTableRowProps,
} from '@mui/material';
import { skipProps, spreadIf } from 'system';
import { AdaptiveColumn, Row } from '../types';
import TableCell from './TableCell';

type TableRowProps = {
  row: Row;
  onRowClick?: (id: string, row: Row) => void;
  columns: AdaptiveColumn[];
  cellPadding?: number;
  condensed?: boolean;
  hideBorder?: boolean;
  sx?: MaterialTableRowProps['sx'];
};

const MaterialTableRow = styled(
  _MaterialTableRow,
  skipProps('hideBorder', 'clickable', 'condensed')
)<{ hideBorder?: boolean; clickable?: boolean; condensed?: boolean }>(
  ({ clickable, condensed, hideBorder, theme }) => ({
    display: 'flex',
    borderBottom: hideBorder ? 0 : `1px solid ${theme.palette.divider}`,
    minHeight: 80,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    ...spreadIf(clickable, { cursor: 'pointer' }),
    ...spreadIf(condensed, {
      paddingLeft: theme.spacing(2),
      minHeight: 40,
      '& .MuiTableCell-root': { p: 0 },
    }),
  })
);

export default function TableRow({
  row,
  onRowClick,
  columns,
  cellPadding,
  condensed,
  hideBorder,
  sx,
}: TableRowProps) {
  return (
    <MaterialTableRow
      hover
      onClick={() => onRowClick?.(row.id, row)}
      hideBorder={hideBorder}
      clickable={Boolean(onRowClick)}
      condensed={condensed}
      sx={sx}
    >
      {columns.map((column) => (
        <TableCell
          key={`${row.id}-${column.field}`}
          {...{ column, row }}
          cellPadding={cellPadding}
        />
      ))}
    </MaterialTableRow>
  );
}

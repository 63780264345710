import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useCallback, useEffect } from 'react';

type PollQueryUntilOptions<
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>,
> = QueryHookOptions<TData, TVariables> & {
  pollInterval?: number;
  pollUntil: (data?: TData) => boolean;
  shouldPoll: (data?: TData) => boolean;
};

export const usePollQueryUntil = <
  TData extends Record<string, unknown>,
  TVariables extends Record<string, unknown>,
>(
  useListData: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  {
    pollInterval: interval = 5000,
    shouldPoll,
    pollUntil: until,
    ...options
  }: PollQueryUntilOptions<TData, TVariables>
) => {
  const { data, startPolling, stopPolling, ...meta } = useListData({ ...options });

  const untilFn = useCallback(until, [until]);
  const shouldPollFn = useCallback(shouldPoll, [shouldPoll]);

  useEffect(() => {
    if (shouldPollFn(data) && !untilFn(data)) {
      startPolling(interval);
    } else {
      stopPolling();
    }
  }, [data, interval, shouldPollFn, startPolling, stopPolling, untilFn]);

  return [untilFn(data), { data, ...meta }] as const;
};

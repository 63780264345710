import { Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Logo } from 'components';
import { AppBar, Spacer, Toolbar } from './AuthBar.styles';

export default function AuthBar({ handleMobileToggle }: { handleMobileToggle: VoidFunction }) {
  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar disableGutters>
        <Logo color="primary" />
        <Spacer />
        <IconButton onClick={handleMobileToggle} size="large">
          <Menu />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

import { useCallback, useState } from 'react';

/**
 * @returns [handleShow, handleHide, open, focus, focusType]
 */
export const useModalControl = <T = unknown, TFocusType extends string | undefined = undefined>(
  defaultFocus?: T,
  defaultFocusType?: TFocusType
) => {
  const [state, setState] = useState({
    open: false,
    focus: undefined as T | undefined,
    focusType: undefined as TFocusType | undefined,
  });
  const handleHide = useCallback(() => setState((prev) => ({ ...prev, open: false })), []);

  const handleShow = useCallback(
    (focus?: T, focusType?: TFocusType): void =>
      setState({
        open: true,
        focus: focus ?? defaultFocus,
        focusType: focusType ?? defaultFocusType,
      }),
    [defaultFocus, defaultFocusType]
  );

  return [handleShow, handleHide, state.open, state.focus, state.focusType] as const;
};

import { styled, TableCellProps, TableHead as MaterialTableHead, TableRow } from '@mui/material';
import { AdaptiveColumn } from '../types';
import TableHeadCell from './TableHeadCell';

type TableHeadProps = {
  sx?: TableCellProps['sx'];
  columns: AdaptiveColumn[];
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default function TableHead({ sx, columns }: TableHeadProps) {
  return (
    <MaterialTableHead>
      <StyledTableRow sx={sx}>
        {columns.map((column) => (
          <TableHeadCell
            sx={column?.headerSx ?? {}}
            key={column.field}
            // TODO This block to be removed after we move to the new MUI 5 style
            flex={column?.flex ?? 1}
            minWidth={column.minWidth}
            margin={column.headerMargin}
            //
          >
            {column.headerName}
          </TableHeadCell>
        ))}
      </StyledTableRow>
    </MaterialTableHead>
  );
}

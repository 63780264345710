import { Grid } from '@mui/material';
import { RequestFieldsFragment } from 'api';
import { BodyText } from 'components';
import { DateTime } from 'luxon';
import { newestToOldest } from 'system';
import RequestHistoryCard from './RequestHistoryCard';

export default function RequestHistory({
  requests,
  loading,
}: {
  requests: RequestFieldsFragment[];
  loading: boolean;
}) {
  const items = requests.map((request) => ({
    isRequest: true as const,
    name: request.name ?? '',
    id: request.id,
    date: DateTime.fromISO(request.fingerprints?.createdZ ?? ''),
    summary: request.summary ?? '',
    status: request.status ?? '',
    tenantName: request.tenant?.name ?? '',
  }));
  return (
    <Grid gap={2}>
      {items
        .slice()
        .sort(newestToOldest)
        .map((item) =>
          item.isRequest ? (
            <Grid item key={item.id} sx={{ mb: 2 }}>
              <RequestHistoryCard loading={loading} item={item} />
            </Grid>
          ) : (
            <Grid item key={item.id}>
              <BodyText loading={loading}>{JSON.stringify(item)}</BodyText>
            </Grid>
          )
        )}
    </Grid>
  );
}

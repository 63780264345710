import { useGetBooksQuery } from 'api';
import { useAuth } from 'context';

export const useBooks = () => {
  const { isBooksReporter } = useAuth();
  const booksMeta = useGetBooksQuery({ skip: !isBooksReporter });

  return {
    account: booksMeta.data?.account,
    books: booksMeta.data?.account?.books,
    ...booksMeta,
  };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitNoteAddedEvent } from './noteAdded';
import OperatorVisitNoteAddedEventSchema from './noteAdded.json';

export const isOperatorVisitNoteAddedEvent = guard<OperatorVisitNoteAddedEvent>(OperatorVisitNoteAddedEventSchema as any);
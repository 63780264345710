import { World } from 'components/icons';
import { MouseEventHandler } from 'react';
import { Box, Button, Paper, SubTitle, Title } from './ErrorPage.styles';

type ErrorTemplateProps = {
  title?: string;
  subtitle?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
};

export default function ErrorTemplate({
  title,
  subtitle,
  onClick,
  buttonText,
}: ErrorTemplateProps) {
  return (
    <Paper>
      <Box style={{ textAlign: 'center' }}>
        <World sx={{ height: 103, width: 103 }} />
        {title && (
          <Title variant="h2">
            {title}
            {subtitle && <SubTitle variant="inherit">{subtitle}</SubTitle>}
          </Title>
        )}
        {buttonText && (
          <Button color="primary" variant="contained" onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Paper>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksBalanceUpdatedEvent } from './balanceUpdated';
import ManagerBooksBalanceUpdatedEventSchema from './balanceUpdated.json';

export const isManagerBooksBalanceUpdatedEvent = guard<ManagerBooksBalanceUpdatedEvent>(ManagerBooksBalanceUpdatedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerAutopayInviteChangedEvent } from './autopayInviteChanged';
import ManagerOwnerAutopayInviteChangedEventSchema from './autopayInviteChanged.json';

export const isManagerOwnerAutopayInviteChangedEvent = guard<ManagerOwnerAutopayInviteChangedEvent>(ManagerOwnerAutopayInviteChangedEventSchema as any);
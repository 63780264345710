import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export default function Working({ ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 46 46" {...props}>
      <path
        d="M0.50024 23.0015C0.500241 34.679 9.38774 44.264 20.7502 45.389L20.7502 40.844C11.8852 39.7415 5.00024 32.159 5.00024 23.0015C5.00024 17.759 7.29524 13.0565 10.8952 9.77154L16.2502 15.1265L16.2502 1.62653L2.75024 1.62654L7.70024 6.57654C3.29024 10.6715 0.50024 16.499 0.50024 23.0015Z"
        fill={theme.palette.tertiary.main}
      />
      <path
        d="M35.1048 36.2317C38.7048 32.9467 40.9998 28.2442 40.9998 23.0017C40.9998 13.8442 34.1148 6.2617 25.2498 5.1592L25.2498 0.614199C36.6123 1.7392 45.4998 11.3242 45.4998 23.0017C45.4998 29.5042 42.7098 35.3317 38.2998 39.4267L43.2498 44.3767L29.7498 44.3767L29.7498 30.8767L35.1048 36.2317Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M12.0092 30.0678L21.3565 20.4979C20.4321 18.0792 20.9456 15.2398 22.8973 13.2417C24.9516 11.1384 28.0331 10.7178 30.4984 11.8746L26.0815 16.3966L29.163 19.5515L33.6826 15.0294C34.9152 17.5534 34.4016 20.7083 32.3473 22.8115C30.3956 24.8096 27.6223 25.3354 25.2598 24.389L15.9125 33.9588C15.5016 34.3794 14.8853 34.3794 14.4744 33.9588L12.1119 31.54C11.5984 31.1194 11.5984 30.3833 12.0092 30.0678Z"
        fill={theme.palette.secondary.main}
      />
    </SvgIcon>
  );
}

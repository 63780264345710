import { Button, styled, Toolbar, Typography } from '@mui/material';
import { SearchBar } from 'components';
import { Csv } from 'components/icons';
import { ReactNode } from 'react';
import { Row } from '../types';

type EnhancedTableToolbarProps = {
  allowSearch: boolean;
  onExportCsv?: VoidFunction;
  toolbarComponent?: ReactNode | ((obj: { rows: Row[] }) => JSX.Element);
  search?: string;
  updateSearch: (search: string) => void;
  toolbarTitle?: string;
  rows: Row[];
};

const RootToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.divider,
}));

const ExportToolbarContainer = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 0),
  minHeight: 0,
}));

export default function EnhancedTableToolbar({
  allowSearch,
  onExportCsv,
  search,
  updateSearch,
  toolbarComponent,
  toolbarTitle,
  rows,
}: EnhancedTableToolbarProps) {
  const ExportToolbar = () => (
    <ExportToolbarContainer>
      <Button
        startIcon={<Csv />}
        onClick={(e) => {
          e.stopPropagation();
          onExportCsv?.();
        }}
        disabled={false}
      ></Button>
    </ExportToolbarContainer>
  );

  return (
    <>
      {onExportCsv && toolbarComponent && <ExportToolbar />}
      <RootToolbar>
        {toolbarTitle && <Typography variant="h4">{toolbarTitle}</Typography>}
        {allowSearch && (
          <SearchBar placeholder="Search" initialSearch={search} onSearchEntered={updateSearch} />
        )}
        {onExportCsv && !toolbarComponent && <ExportToolbar />}
        {typeof toolbarComponent === 'function' ? toolbarComponent({ rows }) : toolbarComponent}
      </RootToolbar>
    </>
  );
}

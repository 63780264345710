import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import clsx from 'clsx';
import { InheritListText } from 'components/InheritListText';
import { compact, groupBy } from 'lodash';
import { ReactElement, ReactNode, useMemo } from 'react';

export type DropdownFieldProps<T = unknown> = Omit<SelectProps<T>, 'error'> & {
  name?: string;
  label?: string;
  data: {
    label: string;
    value: string | number;
    subLabel?: string;
  }[];
  shrink?: boolean;
  helperText?: ReactNode;
  error?: { message?: string };
  controlSx?: SelectProps<T>['sx'];
  groupBy?: (item: { label: string; value: string | number }) => string;
};

export function DropdownField<T = unknown>({
  name,
  data,
  variant,
  helperText,
  className,
  label,
  error,
  shrink,
  controlSx,
  groupBy: groupByKey,
  ...props
}: DropdownFieldProps<T>) {
  const sortedData = useMemo(
    () =>
      groupByKey
        ? Object.entries(groupBy(data, groupByKey)).flatMap(([__groupKey, items]) =>
            items.map((o) => ({ ...o, __groupKey }))
          )
        : data.map((o) => ({ ...o, __groupKey: undefined })),
    [data, groupByKey]
  );

  return (
    <FormControl
      fullWidth={props.fullWidth}
      variant={variant ?? 'filled'}
      className={clsx(className)}
      sx={controlSx}
    >
      {label && (
        <InputLabel shrink={shrink} id={`${name}-label`}>
          {label}
        </InputLabel>
      )}
      <Select<T> {...props} labelId={`${name}-label`} error={Boolean(error?.message)}>
        {sortedData.reduce<ReactElement[]>(
          (items, item, index) =>
            compact([
              ...items,
              sortedData[index - 1]?.__groupKey !== item.__groupKey && (
                <ListSubheader key={`${item.__groupKey}_${index}`}>{item.__groupKey}</ListSubheader>
              ),
              <MenuItem key={index} value={item.value}>
                <InheritListText primary={item.label} secondary={item.subLabel} />
              </MenuItem>,
            ]),
          []
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error?.message && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReconciliationDeletedEvent } from './deleted';
import ManagerBooksReconciliationDeletedEventSchema from './deleted.json';

export const isManagerBooksReconciliationDeletedEvent = guard<ManagerBooksReconciliationDeletedEvent>(ManagerBooksReconciliationDeletedEventSchema as any);
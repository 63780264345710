import { DateTime } from 'luxon';
import { Calendar as BigCalendar, Components, View, Formats } from 'react-big-calendar';
import LuxonLocalizer from './LuxonLocalizer';
import { CalendarEvent, EventInterval } from 'system';
import './calendar.scss';

type CalendarProps = {
  events: CalendarEvent[];
  selectable?: boolean;
  onSelectEvent?: (event: CalendarEvent) => void;
  onSelectSlot?: (selectedSlot: EventInterval) => void;
  showToolbar?: boolean;
  components?: Components<CalendarEvent, Record<string, unknown>>;
  defaultDate?: Date;
  defaultView?: View;
  view?: View[];
  formats?: Formats;
};

export default function Calendar({
  events,
  selectable = false,
  onSelectEvent,
  onSelectSlot,
  showToolbar = false,
  components,
  defaultDate,
  defaultView,
  formats,
}: CalendarProps) {
  const localizer = LuxonLocalizer(DateTime, { firstDayOfWeek: 0 });

  return (
    <BigCalendar
      defaultDate={defaultDate ? defaultDate : DateTime.local(2017, 1, 1).toJSDate()}
      defaultView={defaultView ? defaultView : 'week'}
      formats={formats ? formats : { dayFormat: 'ccc' }}
      scrollToTime={defaultDate ? defaultDate : new Date(2017, 1, 1, 7, 30)}
      events={events}
      style={{ width: '100%' }}
      localizer={localizer}
      selectable={selectable}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      toolbar={showToolbar}
      components={components ? components : undefined}
    />
  );
}

import { CollectionType, ContactRelationship, JournalEntryFieldsFragment } from 'api';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { PropsWithChildren, ReactNode } from 'react';
import { FieldPathValue, Path } from 'react-hook-form';
import { geography } from './util/location';

export type Children = { children: ReactNode };

export type Country = keyof typeof geography;
export const Country = Object.fromEntries(Object.keys(geography).map((k) => [k, k])) as {
  [C in keyof typeof geography]: C;
};
export const CountryDropdown = Object.entries(geography).map(([k, { name }]) => ({
  label: name,
  value: k,
}));
export const ProvincesByCountry = Object.fromEntries(
  Object.entries(geography).map(([k, v]) => [
    k,
    Object.fromEntries(Object.keys(v.provinces).map((p) => [p, p])),
  ])
) as { [C in keyof typeof geography]: { [P in keyof (typeof geography)[C]['provinces']]: P } };

export const TimezonesByCountry = Object.fromEntries(
  Object.entries(geography).map(([k, v]) => [
    k,
    Object.fromEntries(
      Object.entries(v.provinces).map(([p, { timezone = v.timezone }]) => [p, timezone])
    ),
  ])
) as { [C in keyof typeof geography]: { [P in keyof (typeof geography)[C]['provinces']]: string } };

export const ProvincesDropdownFor = (country?: string) =>
  country && Object.keys(Country).includes(country)
    ? Object.values(ProvincesByCountry[country as Country]).map((type) => ({
        label: type,
        value: type,
      }))
    : [];

export const TimezonesDropdownFor = (country?: string) =>
  country && Object.keys(Country).includes(country)
    ? uniqBy(
        Object.values(TimezonesByCountry[country as Country]).map((type) => ({
          label: type,
          value: type,
        })),
        'value'
      )
    : [];

export const Timezones = TimezonesByCountry.CA;
export const Provinces = ProvincesByCountry.CA;
export const States = ProvincesByCountry.US;
export const ProvincesDropdown = ProvincesDropdownFor('CA');

export enum Categories {
  APPLIANCE = 'APPLIANCE',
  CLEANING = 'CLEANING',
  DOOR = 'DOOR',
  ELECTRICAL = 'ELECTRICAL',
  EXTERIOR = 'EXTERIOR',
  GENERAL = 'GENERAL',
  HVAC = 'HVAC',
  KEYS = 'KEYS',
  PLUMBING = 'PLUMBING',
  SAFETY = 'SAFETY',
  SECURITY = 'SECURITY',
  WINDOW = 'WINDOW',
}

export enum RequestStatus {
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  STARTED = 'STARTED',
  APPROVAL = 'APPROVAL',
  PAUSED = 'PAUSED',
}
export type VisitStatus =
  | 'REQUESTED'
  | 'UNSCHEDULED'
  | 'SCHEDULED'
  | 'ASSIGNED'
  | 'STARTED'
  | 'COMPLETED'
  | 'CANCELLED';

export enum RequestDelay {
  OVERDUE = 'OVERDUE',
  LATE = 'LATE',
}

export enum RequestDisplayLabel {
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  APPROVAL = 'APPROVAL',
  STARTED = 'STARTED',
  OVERDUE = 'OVERDUE',
  DELAYED = 'DELAYED',
  MISSED = 'MISSED',
  DEFAULT = 'UNKNOWN',
  PAUSED = 'ON HOLD',
  'NEW VISIT REQUESTED' = 'NEW VISIT REQUESTED',
  'VISIT DECLINED' = 'VISIT DECLINED',
  'NEEDS ATTENTION' = 'NEEDS ATTENTION',
}

export enum NoteType {
  REQUEST = 'request',
  UNIT = 'unit',
  IMAGE = 'image',
  PROPERTY = 'property',
  JOURNAL_ENTRY = 'accounting/journal-entry',
  RESIDENCY = 'residency',
  LEASE = 'lease',
  OWNER = 'owner',
  CHARGES = 'charges',
  BUILDING = 'building',
  TENANT = 'tenant',
  CAMPAIGN_APPLICATION = 'application',
}

export type CalendarEvent = {
  start: Date;
  end: Date;
};

export type EventInterval = {
  start: string | Date;
  end: string | Date;
};

export type FullAddress = {
  street?: string;
  city?: string;
  province?: string;
  postal?: string;
  suite?: string;
  lat?: number;
  lng?: number;
  country?: string;
  timezone?: string;
};

export const OccupancyType = {
  RENTAL: 'rental',
  OWNER: 'owner',
  COMMON: 'common',
  COMMERCIAL: 'commercial',
  COMMERCIALOWNER: 'commercialOwner',
} as const;

export const RentalOccupancies: string[] = [OccupancyType.RENTAL, OccupancyType.COMMERCIAL];
export const ResidentialOccupancies: string[] = [OccupancyType.RENTAL, OccupancyType.OWNER];
export const CommercialOccupancies: string[] = [
  OccupancyType.COMMERCIAL,
  OccupancyType.COMMERCIALOWNER,
];
export const OwnerOccupancies: string[] = [OccupancyType.OWNER, OccupancyType.COMMERCIALOWNER];

export type OccupancyType = typeof OccupancyType;
export type Occupancy = OccupancyType[keyof OccupancyType];
export type OccupancyMap<T = unknown> = Record<Occupancy, T>;

export enum DocumentType {
  PROPERTY = 'property',
  OWNER = 'owner',
  UNIT = 'unit',
  LEASE = 'lease',
  RESIDENCY = 'residency',
  BUILDING = 'building',
  REQUEST = 'request',
  TENANT = 'tenant',
}

export type GeneratedDocument = {
  name: string;
  key: string;
  downloadFn: VoidFunction;
  createdZ: string;
  size: number;
  typename: string;
};

export type JournalEntryWithRelated = JournalEntryFieldsFragment & {
  journalEntries?: JournalEntryFieldsFragment[];
};

export const S3KeyFolder = {
  [DocumentType.OWNER]: 'owners',
  [DocumentType.PROPERTY]: 'properties',
  [DocumentType.UNIT]: 'units',
  [DocumentType.LEASE]: 'leases',
  [DocumentType.RESIDENCY]: 'leases',
  [DocumentType.BUILDING]: 'buildings',
  [DocumentType.REQUEST]: 'requests',
  [DocumentType.TENANT]: 'tenants',
} as const;

export type GetDeepProp<
  T extends Record<string | number | symbol, unknown>,
  K extends string,
> = K extends keyof T
  ? T[K]
  : {
      [P in keyof T]: GetDeepProp<Extract<T[P], Record<string | number | symbol, unknown>>, K>;
    }[keyof T];

type UnionToIntersectionHelper<U> = (U extends unknown ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

export type UnionToIntersection<U> = boolean extends U
  ? UnionToIntersectionHelper<Exclude<U, boolean>> & boolean
  : UnionToIntersectionHelper<U>;

export type Option<TId extends string = string> = {
  id: TId;
  text: string;
  subText?: string;
  disabled?: boolean;
  disabledMessage?: string;
};

export enum ReportDefaultDate {
  START = '1970-01-01',
}

export type PropsWithStyle = {
  style?: Record<string, unknown>;
} & PropsWithChildren<unknown>;

export type Except<Thing extends Record<string, unknown>, Keys extends keyof Thing> = {
  [Prop in keyof Omit<Thing, Keys>]: Thing[Prop];
};

export type Indexed<T = Record<string, unknown>> = T & { index: number };

export enum AnalyticsEvents {
  ManagerCreateRequest = 'createManagerRequest',
  PageView = 'pageview',
  ShareCampaignModalOpened = 'shareCampaignModalOpened',
  TestEvent = 'testEvent',
  CreateCharge = 'createCharge',
  PostBatch = 'postBatch',
  CancelBatch = 'cancelBatch',
  SubmitLeaseForm = 'submitLeaseForm',
}

export type TestedOption<TRowModel extends Record<string, unknown>> = Option & {
  test: (row: TRowModel) => boolean;
};

export type Awaitable<T> = T | Promise<T>;

export type NonNullableField<
  Map extends Record<string, unknown>,
  Breadcrumbs extends Path<Map>,
> = NonNullable<FieldPathValue<Map, Breadcrumbs>>;

export type OccupancyDisplayLabel = keyof typeof OccupancyDisplayLabel;
export const OccupancyDisplayLabel = Object.freeze({
  [OccupancyType.RENTAL]: 'Rental',
  [OccupancyType.COMMERCIAL]: 'Commercial rental',
  [OccupancyType.COMMERCIALOWNER]: 'Commercial owner',
  [OccupancyType.OWNER]: 'Owner Occupied',
  [OccupancyType.COMMON]: 'Common',
});

export type CacheFieldsUpdater<TRecord extends Record<string, unknown>> = {
  [Key in keyof TRecord]: () => TRecord[Key];
};

export type Merge<T> = { [K in keyof T]: T[K] };

export type DateLike = string | Date | DateTime | number;

export type NullableAllDefined<T> =
  T extends Record<string, unknown>
    ? {
        [K in keyof Required<T>]: T[K] extends undefined ? null : NullableAllDefined<T[K]>;
      }
    : T;

export type EmergencyContactFields = {
  name: string;
  relationship?: ContactRelationship;
  phone: string;
};

export const OccupancyCollectionTypeMap: Record<string, CollectionType> = {
  [OccupancyType.RENTAL]: CollectionType.Rent,
  [OccupancyType.COMMERCIAL]: CollectionType.CommercialRent,
  [OccupancyType.COMMERCIALOWNER]: CollectionType.CondoFee,
  [OccupancyType.OWNER]: CollectionType.CondoFee,
};

export enum Ownership {
  SOLD = 'sold',
  OWNED = 'owned',
}

export const InspectionCodes: Record<string, string> = {
  GoodWorking: 'Good / Working',
  Scratched: 'Scratched',
  DamagedBroken: 'Damaged / Broken',
  Missing: 'Missing',
  Dirty: 'Dirty',
  Stained: 'Stained',
  NoneInSuite: 'None in Suite',
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReconciliationStartedEvent } from './started';
import ManagerBooksReconciliationStartedEventSchema from './started.json';

export const isManagerBooksReconciliationStartedEvent = guard<ManagerBooksReconciliationStartedEvent>(ManagerBooksReconciliationStartedEventSchema as any);
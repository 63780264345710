/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerPortfolioUpdatedEvent } from './portfolioUpdated';
import ManagerOwnerPortfolioUpdatedEventSchema from './portfolioUpdated.json';

export const isManagerOwnerPortfolioUpdatedEvent = guard<ManagerOwnerPortfolioUpdatedEvent>(ManagerOwnerPortfolioUpdatedEventSchema as any);
import { useRefundClearableMutation } from 'api';
import { useAuth } from 'context';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { parseJSON } from 'system';

export const useRefundClearable = () => {
  const [refundClearableMutation, { error: refundError }] = useRefundClearableMutation();
  useErrorNotifications(refundError);
  const { accountId } = useAuth();
  const refundClearable = async (id: string) => {
    await refundClearableMutation({
      variables: { id },
      update(cache, { data }) {
        if (data?.refundClearable.success && data.refundClearable.clearable) {
          const books = cache.identify({ accountId, __typename: 'Books' });
          const clearable = cache.identify({
            id: data.refundClearable.clearable.id,
            __typename: 'Clearable',
          });
          cache.modify({
            id: books,
            fields: {
              listClearables({ items = [], ...rest } = {}, { storeFieldName }) {
                const args = parseJSON(storeFieldName.replace(/listClearables:([^)]+)/, '$1'));
                return args?.filter?.balanceType === data.refundClearable.clearable?.balanceType
                  ? { items: items.concat([{ __ref: clearable }]), ...rest }
                  : {
                      ...rest,
                      items: items.filter(({ __ref }: { __ref: string }) => __ref !== clearable),
                    };
              },
            },
          });
        }
      },
    });
  };

  return { refundClearable };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksRunReportPresetAction } from './runReport';
import ManagerBooksRunReportPresetActionSchema from './runReport.json';

export const isManagerBooksRunReportPresetAction = guard<ManagerBooksRunReportPresetAction>(ManagerBooksRunReportPresetActionSchema as any);
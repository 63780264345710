/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerNotifyPayeePaymentReturnedEvent } from './payeePaymentReturned';
import ManagerNotifyPayeePaymentReturnedEventSchema from './payeePaymentReturned.json';

export const isManagerNotifyPayeePaymentReturnedEvent = guard<ManagerNotifyPayeePaymentReturnedEvent>(ManagerNotifyPayeePaymentReturnedEventSchema as any);
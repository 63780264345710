import { TableHead } from '@mui/material';
import { Column, GlobalFilter as GlobalFilterType } from '../types';
import GlobalFilter from './GlobalFilter';
import { FiltersContainer, TableRow } from './styles';
import TableHeadCell from './TableHeadCell';

type SimpleTableHeadProps = {
  columns: Column[];
  globalFilter?: GlobalFilterType;
  globalFilterExcluded: string[];
  onGlobalFilterChange: (value: string, toExclude: boolean) => void;
};

export default function SimpleTableHead({
  columns,
  globalFilter,
  globalFilterExcluded,
  onGlobalFilterChange,
}: SimpleTableHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {columns
          .filter(({ hidden }) => !hidden)
          .map((column, i) => (
            <TableHeadCell
              key={column.field}
              flex={column.flex ?? 1}
              showBorder={i === columns.length - 1}
            >
              {column.headerName}
            </TableHeadCell>
          ))}
        {globalFilter && (
          <FiltersContainer>
            <GlobalFilter
              globalFilter={globalFilter}
              globalFilterExcluded={globalFilterExcluded}
              onFilterSelected={onGlobalFilterChange}
            />
          </FiltersContainer>
        )}
      </TableRow>
    </TableHead>
  );
}

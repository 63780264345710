/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAvailabilityChangedEvent } from './availabilityChanged';
import ManagerAvailabilityChangedEventSchema from './availabilityChanged.json';

export const isManagerAvailabilityChangedEvent = guard<ManagerAvailabilityChangedEvent>(ManagerAvailabilityChangedEventSchema as any);
import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerAccountTierChangedEvent } = eventFactory(
  'manager-account-tier-changed-event',
  z.object({
    accountId: z.string(),
    newTier: z.number(),
    oldTier: maybe(z.number()),
  })
);

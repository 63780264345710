import { Badge as B, badgeClasses, Box, styled, Typography } from '@mui/material';
import plur from 'plur';

export type BadgeNumberCellProps = {
  badgeNumber: number;
  text?: string;
  showZero?: boolean;
};
const Badge = styled(B)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primaryBackground.main,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1em',
  marginLeft: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9em',
  },
}));

export default function BadgeNumberCell({
  badgeNumber,
  text = '',
  showZero = false,
}: BadgeNumberCellProps) {
  return (
    <Box display="flex" alignItems="center" marginLeft={1.6}>
      <Badge badgeContent={badgeNumber} showZero={showZero} />
      {text && (showZero || badgeNumber > 0) && <Text>{plur(text, badgeNumber)}</Text>}
    </Box>
  );
}

import { useMemo } from 'react';

type UseTransformCollectionProps<TItem> = {
  skip?: boolean;
  collection: TItem[];
  transform?: (opts: TItem[]) => TItem[];
  onTransformed?: (opts: TItem[]) => void;
};

export const useTransformCollection = <TItem>({
  collection,
  skip,
  transform,
}: UseTransformCollectionProps<TItem>) => {
  const transformedCollection = useMemo(
    () => (skip ? collection : (transform?.(collection) ?? collection)),
    [collection, skip, transform]
  );

  return { transformedCollection };
};

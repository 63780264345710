import { Reorder } from '@mui/icons-material';
import { Grid, styled } from '@mui/material';
import { memo } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { skipProps } from 'system';

const Icon = styled(Reorder)(({ theme }) => ({
  color: theme.palette.divider,
  height: 30,
  width: 30,
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(1.3),
}));

const DraggableItem = styled(
  Grid,
  skipProps('isDragging')
)<{ isDragging?: boolean }>(({ theme, isDragging }) => ({
  background: isDragging ? theme.palette.inputBackground : 'transparent',
  padding: isDragging ? theme.spacing(2, 0) : theme.spacing(2, 0, 0, 0),
}));

type DraggableListItemProps = {
  children: React.ReactNode;
  index: number;
  disabled?: boolean;
  handle?: 'right' | 'left';
};

type DraggingItemStyle = Omit<DraggingStyle, 'left'> & {
  left: number | undefined;
};

const DraggableListItem = ({
  children,
  index,
  disabled,
  handle = 'left',
}: DraggableListItemProps) => {
  return (
    <Draggable draggableId={index.toString()} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => {
        if (snapshot.isDragging && provided.draggableProps.style) {
          (provided.draggableProps.style as DraggingItemStyle).left = undefined;
        }
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <DraggableItem container alignItems="center" isDragging={snapshot.isDragging}>
              {handle === 'left' && (
                <Grid item xs="auto" {...provided.dragHandleProps}>
                  <Icon />
                </Grid>
              )}

              <Grid item xs>
                {children}
              </Grid>

              {handle === 'right' && (
                <Grid item xs="auto" {...provided.dragHandleProps}>
                  <Icon />
                </Grid>
              )}
            </DraggableItem>
          </div>
        );
      }}
    </Draggable>
  );
};

type DraggableListProps = {
  name: string;
  children: React.ReactNode[];
  onDragEnd: OnDragEndResponder;
  disabled?: boolean;
  handle?: 'right' | 'left';
};

const DraggableList = memo(
  ({ name, children, onDragEnd, disabled, handle = 'left' }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {children.map((child, index) => (
                <DraggableListItem index={index} key={index} handle={handle} disabled={disabled}>
                  {child}
                </DraggableListItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksReconciliationDboPreparedEvent } from './dboPrepared';
import ManagerBooksReconciliationDboPreparedEventSchema from './dboPrepared.json';

export const isManagerBooksReconciliationDboPreparedEvent = guard<ManagerBooksReconciliationDboPreparedEvent>(ManagerBooksReconciliationDboPreparedEventSchema as any);
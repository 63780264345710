import {
  useCreateAccountFeeMutation,
  useDeleteAccountFeeMutation,
  useGetAccountFeesQuery,
  useUpdateAccountFeeMutation,
} from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { find, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ensureArray } from 'system';
import * as Yup from 'yup';

export const feeFormSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required('Must specify a name'),
});

export type FeeFormValues = {
  id?: string;
  name: string;
};

const defaultFees: [] = [];

export const useFees = ({ omitIds }: { omitIds?: string[] } = {}) => {
  const { data, loading, error } = useGetAccountFeesQuery();
  const fees = data?.account?.fees ?? defaultFees;

  const feeOptions = useMemo(
    () =>
      ensureArray(fees)
        .filter(({ deletedZ }) => !deletedZ)
        .filter(({ id }) => isEmpty(omitIds) || !omitIds?.includes(id))
        .map(({ id, name }) => ({ id, text: name })),
    [fees, omitIds]
  );

  const [createFeeMutation, { error: createError }] = useCreateAccountFeeMutation();
  const [updateFeeMutation, { error: updateError }] = useUpdateAccountFeeMutation();
  const [deleteFeeMutation, { error: deleteError }] = useDeleteAccountFeeMutation();
  useAllErrors(error, createError, updateError, deleteError);

  const findFeeName = useCallback((id: string) => find(ensureArray(fees), { id })?.name, [fees]);
  const deleteFee = useCallback((id: string) => deleteFeeMutation({ variables: { id } }), []);
  const updateFee = useCallback(
    ({ id, name }: Required<FeeFormValues>) =>
      updateFeeMutation({
        variables: {
          input: {
            id,
            name,
          },
        },
      }),
    []
  );

  const addFee = useCallback(
    async ({ name }: FeeFormValues) =>
      createFeeMutation({
        variables: {
          input: { name },
        },
      }),
    []
  );

  return {
    addFee,
    updateFee,
    deleteFee,
    feeOptions,
    fees,
    findFeeName,
    loading,
  };
};

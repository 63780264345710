import { MoreVert } from '@mui/icons-material';
import { Card, Grid, IconButton, Skeleton, styled } from '@mui/material';
import { Note } from 'api';
import { AttributeText, BodyText } from 'components';
import { useAnchorEl } from 'hooks/useAnchorEl';
import { DateTime } from 'luxon';
import ReactMarkdown from 'react-markdown';
import NoteCardMenu from './NoteCardMenu';

export const RM = styled(ReactMarkdown)(() => ({
  whiteSpace: 'pre-wrap',
}));

export default function NoteCard({
  loading,
  note,
  handleDelete,
  deleteLoading,
}: {
  loading: boolean;
  note: Note;
  handleDelete: (note: Note) => void;
  deleteLoading: boolean;
}) {
  const created = DateTime.fromISO(note.createdZ ?? '');
  const { anchorEl, clearAnchorEl, handleAnchorElClick } = useAnchorEl();
  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm container>
          <Grid container sx={{ '& p': { mt: 0 } }} direction="column" alignItems="flex-start">
            <Grid item>{loading ? <Skeleton /> : <RM>{note.text ?? ''}</RM>}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} container alignItems="flex-end" direction="column" spacing={1}>
          {(!note.source || note.source === 'manager') && (
            <IconButton
              aria-label="open-note-action-menu"
              onClick={handleAnchorElClick}
              size="large"
            >
              <MoreVert />
            </IconButton>
          )}
          <NoteCardMenu
            open={Boolean(anchorEl)}
            onClose={clearAnchorEl}
            anchorEl={anchorEl}
            note={note}
            onDelete={handleDelete}
            loading={deleteLoading}
          />
          <BodyText item loading={loading} variant="caption">
            {created.toLocaleString(DateTime.DATE_MED)} at{' '}
            {created.toLocaleString(DateTime.TIME_SIMPLE)}
          </BodyText>
          <AttributeText item loading={loading} variant="caption">
            {note.createdName}
            {note.source && note.source !== 'manager' && `, for the ${note.target}`}
          </AttributeText>
        </Grid>
      </Grid>
    </Card>
  );
}

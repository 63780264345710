/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerRequestConfirmedUpdatedEvent } from './confirmedUpdated';
import ManagerRequestConfirmedUpdatedEventSchema from './confirmedUpdated.json';

export const isManagerRequestConfirmedUpdatedEvent = guard<ManagerRequestConfirmedUpdatedEvent>(ManagerRequestConfirmedUpdatedEventSchema as any);
import { styled } from '@mui/material';

export const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const GridContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

export const ListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

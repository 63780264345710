/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayPayeeBatchInvitedEvent } from './payeeBatchInvited';
import ManagerAutopayPayeeBatchInvitedEventSchema from './payeeBatchInvited.json';

export const isManagerAutopayPayeeBatchInvitedEvent = guard<ManagerAutopayPayeeBatchInvitedEvent>(ManagerAutopayPayeeBatchInvitedEventSchema as any);
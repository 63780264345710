import { Button, Stack, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Address } from 'api';
import { DropdownField, GooglePlacesAutocomplete } from 'components';
import { GoogleMap } from 'components/GoogleMap';
import { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import {
  Country,
  CountryDropdown,
  getTimezone,
  ProvincesDropdownFor,
  TimezonesDropdownFor,
  validCountry,
} from 'system';
import { placeToAddress, postalCodeLabel, provinceLabel } from 'system/util';

type FullAddress = Omit<Address, '__typename'> & {
  country?: string;
  timezone?: string;
};

export type AddressFieldsProps = {
  value?: FullAddress;
  errors?: FieldErrors<FullAddress>;
  onAddressChanged: (newAddr: FullAddress) => void;
  minimal?: boolean;
  showMap?: boolean;
  excludes?: (keyof FullAddress)[];
};

export const AddressFields = ({
  value,
  errors,
  minimal,
  showMap,
  onAddressChanged,
  excludes = ['suite'],
}: AddressFieldsProps) => {
  const country = value && validCountry(value?.country) ? value.country : Country.CA;
  const [showCountryPicker, setShowCountryPicker] = useState(country !== Country.CA);

  const handleAddressChanged = (newAddr: Partial<FullAddress>) => {
    const updatedAddr = { ...value, ...newAddr };
    const newCountry = validCountry(updatedAddr.country) ? updatedAddr.country : Country.CA;
    const timezone = getTimezone(newCountry, updatedAddr.province);

    onAddressChanged({
      ...updatedAddr,
      ...(timezone ? { timezone } : {}),
      ...(newCountry && { country: newCountry }),
    });
  };

  return (
    <Grid2 container direction="row" spacing={1}>
      <Grid2 xs={12} sm>
        <Stack spacing={2}>
          {!excludes.includes('country') && showCountryPicker && (
            <DropdownField
              label="Country"
              fullWidth
              data={CountryDropdown}
              value={country}
              onChange={(event) => onAddressChanged({ country: String(event.target.value) })}
              error={errors?.country}
            />
          )}

          {!excludes.includes('street') && (
            <GooglePlacesAutocomplete
              disableClearable
              country={country}
              initialValue={value?.street}
              onChange={(place) => handleAddressChanged(placeToAddress(place, country))}
              renderInput={(params) => {
                const paramsWithoutAdornment = {
                  ...params,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: null,
                  },
                };

                return (
                  <TextField
                    {...paramsWithoutAdornment}
                    variant={'filled'}
                    value={value?.street ?? ''}
                    onChange={(event) =>
                      handleAddressChanged({ street: String(event.target.value) })
                    }
                    label="Street"
                    error={Boolean(errors?.street)}
                    helperText={errors?.street?.message}
                  />
                );
              }}
            />
          )}

          {!excludes.includes('suite') && (
            <TextField
              name="suite"
              label="Suite/Unit"
              variant={'filled'}
              value={value?.suite ?? ''}
              onChange={(event) => handleAddressChanged({ suite: String(event.target.value) })}
              error={Boolean(errors?.suite)}
              helperText={errors?.suite?.message}
            />
          )}

          <TextField
            name="city"
            label="City"
            variant={'filled'}
            value={value?.city ?? ''}
            onChange={(event) => handleAddressChanged({ city: String(event.target.value) })}
            error={Boolean(errors?.city)}
            helperText={
              errors?.city?.message ??
              (!excludes.includes('country') && !showCountryPicker && (
                <Button size="small" variant="text" onClick={() => setShowCountryPicker(true)}>
                  Not in Canada?
                </Button>
              ))
            }
          />

          <DropdownField
            name="province"
            label={provinceLabel(country)}
            fullWidth
            data={ProvincesDropdownFor(country)}
            value={value?.province ?? ''}
            onChange={(event) => handleAddressChanged({ province: String(event.target.value) })}
            error={errors?.province}
          />

          {!excludes.includes('postal') && (
            <TextField
              label={postalCodeLabel(country)}
              variant={'filled'}
              value={value?.postal ?? ''}
              onChange={(event) => handleAddressChanged({ postal: String(event.target.value) })}
              error={Boolean(errors?.postal)}
              helperText={errors?.postal?.message}
            />
          )}

          {!excludes.includes('timezone') && (
            <DropdownField
              name="timezone"
              label="Timezone"
              fullWidth
              data={TimezonesDropdownFor(country)}
              value={value?.timezone ?? ''}
              onChange={(event) => handleAddressChanged({ timezone: String(event.target.value) })}
              error={errors?.timezone}
            />
          )}
        </Stack>
      </Grid2>

      {!minimal && showMap && (
        <Grid2 xs={12} sm>
          <GoogleMap
            height="100%"
            draggableMarker
            sx={{ mt: 2, pb: 2 }}
            lat={value?.lat}
            lng={value?.lng}
            onChange={(pos) => handleAddressChanged({ lat: pos.lat(), lng: pos.lng() })}
          />
        </Grid2>
      )}
    </Grid2>
  );
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitChecklistItemUpdatedEvent } from './checklistItemUpdated';
import OperatorVisitChecklistItemUpdatedEventSchema from './checklistItemUpdated.json';

export const isOperatorVisitChecklistItemUpdatedEvent = guard<OperatorVisitChecklistItemUpdatedEvent>(OperatorVisitChecklistItemUpdatedEventSchema as any);
/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseScheduledRateEvent } from './rate';
import ManagerLeaseScheduledRateEventSchema from './rate.json';

export const isManagerLeaseScheduledRateEvent = guard<ManagerLeaseScheduledRateEvent>(ManagerLeaseScheduledRateEventSchema as any);
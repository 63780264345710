import { guard } from '@propra-system/registry';
import { ClearablesFilterInput, PayeeType, SubAccountInput } from 'api';

export const isPayee = guard<{ payee: PayeeType; payeeId: string }>({
  type: 'object',
  required: ['payee', 'payeeId'],
  properties: {
    payee: {
      type: 'string',
      enum: Object.values(PayeeType),
    },
    payeeId: {
      type: 'string',
    },
  },
});

export const isPropertyUnit = guard<{ unitId: string; propertyId: string }>({
  type: 'object',
  required: ['propertyId'],
  properties: {
    unitId: { type: 'string' },
    propertyId: { type: 'string' },
  },
});

export const isOwnerPropertyBindings = guard<{
  ownerProperty: { ownerId: string; propertyId: string };
}>({
  type: 'object',
  required: ['ownerProperty'],
  properties: {
    ownerProperty: {
      type: 'object',
      required: ['ownerId', 'propertyId'],
      properties: {
        ownerId: { type: 'string' },
        propertyId: { type: 'string' },
      },
    },
  },
});

export const isOwnerBindings = guard<{ ownerId: string; propertyId?: string }>({
  type: 'object',
  required: ['ownerId'],
  properties: {
    ownerId: { type: 'string' },
    propertyId: { type: 'string', nullable: true },
  },
});
export const isClearablesFilter = guard<{ filter?: ClearablesFilterInput }>({
  type: 'object',
  properties: {
    filter: {
      type: 'object',
      nullable: true,
      properties: {
        balanceType: { type: 'string', nullable: true },
        cleared: { type: 'boolean', nullable: true },
        range: {
          type: 'object',
          nullable: true,
          properties: {
            from: { type: 'string', nullable: true },
            to: { type: 'string', nullable: true },
          },
        },
      },
    },
  },
});

export const isSubAccountInput = guard<{ input: SubAccountInput }>({
  type: 'object',
  required: ['input'],
  properties: {
    input: {
      type: 'object',
      required: ['range'],
      properties: {
        ownerId: { type: 'string', nullable: true },
        ownerIds: { type: 'array', nullable: true, items: { type: 'string' } },
        propertyId: { type: 'string', nullable: true },
        propertyIds: { type: 'array', nullable: true, items: { type: 'string' } },
        range: {
          type: 'object',
          properties: {
            from: { type: 'string', nullable: true },
            to: { type: 'string', nullable: true },
          },
        },
      },
    },
  },
});

export const isSubAccountItemArray = guard<{ balance: number; item?: string | null }[]>({
  type: 'array',
  items: {
    type: 'object',
    required: ['balance'],
    properties: {
      balance: { type: 'number' },
      item: { type: 'string', nullable: true },
    },
  },
});

export const maybeHasIdAmount = guard<null | { id: string; amount: number }>({
  type: 'object',
  nullable: true,
  required: ['id', 'amount'],
  properties: {
    id: { type: 'string' },
    amount: { type: 'number' },
  },
});

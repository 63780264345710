/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksPreviewChequesAction } from './previewCheques';
import ManagerBooksPreviewChequesActionSchema from './previewCheques.json';

export const isManagerBooksPreviewChequesAction = guard<ManagerBooksPreviewChequesAction>(ManagerBooksPreviewChequesActionSchema as any);
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export default function World({ ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 124 124" width="124" height="124" {...props}>
      <g clipPath="url(#clip0_40245_170842)">
        <path
          d="M86.1598 103.624C109.362 80.4221 116.93 54.6386 93.7278 31.4367C70.526 8.23485 42.9955 22.7883 19.7936 45.9902L86.1598 103.624Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M22.1224 46.5725C10.8436 57.8513 7.36094 69.0698 30.5628 92.2717C53.7647 115.474 63.8201 110.828 75.0989 99.549L22.1224 46.5725Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M62 10.3334C33.48 10.3334 10.3334 33.48 10.3334 62C10.3334 90.52 33.48 113.667 62 113.667C90.52 113.667 113.667 90.52 113.667 62C113.667 33.48 90.52 10.3334 62 10.3334ZM56.8334 102.972C36.425 100.44 20.6667 83.08 20.6667 62C20.6667 58.7967 21.08 55.7484 21.7517 52.7517L46.5 77.5V82.6667C46.5 88.35 51.15 93 56.8334 93V102.972ZM92.4834 89.8484C91.14 85.6634 87.3167 82.6667 82.6667 82.6667H77.5V67.1667C77.5 64.325 75.175 62 72.3334 62H41.3334V51.6667H51.6667C54.5084 51.6667 56.8334 49.3417 56.8334 46.5V36.1667H67.1667C72.85 36.1667 77.5 31.5167 77.5 25.8334V23.715C92.6384 29.8634 103.333 44.6917 103.333 62C103.333 72.7467 99.2 82.5117 92.4834 89.8484Z"
          fill={theme.palette.common.black}
        />
      </g>
      <defs>
        <clipPath id="clip0_40245_170842">
          <rect width="124" height="124" fill={theme.palette.common.white} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

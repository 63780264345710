import { z } from 'zod';
export type AnyShape = z.ZodTypeAny;
export type ZodInfer<T extends AnyShape> = z.infer<T>;

export type HandlerFunction<T> = (data: T, ...rest: unknown[]) => Promise<unknown>;
export type ShapeHandlerTuple<TShape extends z.ZodTypeAny, TArgs = z.infer<TShape>> = [
  TShape,
  HandlerFunction<TArgs>,
];

export * from './eventFactory';

/**
 * Creates a handler function that will execute the all handlers where the input matches the shape.
 * @param schemaHandlers A list of tuples where the first element is a Zod schema and the second element is a handler function.
 * @returns A function that will execute the handlers.
 */
export const executeHandlers =
  <TShape extends z.ZodTypeAny>(...schemaHandlers: ShapeHandlerTuple<TShape>[]) =>
  (...args: unknown[]) => {
    const errors: z.ZodError[] = [];
    const providers: (() => unknown)[] = schemaHandlers.flatMap(([schema, handler]) => {
      const [input, ...rest] = args;
      const result = schema.safeParse(input);
      if (!result.success) {
        errors.push(result.error);
      }
      return result.success ? [() => handler(result.data as TShape, ...rest)] : [];
    });

    return Promise.all(providers.map((provider) => provider()));
  };

/**
 * Basic shape handler identity function used to allow type inference for a better DX.
 * @param shape The Zod shape to match.
 * @param handler The handler function to execute.
 * @returns A tuple of the shape and handler.
 */
export const shapeHandler = <TShape extends z.ZodTypeAny>(
  shape: TShape,
  handler: HandlerFunction<ZodInfer<TShape>>
) => {
  if (!shape.description) {
    console.warn('No description found for shape');
  }
  return [shape, handler] as ShapeHandlerTuple<z.ZodTypeAny>;
};

import { OwnerListFieldsFragment, useGetOwnersQuery } from 'api';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { useTeams } from 'hooks/useTeams';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { emptyArray } from 'system';

const orderEnabledFirst = <T extends { enabled?: boolean; name: string }>(owners: T[]) => [
  ...orderBy(
    owners.filter((owner) => owner.enabled),
    'name'
  ),
  ...orderBy(
    owners.filter((owner) => !owner.enabled),
    'name'
  ),
];

export const useOwners = () => {
  const { items, loading, error } = usePaginateAllQuery(useGetOwnersQuery, {
    getNextToken: (d) => d?.account?.listOwners?.nextToken,
    getItems: (d) => d.account?.listOwners?.items,
    notifyOnNetworkStatusChange: true,
    variables: { filter: { includeDisabled: true } },
  });
  const [owners, setOwners] = useState<OwnerListFieldsFragment[]>(emptyArray);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const { isOwnerVisible } = useTeams();

  useEffect(() => {
    const filteredOwners = items.filter(isOwnerVisible);
    setOwners(orderEnabledFirst(filteredOwners));
    setShowEmptyState((filteredOwners.length ?? 0) == 0);
  }, [isOwnerVisible, items]);

  return { error, loading, owners, showEmptyState, hasOwners: owners.length > 0 };
};

import { makeVar } from '@apollo/client';
import { head } from 'lodash';
import { ReactNode, createContext, useContext, useState } from 'react';
import { emptyArray, noOp } from 'system';

type BalanceUpdate = {
  id: string;
  glId: string;
  storeFieldName: string;
  adjustmentAmount: number;
};

const queuedIds = makeVar<Set<string>>(new Set());
const dequeuedIds = makeVar<Set<string>>(new Set());

const balanceKey = (update: { glId: string; storeFieldName: string }) =>
  [update.glId, update.storeFieldName].join('#');

const BalanceCacheContext = createContext<ReturnType<typeof useBalanceCacheContext>>({
  enqueueBalanceUpdate: noOp,
  onBalanceUpdated: noOp,
  dequeueBalanceUpdate: () => undefined,
});

const useBalanceCacheContext = () => {
  const [balanceUpdateQueue, setBalanceUpdateQueue] = useState<BalanceUpdate[]>(emptyArray);
  const [balanceUpdateLock, setBalanceUpdateLock] = useState<Set<string>>(new Set());
  const notLocked = (update: BalanceUpdate) => !balanceUpdateLock.has(balanceKey(update));

  const enqueueBalanceUpdate = (balanceUpdate: BalanceUpdate) => {
    const queued = queuedIds();
    if (!queued.has(balanceUpdate.id)) {
      queuedIds(new Set([...queued, balanceUpdate.id]));
      setBalanceUpdateQueue((prev) => [...prev, balanceUpdate]);
    } else {
      console.warn(`Balance update ${balanceUpdate.id} has already been handled.`);
    }
  };

  const dequeueBalanceUpdate = () => {
    const next = head(balanceUpdateQueue.filter(notLocked));
    const dequeued = dequeuedIds();
    if (next && !dequeued.has(next.id)) {
      dequeuedIds(new Set([...dequeued, next.id]));
      setBalanceUpdateLock((prev) => new Set([...prev, next.storeFieldName]));
      setBalanceUpdateQueue((prev) => prev.filter((update) => update !== next));
      return next;
    }
  };

  const onBalanceUpdated = (update: { glId: string; storeFieldName: string }) => {
    setBalanceUpdateLock(
      (prev) => new Set([...prev].filter((locked) => locked !== balanceKey(update)))
    );
  };

  return {
    onBalanceUpdated,
    enqueueBalanceUpdate,
    dequeueBalanceUpdate,
  };
};

export const useBalanceCache = () => useContext(BalanceCacheContext);

export default function BalanceCacheProvider({ children }: { children: ReactNode }) {
  return (
    <BalanceCacheContext.Provider value={useBalanceCacheContext()}>
      {children}
    </BalanceCacheContext.Provider>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertyStatsInvalidatedEvent } from './statsInvalidated';
import ManagerPropertyStatsInvalidatedEventSchema from './statsInvalidated.json';

export const isManagerPropertyStatsInvalidatedEvent = guard<ManagerPropertyStatsInvalidatedEvent>(ManagerPropertyStatsInvalidatedEventSchema as any);
import { MouseEvent, useState } from 'react';

export const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const clearAnchorEl = () => setAnchorEl(null);
  const clearAnchorElThen = (fn: () => Promise<void> | unknown) => async () => {
    setAnchorEl(null);
    await fn();
  };

  const handleAnchorElClick = ({
    currentTarget,
  }: Pick<MouseEvent<HTMLElement>, 'currentTarget'>) => {
    setAnchorEl(currentTarget);
  };

  return { anchorEl, setAnchorEl, clearAnchorEl, clearAnchorElThen, handleAnchorElClick };
};

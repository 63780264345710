import { Add, DeleteForever } from '@mui/icons-material';
import { Box, Button, Collapse, Stack } from '@mui/material';
import { DataGridPro, GridColDef, GridRowModel, gridClasses } from '@mui/x-data-grid-pro';
import { AccountType, CreateChargeInput } from 'api';
import {
  ConfirmModal,
  CurrencyFieldController,
  ExpandableMenu,
  Form,
  FullSizePaper,
  GridSearchToolbar,
  Modal,
  TextFieldController,
} from 'components';
import { useModalControl } from 'hooks/useModalControl';
import { usePageSize } from 'hooks/usePageSize';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatCurrency } from 'system';
import { number, object, string } from 'yup';
import { GLAccountAutocompleteController } from '../../../components/Accounting/GLAccountAutocompleteController';
import { FinancialSettingsFormFields, useGlAccounts } from '../hooks';
import { useCharge } from '../hooks/useCharge';
import { ChargeMapping, useChargeMapping } from '../hooks/useChargeMapping';
import { formatAccountName } from '../utils';
import FinancialSettingsToolBar from './FinancialSettingsToolBar';

const LocalForm = ({
  onHideModal,
  showModal,
  loading,
  onSubmit,
}: {
  onHideModal: VoidFunction;
  showModal: boolean;
  loading: boolean;
  onSubmit: (values: CreateChargeInput) => void;
}) => {
  const { handleSubmit, reset } = useFormContext<CreateChargeInput>();
  return (
    <Modal
      onClose={onHideModal}
      open={showModal}
      title="Add Charge"
      actions={{
        cancel: {
          label: 'Cancel',
          onClick: onHideModal,
          disabled: loading,
        },
        confirm: {
          label: 'Save',
          onClick: () => {
            handleSubmit(onSubmit)();
            onHideModal();
            reset();
          },
          loading,
        },
      }}
    >
      <Stack spacing={2}>
        <TextFieldController<CreateChargeInput> label="Charge name" name="name" />
        <CurrencyFieldController label="Default amount" name="default" />
        <CurrencyFieldController label="Requires approval above" name="limit" />
        <GLAccountAutocompleteController<CreateChargeInput>
          variant="outlined"
          label="Account"
          name="glId"
          manualSort
          filter={({ accountType, category }) =>
            accountType === AccountType.Revenue ||
            (accountType === AccountType.Liability && category !== 'accountsPayable')
          }
          sort={(a, b) =>
            a.accountType === b.accountType
              ? formatAccountName(a).localeCompare(formatAccountName(b))
              : b.accountType.localeCompare(a.accountType)
          }
        />
      </Stack>
    </Modal>
  );
};

type ChargeRowModel = GridRowModel<ChargeMapping>;

export default function ChargesForm({ isCollapsible = true }: { isCollapsible?: boolean }) {
  const { glAccounts } = useGlAccounts();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { chargeMapping } = useChargeMapping();
  const [handleShowModal, handleHideModal, showModal] = useModalControl<CreateChargeInput>();
  const [showConfirmModal, hideConfirmModal, confirmModalOpen, chargeIdToDelete] =
    useModalControl<string>();
  const { createCharge, loading, deleteCharge } = useCharge();
  const { setValue } = useFormContext<FinancialSettingsFormFields>();

  useEffect(() => {
    chargeMapping.forEach(({ chargeId, glId }) => {
      setValue(`chargeMapping.${chargeId}`, glId);
    });
  }, [chargeMapping, setValue]);

  const onSubmit = async (args: CreateChargeInput) => {
    await createCharge(args);
    handleHideModal();
  };

  const columns: Array<GridColDef<ChargeRowModel>> = [
    {
      field: 'glId',
      headerName: 'Account',
      flex: 1.5,
      renderCell({ row }) {
        return (
          <GLAccountAutocompleteController
            variant="outlined"
            name={`chargeMapping.${row.id}`}
            manualSort
            size="small"
            filter={({ accountType, category }) =>
              accountType === AccountType.Revenue ||
              (accountType === AccountType.Liability && category !== 'accountsPayable')
            }
            sort={(a, b) =>
              a.accountType === b.accountType
                ? formatAccountName(a).localeCompare(formatAccountName(b))
                : b.accountType.localeCompare(a.accountType)
            }
            sx={{ my: 2 }}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Charge Name',
      flex: 1.5,
    },
    {
      field: 'amount',
      headerName: 'Default',
      flex: 0.5,
      renderCell({ value }) {
        return formatCurrency(value);
      },
    },
    {
      field: 'limit',
      headerName: 'Approval Limit',
      flex: 0.5,
      renderCell({ value }) {
        return formatCurrency(value);
      },
    },
    {
      field: 'menu',
      headerName: '',
      flex: 0.5,
      renderCell({ row }) {
        return (
          <ExpandableMenu
            menuOptions={[
              {
                id: 'delete',
                actionName: 'Delete',
                icon: <DeleteForever />,
                onAction: () => showConfirmModal(row.id),
              },
            ]}
          />
        );
      },
    },
  ];

  const schema = object({
    name: string().required(),
    default: number().required(),
    limit: number().required(),
  });

  const defaultValues = {
    default: 0,
    limit: 0,
  };

  const pageConfig = usePageSize({ cacheKey: 'charges-mapping', defaultPageSize: 100 });

  return glAccounts.length === 0 ? (
    <></>
  ) : (
    <FullSizePaper sx={{ mb: 2, ...(isCollapsible ? { pt: 2, pb: 1.5 } : { p: 0, border: 0 }) }}>
      {isCollapsible && (
        <FinancialSettingsToolBar
          open={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
          title="Charges Mapping"
        />
      )}

      <Collapse in={isOpen || !isCollapsible}>
        <Box sx={{ height: '100%', width: '100%', mt: 1 }}>
          <DataGridPro
            autoHeight
            disableColumnReorder
            disableRowSelectionOnClick
            disableColumnPinning
            disableColumnMenu
            disableMultipleRowSelection
            isRowSelectable={() => false}
            {...pageConfig}
            getRowHeight={() => 'auto'}
            rows={chargeMapping}
            columns={columns}
            components={{ Toolbar: GridSearchToolbar }}
            componentsProps={{
              toolbar: {
                right: (
                  <Button startIcon={<Add />} onClick={() => handleShowModal()}>
                    Add Charge
                  </Button>
                ),
              },
            }}
            sx={{
              [`& .${gridClasses.cell}:focus`]: { outline: 'none' },
              [`& .${gridClasses.cell}:focus-within`]: { outline: 'none' },
            }}
            hideFooter={true}
          />
        </Box>
      </Collapse>
      {chargeIdToDelete && (
        <ConfirmModal
          onClose={hideConfirmModal}
          open={confirmModalOpen}
          onConfirm={() => deleteCharge(chargeIdToDelete)}
        >
          This will permanently delete the charge.
        </ConfirmModal>
      )}
      <Form<CreateChargeInput> {...{ schema, onSubmit, defaultValues }}>
        <LocalForm
          onSubmit={createCharge}
          loading={loading}
          onHideModal={handleHideModal}
          showModal={showModal}
        />
      </Form>
    </FullSizePaper>
  );
}

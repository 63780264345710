/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertyContactInformationUpdatedEvent } from './contactInformationUpdated';
import ManagerPropertyContactInformationUpdatedEventSchema from './contactInformationUpdated.json';

export const isManagerPropertyContactInformationUpdatedEvent = guard<ManagerPropertyContactInformationUpdatedEvent>(ManagerPropertyContactInformationUpdatedEventSchema as any);
import { styled, Typography as T, TypographyProps } from '@mui/material';
import { ElementType } from 'react';

const Text = styled(T)<TypographyProps<ElementType>>(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontWeight: 500,
}));

export default function AttributeLabel({ children, className, ...props }: TypographyProps<'h3'>) {
  return (
    <Text {...props} component="h3" variant="overline" className={className}>
      {children}
    </Text>
  );
}

import { TableCellProps } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { spreadIf } from 'system';
import { StyledTableHeadCell } from './TableHeadCell.styles';

type SxProps = TableCellProps['sx'];
type StyledTableHeadCellProps = {
  flex: CSSProperties['flex'];
  children: ReactNode;
  minWidth?: number;
  margin?: number;
  className?: string;
  sx?: SxProps;
};

export default function StyledHeadCell({
  flex,
  children,
  minWidth,
  margin,
  className,
  sx,
}: StyledTableHeadCellProps) {
  return (
    <StyledTableHeadCell
      {...spreadIf(sx, { sx })}
      // TODO This block to be removed after we move to the new MUI 5 style
      flex={flex}
      minwidth={minWidth}
      margin={margin}
      className={clsx(className)}
      //
    >
      {children}
    </StyledTableHeadCell>
  );
}

import {
  JournalEntryFieldsFragment,
  useGetJournalEntryDetailsQuery,
  useGetJournalEntryQuery,
  useJournalWithJournalsQuery,
} from 'api';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useMeta } from 'hooks/useMeta';
import { useEffect, useState } from 'react';

export const useJournalEntry = ({ journalEntryId }: { journalEntryId: string }) => {
  const { data, loading, error } = useGetJournalEntryQuery({ variables: { id: journalEntryId } });
  useErrorNotifications(error);
  const [journalEntry, setJournalEntry] = useState<JournalEntryFieldsFragment>();
  useEffect(() => {
    setJournalEntry(data?.journalEntry);
  }, [data]);

  return { error, loading, journalEntry };
};

export const useJournalEntryDetails = ({ journalEntryId }: { journalEntryId: string }) => {
  const { data, ...getJournalEntryDetailsMeta } = useGetJournalEntryDetailsQuery({
    variables: { id: journalEntryId },
  });
  const { loading } = useMeta(getJournalEntryDetailsMeta);

  return {
    loading,
    journalEntry: data?.journalEntry,
  };
};

export const useJeDetails = ({ journalEntryId }: { journalEntryId: string }) => {
  const { data, ...meta } = useJournalWithJournalsQuery({
    variables: { id: journalEntryId },
  });

  const { loading } = useMeta(meta);

  return { loading, journalEntry: data?.journalEntry };
};

import { DocumentNode, gql } from '@apollo/client';
import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
  datadogRum,
} from '@datadog/browser-rum';
import { FieldNode, visit } from 'graphql';
import { parseJSON } from 'system';
import { z } from 'zod';

const env = process.env.REACT_APP_ENV ?? 'dev';
const version = process.env.REACT_APP_DD_VERSION;

const guard =
  <T extends z.ZodTypeAny>(shape: T) =>
  (value: unknown): value is z.infer<T> =>
    shape.safeParse(value).success;

const getFieldNodes = (document: DocumentNode): FieldNode[] => {
  const fieldNodes: FieldNode[] = [];

  visit(document, {
    Field: (node) => {
      fieldNodes.push(node);
    },
  });

  return fieldNodes;
};

const isFetchContext = (
  context:
    | RumViewEventDomainContext
    | RumActionEventDomainContext
    | RumFetchResourceEventDomainContext
    | RumXhrResourceEventDomainContext
    | RumOtherResourceEventDomainContext
    | RumErrorEventDomainContext
    | RumLongTaskEventDomainContext
): context is RumFetchResourceEventDomainContext => 'requestInput' in context;

const isGraphqlBody = guard(
  z.object({
    operationName: z.string(),
    variables: z.record(z.unknown()).optional(),
    query: z.string(),
  })
);

datadogRum.init({
  applicationId: 'd1154dbc-1d05-4516-93e8-6596d5526174',
  clientToken: 'pube12f5a4b146223d4f4e2dff4a7666f18',
  site: 'datadoghq.com',
  service: `manager-web-${env}`,
  env,
  sampleRate: 100,
  trackInteractions: true,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: true,
  trackFrustrations: true,
  version,
  enableExperimentalFeatures: ['clickmap', 'feature_flags'],
  defaultPrivacyLevel: 'allow',
  beforeSend: (event, context) => {
    if (event.type === 'resource' && event.resource.type === 'fetch' && isFetchContext(context)) {
      const body = parseJSON(String(context.requestInit?.body));
      if (isGraphqlBody(body)) {
        const query = gql(body.query);
        const nodes = getFieldNodes(query);
        if (nodes.some((node) => node.name.value === 'lease')) {
          console.error('FETCHED LEASE DATA: `lease`');
        }
        if (nodes.some((node) => node.name.value === 'leaseFuture')) {
          console.error('FETCHED LEASE DATA: `leaseFuture`');
        }
        if (nodes.some((node) => node.name.value === 'leaseHistory')) {
          console.error('FETCHED LEASE DATA: `leaseHistory`');
        }
        if (nodes.some((node) => node.name.value === 'leases')) {
          console.error('FETCHED LEASE DATA: `leases`');
        }
        if (nodes.some((node) => node.name.value === 'allLeases')) {
          console.error('FETCHED LEASE DATA: `allLeases`');
        }

        event.resource.url = [event.resource.url, body.operationName].join('/');
        event.context = {
          ...event.context,
          graphql: {
            operationName: body.operationName,
            variables: body.variables,
          },
        };
      }
    }
  },
});

datadogRum.startSessionReplayRecording();

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayNsfClearedEvent } from './nsfCleared';
import ManagerAutopayNsfClearedEventSchema from './nsfCleared.json';

export const isManagerAutopayNsfClearedEvent = guard<ManagerAutopayNsfClearedEvent>(ManagerAutopayNsfClearedEventSchema as any);
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  dialogClasses,
  useTheme,
} from '@mui/material';
import { GaEvent, LoadingButton } from 'components';
import { ReactNode } from 'react';
import { AnalyticsEvents, spreadIf } from 'system';
import { LoadingButtonProps } from './LoadingButton';

type ModalAction = {
  label: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  submit?: boolean;
};

export type ModalProps = {
  onClose: VoidFunction;
  open: boolean;
  title?: ReactNode;
  children: ReactNode;
  double?: boolean;
  hasPadding?: boolean;
  actions?: {
    cancel?: ModalAction;
    confirm?: ModalAction;
  } & Record<string, ModalAction>;
  customWidth?: number;
  showCloseButton?: boolean;
  gaEvent?: AnalyticsEvents;
  confirmOnEnterKey?: boolean;
} & Omit<DialogProps, 'title'>;

const loadingButtonProps = ({
  onClick,
  loading,
  disabled,
  submit,
}: ModalAction): LoadingButtonProps => ({
  onClick,
  loading,
  disabled,
  ...spreadIf(submit, { type: 'submit' }),
});

export default function Modal({
  open,
  onClose,
  title,
  children,
  actions,
  double,
  customWidth,
  hasPadding = true,
  showCloseButton = false,
  gaEvent,
  confirmOnEnterKey = false,
  sx,
  ...dialogProps
}: ModalProps) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyUp={
        confirmOnEnterKey
          ? (e) => {
              if (e.key === 'Enter') {
                actions?.confirm?.onClick?.();
              }
            }
          : undefined
      }
      sx={{
        [`& .${dialogClasses.paper}`]: {
          ...(!dialogProps.fullScreen &&
            !customWidth && {
              width: 395,
              [theme.breakpoints.down('sm')]: {
                width: 320,
              },
            }),
          ...(!dialogProps.fullScreen &&
            !customWidth &&
            double && {
              width: 790,
            }),
          ...(customWidth && {
            maxWidth: customWidth,
            width: `min(${customWidth}px, 100vw)`,
            [theme.breakpoints.down('sm')]: {
              width: 320,
            },
          }),
        },
        ...sx,
      }}
      {...dialogProps}
    >
      {gaEvent && <GaEvent name={gaEvent} />}

      {title && (
        <DialogTitle sx={{ p: 3 }}>
          {title}
          {showCloseButton && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', right: 12, top: 12 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent
        sx={{
          maxWidth: '100%',
          ...(dialogProps.fullScreen && {
            padding: '0 !important',
            width: '100%',
            heigh: '100%',
            display: 'flex',
            flexDirection: 'column',
          }),
          ...(hasPadding && { padding: theme.spacing(0, 3) }),
        }}
      >
        {children}
      </DialogContent>

      {actions && (
        <DialogActions sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          {actions.cancel && (
            <LoadingButton
              color={'primary'}
              variant="cancel"
              {...loadingButtonProps(actions.cancel)}
            >
              {actions.cancel.label}
            </LoadingButton>
          )}
          {Object.entries(actions)
            .filter(([key]) => !['cancel', 'confirm'].includes(key))
            .map(([key, props]) => (
              <LoadingButton key={key} {...loadingButtonProps(props)}>
                {props.label}
              </LoadingButton>
            ))}
          {actions.confirm && (
            <LoadingButton
              variant="contained"
              color="primary"
              {...loadingButtonProps(actions.confirm)}
            >
              {actions.confirm.label}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

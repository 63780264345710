/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerApprovalSubmittedEvent } from './submitted';
import ManagerApprovalSubmittedEventSchema from './submitted.json';

export const isManagerApprovalSubmittedEvent = guard<ManagerApprovalSubmittedEvent>(ManagerApprovalSubmittedEventSchema as any);
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, BoxProps, Link, styled } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const Item = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const Prev = ({ name }: { name?: string }) => {
  return (
    <Item>
      <NavigateBefore fontSize="small" />
      {`Prev${name ? ` ${name}` : ''}`}
    </Item>
  );
};

const Next = ({ name }: { name?: string }) => {
  return (
    <Item>
      {`Next${name ? ` ${name}` : ''}`}
      <NavigateNext fontSize="small" />
    </Item>
  );
};

const KeepTabLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, state, ...other } = props;
  return <RouterLink ref={ref} to={href} state={{ ...state, keepTab: true }} {...other} />;
});

export default function ItemNavigation({
  prev,
  next,
  name,
  sx,
}: {
  prev?: string;
  next?: string;
  name?: string;
  className?: string;
  sx?: BoxProps['sx'];
}) {
  if (!prev && !next) return null;

  return (
    <Box display="flex" sx={sx}>
      {prev ? (
        <Link href={prev} component={KeepTabLink}>
          <Prev name={name} />
        </Link>
      ) : (
        <Prev name={name} />
      )}
      {next ? (
        <Link href={next} component={KeepTabLink}>
          <Next name={name} />
        </Link>
      ) : (
        <Next name={name} />
      )}
    </Box>
  );
}

import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionContext } from 'Session';
import { AnalyticsEvents } from 'system';

export const RouteChangeListener = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { fireEvent } = useSessionContext();
  useEffect(() => {
    fireEvent({ name: AnalyticsEvents.PageView, payload: { pagePath: location.pathname } });
  }, [location]);

  return <>{children}</>;
};

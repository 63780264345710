/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorOperatorEnabledEvent } from './enabled';
import OperatorOperatorEnabledEventSchema from './enabled.json';

export const isOperatorOperatorEnabledEvent = guard<OperatorOperatorEnabledEvent>(OperatorOperatorEnabledEventSchema as any);
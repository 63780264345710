import { Alert, Box, Grid, Stack, Typography } from '@mui/material';
import { ConnectForm, FullScreenSpinner, LoadingButton, SubmitSplitButton } from 'components';
import { FormProps } from 'components/FormController/Form';
import { LoadingBox } from 'components/icons';
import { groupBy, isEmpty } from 'lodash';
import { PropsWithChildren } from 'react';
import { DeepPartial, FieldValues } from 'react-hook-form';
import { Merge, UnionToIntersection } from 'system';
import { z } from 'zod';
import {
  Form,
  FullSizePaper,
  IconContainer,
  LinearProgress,
  LinearProgressContainer,
} from './WizardForm.styles';
import { WizardStep, useWizard } from './useWizard';

type WizardFormProps<
  T extends FieldValues = Record<string, unknown>,
  Z extends z.ZodTypeAny = never,
> = PropsWithChildren<{
  initialValues?: DeepPartial<T>;
  steps: WizardStep<T>[];
  startingStep?: number;
  goToStep?: number;
  messages?: string[];
  loading?: boolean;
  loadingComponent?: JSX.Element;
  submitting?: boolean;
  submitComponent?: JSX.Element;
  formProps?: Omit<FormProps<T, Z>, 'defaultValues' | 'schema' | 'children'>;
}>;

export default function WizardForm<
  T extends FieldValues = Record<string, unknown>,
  Z extends z.ZodTypeAny = never,
>({
  initialValues,
  steps,
  children,
  startingStep,
  goToStep,
  messages,
  loading,
  loadingComponent = <FullScreenSpinner />,
  submitting,
  submitComponent = <LoadingBox />,
  formProps,
}: WizardFormProps<T, Z>) {
  const { stepIndex, schema, activeStep, handleNext, handleBack, last } = useWizard<T>({
    steps,
    startingStep,
    goToStep,
  });

  const stepsToPercent = (100 / steps.length) * (stepIndex + 1);

  return (
    <FullSizePaper>
      <Form<T, Z>
        {...formProps}
        schema={schema}
        shouldUnregister={false}
        defaultValues={initialValues}
      >
        {loading
          ? loadingComponent
          : submitting
            ? submitComponent
            : activeStep && (
                <Stack direction="column">
                  <Box sx={{ textAlign: 'center' }}>
                    {activeStep.header?.icon && (
                      <IconContainer>{activeStep.header.icon}</IconContainer>
                    )}
                    {activeStep.header?.label && (
                      <Typography variant="h5" textAlign={'center'}>
                        {activeStep.header.label}
                      </Typography>
                    )}
                    {activeStep.header?.subLabel && (
                      <Typography variant="body1" textAlign={'center'}>
                        {activeStep.header.subLabel}
                      </Typography>
                    )}
                  </Box>

                  {steps.length > 1 && (
                    <LinearProgressContainer>
                      <LinearProgress variant="determinate" value={stepsToPercent} />
                    </LinearProgressContainer>
                  )}

                  <Grid container alignItems="center" justifyContent="center" marginBottom={3}>
                    <Grid item xs={12} md={10}>
                      <activeStep.FieldsComponent
                        {...activeStep.props}
                      ></activeStep.FieldsComponent>
                    </Grid>
                  </Grid>

                  <ConnectForm<UnionToIntersection<T> & FieldValues>>
                    {({
                      handleSubmit,
                      formState: { isSubmitting, errors },
                      getValues,
                      clearErrors,
                    }) => {
                      const { left: leftActions = [], right: rightActions = [] } = groupBy(
                        activeStep.actions,
                        'align'
                      );

                      const handleBackWithReset = () => {
                        clearErrors();
                        handleBack();
                      };

                      return (
                        <>
                          <Grid container alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={10}>
                              {!isSubmitting &&
                                messages?.map((message) => (
                                  <Alert key={message} sx={{ mb: 1 }} severity="info">
                                    {message}
                                  </Alert>
                                ))}

                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 'auto',
                                }}
                              >
                                <Stack direction="row" gap={4}>
                                  {leftActions.map((action, index) =>
                                    action.splitOptions ? (
                                      <SubmitSplitButton<Merge<UnionToIntersection<T>>>
                                        key={index}
                                        type="button"
                                        disabled={action.disabled || isSubmitting}
                                        options={action.splitOptions.map((option) => ({
                                          label: option.label ?? '',
                                          onClick: (values) =>
                                            option.onClick?.({
                                              values,
                                              handleNext,
                                              handleBack: handleBackWithReset,
                                              last,
                                            }),
                                        }))}
                                      />
                                    ) : (
                                      <LoadingButton
                                        key={index}
                                        type={action.skipValidation ? 'button' : 'submit'}
                                        hideProgress={action.hideProgress ?? true}
                                        onClick={() =>
                                          action.skipValidation
                                            ? action.onClick?.({
                                                values: getValues(),
                                                handleNext,
                                                handleBack: handleBackWithReset,
                                                last,
                                              })
                                            : handleSubmit((values) =>
                                                action.onClick?.({
                                                  values,
                                                  handleNext,
                                                  handleBack: handleBackWithReset,
                                                  last,
                                                })
                                              )()
                                        }
                                        variant={action.variant ?? 'outlined'}
                                        disabled={action.disabled || isSubmitting}
                                        loading={isSubmitting || action.loading}
                                      >
                                        {action.label}
                                      </LoadingButton>
                                    )
                                  )}
                                </Stack>

                                <Stack direction="row" gap={4}>
                                  {rightActions.map((action, index) =>
                                    action.splitOptions ? (
                                      <SubmitSplitButton<Merge<UnionToIntersection<T>>>
                                        key={index}
                                        disabled={action.disabled || isSubmitting}
                                        options={action.splitOptions.map((option) => ({
                                          label: option.label ?? '',
                                          onClick: (values) =>
                                            option.onClick?.({
                                              values,
                                              handleNext,
                                              handleBack: handleBackWithReset,
                                              last,
                                            }),
                                        }))}
                                      />
                                    ) : (
                                      <LoadingButton
                                        key={index}
                                        type={action.skipValidation ? 'button' : 'submit'}
                                        hideProgress={action.hideProgress ?? true}
                                        onClick={() =>
                                          action.skipValidation
                                            ? action.onClick?.({
                                                values: getValues(),
                                                handleNext,
                                                handleBack: handleBackWithReset,
                                                last,
                                              })
                                            : handleSubmit((values) =>
                                                action.onClick?.({
                                                  values,
                                                  handleNext,
                                                  handleBack: handleBackWithReset,
                                                  last,
                                                })
                                              )()
                                        }
                                        variant={action.variant ?? 'contained'}
                                        disabled={
                                          action.disabled ||
                                          isSubmitting ||
                                          (!action.skipValidation && !isEmpty(errors))
                                        }
                                        loading={isSubmitting || action.loading}
                                      >
                                        {action.label}
                                      </LoadingButton>
                                    )
                                  )}
                                </Stack>
                              </Box>
                            </Grid>
                          </Grid>
                          {children}
                        </>
                      );
                    }}
                  </ConnectForm>
                </Stack>
              )}
      </Form>
    </FullSizePaper>
  );
}

import { castArray, compact, differenceBy, isEqual, map, uniq, without } from 'lodash';
import { Option } from 'system';

export const ownerSelectionFor = ({
  ownerOptions,
  accountOption,
  defaultOwnerSelection,
}: {
  defaultOwnerSelection: string | string[];
  ownerOptions: Option[];
  accountOption?: Option;
}) => {
  return ({
    newOwnerId,
    newPropertyId,
    newOwnerOptions,
    propertyOwnerOptions,
  }: {
    newOwnerId?: string | string[];
    newPropertyId?: string | string[];
    newOwnerOptions: Option[];
    propertyOwnerOptions: string[];
  }) => {
    const isMultiSelect = Array.isArray(newOwnerId) || Array.isArray(defaultOwnerSelection);
    const multiSingle = (multi: string[], single?: string | null) =>
      isMultiSelect ? multi : single;
    const ownerIds = new Set(isMultiSelect ? newOwnerId : (newOwnerId ?? '').split(','));

    const removedOwnerOptions = map(differenceBy(ownerOptions, newOwnerOptions, 'id'), 'id');
    const addedOwnerOptions = map(differenceBy(newOwnerOptions, ownerOptions, 'id'), 'id');

    const applicableOwnerOption = isMultiSelect
      ? compact(propertyOwnerOptions)
      : compact(propertyOwnerOptions).length > 2
        ? null
        : propertyOwnerOptions[0];

    if (!newPropertyId) {
      return defaultOwnerSelection;
    }

    return newOwnerId === 'all'
      ? multiSingle(map(ownerOptions, 'id'), newOwnerOptions[0].id)
      : !newOwnerOptions.length
        ? multiSingle(compact([accountOption?.id]), accountOption?.id)
        : !newOwnerOptions.some(({ id }) => ownerIds.has(id)) &&
            !isEqual(applicableOwnerOption, newOwnerId)
          ? applicableOwnerOption
          : addedOwnerOptions.length || removedOwnerOptions.length
            ? multiSingle(
                uniq(
                  without([...castArray(newOwnerId), ...addedOwnerOptions], ...removedOwnerOptions)
                ),
                null
              )
            : null;
  };
};

import {
  FieldMergeFunction,
  FieldReadFunction,
  InMemoryCache,
  StoreObject,
  TypePolicy,
} from '@apollo/client';
import { OperatorWindowKeySpecifier, StrictTypedTypePolicies } from 'api';
import { keyBy } from 'lodash';

type Connection = { items?: { id: string }[]; nextToken?: string };

const mergeItemsWith =
  <T>(getKey: (item: T) => string) =>
  (existing: T[] = [], incoming: T[] = []) =>
    Object.values({
      ...keyBy(existing, getKey),
      ...keyBy(incoming, getKey),
    });

const listConnection = (field = 'id'): TypePolicy => ({
  keyFields: false,
  fields: {
    items: {
      merge(existing = [], incoming = [], { readField }) {
        const asKey = (item: StoreObject) => readField<string>(field, item) ?? 'unknown';
        const items = mergeItemsWith(asKey)(existing, incoming);
        return items;
      },
    },
    read(existing) {
      return existing;
    },
  },
});

const cacheFirst: (__typename: string) => { read: FieldReadFunction } = (__typename: string) => ({
  read: (_, { args, toReference }) => toReference({ __typename, id: args?.id ?? 'invalid' }),
});

// I don't know why this is necessary but without it `connectionMerge` doesn't seem to work
const connectionRead: FieldReadFunction<Connection> = (existing) => existing;
const connectionMerge: (fields?: string[]) => FieldMergeFunction<Connection> =
  (fields = ['id']) =>
  (existing, incoming, { readField }) => {
    const asKey = (item: NonNullable<Connection['items']>[0]) =>
      fields.map((field) => readField(field, item)).join();

    const items = mergeItemsWith(asKey)(existing?.items, incoming?.items);

    return {
      items,
      nextToken: incoming.nextToken,
    };
  };

const typePolicies: StrictTypedTypePolicies = {
  Account: {
    fields: {
      address: { merge: (existing = {}, incoming) => ({ ...existing, ...incoming }) },
      listApprovals: { merge: connectionMerge(), read: connectionRead },
      listActiveApprovals: {
        keyArgs: ['filter', ['type']],
        merge: connectionMerge(),
        read: connectionRead,
      },
      listOwners: { keyArgs: ['filter'], merge: connectionMerge(), read: connectionRead },
      listProperties: { keyArgs: ['filter'], merge: connectionMerge(), read: connectionRead },
      listSuppliers: { keyArgs: ['filter'], merge: connectionMerge(), read: connectionRead },
      listPropertyFacts: {
        keyArgs: ['filter'],
        merge: connectionMerge(['propertyId', 'dateKey']),
        read: connectionRead,
      },
      listTenants: { merge: connectionMerge(), read: connectionRead },
      listResidents: { merge: connectionMerge(), read: connectionRead },
      listResidencies: { keyArgs: ['filter'], merge: connectionMerge(), read: connectionRead },
      listUnits: { keyArgs: ['filter'], merge: connectionMerge(), read: connectionRead },
      listAnnouncements: { merge: connectionMerge(), read: connectionRead },
      listAutopaySummaries: {
        merge: connectionMerge(['id', 'autopayId']),
        read: connectionRead,
      },
      listRequests: { merge: connectionMerge(), read: connectionRead },
      listActiveRequests: { merge: connectionMerge(), read: connectionRead },
      listRecentFinalizedRequests: { merge: connectionMerge(), read: connectionRead },
      listAllFinalizedRequests: { merge: connectionMerge(), read: connectionRead },
      listAutopayEnrollments: {
        keyArgs: ['filter'],
        merge: connectionMerge(),
        read: connectionRead,
      },
      listAutopayProperties: { merge: connectionMerge(), read: connectionRead },
      listFloorplans: { merge: connectionMerge(), read: connectionRead },
    },
  },
  Autopay: { keyFields: ['accountId'] },
  Address: { keyFields: false },
  JEPrototypeField: { keyFields: false },
  AutopayEnrollmentConnection:
    listConnection() as StrictTypedTypePolicies['AutopayEnrollmentConnection'],
  PropertyConnection: listConnection() as StrictTypedTypePolicies['PropertyConnection'],
  ClearableConnection: listConnection() as StrictTypedTypePolicies['ClearableConnection'],
  JournalEntryConnection: listConnection() as StrictTypedTypePolicies['JournalEntryConnection'],
  UnitConnection: listConnection() as StrictTypedTypePolicies['UnitConnection'],
  RequestConnection: listConnection() as StrictTypedTypePolicies['RequestConnection'],
  CampaignConnection: listConnection() as StrictTypedTypePolicies['CampaignConnection'],
  DraftedJournalEntry: { keyFields: false },
  DraftedJournalEntryEdge: { keyFields: false },
  Batch: {
    fields: {
      listPrototypes: {
        keyArgs: ['input', ['batchId']],
        merge: connectionMerge(),
        read: connectionRead,
      },
    },
  },
  BillingRate: {
    keyFields: false,
    merge: (existing = {}, incoming) => ({ ...existing, ...incoming }),
  },
  Books: {
    keyFields: ['accountId'],
    fields: {
      getBudgets: {
        keyArgs: ['input'],
      },
      listCheques: {
        keyArgs: ['glId', 'printedZ'],
        merge: connectionMerge(),
        read: connectionRead,
      },
      listClearables: {
        keyArgs: ['filter', ['balanceType', 'cleared', 'range']],
        merge: connectionMerge(),
        read: connectionRead,
      },
      listOwners: { merge: connectionMerge(), read: connectionRead },
      payeeClearables: {
        keyArgs: ['payeeId'],
      },
      unitClearables: {
        keyArgs: ['unitId'],
        merge: connectionMerge(),
        read: connectionRead,
      },
      previewCheques: {
        keyArgs: false,
      },
      listJournalEntriesForReconciliation: {
        keyArgs: false,
      },
      listReconciliations: { merge: connectionMerge(), read: connectionRead },
      listReconciliationConfigs: { merge: connectionMerge(), read: connectionRead },
    },
  },
  Budget: {
    keyFields: ['ownerId', 'propertyId', 'glId', 'period'],
  },
  Document: { keyFields: ['key'] },
  GLAccount: {
    keyFields: ['id'],
    fields: {
      combinedSubAccount: {
        keyArgs: ['input'],
      },
    },
  },
  GLMapping: { keyFields: ['id', 'parentId'] },
  InspectionField: { keyFields: false },
  MessageSubscription: { keyFields: false },
  Operator: {
    fields: {
      exceptions: { merge: false },
    },
  },
  OperatorWindow: { keyFields: ['operator', ['id']] as OperatorWindowKeySpecifier },
  Owner: {
    fields: {
      units: { merge: false },
      properties: { merge: false },
      address: { merge: true },
      listUnits: {
        keyArgs: ['filter'],
        merge: connectionMerge(['unitIds']),
        read: connectionRead,
      },
    },
  },
  PresetInputField: { keyFields: false },
  Property: {
    fields: {
      address: { merge: true },
    },
  },
  PropertyFact: { keyFields: ['propertyId', 'dateKey'] },
  Query: {
    fields: {
      announcement: cacheFirst('Announcement'),
      batch: cacheFirst('Batch'),
      clearable: cacheFirst('Clearable'),
      contact: cacheFirst('Contact'),
      glAccount: cacheFirst('GLAccount'),
      je: cacheFirst('JournalEntry'),
      journalEntry: cacheFirst('JournalEntry'),
      operator: cacheFirst('Operator'),
      owner: cacheFirst('Owner'),
      property: cacheFirst('Property'),
      recurrence: cacheFirst('Recurrence'),
      request: cacheFirst('Request'),
      supplier: cacheFirst('Supplier'),
      tenant: cacheFirst('Tenant'),
      unit: cacheFirst('Unit'),
      campaigns: { merge: connectionMerge(), read: connectionRead },
    },
  },
  RentRollFee: { keyFields: false },
  SettlementSummary: { keyFields: ['id', 'autopayId'] },
  Subscription: {
    fields: {
      onAccountEvent: { merge: false },
    },
  },
  SubscriptionEndpoint: { keyFields: false },
  Template: { keyFields: ['id', 'default'] },
};

export const cache = new InMemoryCache({
  resultCacheMaxSize: 200_000,
  typePolicies,
});

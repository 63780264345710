/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksClearableNsfEvent } from './clearableNsf';
import ManagerBooksClearableNsfEventSchema from './clearableNsf.json';

export const isManagerBooksClearableNsfEvent = guard<ManagerBooksClearableNsfEvent>(ManagerBooksClearableNsfEventSchema as any);
import { useBooksActivated } from 'hooks/useBooksActivated';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useActivationRedirection = () => {
  const navigate = useNavigate();
  const { activated, loading } = useBooksActivated();

  useEffect(() => {
    if (!loading && !activated) {
      navigate('/accounting/accounts/activation');
    }
  }, [loading, activated, navigate]);
};

import { BrowserStorageCache } from '@aws-amplify/cache';
import { useEffect, useState } from 'react';

export const EXPANDED_WIDTH = 240;
export const COLLAPSED_WIDTH = 80;

export const LOGIN_PATH = '/login';
export const DEFAULT_PATH = '/';

export const useAuthFrame = () => {
  const cachedCollapsed = Boolean(BrowserStorageCache.getItem('drawerCollapsed'));
  const [collapse, setCollapse] = useState(cachedCollapsed);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    BrowserStorageCache.setItem('drawerCollapsed', collapse);
  }, [collapse]);

  return {
    collapse: collapse && !mobileOpen,
    mobileOpen,
    handleCollapse: () => setCollapse(true),
    handleExpand: () => setCollapse(false),
    handleMobileToggle: () => setMobileOpen(!mobileOpen),
  };
};

import { CloudUpload } from '@mui/icons-material';
import { Chip, Grid, styled, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import plur from 'plur';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

type DropzoneFieldProps = {
  filesToUpload: File[];
  maxFileSizeMB?: number;
  onDrop: (f: File[]) => void;
  onRemove: (f: File) => void;
  maxFiles?: number;
  title?: string;
  dropzoneOptions?: Pick<DropzoneOptions, 'accept'>;
};

const DropzoneArea = styled(Grid)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 16,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.paper,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}));

export const DropZoneField = ({
  onDrop,
  onRemove,
  filesToUpload,
  maxFileSizeMB = 40,
  maxFiles,
  dropzoneOptions,
  title = 'Document',
}: DropzoneFieldProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    multiple: true,
    maxFiles,
    maxSize: maxFileSizeMB * 1024 * 1024,
  });

  const renderedTitle = plur(title, maxFiles ?? 2).toLowerCase();

  return (
    <Grid>
      <DropzoneArea item xs={12} {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUpload color="secondary" sx={{ height: 48, width: 48 }} />
        <Typography variant="h5">Upload {capitalize(renderedTitle)}</Typography>
        <Typography variant="subtitle1">
          {`Drag and drop your ${plur(
            title.toLowerCase(),
            maxFiles ?? 2
          )} or click here to select the
          ${renderedTitle}.`}
        </Typography>
        <Typography variant="caption">Maximum file upload size: {maxFileSizeMB} MB</Typography>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          {filesToUpload.length > 0 &&
            filesToUpload.map((file) => (
              <Grid item key={file.name}>
                <Chip label={file.name} onDelete={() => onRemove(file)} sx={{ m: 1 }} />
              </Grid>
            ))}
        </Grid>
      </DropzoneArea>
    </Grid>
  );
};

import { Document } from 'api';
import { Form, Modal, TextFieldController } from 'components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';

type DocumentFields = {
  name: string;
};

const LocalForm = ({
  document,
  onClose,
  open,
  onSubmit,
  defaultValues,
}: {
  onClose: () => void;
  document?: Document;
  open: boolean;
  onSubmit: (values: DocumentFields) => void;
  defaultValues?: DocumentFields;
}) => {
  const { handleSubmit, formState, reset } = useFormContext<DocumentFields>();
  const { isSubmitting } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // TODO: marginBottom:3 - confirm it works fine
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={`Rename '${document?.name ?? document?.key?.split('/').reverse()[0]}'`}
      double
      sx={{ minHeight: 230 }}
      actions={{
        cancel: {
          label: 'Cancel',
          onClick: onClose,
        },
        confirm: {
          label: 'Save',
          onClick: handleSubmit(onSubmit),
          loading: isSubmitting,
        },
      }}
    >
      <TextFieldController name="name" label="Name" sx={{ marginBottom: 3 }} />
    </Modal>
  );
};

export type RenameDocumentModalProps = {
  onClose: () => void;
  open: boolean;
  onSubmit: (args: { document: Document; values: DocumentFields }) => void;
  document?: Document;
};

export function RenameDocumentModal({
  onClose,
  open,
  onSubmit,
  document,
}: RenameDocumentModalProps) {
  const schema = Yup.object({
    name: Yup.string()
      .max(100, 'Maximum document name length is 100 characters')
      .nullable()
      .matches(/^[^/]+$/, 'The character / is not permited'),
  });

  const defaultValues = {
    name: `${document?.name ?? document?.key?.split('/').reverse()[0] ?? ''}`,
  };

  return document ? (
    <Form<DocumentFields> {...{ schema, defaultValues }}>
      <LocalForm
        {...{ document, onClose, open, defaultValues }}
        onSubmit={async (values: DocumentFields) => {
          if (document) {
            await onSubmit({
              document,
              values: {
                ...values,
                name: document.key.split('/').slice(0, -1).concat(values.name).join('/'),
              },
            });
          }
          onClose();
        }}
      />
    </Form>
  ) : (
    <></>
  );
}

import { StyledCell } from './TableCell.styles';
import { AdaptiveColumn, Row } from '../types';

type StyledTableCellProps = {
  row: Row;
  column: AdaptiveColumn;
  cellPadding?: number;
};

export default function StyledTableCell({ column, row, cellPadding }: StyledTableCellProps) {
  return (
    <StyledCell cellPadding={cellPadding} padding="normal" column={column} sx={column?.sx ?? {}}>
      {column.renderCell
        ? column.renderCell({ id: row.id, value: row[column.field], row })
        : row[column.field]}
    </StyledCell>
  );
}

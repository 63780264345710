import { OwnerListFieldsFragment } from 'api';
import { useOwners } from 'pages/owners/hooks/useOwners';
import { useMemo } from 'react';
import AutocompleteFieldController from './AutocompleteFieldController';

export type OwnerAutocompleteControllerProps = {
  name: string;
  controllerProps?: Partial<Parameters<typeof AutocompleteFieldController>[0]>;
  onChange: (owner?: OwnerListFieldsFragment) => void;
};

export default function OwnerAutocompleteController({
  name,
  onChange,
  controllerProps,
}: OwnerAutocompleteControllerProps) {
  const { loading, owners } = useOwners();

  const options = useMemo(
    () =>
      owners.map(({ id, name: ownerName, email }) => ({
        id,
        text: `${ownerName}${email ? ` (${email})` : ''}`,
      })),
    [owners]
  );

  return (
    <AutocompleteFieldController
      variant="outlined"
      name={name}
      options={options}
      hideIcons={true}
      loading={loading}
      {...controllerProps}
      onValueChange={(id: string) => {
        onChange(owners.find(({ id: ownerId }) => ownerId === id));
      }}
    />
  );
}

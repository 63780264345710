import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { skipProps } from 'system';
import { AdaptiveColumn } from '../types';

type StyledCellProps = {
  cellPadding: number | undefined;
  column: AdaptiveColumn;
  minWidth?: number;
};

export const StyledCell = styled(
  TableCell,
  skipProps('column')
)<StyledCellProps>(({ column, cellPadding = 2, theme }) => ({
  align: 'left',
  alignSelf: 'center',
  margin: theme.spacing(2, 1, 2, cellPadding),
  padding: '0px',
  border: 'none',
  flex: column?.flex ?? 1,
  minWidth: column?.minWidth,
}));

import { ApolloCache } from '@apollo/client';
import { Modifier } from '@apollo/client/cache';
import {
  AccountType,
  BalanceType,
  Clearable,
  ClearablesFilterInput,
  GlAccountFieldsFragment,
  GstInfo,
  PayeeType,
  SubAccountInput,
} from 'api';
import jsonata, { Expression } from 'jsonata';
import { compact, find, isEmpty, toNumber } from 'lodash';
import { DateTime, DurationInput, DurationObjectUnits, Interval } from 'luxon';
import { ReactNode } from 'react';
import {
  ensureArray,
  isSubAccountInput,
  maxISODate,
  safeRound,
  safeSum,
  sortByAttribute,
  stringsOnly,
} from 'system';
import { payablesGridState, receivablesGridState } from '../../cache/dataGrid';
import { parseJSON } from '../../system';
import { BatchProgressFields } from './hooks/useDboBatchEvents';
import { ClearableLabels, Timeframe } from './types';

export const otherBalanceType = (balanceType: BalanceType) =>
  balanceType === BalanceType.Credit ? BalanceType.Debit : BalanceType.Credit;

export enum GlobalMapping {
  accountsPayable = 'accountsPayable',
  accountsReceivable = 'accountsReceivable',
  operatingBank = 'operatingBank',
  trustBank = 'trustBank',
  depositLiability = 'depositLiability',
  maintenanceExpense = 'maintenanceExpense',
  otherRevenue = 'otherRevenue',
  bankCharges = 'bankCharges',
  gstHstPayable = 'gstHstPayable',
  incomeSummary = 'incomeSummary',
  ownerContributions = 'ownerContributions',
}

export const clearableLabels = <TString extends string>(hint: TString): ClearableLabels => {
  const labelMap = {
    [BalanceType.Credit]: {
      balanceType: BalanceType.Credit,
      tableState: payablesGridState,
      clearableLabel: 'Payable',
      clearingEntryLabel: 'Payment',
      clearingEntryDescription: 'Payment to',
      clearablePath: 'payables',
      clearingEntryPath: 'payments',
      clearableDocumentLabel: 'Bill',
    },
    [BalanceType.Debit]: {
      balanceType: BalanceType.Debit,
      tableState: receivablesGridState,
      clearableLabel: 'Receivable',
      clearingEntryLabel: 'Deposit',
      clearingEntryDescription: 'Payment from',
      clearablePath: 'receivables',
      clearingEntryPath: 'deposits',
      clearableDocumentLabel: 'Invoice',
    },
  };

  return Object.values(BalanceType).includes(hint as BalanceType)
    ? labelMap[hint as BalanceType]
    : (find(labelMap, (m) =>
        stringsOnly(Object.values(m)).some((str) => hint.toLowerCase().includes(str.toLowerCase()))
      ) ?? labelMap.debit);
};

export const formatAccountName = (glAccount?: GlAccountFieldsFragment) => {
  return `${glAccount?.key ? `[${glAccount?.key}] ` : ''}${glAccount?.name}`;
};

export const calculateBalancedLines = (lines?: { debit?: number; credit?: number }[]) => {
  const debitSum = safeRound(
    ensureArray(lines).reduce((prev, curr) => safeSum(prev, curr.debit ?? 0), 0)
  );
  const creditSum = safeRound(
    ensureArray(lines).reduce((prev, curr) => safeSum(prev, curr.credit ?? 0), 0)
  );
  const balanced = Boolean(lines) && debitSum === creditSum;

  return {
    debitSum,
    creditSum,
    balanced,
  };
};

export const fromAmountToCreditDebit = (amount: number, glAccount?: GlAccountFieldsFragment) => {
  const isDebitAccount = glAccount?.balanceType === BalanceType.Debit;

  const debit =
    isDebitAccount && amount > 0 ? amount : !isDebitAccount && amount < 0 ? -amount : undefined;

  const credit =
    isDebitAccount && amount < 0 ? -amount : !isDebitAccount && amount > 0 ? amount : undefined;

  return {
    credit,
    debit,
  };
};

export const fromCreditDebitToAmount = (
  { credit, debit }: { credit?: number; debit?: number },
  glAccount?: GlAccountFieldsFragment
) => {
  const operands =
    glAccount?.balanceType === BalanceType.Debit
      ? [debit ?? 0, -(credit ?? 0)]
      : [credit ?? 0, -(debit ?? 0)];
  return safeSum(...operands);
};

export const amountFromBalanceTypeFor =
  (balanceTypeContext?: BalanceType) => (amount: number, balanceType?: BalanceType) =>
    balanceType && balanceTypeContext && balanceTypeContext !== balanceType ? -amount : amount;

export const accountTypeOrder = ({ accountType }: { accountType: AccountType }) =>
  [
    AccountType.Asset,
    AccountType.Liability,
    AccountType.Expense,
    AccountType.Revenue,
    AccountType.Equity,
  ].indexOf(accountType);

export const byAccountType = (a: { accountType: AccountType }, b: { accountType: AccountType }) =>
  accountTypeOrder(a) - accountTypeOrder(b);

export const byAccountTypeKeyName = (
  a: { accountType: AccountType; key?: string; name: string },
  b: { accountType: AccountType; key?: string; name: string }
) =>
  a.accountType === b.accountType
    ? a.key === b.key
      ? sortByAttribute('name')(a, b)
      : sortByAttribute('key')(a, b)
    : byAccountType(a, b);

export const intervalToParam = (interval: Interval): string =>
  interval.start.toISODate().concat('/', interval.end.toISODate());

export const dateRangeToParam = (start?: string, end?: string): string =>
  (start ?? '').concat('/', end ?? '');

export const intervalToISOStrings = (interval: Interval): { start: string; end: string } => ({
  start: interval.start.toISODate(),
  end: interval.end.toISODate(),
});

export const timeframeForPeriod = (
  period: Interval,
  defaultTimeFrame: Timeframe,
  refDate = DateTime.now()
): Timeframe => {
  if (!period.isValid) {
    return defaultTimeFrame;
  }
  const iso = period.toISODate();
  const timeframes: Record<string, Timeframe> = {
    [Interval.fromDateTimes(refDate.startOf('month'), refDate.endOf('month')).toISODate()]: 'month',
    [Interval.fromDateTimes(refDate.startOf('month'), refDate.endOf('month'))
      .mapEndpoints((dt) => dt.minus({ month: 1 }))
      .toISODate()]: 'last-month',
    [Interval.fromDateTimes(refDate.startOf('quarter'), refDate.endOf('quarter')).toISODate()]:
      'quarter',
    [Interval.fromDateTimes(refDate.startOf('quarter'), refDate.endOf('quarter'))
      .mapEndpoints((dt) => dt.minus({ quarter: 1 }))
      .toISODate()]: 'last-quarter',
    [Interval.fromDateTimes(refDate.startOf('year'), refDate.endOf('year')).toISODate()]: 'year',
    [Interval.fromDateTimes(refDate.startOf('year'), refDate.endOf('year'))
      .mapEndpoints((dt) => dt.minus({ year: 1 }))
      .toISODate()]: 'last-year',
  };

  return iso === '1970-01-01/9999-12-31' ? 'all' : (timeframes[iso] ?? defaultTimeFrame);
};

export const periodForTimeframe = (timeframe: Timeframe, refDate = DateTime.now()): Interval => {
  if (timeframe === 'all') return Interval.fromISO('1970-01-01/9999-12-31');
  if (timeframe === 'custom') return Interval.fromISO('1970-01-01/1970-01-01');

  const unit = timeframe.split('-').slice(-1)[0] as keyof DurationObjectUnits;
  const span: Record<string, DurationInput> = {
    year: { year: 1 },
    quarter: { months: 3 },
    month: { month: 1 },
  };

  const current = Interval.after(refDate.startOf(unit), span[unit]);
  const maybeLast = timeframe.startsWith('last')
    ? current.mapEndpoints((dt) => dt.minus(span[unit]))
    : current;
  const adjustedEnd = Interval.fromDateTimes(maybeLast.start, maybeLast.end.minus({ day: 1 }));

  return adjustedEnd;
};

export const rangeForTimeframe = (
  timeframe: Timeframe,
  refDate = DateTime.now()
): { from: string; to: string } => {
  const period = periodForTimeframe(timeframe, refDate);
  return {
    from: period.start.toISODate(),
    to: period.end.toISODate(),
  };
};

export const journalSortDate = ({ id, posted }: { id: string; posted: string }) => `${posted}${id}`;

export const withinTimeframe =
  (timeframe: Timeframe) =>
  ({ cleared }: { cleared?: string }) =>
    periodForTimeframe(timeframe).engulfs(Interval.after(DateTime.fromISO(cleared ?? ''), {}));

export const fromDecimal = (arg?: number) => arg && safeRound(arg * 100, 4);
export const toPercentage = (arg?: number) => arg && safeRound(arg / 100, 4);

export const gstInfoDetail = {
  [GstInfo.Line103]: 'Line 103 - GST/HST collectible',
  [GstInfo.Line104]: 'Line 104 - Adjustment on collectible',
  [GstInfo.Line106]: 'Line 106 - GST/HST payable ITCs',
  [GstInfo.Line107]: 'Line 107 - Adjustments payable ITCs',
  [GstInfo.Line110]: 'Line 110 - Instalments',
  [GstInfo.Line111]: 'Line 111 - Form indicated rebates',
  [GstInfo.Line205]: 'Line 205 - GST/HST due on purchases of real property',
  [GstInfo.Line405]: 'Line 405 - GST/HST self assessed',
};

export const accountOption = ({ id, name }: { id: string; name: string }) => ({ id, text: name });

export const dueOffsetOptions = [
  {
    label: 'Due on Receipt',
    value: '0',
  },

  {
    label: 'Net 15',
    value: '15',
  },

  {
    label: 'Net 30',
    value: '30',
  },
];

type MaybeValidLine = {
  amount?: number;
  extraColumns?: { value?: unknown; keepNonZero?: boolean; columnType?: string }[];
  lines?: Array<MaybeValidLine>;
};
export const keepValidLinesWith =
  (options?: { showZeroAmounts?: boolean }) =>
  ({ amount, lines, extraColumns }: MaybeValidLine): boolean => {
    return lines?.length
      ? lines.some((l) => keepValidLinesWith(options)(l))
      : options?.showZeroAmounts ||
          Boolean(amount ? safeRound(amount) : amount) ||
          (extraColumns ?? []).some(
            ({ value, keepNonZero, columnType }) =>
              columnType === 'amount' && toNumber(value) && keepNonZero
          );
  };

export const omitRelevantRanges = (subAccountCache: Record<string, unknown>, posted: string) => {
  const cloned = { ...subAccountCache };
  for (const key of Object.keys(cloned)) {
    const params: { input?: { from?: string; to?: string } } = JSON.parse(
      key.match(/\{"input":\{"from":"\d{4}-\d{2}-\d{2}","to":"\d{4}-\d{2}-\d{2}"\}\}/)?.[0] ?? '{}'
    );
    const range = Interval.fromISO(`${params.input?.from}/${params.input?.to}`);
    if (range.isValid && range.contains(DateTime.fromISO(posted))) {
      delete cloned[key];
    }
  }

  return cloned;
};

export const clearablesFilterMatches = (
  filter?: ClearablesFilterInput,
  clearable?: Pick<Clearable, 'id' | 'balanceType' | 'cleared'> | null
) =>
  Boolean(
    filter &&
      clearable &&
      filter.balanceType === clearable.balanceType &&
      filter.cleared === !!clearable.cleared &&
      (!filter.cleared ||
        (filter.range &&
          clearable.cleared &&
          Interval.fromISO(`${filter.range.from}/${filter.range.to}`).contains(
            DateTime.fromISO(clearable.cleared)
          )))
  );

export const storeFieldNameArgs = <T = unknown>(storeFieldName: string): Partial<T> => {
  const argsJSON = storeFieldName.includes('(')
    ? storeFieldName.substring(storeFieldName.indexOf('(') + 1, storeFieldName.length - 1)
    : storeFieldName.substring(storeFieldName.indexOf(':') + 1);
  const args = parseJSON(argsJSON);
  return args as Partial<T>;
};

export const balanceAffected = (
  input: Partial<SubAccountInput>,
  journal: { ownerId: string; propertyId: string; posted: string }
) => {
  const ownerIds = new Set(compact(ensureArray(input.ownerIds).concat(input.ownerId ?? '')));
  const propertyIds = new Set(
    compact(ensureArray(input.propertyIds).concat(input.propertyId ?? ''))
  );
  const range = Interval.fromISO(
    `${input.range?.from ?? '1970-01-01'}/${input.range?.to ?? '9999-12-31'}`
  );

  return (
    ownerIds.has(journal.ownerId) &&
    propertyIds.has(journal.propertyId) &&
    DateTime.fromISO(journal.posted) < range.start
  );
};

export const subAccountMatches = (
  input: Partial<SubAccountInput>,
  journal: { ownerId: string; propertyId: string; posted: string }
) => {
  const ownerIds = new Set(compact(ensureArray(input.ownerIds).concat(input.ownerId ?? '')));
  const propertyIds = new Set(
    compact(ensureArray(input.propertyIds).concat(input.propertyId ?? ''))
  );
  const range = Interval.fromISO(
    `${input.range?.from ?? '1970-01-01'}/${input.range?.to ?? '9999-12-31'}`
  );

  return (
    ownerIds.has(journal.ownerId) &&
    propertyIds.has(journal.propertyId) &&
    range.contains(DateTime.fromISO(journal.posted))
  );
};

export const invalidateSubAccount =
  (cache: ApolloCache<unknown>) =>
  ({
    glId,
    ownerId,
    propertyId,
    posted,
  }: {
    glId: string;
    ownerId: string;
    propertyId: string;
    posted: string;
  }) => {
    const rangeInvalidation: Modifier<Record<string, unknown>> = (
      existing,
      { storeFieldName, DELETE }
    ) => {
      const rawInputObject = storeFieldNameArgs(storeFieldName);

      const {
        input: { ownerIds = [], propertyIds = [], range, ...input },
      } = isSubAccountInput(rawInputObject) ? rawInputObject : { input: { range: {} } };

      const rangeInterval = !isEmpty(range)
        ? Interval.fromISO(`${range.from}/${range.to}`)
        : undefined;

      const predicates = [
        compact([input.ownerId, ...ownerIds]).includes(ownerId),
        compact([input.propertyId, ...propertyIds]).includes(propertyId),
        rangeInterval?.contains(DateTime.fromISO(posted)),
      ];

      return predicates.every(Boolean) ? DELETE : existing;
    };

    cache.modify({
      id: cache.identify({ id: glId, __typename: 'GLAccount' }),
      fields: {
        subAccount: rangeInvalidation,
        combinedSubAccount: rangeInvalidation,
      },
    });
  };

export const updateReconciliationJournalsCache =
  (cache: ApolloCache<unknown>) =>
  <TJournal extends { id: string; reconciliationId?: string }>(
    accountId: string,
    ...journals: TJournal[]
  ) =>
    cache.modify({
      id: cache.identify({ __typename: 'Books', accountId }),
      fields: {
        listJournalEntriesForReconciliation: (data: { journalEntries?: string[] }) => {
          const parsedJournals = ensureArray(data?.journalEntries).map(
            (x) => [parseJSON(x), x] as [Record<string, unknown>, string]
          );

          return {
            ...data,

            journalEntries: [
              ...parsedJournals.map(([parsed, json]) => {
                const je = journals.find((j) => j.id === parsed.id);
                return je ? JSON.stringify({ ...parsed, ...je }) : json;
              }),
              ...journals
                .filter(
                  (j) =>
                    j.reconciliationId && !parsedJournals.some(([parsed]) => parsed.id === j.id)
                )
                .map((x) => JSON.stringify(x)),
            ],
          };
        },
      },
    });

const states = ['waiting', 'starting', 'progress', 'ending', 'done'] as const;

export const batchProgressState = ({ done, progress, batchId }: BatchProgressFields) =>
  !batchId
    ? 'waiting'
    : done
      ? 'done'
      : progress <= 0
        ? 'starting'
        : progress >= 100
          ? 'ending'
          : 'progress';

export const progressMessageFor = ({
  title = 'task',
  ...stateLabels
}: {
  title?: string;
} & Partial<Record<(typeof states)[number], (b: BatchProgressFields) => ReactNode>>) => {
  const prevState = (state: (typeof states)[number]): null | (typeof states)[number] =>
    state ? (state in stateLabels ? state : prevState(states[states.indexOf(state) - 1])) : null;

  const defaultLabel = ({ batchId, done, progress }: BatchProgressFields) =>
    batchId
      ? `We're ${done ? 'done with' : 'preparing'} your ${title}, ${
          progress <= 0
            ? 'getting started...'
            : progress >= 100 && !done
              ? 'wrapping up'
              : `${progress}% done`
        }`
      : `Waiting for ${title} to start...`;

  return (batchProgress: BatchProgressFields) => {
    const state = batchProgressState(batchProgress);
    const prev = prevState(state);

    const getLabel = stateLabels
      ? state in stateLabels
        ? stateLabels[state]
        : prev && prev in stateLabels
          ? stateLabels[prev]
          : null
      : defaultLabel;

    return getLabel?.(batchProgress) ?? defaultLabel(batchProgress);
  };
};

export const isFiniteDate = <TDate extends string>(
  isoDate?: TDate
): isoDate is Exclude<TDate, typeof maxISODate> =>
  isoDate
    ? DateTime.fromISO(isoDate).isValid && isoDate.slice(0, 10) !== maxISODate.slice(0, 10)
    : false;

export const payeeLink = ({ payee, payeeId }: { payee?: PayeeType | string; payeeId?: string }) =>
  payee === PayeeType.Account
    ? '/account'
    : payee === PayeeType.Owner
      ? `/owners/${payeeId}`
      : payee === PayeeType.Operator
        ? `/operators/${payeeId}`
        : payee === PayeeType.Tenant
          ? `/tenants/${payeeId}`
          : payee === PayeeType.Supplier
            ? `/suppliers/${payeeId}`
            : undefined;

export const getPropertyUnitName = ({
  propertyName,
  propertyKey,
  buildingName,
  buildingKey,
  unitName,
}: Partial<{
  propertyName: string;
  propertyKey: string;
  buildingName: string;
  buildingKey: string;
  unitName: string;
}>) => {
  const unitDescription = unitName ? ` - ${unitName}` : '';
  const buildingDescription = buildingName
    ? buildingKey
      ? ` ${buildingName} [${buildingKey}]`
      : ` ${buildingName}`
    : '';
  const propertyDescription = propertyKey ? `(${propertyKey}) ${propertyName}` : propertyName;

  return `${propertyDescription}${buildingDescription}${unitDescription}`;
};

export const tryJsonata = (
  expression: string,
  input?: Parameters<Expression['evaluate']>[0],
  bindings?: Parameters<Expression['evaluate']>[1]
) => {
  try {
    return jsonata(expression).evaluate(input ?? {}, bindings) as unknown;
  } catch (e) {
    console.warn({
      jsonata: e,
      context: JSON.stringify({ expression, input: input as unknown, bindings }),
    });

    return undefined;
  }
};

export const subAccountHolder = ({
  ownerId,
  propertyId,
}: {
  ownerId: string;
  propertyId: string;
}) => [ownerId, propertyId].join('#');

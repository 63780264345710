/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerStatsAccountTrueUpAction } from './trueUp';
import ManagerStatsAccountTrueUpActionSchema from './trueUp.json';

export const isManagerStatsAccountTrueUpAction = guard<ManagerStatsAccountTrueUpAction>(ManagerStatsAccountTrueUpActionSchema as any);
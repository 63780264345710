import { FileCopy as _FileCopy } from '@mui/icons-material';
import { IconButton as _IconButton, styled, Typography, TypographyProps } from '@mui/material';
import { useNotification } from 'hooks/useNotification';
import { MouseEvent, useState } from 'react';
import { skipProps } from 'system';

const IconButton = styled(
  _IconButton,
  skipProps('visible')
)<{ visible?: boolean }>(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
}));

const FileCopy = styled(_FileCopy)(({ theme }) => ({
  marginLeft: 1,
  cursor: 'pointer',
  verticalAlign: 'middle',
  color: theme.palette.text.secondary,
}));

type CopyToClipboardProps = {
  textToCopy: string;
  displayText?: string;
  notificationMessage?: string;
} & TypographyProps;

export default function CopyToClipboard({
  textToCopy,
  displayText,
  notificationMessage,
  ...typographyProps
}: CopyToClipboardProps) {
  const { sendNotification } = useNotification();
  const [showCopy, setShowCopy] = useState(false);

  const copyTextToClipboard = async (e: MouseEvent<HTMLButtonElement>) => {
    if (showCopy) {
      e.stopPropagation();

      navigator.clipboard.writeText(textToCopy);
      sendNotification(notificationMessage ?? 'Copied to clipboard', 'success');
    }
  };

  return displayText || textToCopy ? (
    <Typography
      variant="body2"
      lineHeight={1.25}
      {...{
        ...typographyProps,
        onMouseEnter: () => setShowCopy(true),
        onMouseLeave: () => setShowCopy(false),
      }}
    >
      {displayText ?? textToCopy}

      <IconButton
        aria-label="copy email to clipboard"
        onClick={copyTextToClipboard}
        size="small"
        visible={showCopy}
      >
        <FileCopy fontSize="inherit" />
      </IconButton>
    </Typography>
  ) : (
    <></>
  );
}

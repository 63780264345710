import { Button as _Button, ButtonProps, CircularProgress, styled } from '@mui/material';
import { Tooltip } from './Tooltip';

export type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
  hideProgress?: boolean;
  disabledTooltip?: string;
};

const Button = styled(_Button)(({ size }) => ({
  fontWeight: 500,
  fontSize: size === 'small' ? 12 : 14,
}));

export default function LoadingButton({
  loading = false,
  children,
  disabled,
  hideProgress,
  disabledTooltip,
  ...props
}: LoadingButtonProps) {
  return disabledTooltip ? (
    <Tooltip title={disabledTooltip}>
      <Button {...props} disabled={disabled || loading} disableElevation>
        {loading && !hideProgress ? <CircularProgress size={24} /> : children}
      </Button>
    </Tooltip>
  ) : (
    <Button {...props} disabled={disabled || loading} disableElevation>
      {loading && !hideProgress ? <CircularProgress size={24} /> : children}
    </Button>
  );
}

import { CreateChargeInput, useCreateChargeMutation, useDeleteChargeMutation } from 'api';
import { useMeta } from 'hooks/useMeta';

export const useCharge = () => {
  const [createChargeMutation, createChargeMeta] = useCreateChargeMutation();
  const [deleteChargeMutation, deleteChargeMeta] = useDeleteChargeMutation();
  const { loading } = useMeta(createChargeMeta, deleteChargeMeta);

  const createCharge = async (args: CreateChargeInput) => {
    await createChargeMutation({ variables: { input: args } });
  };

  const deleteCharge = async (chargeId: string) => {
    const result = await deleteChargeMutation({ variables: { id: chargeId } });
    return result.data?.deleteCharge?.success;
  };

  return { loading, createCharge, deleteCharge };
};

import { InMemoryCache, makeVar, useApolloClient } from '@apollo/client';
import { managerAccountTierChangedEvent } from '@propra-manager/shapes/account/managerAccountTierChangedEvent';
import { GuardHandler, HandlerMap, handleRecord } from '@propra-system/registry';
import { executeHandlers, shapeHandler } from '@propra-system/shape-handler';
import { AccountEvent } from 'api';
import { useAuth, useAuthDispatch } from 'context/auth';
import { updateUser } from 'context/auth/actions';
import { keyBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { hashCode, shallowOmit } from 'system';
import { z } from 'zod';
import { cacheHandlers } from './cacheHandlers';
import { cacheShapeHandlers } from './cacheShapeHandlers';

const handledEvents = makeVar<Set<string>>(new Set());

export const useEventHandlers = () => {
  const dispatch = useAuthDispatch();
  const { cache } = useApolloClient();
  const { accountId } = useAuth();
  const defaultHandlers = cacheHandlers(cache as InMemoryCache, accountId);
  const defaultShapeHandlers = cacheShapeHandlers(cache as InMemoryCache, accountId);

  const [hashedHandlerMap, setHashedHandlerMap] = useState<Record<number, GuardHandler>>(
    keyBy(defaultHandlers, (guardHandler) => hashCode(guardHandler.map((fn) => fn.toString())))
  );
  const handlerMap = useMemo(() => Object.values(hashedHandlerMap), [hashedHandlerMap]);
  const hashMap = useMemo(
    () => Object.keys(hashedHandlerMap).map((d) => parseInt(d)),
    [hashedHandlerMap]
  );

  const removeHandler = useCallback((guardHandler: GuardHandler) => {
    const hash = hashCode(guardHandler.map((fn) => fn.toString()));
    setHashedHandlerMap((existing) => shallowOmit(existing, [hash]));
  }, []);

  const removeHandlerMap = useCallback(
    (map: HandlerMap) => map.forEach(removeHandler),
    [removeHandler]
  );

  const addHandler = useCallback((guardHandler: GuardHandler) => {
    const hash = hashCode(guardHandler.map((fn) => fn.toString()));
    setHashedHandlerMap((existing) => ({ ...existing, [hash]: guardHandler }));
  }, []);

  const addHandlerMap = useCallback((map: HandlerMap) => map.forEach(addHandler), [addHandler]);

  const handleEvent = useCallback(
    (event?: AccountEvent) => {
      if (!event) {
        console.warn('Event already handled', event);
        return;
      }

      const handled = handledEvents();
      if (handled.has(event.id)) return;
      handledEvents(new Set([...handled, event.id]));

      if (event.detailType) {
        const parsedEvent = {
          ...event,
          'detail-type': event.detailType,
          detail: JSON.parse(event.detail || '{}'),
        };

        executeHandlers(
          shapeHandler(managerAccountTierChangedEvent, async () => {
            setTimeout(async () => await updateUser(dispatch), 5000);
          }),
          ...defaultShapeHandlers,
          shapeHandler(z.unknown().describe('catch all'), () => Promise.resolve())
        )(parsedEvent);

        handlerMap.some(([p]) => p(parsedEvent)) &&
          handleRecord(handlerMap, parsedEvent, undefined, { errorOnNoMatch: false });
      }
    },
    [defaultShapeHandlers, dispatch, handlerMap]
  );

  return {
    handleEvent,
    addHandler,
    addHandlerMap,
    removeHandler,
    removeHandlerMap,
    hashMap,
    handlerMap,
  };
};

import { ContactRelationship } from 'api';
import { DateTime } from 'luxon';
import {
  array,
  boolean,
  BooleanSchema,
  mixed,
  number,
  NumberSchema,
  object,
  ObjectSchema,
  SchemaOf,
  string,
  StringSchema,
} from 'yup';
import { OptionalArraySchema } from 'yup/lib/array';
import Lazy from 'yup/lib/Lazy';
import { MixedSchema } from 'yup/lib/mixed';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { coalesceTo } from './shapes';
import { Country, EmergencyContactFields, FullAddress, ProvincesByCountry } from './types';
import { parseDates } from './util';
import { validPhone } from './validate';
import { z } from 'zod';

export const nullableBoolean = () =>
  boolean()
    .nullable()
    .notRequired()
    .transform((bool) => bool ?? undefined) as BooleanSchema<boolean | undefined>;

export const nullableString = () =>
  string()
    .nullable()
    .notRequired()
    .transform((str) => str ?? undefined) as StringSchema<string | undefined>;

export const requiredString = (msg?: string) => string().required(msg) as StringSchema<string>;

export const requiredDateString = (message?: string) =>
  requiredString()
    .transform((_, v) => {
      const [parsedDate] = parseDates(v);
      return parsedDate.isValid ? parsedDate.toISODate() : undefined;
    })
    .test(
      'isValidDate',
      message ?? 'Invalid Date',
      (v) => DateTime.fromISO(v ?? 'invalid').isValid
    );

export const nullableDateString = (message?: string) =>
  nullableString()
    .transform((_, v) => {
      const [parsedDate] = parseDates(v);
      return parsedDate.isValid ? parsedDate.toISODate() : undefined;
    })
    .test(
      'isValidDate',
      message ?? 'Invalid Date',
      (v, ctx) =>
        DateTime.fromISO(v ?? 'invalid').isValid || ctx.schema.spec.presence !== 'required'
    );

export const nullableNumber = () =>
  number().nullable().notRequired().transform(coalesceTo(undefined)) as NumberSchema<
    number | undefined
  >;

export const requiredNumber = (msg?: string) => number().required(msg) as NumberSchema<number>;

export const nullableMixed = <T>() =>
  mixed<T>().nullable().notRequired().transform(coalesceTo(undefined)) as MixedSchema<
    T | undefined
  >;

export const requiredMixed = <T>(msg?: string) => mixed<T>().required(msg) as MixedSchema<T>;

export const nullableArray = <T extends Record<string, unknown> = Record<string, unknown>>(
  shape?: ObjectSchema<ObjectShape, T>
) =>
  array(shape).nullable().notRequired().transform(coalesceTo(undefined)) as OptionalArraySchema<
    ObjectSchema<ObjectShape, T> | Lazy<ObjectSchema<ObjectShape, T>>,
    T
  >;

export const nullableObject = <TShape extends ObjectShape = ObjectShape>(shape: TShape) =>
  object()
    .shape(shape)
    .nullable()
    .notRequired()
    .default(undefined)
    .transform(coalesceTo(undefined)) as OptionalObjectSchema<TShape>;

export const addressSchema = (): SchemaOf<FullAddress> =>
  object().shape({
    suite: nullableString().label('Suite'),
    street: nullableString().label('Street'),
    city: nullableString().label('City'),
    lat: nullableNumber().label('Latitude'),
    lng: nullableNumber().label('Longitude'),
    timezone: nullableString().label('Timezone'),
    country: nullableString().label('Country').oneOf(Object.values(Country)),
    postal: nullableString().when('country', {
      is: Country.US,
      then: (s) => s.label('Zip Code'),
      otherwise: (s) => s.label('Postal Code'),
    }),
    province: nullableString().when('country', {
      is: Country.US,
      then: (s) => s.label('State').oneOf(Object.values(ProvincesByCountry.US)),
      otherwise: (s) => s.label('Province').oneOf(Object.values(ProvincesByCountry.CA)),
    }),
  });

export const emergencyContactSchema = (): SchemaOf<EmergencyContactFields> =>
  object({
    name: string().required('Name is required').max(200, 'Max 200 characters allowed'),
    relationship: nullableMixed<ContactRelationship>()
      .label('Relationship')
      .oneOf(Object.values(ContactRelationship)),
    phone: string()
      .required('Phone is required')
      .test('phone', 'Emergency Contact Phone must be a valid phone number', function (value) {
        return validPhone(value ?? '');
      }),
  });

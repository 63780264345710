import { Clear } from '@mui/icons-material';
import {
  filledInputClasses,
  IconButton,
  InputAdornment,
  outlinedInputClasses,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { spreadIf } from 'system';

const numberFormatSx: TextFieldProps['sx'] = {
  ['& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button']:
    {
      display: 'none',
    },
  [`& .${outlinedInputClasses.notchedOutline} legend, & .${filledInputClasses.input} legend`]: {
    visibility: 'inherit',
  },
};

export type PercentFieldProps = Omit<TextFieldProps, 'value' | 'ref' | 'defaultValue'> & {
  onClear?: () => void;
  onValueChange?: NumberFormatProps['onValueChange'];
  value?: NumberFormatProps['value'];
  decimalScale?: NumberFormatProps['decimalScale'];
};

export const PercentField = forwardRef<NumberFormat<TextFieldProps>, PercentFieldProps>(
  (
    { onClear, type: _type, variant, onValueChange, decimalScale, ...props }: PercentFieldProps,
    ref
  ) => {
    return (
      <NumberFormat<TextFieldProps>
        ref={ref}
        isNumericString
        fullWidth
        decimalScale={decimalScale}
        customInput={TextField}
        variant={variant ?? 'filled'}
        allowNegative={false}
        isAllowed={(vals) => (vals.floatValue !== undefined ? vals.floatValue <= 100 : false)}
        sx={numberFormatSx}
        onValueChange={(vals, sourceInfo) => {
          sourceInfo.source === 'event' && onValueChange?.(vals, sourceInfo);
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          ...spreadIf(onClear, {
            endAdornment: (
              <IconButton sx={{ visibility: props.value ? 'visible' : 'hidden' }} onClick={onClear}>
                <Clear />
              </IconButton>
            ),
          }),
        }}
        {...props}
      />
    );
  }
);

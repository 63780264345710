import { useTaxesPropertiesQuery } from 'api';
import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Provinces, ensureArray } from 'system';
import { useAllErrors } from './useErrorNotifications';
import { useGetProvinceTaxDetails } from './useGetProvinceTaxDetails';
import { usePaginateAllQuery } from './usePaginateAllQuery';

export function useTaxes() {
  const { getTaxDetails } = useGetProvinceTaxDetails();
  const { data, error } = usePaginateAllQuery(useTaxesPropertiesQuery, {
    getNextToken: (d) => d?.account?.listProperties?.nextToken,
  });
  useAllErrors(error);

  const properties = useMemo(
    () => keyBy([data?.account, ...ensureArray(data?.account?.listProperties?.items)], 'id'),
    [data?.account]
  );

  const shouldApplyPst = useCallback(
    (propertyId?: string) =>
      propertyId ? properties[propertyId]?.address?.province === Provinces.SK : false,
    [properties]
  );

  const accountAppliesPst = useMemo(
    () =>
      shouldApplyPst(data?.account?.id) ||
      data?.account?.listProperties?.items?.some(({ id }) => shouldApplyPst(id)),
    [data?.account, shouldApplyPst]
  );

  const getTaxesPct = useCallback(
    async (propertyId?: string) =>
      await getTaxDetails({
        province: properties[propertyId ?? '']?.address?.province,
      }),
    [getTaxDetails, properties]
  );

  const getGstPct = useCallback(
    async (propertyId?: string) => {
      const taxes = await getTaxDetails({
        province: properties[propertyId ?? '']?.address?.province,
      });
      return taxes?.find((tax) => tax.gst)?.rate;
    },
    [getTaxDetails, properties]
  );

  return {
    shouldApplyPst,
    accountAppliesPst,
    getGstPct,
    getTaxesPct,
  };
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEndUpdatedEvent } from './endUpdated';
import ManagerResidencyEndUpdatedEventSchema from './endUpdated.json';

export const isManagerResidencyEndUpdatedEvent = guard<ManagerResidencyEndUpdatedEvent>(ManagerResidencyEndUpdatedEventSchema as any);
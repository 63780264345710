/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksBatchFailedError } from './batchFailed';
import ManagerBooksBatchFailedErrorSchema from './batchFailed.json';

export const isManagerBooksBatchFailedError = guard<ManagerBooksBatchFailedError>(ManagerBooksBatchFailedErrorSchema as any);
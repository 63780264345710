/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerPropertiesAutopayActivateAction } from './activateAction';
import ManagerPropertiesAutopayActivateActionSchema from './activateAction.json';

export const isManagerPropertiesAutopayActivateAction = guard<ManagerPropertiesAutopayActivateAction>(ManagerPropertiesAutopayActivateActionSchema as any);
import { ListItemButton as _ListItemButton, styled } from '@mui/material';
import { skipProps, spreadIf } from 'system';

export const ListItemButton = styled(
  _ListItemButton,
  skipProps('isActive')
)<{ isActive?: boolean }>(({ theme, isActive }) => ({
  borderLeft: `3px solid ${theme.palette.background.paper}`,
  paddingLeft: theme.spacing(3),
  marginTop: theme.spacing(0.5),
  overflow: 'hidden',
  ...spreadIf(isActive, {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
  }),
}));

import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export default function AddPhoto({ ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 23 21" {...props}>
      <path
        d="M3 3V0H5V3H8V5H5V8H3V5H0V3H3ZM6 9V6H9V3H16L17.83 5H21C22.1 5 23 5.9 23 7V19C23 20.1 22.1 21 21 21H5C3.9 21 3 20.1 3 19V9H6ZM13 18C15.76 18 18 15.76 18 13C18 10.24 15.76 8 13 8C10.24 8 8 10.24 8 13C8 15.76 10.24 18 13 18ZM9.8 13C9.8 14.77 11.23 16.2 13 16.2C14.77 16.2 16.2 14.77 16.2 13C16.2 11.23 14.77 9.8 13 9.8C11.23 9.8 9.8 11.23 9.8 13Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerCampaignShareRequestedEvent } from './shareRequested';
import ManagerCampaignShareRequestedEventSchema from './shareRequested.json';

export const isManagerCampaignShareRequestedEvent = guard<ManagerCampaignShareRequestedEvent>(ManagerCampaignShareRequestedEventSchema as any);
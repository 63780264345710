import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';
import { Box, IconButton as IB, styled, Typography } from '@mui/material';

export type TristateCellProps = {
  id: string;
  name?: string;
  onChange?: (args: { id: string; newState: Tristate }) => void;
  value: Tristate;
  disabled?: boolean;
};

export type Tristate = 'selected' | 'deselected' | 'unavailable';

const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const IconButton = styled(IB)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginRight: theme.spacing(0.5),
  },
}));

const Name = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '1em',
  fontWeight: 500,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.8em',
  },
}));

export default function TristateCell({ id, name, onChange, disabled, value }: TristateCellProps) {
  return (
    <Root>
      <IconButton
        onClick={() =>
          onChange?.({ id, newState: value === 'selected' ? 'deselected' : 'selected' })
        }
        disabled={disabled || value === 'unavailable'}
        size="large"
      >
        {value === 'unavailable' ? (
          <IndeterminateCheckBox fontSize="small" />
        ) : value === 'selected' ? (
          <CheckBox fontSize="small" {...(!disabled && { color: 'primary' })} />
        ) : (
          <CheckBoxOutlineBlank fontSize="small" {...(!disabled && { color: 'primary' })} />
        )}
      </IconButton>
      {name && <Name>{name}</Name>}
    </Root>
  );
}

import { Grid } from '@mui/material';
import { Children } from 'system';
import SiteNav from './SiteNav';

export default function DefaultFrame({ children }: Children) {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: 'calc(100vh - 73px)' }} // 73px is the height of the SiteNav
    >
      <SiteNav />
      {children}
    </Grid>
  );
}

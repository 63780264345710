import { Autocomplete, Grid, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const isDateValid = (
  date?: DateTime,
  { disablePast, disableFuture }: { disablePast?: boolean; disableFuture?: boolean } = {}
) =>
  !date ||
  (date.isValid &&
    (!disablePast || date > DateTime.now()) &&
    (!disableFuture || date < DateTime.now()));

export default function DateTimePickers({
  selectedAppointment,
  setSelectedAppointment,
  allAppointments,
  className,
  datePickerProps,
  disabled = false,
}: {
  className?: string;
  selectedAppointment?: DateTime;
  setSelectedAppointment: (appointment?: DateTime) => void;
  allAppointments: DateTime[];
  datePickerProps?: Partial<Parameters<typeof DesktopDatePicker>[0]>;
  disabled?: boolean;
}) {
  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(selectedAppointment);
  const [selectedTime, setSelectedTime] = useState<DateTime | undefined>(selectedAppointment);
  const [availableTimes, setAvailableAppointmentTimes] = useState<DateTime[]>([]);

  useEffect(() => {
    setSelectedTime(selectedAppointment);
    if (selectedAppointment) setSelectedDate(selectedAppointment);
  }, [selectedAppointment]);

  useEffect(() => {
    const availableTimesForDate = selectedDate
      ? allAppointments.filter((d) => d.hasSame(selectedDate, 'day'))
      : [];

    setAvailableAppointmentTimes(availableTimesForDate);

    if (selectedTime && selectedDate && !selectedDate?.hasSame(selectedTime, 'day')) {
      const diffToSelectedTime = selectedTime.diff(selectedTime.startOf('day'));
      const selectedTimeInDate = selectedDate.startOf('day').plus(diffToSelectedTime);

      const matchingTimeInDate = availableTimesForDate.find((day) =>
        day.equals(selectedTimeInDate)
      );

      setSelectedAppointment(matchingTimeInDate ?? availableTimesForDate[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAppointments, selectedDate]);

  return (
    <Grid
      container
      spacing={2}
      alignItems={'center'}
      sx={{ position: 'relative' }}
      className={clsx(className)}
    >
      <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DesktopDatePicker
            disabled={disabled}
            {...datePickerProps}
            value={selectedDate}
            onChange={(date) => setSelectedDate(date as DateTime)}
            format="yyyy-MM-dd"
            label="Date"
            slotProps={{
              textField: {
                type: 'text',
                variant: 'filled',
                disabled,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={selectedTime ? selectedTime : null}
          inputValue={selectedTime ? selectedTime.toLocaleString(DateTime.TIME_SIMPLE) : ''}
          disabled={disabled}
          onChange={(event, newValue) => event && setSelectedAppointment(newValue ?? undefined)}
          groupBy={(appointment) =>
            appointment.startOf('day').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
          }
          options={availableTimes}
          getOptionLabel={(appointment) => appointment.toLocaleString(DateTime.TIME_SIMPLE)}
          isOptionEqualToValue={(option, value) => +option === +value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Time"
              variant="filled"
              error={!isDateValid(selectedDate, datePickerProps)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

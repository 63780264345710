import { ListItemIcon, Menu, MenuItem, MenuProps } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { Note } from 'api';

export default function NoteCardMenu({
  onDelete,
  onClose,
  open,
  note,
  loading,
  anchorEl,
}: {
  onDelete: (note: Note) => void;
  onClose: VoidFunction;
  open: boolean;
  note: Note;
  loading: boolean;
  anchorEl: MenuProps['anchorEl'];
}) {
  return (
    <Menu keepMounted {...{ anchorEl, open, onClose }}>
      <MenuItem disabled={loading} onClick={() => onDelete(note)}>
        <ListItemIcon>
          <DeleteForever fontSize="small" />
        </ListItemIcon>
        Delete
      </MenuItem>
    </Menu>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitUpdatedChecklistEvent } from './updatedChecklist';
import OperatorVisitUpdatedChecklistEventSchema from './updatedChecklist.json';

export const isOperatorVisitUpdatedChecklistEvent = guard<OperatorVisitUpdatedChecklistEvent>(OperatorVisitUpdatedChecklistEventSchema as any);
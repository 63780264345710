import { RequestListFieldsFragment, useGetRequestHeaderQuery } from 'api';
import { useEffect, useState } from 'react';
import { useErrorNotifications } from './useErrorNotifications';

export const useRequestHeader = (id: string) => {
  const { data, loading, error } = useGetRequestHeaderQuery({ variables: { id } });
  const [request, setRequest] = useState<RequestListFieldsFragment>();
  useErrorNotifications(error);

  useEffect(() => {
    setRequest(data?.request);
  }, [data]);

  return { error, loading, request };
};

/* eslint-disable @typescript-eslint/no-explicit-any */

import { MutationTuple } from '@apollo/client';
import {
  Note,
  useCreateApplicationNoteMutation,
  useCreateBuildingNoteMutation,
  useCreateImageNoteMutation,
  useCreateJournalEntryNoteMutation,
  useCreateOwnerNoteMutation,
  useCreatePropertyNoteMutation,
  useCreateRequestNoteMutation,
  useCreateResidencyNoteMutation,
  useCreateTenantNoteMutation,
  useCreateUnitNoteMutation,
  useDeleteApplicationNoteMutation,
  useDeleteBuildingNoteMutation,
  useDeleteImageNoteMutation,
  useDeleteJournalEntryNoteMutation,
  useDeleteOwnerNoteMutation,
  useDeletePropertyNoteMutation,
  useDeleteRequestNoteMutation,
  useDeleteResidencyNoteMutation,
  useDeleteTenantNoteMutation,
  useDeleteUnitNoteMutation,
} from 'api';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useState } from 'react';
import { NoteType } from 'system';

type MutationHook<TData = any, TVariables = any> = () => MutationTuple<TData, TVariables>;

const useNoteWithType = (
  type: NoteType,
  useAddMutation: MutationHook,
  useDeleteMutation: MutationHook,
  id?: string,
  addInput?: Record<string, unknown>,
  removeInput?: Record<string, unknown>
) => {
  const [addNoteMutation, { loading: addLoading, error: apiAddError }] = useAddMutation();
  const [deleteNoteMutation, { loading: deleteLoading, error: apiDeleteError }] =
    useDeleteMutation();
  useErrorNotifications(apiAddError);
  useErrorNotifications(apiDeleteError);
  const [text, setText] = useState('');

  const handleAdd = async () => {
    if (id) {
      await addNoteMutation(
        addInput
          ? { variables: { input: { ...addInput, text } } }
          : { variables: { [`${type}Id`]: id, text } }
      );
      if (!apiAddError) {
        setText('');
      }
    } else {
      console.error(`Unable to save note, ${type} not yet loaded`);
    }
  };

  const handleDelete = async ({ id: noteId }: Note) => {
    if (id) {
      await deleteNoteMutation(
        removeInput
          ? { variables: { input: { ...removeInput, noteId } } }
          : { variables: { [`${type}Id`]: id, noteId } }
      );
    } else {
      console.error(`Unable to delete note, ${type} not yet loaded`);
    }
  };

  return {
    text,
    setText,
    handleAdd,
    addLoading,
    deleteLoading,
    handleDelete,
  };
};

export const useNotes = (
  type: NoteType,
  id?: string,
  addInput?: Record<string, unknown>,
  removeInput?: Record<string, unknown>
) => {
  const [createNoteMutation, deleteNoteMutation] =
    type === NoteType.UNIT
      ? [useCreateUnitNoteMutation, useDeleteUnitNoteMutation]
      : type === NoteType.REQUEST
        ? [useCreateRequestNoteMutation, useDeleteRequestNoteMutation]
        : type === NoteType.PROPERTY
          ? [useCreatePropertyNoteMutation, useDeletePropertyNoteMutation]
          : type === NoteType.IMAGE
            ? [useCreateImageNoteMutation, useDeleteImageNoteMutation]
            : type === NoteType.JOURNAL_ENTRY
              ? [useCreateJournalEntryNoteMutation, useDeleteJournalEntryNoteMutation]
              : type === NoteType.OWNER
                ? [useCreateOwnerNoteMutation, useDeleteOwnerNoteMutation]
                : type === NoteType.RESIDENCY
                  ? [useCreateResidencyNoteMutation, useDeleteResidencyNoteMutation]
                  : type === NoteType.BUILDING
                    ? [useCreateBuildingNoteMutation, useDeleteBuildingNoteMutation]
                    : type === NoteType.TENANT
                      ? [useCreateTenantNoteMutation, useDeleteTenantNoteMutation]
                      : type === NoteType.CAMPAIGN_APPLICATION
                        ? [useCreateApplicationNoteMutation, useDeleteApplicationNoteMutation]
                        : [];

  if (!createNoteMutation || !deleteNoteMutation) {
    throw new Error(`Note type not handled: "${type}"`);
  }

  return useNoteWithType(type, createNoteMutation, deleteNoteMutation, id, addInput, removeInput);
};

import { ApolloLink, Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createAppSyncHttpLink } from './appSyncHttpLink';
import {
  createAppSyncSubscriptionWebsocketLink,
  StatusChangeHandler,
} from './appSyncSubscriptionWebSocketLink';

export const createAppSyncHybridLink = ({
  appSyncApiUrl,
  getJwtToken,
  onStatusChange,
}: {
  appSyncApiUrl: string;
  getJwtToken: () => string;
  onStatusChange?: StatusChangeHandler;
}) =>
  ApolloLink.split(
    isSubscriptionOperation,
    createAppSyncSubscriptionWebsocketLink({ appSyncApiUrl, getJwtToken, onStatusChange }),
    createAppSyncHttpLink({ appSyncApiUrl, getJwtToken })
  );

const isSubscriptionOperation = ({ query }: Operation) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

import { CloudDownload, Delete, Edit, Share } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { Document } from 'api';
import bytes from 'bytes';
import { AutoSizeBox } from 'components/AutoSizeBox';
import { usePageSize } from 'hooks/usePageSize';
import { compact, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { GeneratedDocument, ensureArray } from 'system';
import { DocumentCell } from './DocumentCell';

type KeyAction = (key: string) => void;

type DocumentTableProps = {
  documents: Document[];
  loading: boolean;
  downloadFn?: KeyAction;
  renameFn?: (document: Document) => void;
  deleteFn?: KeyAction;
  shareFn?: (args: {
    document: Document;
    shareDestinations: string[];
    recipientId?: string;
    recipientType: string;
  }) => Promise<void>;
  disabled?: boolean;
  generatedDocuments?: GeneratedDocument[];
  shareActions?: (document: Document) => {
    recipientId?: string;
    recipientType: string;
    recipientLabel?: string;
    shareDestinations: string[];
    disabled?: boolean;
  }[];
  getExtraActions?: (key: string) => {
    id: string;
    onAction: (document: GeneratedDocument) => void;
    icon?: JSX.Element;
    actionName: string;
  }[];
  isDocumentEditable?: (document: Document) => boolean;
  isDocumentDeletable?: (document: Document) => boolean;
  onRowClick?: (document: Document) => void;
};

export function DocumentTable({
  documents,
  downloadFn,
  renameFn,
  deleteFn,
  shareFn,
  shareActions,
  getExtraActions,
  generatedDocuments,
  disabled,
  loading,
  isDocumentEditable,
  isDocumentDeletable,
  onRowClick,
}: DocumentTableProps) {
  const keyToShareAction = (key: string, recipient: string, matchers: string[]): string =>
    [
      matchers.some((matcher) => new RegExp(`${matcher}`).test(key)) ? 'Unshare' : 'Share',
      'with',
      recipient,
    ].join(' ');

  const rows = useMemo(
    () => [
      ...ensureArray(generatedDocuments).map((document) => ({
        id: document.key,
        document: {
          name: document.name ?? document.key,
          ...{ ...(document.size ? { size: bytes(document.size, { unitSeparator: ' ' }) } : {}) },
        },
        created: DateTime.fromISO(document.createdZ).toJSDate(),
        menu: [
          {
            id: 'download',
            onAction: document.downloadFn,
            icon: <CloudDownload />,
            actionName: 'Download',
          },
          ...ensureArray(getExtraActions?.(document.key)).map(({ onAction, ...act }) => ({
            ...act,
            onAction: () => onAction?.(document),
          })),
        ],
        downloadFn: document.downloadFn,
      })),
      ...documents.map((document: Document) => ({
        id: document.key,
        document: {
          name: document.name ?? document.key,
          subtext: document.name ? `(${document.key})` : undefined,
          size: document.size ? bytes(document.size, { unitSeparator: ' ' }) : undefined,
        },
        created: DateTime.fromISO(document.createdZ).toJSDate(),
        menu: [
          downloadFn && {
            id: 'download',
            onAction: () => downloadFn?.(document?.key),
            icon: <CloudDownload />,
            actionName: 'Download',
          },
          ...uniqBy(
            shareActions?.(document).map(
              ({ recipientId, recipientType, recipientLabel, shareDestinations, disabled }) => ({
                id: recipientId ?? '',
                onAction: () =>
                  !disabled &&
                  shareFn?.({ document, recipientId, recipientType, shareDestinations }),
                icon: <Share />,
                actionName: keyToShareAction(document.key, recipientLabel ?? recipientType, [
                  `${recipientType}${recipientId}`,
                  'shared/',
                ]),
                disabled,
              })
            ),
            'id'
          ),
          ...ensureArray(getExtraActions?.(document.key)).map(({ onAction, ...act }) => ({
            ...act,
            // TODO: fix this type
            onAction: () => onAction?.(document as unknown as GeneratedDocument),
          })),
          renameFn &&
            (isDocumentEditable?.(document) ?? true) && {
              id: 'rename',
              onAction: () => renameFn(document),
              icon: <Edit />,
              actionName: 'Rename',
              disabled,
            },
          deleteFn &&
            (isDocumentDeletable?.(document) ?? true) && {
              id: 'delete',
              onAction: () => deleteFn(document.key),
              icon: <Delete />,
              actionName: 'Delete',
              disabled,
            },
        ],
      })),
    ],
    [
      documents,
      generatedDocuments,
      getExtraActions,
      shareActions,
      shareFn,
      downloadFn,
      renameFn,
      deleteFn,
      disabled,
    ]
  );

  const columns: Array<GridColDef<(typeof rows)[0]>> = [
    {
      flex: 2,
      field: 'id',
      headerName: 'Name',
      renderCell({ row }) {
        const { document } = row;
        return <DocumentCell {...document} />;
      },
    },
    {
      width: 175,
      field: 'created',
      type: 'date',
      valueFormatter: ({ value }) =>
        DateTime.fromJSDate(value).toLocaleString(DateTime.DATETIME_SHORT),
      headerName: 'Date Added',
    },
    {
      width: 40,
      field: 'actions',
      type: 'actions',
      headerName: '',
      getActions: ({ row }) =>
        compact(row.menu).map((m) => (
          <GridActionsCellItem
            showInMenu
            key={m.id}
            icon={m.icon}
            label={m.actionName}
            onClick={m.onAction}
            disabled={'disabled' in m ? m.disabled : false}
          />
        )),
    },
  ];

  return (
    <AutoSizeBox>
      <DataGridPro
        {...usePageSize()}
        getRowHeight={() => 'auto'}
        sx={{
          [`&.${gridClasses['root--densityCompact']} .${gridClasses.cell}`]: { py: '8px' },
          [`&.${gridClasses['root--densityStandard']} .${gridClasses.cell}`]: { py: '15px' },
          [`&.${gridClasses['root--densityComfortable']} .${gridClasses.cell}`]: { py: '22px' },
        }}
        disableRowSelectionOnClick
        rows={rows}
        loading={loading}
        columns={columns}
        density="comfortable"
        slots={{
          noResultsOverlay: () => <Alert severity="info">There are no documents to show.</Alert>,
        }}
        onRowClick={
          onRowClick ? ({ row }) => onRowClick({ ...row, ...row.document, key: row.id }) : undefined
        }
      />
    </AutoSizeBox>
  );
}

import { styled, Typography } from '@mui/material';
import { LeaseStatus, useGetAccountSettingsQuery } from 'api';
import { DateTime } from 'luxon';
import { skipProps } from 'system';

const SOON_DAYS_THRESHOLD_DEFAULT = 60;

const Text = styled(
  Typography,
  skipProps('kind')
)<{ kind?: 'warning' | 'error' }>(({ theme, kind }) => ({
  fontSize: '1em',
  color: kind ? theme.palette[kind].main : undefined,
}));

type LeaseExpiringProps = {
  start?: string;
  end?: string;
  status?: string;
};

export default function LeaseExpiring({ start, end, status }: LeaseExpiringProps) {
  const { data } = useGetAccountSettingsQuery();

  const endDate = DateTime.fromISO(end ?? '');
  const startDate = DateTime.fromISO(start ?? '');
  const today = DateTime.local().startOf('day');

  const isMonthToMonth = startDate.isValid && !endDate.isValid;
  const isEmptyUnit = !startDate.isValid && !endDate.isValid;

  const isExpired = endDate <= today;
  const isTerminated = status === LeaseStatus.Terminated;

  const untilEnd = today.until(endDate);
  const soonDays = data?.account?.settings?.soon_days_threshold || SOON_DAYS_THRESHOLD_DEFAULT;
  const isSoon = untilEnd.length('days') <= soonDays;

  const isFutureLease = startDate > today;

  return isMonthToMonth ? (
    <>Month to Month lease</>
  ) : isEmptyUnit ? (
    <>Empty Unit</>
  ) : (
    <>
      <Text
        fontWeight="bold"
        kind={isSoon ? 'warning' : isExpired || isTerminated ? 'error' : undefined}
      >
        {isTerminated
          ? 'Terminated '
          : isExpired
            ? 'Expired '
            : isFutureLease
              ? 'Will start '
              : 'Expires '}
        {isFutureLease ? startDate.toRelativeCalendar() : endDate.toRelativeCalendar()}
      </Text>
      <Text>{endDate.toLocaleString(DateTime.DATE_FULL)}</Text>
    </>
  );
}

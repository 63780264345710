import { Auth } from '@aws-amplify/auth';
import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import { ManagerRole } from 'api';
import { isEqual } from 'lodash';
import { Dispatch } from 'react';
import { getRolesFrom } from './reducer';
import { AuthAction, AuthActions, CognitoResponse } from './types';

export async function login(
  dispatch: Dispatch<AuthAction>,
  loginPayload: { username: string; password: string }
): Promise<CognitoResponse | null> {
  try {
    dispatch({ type: AuthActions.REQUEST_LOGIN });
    const cognitoResponse: CognitoResponse = await Auth.signIn(
      loginPayload.username,
      loginPayload.password
    );

    if (cognitoResponse.challengeName === AuthActions.NEW_PASSWORD_REQUIRED) {
      dispatch({ type: AuthActions.NEW_PASSWORD_REQUIRED, payload: cognitoResponse });
    } else {
      const roles = getRolesFrom(cognitoResponse);
      const onlyInspectorRole = isEqual([ManagerRole.Inspector], roles);

      if (onlyInspectorRole) {
        dispatch({
          type: AuthActions.LOGIN_ERROR,
          error:
            'You are not allowed to access the manager portal. Please login using the mobile app.',
        });
      } else {
        dispatch({ type: AuthActions.LOGIN_SUCCESS, payload: cognitoResponse });
        Cache.setItem('currentUser', pruneCognitoResponse(cognitoResponse));
        return cognitoResponse;
      }
    }
  } catch (e) {
    const message = e instanceof Error ? e.message : JSON.stringify(e);
    dispatch({ type: AuthActions.LOGIN_ERROR, error: message });
  }
  return null;
}
export const pruneCognitoResponse = ({
  challengeName,
  username,
  signInUserSession,
}: CognitoResponse) => ({
  challengeName,
  username,
  signInUserSession,
});

export async function createPassword(
  dispatch: Dispatch<AuthAction>,
  { user, newPassword }: { user?: CognitoResponse; newPassword: string }
) {
  if (user) {
    try {
      const cognitoResponse: CognitoResponse = pruneCognitoResponse(
        await Auth.completeNewPassword(user, newPassword)
      );

      dispatch({ type: AuthActions.PASSWORD_CREATE_SUCCESS });
      dispatch({ type: AuthActions.LOGIN_SUCCESS, payload: cognitoResponse });
      Cache.setItem('currentUser', pruneCognitoResponse(cognitoResponse));
      return cognitoResponse;
    } catch (e) {
      const message = e instanceof Error ? e.message : JSON.stringify(e);
      dispatch({ type: AuthActions.LOGIN_ERROR, error: message });
    }
  } else {
    dispatch({
      type: AuthActions.LOGIN_ERROR,
      error: 'Unable to set password: missing user details',
    });
  }
}

export async function updateUser(dispatch: Dispatch<AuthAction>) {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();
  dispatch({ type: AuthActions.UPDATE_USER, payload: { idToken } });

  const localUserDetails: CognitoResponse = Cache.getItem('currentUser');
  const newLocalUserDetails: CognitoResponse = {
    ...localUserDetails,
    signInUserSession: {
      ...localUserDetails?.signInUserSession,
      idToken: {
        ...localUserDetails?.signInUserSession?.idToken,
        jwtToken: idToken,
      },
    },
  };
  Cache.setItem('currentUser', pruneCognitoResponse(newLocalUserDetails));
}

export async function logout(dispatch: Dispatch<AuthAction>) {
  dispatch({ type: AuthActions.REQUEST_LOGOUT });
  try {
    await Auth.signOut();

    dispatch({ type: AuthActions.LOGOUT_SUCCESS });
  } catch (e) {
    console.error(e);
  }
  Cache.removeItem('currentUser');
}

export const cacheWithAsyncRefresh = (
  asyncSupplier: () => { host: string; Authorization: string }
) => {
  let value: { host: string; Authorization: string };

  const asyncRefresh = () => (value = asyncSupplier());

  // Warm cache
  asyncRefresh();

  return () => {
    asyncRefresh();
    return value;
  };
};

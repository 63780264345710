import { FormControlLabel, Switch } from '@mui/material';
import { Logo } from 'components';
import { useTheme } from 'context';
import { AppBar, GrowDiv, Toolbar } from './SiteNav.styles';

export default function SiteNav() {
  const { darkMode, toggleDarkMode } = useTheme();
  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar variant="dense" disableGutters>
        <Logo color="primary" />
        <GrowDiv />
        <FormControlLabel
          labelPlacement="start"
          control={<Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />}
          label="Dark mode"
        />
      </Toolbar>
    </AppBar>
  );
}

import { Alert, Grid } from '@mui/material';
import { UnitFieldsFragment } from 'api';
import { BodyText } from 'components';
import { newestToOldest } from 'system';
import { useRequestHistory } from './hooks/useRequestHistory';
import RequestHistoryCard from './RequestHistoryCard';

export default function RequestHistoryForUnit({
  loading,
  unit,
}: {
  loading: boolean;
  unit?: UnitFieldsFragment;
}) {
  const { items } = useRequestHistory(unit);
  return (
    <Grid gap={2}>
      {items.length > 0 ? (
        items
          .slice()
          .sort(newestToOldest)
          .map((item) =>
            item.isRequest ? (
              <Grid item key={item.id}>
                <RequestHistoryCard loading={loading} item={item} />
              </Grid>
            ) : (
              <Grid item key={item.id}>
                <BodyText loading={loading}>{JSON.stringify(item)}</BodyText>
              </Grid>
            )
          )
      ) : (
        <Alert severity="info">There are no past requests to show</Alert>
      )}
    </Grid>
  );
}

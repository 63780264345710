import { datadogRum } from '@datadog/browser-rum';
import { useGetCurrentUserQuery } from 'api';
import { useAuth } from 'context';
import { useFlagsmith } from 'flagsmith/react';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { ReactNode, createContext, useCallback, useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { noOp } from 'system';

TagManager.initialize({ gtmId: 'GTM-K7MQNGK' });

type SessionContextType = {
  fireEvent: (arg: { name: string; payload?: Record<string, unknown> }) => void;
};
const SessionContext = createContext<SessionContextType>({
  fireEvent: noOp,
});
export const useSessionContext = () => useContext(SessionContext);
const app_env = process.env.REACT_APP_ENV ?? 'dev';

const SetUser = ({ children }: { children: ReactNode }) => {
  const { data: userData, loading, error: userError } = useGetCurrentUserQuery();
  const { accountId } = useAuth();
  useAllErrors(userError);

  const flagsmith = useFlagsmith();

  useEffect(() => {
    if (userData?.manager && !loading) {
      const userPayload = {
        ...userData.manager,
        accountId,
        user_id: userData.manager.id,
        app_env,
        accountName: userData.manager.account.name,
      };
      const flagsmithTraits = {
        user_id: userPayload.user_id,
        email: userPayload.email,
        name: userPayload.name,
        accountName: userPayload.accountName,
        app_env: userPayload.app_env,
        accountId: userPayload.accountId,
      };

      datadogRum.setUser(userPayload);
      flagsmith.identify(userData.manager.id, flagsmithTraits);
      TagManager.dataLayer({ dataLayer: userPayload });
    }
  }, [userData, loading, accountId, flagsmith]);

  const fireEvent = useCallback(
    ({ name: event, payload }: { name: string; payload?: Record<string, unknown> }) => {
      TagManager.dataLayer({
        dataLayer: {
          ...payload,
          user_id: userData?.manager?.id,
          app_env,
          event,
        },
      });
    },
    [userData]
  );

  return <SessionContext.Provider value={{ fireEvent }}>{children}</SessionContext.Provider>;
};

export default function Session({ children }: { children: ReactNode }) {
  const { token: isLoggedIn } = useAuth();

  return isLoggedIn ? <SetUser>{children}</SetUser> : <>{children}</>;
}

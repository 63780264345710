import { Grid } from '@mui/material';
import { FullSizePaper, Tabs } from 'components';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';
import AccountingTasksForm from './AccountingTasksForm';
import ChargesForm from './ChargesForm';
import CompanyInformationForm from './CompanyInformationForm';
import FeeMappingForm from './FeeMappingForm';
import GlAccountMappingForm from './GlAccountMappingForm';
import MaintenanceBillingRatesForm from './MaintenanceBillingRatesForm';

export enum SettingsTabs {
  COMPANY_INFO = '1',
  ACCOUNT_MAPPING = '2',
  FEE_MAPPING = '3',
  CHARGES = '4',
  MAINTENANCE_BILLING_RATES = '5',
  ACCOUNTING_TASKS = '6',
}

export default function FinancialSettingsTabForm() {
  const location = useLocation();
  const { financialSettingsTab } = useMemo(
    () =>
      z
        .object({ financialSettingsTab: z.nativeEnum(SettingsTabs) })
        .catch({ financialSettingsTab: SettingsTabs.COMPANY_INFO })
        .parse(location.state),
    [location.state]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <FullSizePaper sx={{ py: 1, mb: 2 }}>
          <Tabs
            initialValue={financialSettingsTab}
            variant="fullWidth"
            tabs={[
              {
                value: SettingsTabs.COMPANY_INFO,
                label: 'Company Information',
                render: <CompanyInformationForm isStandAlone={true} />,
              },
              {
                value: SettingsTabs.ACCOUNT_MAPPING,
                label: 'Account Mapping',
                render: <GlAccountMappingForm isCollapsible={false} />,
              },
              {
                value: SettingsTabs.FEE_MAPPING,
                label: 'Fee Mapping',
                render: <FeeMappingForm isCollapsible={false} />,
              },
              {
                value: SettingsTabs.CHARGES,
                label: 'Charges Mapping',
                render: <ChargesForm isCollapsible={false} />,
              },
              {
                value: SettingsTabs.MAINTENANCE_BILLING_RATES,
                label: 'Maintenance Billing',
                render: <MaintenanceBillingRatesForm isCollapsible={false} />,
              },
              {
                value: SettingsTabs.ACCOUNTING_TASKS,
                label: 'Accounting Tasks',
                render: <AccountingTasksForm isCollapsible={false} />,
              },
            ]}
          />
        </FullSizePaper>
      </Grid>
    </Grid>
  );
}

import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

type SwitchFieldProps = {
  label?: ReactNode;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
} & Omit<SwitchProps, 'value' | 'onChange'>;

export const SwitchField = forwardRef(
  ({ checked, onChange, label, ...props }: SwitchFieldProps, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={
          <Switch
            checked={checked}
            onChange={(event, newChecked) => onChange?.(event, newChecked)}
            color="primary"
            {...props}
          />
        }
        label={label ?? ''}
      />
    );
  }
);

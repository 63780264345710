/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayPropertyCreatedEvent } from './propertyCreated';
import ManagerAutopayPropertyCreatedEventSchema from './propertyCreated.json';

export const isManagerAutopayPropertyCreatedEvent = guard<ManagerAutopayPropertyCreatedEvent>(ManagerAutopayPropertyCreatedEventSchema as any);
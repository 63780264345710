import { TextFieldProps } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

export default function TimeFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  minTime,
  className,
  disabled,
  label,
  defaultValue,
  fullWidth = true,
}: TextFieldProps &
  Partial<Parameters<typeof TimePicker>[0]> & {
    name: Path<T>;
    defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  }) {
  const { control, setValue, getValues } = useFormContext<T>();
  useEffect(() => {
    if (defaultValue && !getValues(name)) {
      setValue(name, defaultValue, { shouldValidate: true });
    }
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <TimePicker
              {...field}
              value={!field.value ? null : DateTime.fromISO(field.value as string)}
              {...{
                minTime: minTime && DateTime.fromISO(minTime as string),
              }}
              slotProps={{
                textField: {
                  type: 'text',
                  fullWidth,
                  error: Boolean(fieldState.error?.message),
                  helperText: fieldState.error?.message,
                  label,
                  disabled,
                  variant: 'filled',
                  className,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}

import {
  Button,
  FormHelperText,
  Input,
  InputLabel,
  InputProps,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import SignaturePad from 'react-signature-pad-wrapper';

const ControlContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1),
  background: theme.palette.inputBackground,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
}));

const cropSignature = function (svgStr: string) {
  const div = document.createElement('div');
  div.innerHTML = svgStr.trim();
  document.body.appendChild(div);
  const svg = div.querySelector('svg');
  if (svg) {
    const bbox = svg.getBBox();
    const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
    svg.setAttribute('viewBox', viewBox);
    const trimmedSvg = svg.outerHTML;
    div.remove();
    return trimmedSvg;
  }
  return svgStr;
};

export default function SignatureFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  label,
  helperText,
  onChange,
  defaultValue,
  ...props
}: Omit<InputProps, 'ref' | 'defaultValue'> & {
  name: Path<T>;
  label?: string;
  helperText?: string;
  defaultValue?: string;
}) {
  const theme = useTheme();
  const { control, setValue } = useFormContext<T>();
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const [interactive, setInteractive] = useState(!defaultValue);
  const signaturePadRef = useCallback((pad: SignaturePad) => {
    setSignaturePad(pad);
  }, []);

  const onStrokeEnd = useCallback(() => {
    if (signaturePad) {
      const signature = cropSignature(
        atob(
          signaturePad
            .toDataURL('image/svg+xml')
            .replace(/data:image\/svg\+xml;base64,/, '')
            .replace(/stroke="[^"]*"/g, `stroke="black"`)
        )
      );
      setValue(name, signature as PathValue<T, (string | undefined) & Path<T>>, {
        shouldTouch: true,
      });
    }
  }, [name, setValue, signaturePad]);

  const clearSignature = () => {
    setInteractive(true);
    signaturePad?.clear();
    setValue(name, '' as PathValue<T, (string | undefined) & Path<T>>);
  };

  useEffect(() => {
    if (signaturePad) {
      signaturePad.instance.addEventListener('endStroke', onStrokeEnd);
    }
    return () => signaturePad?.instance?.removeEventListener('endStroke', onStrokeEnd);
  }, [onStrokeEnd, signaturePad]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <ControlContainer>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {label && (
                <InputLabel shrink htmlFor={name}>
                  {label}
                </InputLabel>
              )}
              <Button size="small" onClick={clearSignature}>
                Clear
              </Button>
            </Stack>
            {interactive ? (
              <SignaturePad
                ref={signaturePadRef}
                redrawOnResize={false}
                options={{ penColor: theme.palette.ink.primary }}
              />
            ) : (
              <SVG src={field.value} onClick={clearSignature} />
            )}
            <Input
              {...props}
              {...field}
              value={field.value ?? ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange?.(e);
              }}
              type="hidden"
              error={Boolean(fieldState.error?.message)}
              sx={{ mt: 0 }}
            />
          </ControlContainer>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  );
}

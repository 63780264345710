/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitAdhocCompletedEvent } from './adhocCompleted';
import OperatorVisitAdhocCompletedEventSchema from './adhocCompleted.json';

export const isOperatorVisitAdhocCompletedEvent = guard<OperatorVisitAdhocCompletedEvent>(OperatorVisitAdhocCompletedEventSchema as any);
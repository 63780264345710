import {
  Autocomplete,
  autocompleteClasses,
  AutocompleteProps,
  Button,
  Checkbox,
  Chip,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  Link,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { compact, isEqual, map, orderBy, without } from 'lodash';
import {
  createContext,
  HTMLAttributes,
  JSXElementConstructor,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RefCallBack } from 'react-hook-form';
import { ensureArray, Option, renderNothing, spreadIf } from 'system';
import { StyledPopper } from './styles';
import {
  hasGroup,
  ItemData,
  ItemGroupData,
  listboxComponentFactory,
  renderVirtualGroup,
  renderVirtualOption,
  spacingToNumber,
} from './virtual';

export type AutocompleteMultiFieldProps<TId extends string> = {
  value: TId[];
  options: Option<TId>[];
  getOptionLabel?: (option: Option<TId>) => string;
  getChipOptionLabel?: (option: Option<TId>) => ReactNode;
  filterOptions?: (option: Option<TId>[], state: FilterOptionsState<Option<TId>>) => Option<TId>[];
  loading?: boolean;
  reset?: number;
  size?: TextFieldProps['size'];
  onSelected?: (selected: TId[]) => void;
  limitTags?: number;
  showSelectAllNone?: boolean;
  showConfirmCancel?: boolean;
  limitTagsInFocus?: boolean;
  freeSolo?: boolean;
  onInputChange?: (evt: unknown, input: string) => void;
  defaultValue?: (Option<TId> | TId)[];
  startAdornment?: JSX.Element;
  className?: string;
  fullWidth?: boolean;
  groupBy?: (option: Option<TId>) => string;
  hideOptions?: boolean;
  maxSelections?: number;
  error?: { message?: string };

  inputRef?: RefCallBack;
  onSearchChanged?: TextFieldProps['onChange'];
  onSearchKeydown?: TextFieldProps['onKeyDown'];
} & Pick<
  TextFieldProps,
  'variant' | 'disabled' | 'label' | 'name' | 'helperText' | 'placeholder' | 'sx'
> &
  Pick<AutocompleteProps<unknown, true, false, boolean, 'div'>, 'PaperComponent'>;

const OptionElement = memo(
  ({ index: _index, data }: { index: number; data: ItemData | ItemGroupData }) => {
    if (hasGroup(data)) {
      return <ListSubheader key={data.key}>{data.group}</ListSubheader>;
    }

    const [renderProps, option, { selected }] = data;

    return (
      <Typography component="li" key={option.id} {...renderProps}>
        <Checkbox
          size="medium"
          style={{ marginRight: 8, padding: 8 }}
          color="primary"
          checked={selected}
          disabled={option.disabled}
        />
        <ListItemText primary={option.text} secondary={option.subText} />
      </Typography>
    );
  }
);

function renderOption(index: number, data: ItemData | ItemGroupData) {
  return <OptionElement index={index} data={data} />;
}

const AutocompleteMultiFieldContext = createContext<{
  showSelectAllNone?: boolean;
  onSelectAll?: VoidFunction;
  onDeselectAll?: VoidFunction;

  showConfirmCancel?: boolean;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}>({});

const CustomPaperComponent: JSXElementConstructor<HTMLAttributes<HTMLElement>> = ({ children }) => {
  const { showSelectAllNone, onSelectAll, onDeselectAll, showConfirmCancel, onConfirm, onCancel } =
    useContext(AutocompleteMultiFieldContext);

  return (
    <Paper>
      {showSelectAllNone && (
        <Stack sx={{ p: 1.5 }} gap={1} direction="row">
          <Link
            underline="always"
            component="button"
            onMouseDown={(e) => {
              e.preventDefault();
              onSelectAll?.();
            }}
          >
            All
          </Link>
          <Typography variant="inherit">/</Typography>
          <Link
            underline="always"
            component="button"
            onMouseDown={(e) => {
              e.preventDefault();
              onDeselectAll?.();
            }}
          >
            None
          </Link>
        </Stack>
      )}
      {children}

      {showConfirmCancel && (
        <Stack sx={{ p: 1.5 }} gap={1} direction="row" justifyContent="flex-end">
          <Button
            variant="cancel"
            onMouseDown={() => {
              onCancel?.();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onMouseDown={() => {
              onConfirm?.();
            }}
          >
            Confirm
          </Button>
        </Stack>
      )}
    </Paper>
  );
};

export function AutocompleteMultiField<TId extends string = string>({
  name,
  value,
  variant = 'filled',
  disabled,
  options = [],
  hideOptions,
  size = 'small',
  fullWidth,
  label = '',
  getOptionLabel = (option) => (typeof option === 'string' ? option : option.text),
  groupBy,
  helperText,
  onSelected,
  limitTags,
  limitTagsInFocus,
  placeholder,
  freeSolo,
  startAdornment,
  onInputChange,
  filterOptions = freeSolo
    ? (opts, { inputValue }) =>
        opts.filter((opt) => opt.text.toLowerCase().includes(inputValue.toLowerCase()))
    : undefined,
  className,
  maxSelections,
  sx,
  error,
  onSearchChanged,
  onSearchKeydown,
  PaperComponent,
  showSelectAllNone,
  inputRef,
  getChipOptionLabel,
  showConfirmCancel,
  ...props
}: AutocompleteMultiFieldProps<TId>) {
  const theme = useTheme();
  const sortedOptions = useMemo(
    () => (groupBy ? options : orderBy(ensureArray(options), 'text')),
    [groupBy, options]
  );

  const [localValue, setLocalValue] = useState<Option<TId>[]>([]);
  const idsToOptions = useCallback(
    (ids: TId[]) =>
      Array.isArray(ids) ? compact(ids.map((id) => sortedOptions.find((o) => o.id === id))) : [],
    [sortedOptions]
  );

  useEffect(() => {
    setLocalValue(idsToOptions(value));
  }, [idsToOptions, sortedOptions, value]);

  const getOptionDisabled = useCallback(
    () =>
      Array.isArray(value) && maxSelections !== undefined ? value.length >= maxSelections : false,
    [maxSelections, value]
  );

  const ListboxComponent = useMemo(
    () =>
      listboxComponentFactory({
        renderOption,
        getItemHeight: (item) => {
          const selectAllNoneHeight = showSelectAllNone ? spacingToNumber(theme.spacing(1.5)) : 0;
          const confirmCancelHeight = showConfirmCancel ? spacingToNumber(theme.spacing(1.5)) : 0;

          if (hasGroup(item)) {
            return selectAllNoneHeight + spacingToNumber(theme.spacing(3)) + confirmCancelHeight;
          }

          const [, option] = item;
          const mainTextHeight = spacingToNumber(theme.spacing(5));
          const subTextHeight = option.subText?.trim()
            ? spacingToNumber(theme.spacing(2)) * option.subText.split('\n').length
            : 0;

          return (
            selectAllNoneHeight +
            mainTextHeight +
            subTextHeight +
            spacingToNumber(theme.spacing(1.5)) +
            confirmCancelHeight
          );
        },
      }),
    [showConfirmCancel, showSelectAllNone, theme]
  );

  const [inputFocused, setInputFocused] = useState(false);
  const tagsAdornment = useMemo(() => {
    let adornment = localValue.map((option) => (
      <Chip
        key={`${option.id}_chip`}
        label={getChipOptionLabel ? getChipOptionLabel(option) : option.text}
        tabIndex={-1}
        placeholder={placeholder}
        disabled={disabled}
        onDelete={() => {
          onSelected?.(without(map(localValue, 'id'), option.id));
        }}
      />
    ));

    if ((!inputFocused || limitTagsInFocus) && limitTags !== undefined && limitTags > -1) {
      const more = adornment.length - limitTags;
      if (more > 0) {
        adornment = adornment.splice(0, limitTags);
        adornment.push(<span key={adornment.length}>+{more}</span>);
      }
    }

    return adornment;
  }, [
    localValue,
    inputFocused,
    limitTagsInFocus,
    limitTags,
    getChipOptionLabel,
    placeholder,
    disabled,
    onSelected,
  ]);

  const onSelectAll = useCallback(() => onSelected?.(map(options, 'id')), [onSelected, options]);
  const onDeselectAll = useCallback(() => onSelected?.([]), [onSelected]);

  const onCancel = useCallback(() => {
    setLocalValue(idsToOptions(value));
  }, [idsToOptions, value]);

  return (
    <FormControl variant={variant} fullWidth={fullWidth} className={clsx(className)} sx={sx}>
      <AutocompleteMultiFieldContext.Provider
        value={{
          showSelectAllNone,
          onSelectAll,
          onDeselectAll,
          showConfirmCancel,
          onCancel,
        }}
      >
        <Autocomplete
          {...{
            ...props,
            disabled,
            groupBy,
            fullWidth,
            limitTags,
            freeSolo,
            filterOptions,
            onInputChange,
            getOptionDisabled,
          }}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : getOptionLabel(option)
          }
          multiple
          options={hideOptions ? [] : sortedOptions}
          onClose={() => {
            if (showConfirmCancel && !isEqual(value, map(localValue, 'id'))) {
              onSelected?.(localValue.map((val) => val.id));
            }
          }}
          onChange={(_event, values) => {
            const selectedIds = values.map((val) =>
              typeof val === 'string' ? (val as TId) : val.id
            );

            if (showConfirmCancel) {
              setLocalValue(idsToOptions(selectedIds));
            } else {
              onSelected?.(selectedIds);
            }
          }}
          value={localValue}
          disableCloseOnSelect
          PopperComponent={StyledPopper}
          PaperComponent={PaperComponent ?? CustomPaperComponent}
          sx={{
            [`& .${autocompleteClasses.inputRoot} .${autocompleteClasses.input}`]: {
              width: 'auto',
            },
          }}
          renderInput={({ InputProps, ...params }) =>
            props.loading ? (
              <Skeleton variant="rectangular" height={54} />
            ) : (
              <TextField
                ref={inputRef}
                key={`${name}__input`}
                variant={variant}
                label={label}
                {...params}
                placeholder={placeholder}
                size={size ?? 'small'}
                fullWidth={fullWidth}
                InputProps={{
                  ...InputProps,
                  startAdornment: (
                    <>
                      {startAdornment}
                      {InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {tagsAdornment && (
                        <Stack
                          gap={0.5}
                          direction="row"
                          alignItems="center"
                          flexWrap="wrap"
                          sx={{
                            pb: 1,
                            width: '100%',
                            maxHeight: 155,
                            overflow: 'auto',
                          }}
                        >
                          {tagsAdornment}
                        </Stack>
                      )}
                      {InputProps.endAdornment}
                    </>
                  ),
                }}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
                onChange={onSearchChanged}
                onKeyDown={onSearchKeydown}
                sx={spreadIf(disabled, {
                  '& svg': { color: 'text.disabled' },
                })}
              />
            )
          }
          renderGroup={renderVirtualGroup}
          renderOption={renderVirtualOption}
          ListboxComponent={ListboxComponent}
          renderTags={renderNothing}
        />
      </AutocompleteMultiFieldContext.Provider>

      {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayUnitInvitedEvent } from './unitInvited';
import ManagerAutopayUnitInvitedEventSchema from './unitInvited.json';

export const isManagerAutopayUnitInvitedEvent = guard<ManagerAutopayUnitInvitedEvent>(ManagerAutopayUnitInvitedEventSchema as any);
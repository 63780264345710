/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerUnitAddedEvent } from './added';
import ManagerUnitAddedEventSchema from './added.json';

export const isManagerUnitAddedEvent = guard<ManagerUnitAddedEvent>(ManagerUnitAddedEventSchema as any);
import * as MUIIcons from '@mui/icons-material';
import {
  AddBox,
  Apartment,
  Attachment,
  AttachMoney,
  Ballot,
  CreditCard,
  CreditScore,
  LibraryBooks,
  LineAxis,
  LocalAtmRounded,
  PriceChange,
  Update,
} from '@mui/icons-material';
import * as CustomIcons from 'components/icons';

import { Palette, Theme, useTheme } from '@mui/material';
import { BooksReportVersion, ManagerRole, PresetFieldsFragment, PresetType } from 'api';
import { useAuth } from 'context';
import { useFlags } from 'flagsmith/react';
import { useMeta } from 'hooks/useMeta';
import { useModalControl } from 'hooks/useModalControl';
import { compact, get, keyBy, partition, pick } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize, ensureArray, sortByAttribute } from 'system';
import { FeeReportScope, TaskListItem } from '../types';
import { tryJsonata } from '../utils';
import { useBooks } from './useBooks';
import { useImportJournals } from './useImportJournals';
import { useReconciliations } from './useReconciliations';
import { useTransfer } from './useTransfer';

const presetIconWith = (theme: Theme) => (id: string) => {
  const reportIcons: Record<string, { icon: ReactNode } | undefined> = {
    attachments: {
      icon: <Attachment style={{ color: theme.palette.quinary.main }} />,
    },
  };

  const taskIcons: Record<string, { icon: ReactNode } | undefined> = {};

  return reportIcons[id] ?? taskIcons[id];
};

export const usePresets = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { includesAnyRole } = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps -- manually verified callback deps
  const presetIcon = useCallback(presetIconWith(theme), [theme]);
  const { books, ...booksMeta } = useBooks();
  const { loading } = useMeta(booksMeta);

  const [presets, setPresets] = useState<PresetFieldsFragment[]>([]);
  const [taskList, setTaskList] = useState<TaskListItem[]>([]);
  const [reportList, setReportList] = useState<TaskListItem[]>([]);
  const [showPresetModal, hidePresetModal, isPresetModalOpen, presetFocused] =
    useModalControl<PresetFieldsFragment>();
  const [showMappingModal, hideMappingModal, isMappingModalOpen, presetMappingFocused] =
    useModalControl<PresetFieldsFragment>();
  const [showRecurrenceModal, hideRecurrenceModal, isRecurrenceModalOpen, presetRecurrenceFocused] =
    useModalControl<PresetFieldsFragment>();
  const [showChequeModal, hideChequeModal, chequeModalOpen] =
    useModalControl<PresetFieldsFragment>();
  const [showRentRollModal, hideRentRollModal, rentRollModalOpen, rentRollFocused] =
    useModalControl<{ name: string; scope: FeeReportScope }>();
  const [showCondoFeeModal, hideCondoFeeModal, condoFeeModalOpen, condoFeeFocused] =
    useModalControl<{ name: string; scope: FeeReportScope }>();
  const [showReportSetModal, hideReportSetModal, reportSetModalOpen] = useModalControl();

  const {
    taskListItem: reconciliationTask,
    showReconciliationPresetModal,
    isReconciliationPresetModalOpen,
    hideReconciliationPresetModal,
  } = useReconciliations();

  const {
    importJournalsTask,
    importJournalsModalOpen,
    submitImportJournalsModal,
    openImportJournalsModal,
    hideImportJournalsModal,
  } = useImportJournals();

  const { transferTask } = useTransfer();

  useEffect(() => {
    setPresets(ensureArray(books?.presets));
  }, [books?.presets, setPresets]);

  const presetDefaults = useMemo(
    () =>
      presets.reduce<Record<string, Record<string, unknown>>>(
        (defaultsByPresetId, preset) => ({
          ...defaultsByPresetId,
          [preset.id]: (preset.inputFields ?? []).reduce<Record<string, unknown>>(
            (defaults, field) => ({
              ...defaults,
              ...(field.defaultExpression && {
                [field.id]: tryJsonata(field.defaultExpression, undefined, {
                  books: pick(books, ['incomePayoutOptions']),
                }),
              }),
            }),
            {}
          ),
        }),
        {}
      ),
    [books, presets]
  );

  const getPresetDefaults = useCallback(
    (presetId: string) => presetDefaults[presetId] ?? {},
    [presetDefaults]
  );

  useEffect(() => {
    const visibleTasks = presets.filter((t) => t.type !== PresetType.Report);
    const presetTasks: Array<TaskListItem> = visibleTasks.map((preset) => {
      const missingMapping = ensureArray(preset.glMapping).some(
        (mapping) => !mapping.glId || mapping.glId === ''
      );

      const iconName = preset.icon?.name ?? '';
      const PresetIcon = get(CustomIcons, iconName) ?? get(MUIIcons, iconName) ?? Apartment;

      const iconColor = (preset.icon?.color ?? 'quaternary') as keyof Palette;
      const presetColor = get(theme.palette, `${iconColor}.main`);

      return {
        id: preset.id,
        icon: <PresetIcon style={{ color: presetColor }} />,
        title: capitalize(preset.name),
        subtitle: preset.description,
        recurring: preset.recurrence?.enabled,
        locked: preset.recurrence?.locked,
        onClick: () => (missingMapping ? showMappingModal(preset) : showPresetModal(preset)),
        ...(preset.lastPosted
          ? {
              subtitle: `Last Posted on ${preset.lastPosted}. ${preset.description}`,
            }
          : {}),
        ...(preset.glMapping &&
          Object.keys(preset.glMapping).length > 0 && {
            menu: [
              {
                actionName: 'Settings',
                onActionClick: () => {
                  hidePresetModal();
                  showMappingModal(preset);
                },
              },
              {
                actionName: 'Recurring',
                onActionClick: () => {
                  hidePresetModal();
                  missingMapping ? showMappingModal(preset) : showRecurrenceModal(preset);
                },
              },
            ],
          }),
      };
    });
    const customTasks: Array<TaskListItem> = [
      {
        id: 'journal-entries',
        icon: <AddBox style={{ color: theme.palette.primary.main }} />,
        title: 'Journal Entry',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        roles: [ManagerRole.BooksAdmin, ManagerRole.BooksAdvanced],
        subtitle: 'Create a new custom journal entry',
      },
      {
        id: 'enter-bill',
        icon: <CreditCard style={{ color: theme.palette.primary.main }} />,
        title: 'Enter Bill',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        subtitle: 'Enter a new payable bill',
      },
      {
        id: 'enter-expense',
        icon: <CreditScore style={{ color: theme.palette.primary.main }} />,
        title: 'Enter Expense',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        subtitle: 'Enter an expense that has already been paid',
      },
      {
        id: 'create-invoice',
        icon: <AttachMoney style={{ color: theme.palette.primary.main }} />,
        title: 'Create Invoice',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        subtitle: 'Enter a new receivable invoice',
      },
      {
        id: 'recurring-entries',
        icon: <Update style={{ color: theme.palette.quaternary.main }} />,
        title: 'Recurring Entries',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        subtitle: 'View and manage recurring entries',
      },
      {
        id: 'print-cheques',
        icon: <LocalAtmRounded style={{ color: theme.palette.quinary.main }} />,
        title: 'Print Cheques',
        onClick: () => showChequeModal(),
        menu: [
          {
            actionName: 'Print Last Cheque',
            onActionClick: () => navigate(`/accounting/tasks/print-cheques/`),
          },
        ],
        subtitle: 'Print pending cheque payments',
      },
      {
        id: 'budgets',
        icon: <PriceChange style={{ color: theme.palette.primary.main }} />,
        title: 'Property Budgets',
        onClick: (id: string) => navigate(`/accounting/tasks/${id}`),
        subtitle: 'View and manage monthly budgets',
      },
    ];

    setTaskList(
      compact([
        ...customTasks,
        ...presetTasks,
        transferTask,
        reconciliationTask,
        importJournalsTask,
      ])
        .filter((task) => !task.roles || includesAnyRole(...task.roles))
        .sort(sortByAttribute<TaskListItem>('title'))
    );
  }, [
    presets,
    reconciliationTask,
    navigate,
    showChequeModal,
    presetIcon,
    showMappingModal,
    showPresetModal,
    hidePresetModal,
    includesAnyRole,
    showRecurrenceModal,
    theme.palette,
    importJournalsTask,
    transferTask,
  ]);

  const customV1Reports = useCallback(
    () => [
      {
        id: 'property-rent-roll',
        icon: <LineAxis style={{ color: theme.palette.primary.main }} />,
        title: 'Property rent roll',
        onClick: () => showRentRollModal({ name: 'Property rent roll', scope: 'property' }),
      },
      {
        id: 'property-condo-fee',
        icon: <LineAxis style={{ color: theme.palette.primary.main }} />,
        title: 'Property unit fees',
        onClick: () => showCondoFeeModal({ name: 'Property unit fees', scope: 'property' }),
      },
      {
        id: 'report-set',
        icon: <Ballot style={{ color: theme.palette.quaternary.main }} />,
        title: 'Bundled Reports',
        subtitle: 'View and manage bundled reports',
        onClick: () => navigate('bundled-reports'),
        menu: [
          {
            actionName: 'Manage Bundles',
            onActionClick: () => navigate('manage-bundles'),
          },
        ],
      },
    ],
    [
      navigate,
      showCondoFeeModal,
      showRentRollModal,
      theme.palette.primary.main,
      theme.palette.quaternary.main,
    ]
  );

  const flags = useFlags(['reports_v2']);
  useEffect(() => {
    const [v2Reports, v1Reports] = partition(
      presets.filter((t) => t.type === PresetType.Report),
      (t) => t.version === BooksReportVersion.V2
    );

    const v2ByName = keyBy(v2Reports, (r) => r.name.toLowerCase());
    const replacedReports = v1Reports.map((v1) => v2ByName[v1.name.toLowerCase()] ?? v1);
    const replacedNames = new Set(replacedReports.map((r) => r.name.toLowerCase()));
    const missingReports = v2Reports.filter((v2) => !replacedNames.has(v2.name.toLowerCase()));

    const visibleReports = flags.reports_v2.enabled
      ? [...replacedReports, ...missingReports]
      : v1Reports;

    const visibleNames = new Set(visibleReports.map((r) => r.name.toLowerCase()));
    const customReports = customV1Reports().filter(
      ({ title }) => !visibleNames.has(title.toLowerCase())
    );

    setReportList(
      (
        [
          ...customReports,
          ...visibleReports.map((preset) => {
            const iconName = preset.icon?.name ?? '';
            const PresetIcon =
              get(CustomIcons, iconName) ?? get(MUIIcons, iconName) ?? LibraryBooks;

            const iconColor = (preset.icon?.color ?? 'quaternary') as keyof Palette;
            const presetColor = get(theme.palette, `${iconColor}.main`);

            return {
              icon: <PresetIcon style={{ color: presetColor }} htmlColor={presetColor} />,
              ...presetIcon(preset.id),
              id: preset.id,
              title: capitalize(preset.name),
              subtitle: preset.description,
              onClick: () => showPresetModal(preset),
            };
          }),
        ] as TaskListItem[]
      ).sort(sortByAttribute('title'))
    );
  }, [
    flags.reports_v2.enabled,
    presets,
    presetIcon,
    theme.palette,
    showCondoFeeModal,
    showRentRollModal,
    showPresetModal,
    showReportSetModal,
    navigate,
    customV1Reports,
  ]);

  return {
    loading,
    presets,
    taskList,
    reportList,
    hidePresetModal,
    isPresetModalOpen,
    presetFocused,
    getPresetDefaults,
    hideMappingModal,
    isMappingModalOpen,
    presetMappingFocused,
    hideRecurrenceModal,
    isRecurrenceModalOpen,
    presetRecurrenceFocused,
    hideChequeModal,
    chequeModalOpen,
    showReconciliationPresetModal,
    isReconciliationPresetModalOpen,
    hideReconciliationPresetModal,
    hideRentRollModal,
    rentRollModalOpen,
    rentRollFocused,
    hideCondoFeeModal,
    condoFeeModalOpen,
    condoFeeFocused,
    importJournalsModalOpen,
    openImportJournalsModal,
    hideImportJournalsModal,
    submitImportJournalsModal,
    showReportSetModal,
    hideReportSetModal,
    reportSetModalOpen,
  };
};

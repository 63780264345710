import { GlAccountFieldsFragment } from 'api';
import { useMeta } from 'hooks/useMeta';
import { find } from 'lodash';
import { GLCategory } from 'pages/accounting/categories';
import { useCallback, useEffect, useState } from 'react';
import { ensureArray } from 'system';
import { useBooks } from './useBooks';
import { useGlAccounts } from './useGlAccounts';

interface CategoryGlAccounts {
  (category: GLCategory): GlAccountFieldsFragment[];
  (categoryArray: GLCategory[]): GlAccountFieldsFragment[];
}

export type FeeMapping = {
  id: string;
  feeId: string;
  glId: string;
  description?: string;
};

export const useFeeMapping = () => {
  const { books, account, ...booksMeta } = useBooks();
  const { glAccounts, ...glAccountMeta } = useGlAccounts();
  const [feeMapping, setFeeMapping] = useState<FeeMapping[]>([]);

  const { loading } = useMeta(booksMeta, glAccountMeta);

  useEffect(() => {
    const fees = ensureArray(account?.fees);
    const mapping = ensureArray(books?.feeMapping);

    setFeeMapping(
      fees.map(({ id, name, deletedZ }) => ({
        id,
        feeId: id,
        glId: find(mapping, { feeId: id })?.glId ?? '',
        description: `${deletedZ ? '[DELETED] ' : ''}${name}`,
      }))
    );
  }, [account?.fees, books?.feeMapping, setFeeMapping]);

  const mappedGlId = useCallback(
    (feeId: string) => find(feeMapping, { feeId })?.glId,
    [feeMapping]
  );

  const mappedGlAccount = useCallback(
    (feeId: string) => find(glAccounts, { id: find(feeMapping, { feeId })?.glId }),
    [glAccounts, feeMapping]
  );

  const categoryGlAccounts: CategoryGlAccounts = useCallback(
    (category: GLCategory | GLCategory[]) =>
      glAccounts.filter((gl) =>
        Array.isArray(category)
          ? gl.category && category.includes(gl.category as GLCategory)
          : category === gl.category
      ),
    [glAccounts]
  );

  return {
    loading,
    feeMapping,
    mappedGlId,
    mappedGlAccount,
    categoryGlAccounts,
  };
};

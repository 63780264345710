import { Clear, Search } from '@mui/icons-material';
import {
  IconButton,
  IconButtonProps,
  InputAdornment,
  TextFieldProps,
  TextField as _TextField,
  styled,
} from '@mui/material';
import { useDebounce } from 'hooks/useDebounce';
import { ChangeEvent, useEffect, useState } from 'react';

const TextField = styled(_TextField)(({ theme }) => ({
  maxWidth: 650,
  background: theme.palette.background.paper,
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

type SearchBarProps = {
  placeholder: string;
  onSearchEntered: (search: string) => void;
  initialSearch?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: TextFieldProps['size'];
  iconSize?: IconButtonProps['size'];
  disabled?: boolean;
};

export default function SearchBar({
  placeholder,
  onSearchEntered,
  initialSearch = '',
  variant = 'outlined',
  size,
  iconSize = 'large',
  disabled,
}: SearchBarProps) {
  const [search, setSearch] = useState<string | undefined>(initialSearch);
  const debouncedSearch = useDebounce(search, 200);

  useEffect(() => {
    onSearchEntered(debouncedSearch ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <TextField
      variant={variant}
      fullWidth
      placeholder={placeholder}
      disabled={disabled}
      value={search}
      onChange={(event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
      size={size}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton size={iconSize}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {search && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => setSearch('')}
                  onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                  edge="end"
                  size={iconSize}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
}

import { useApolloClient } from '@apollo/client';
import {
  DraftedJournalEntriesPage,
  ReconciliationStatus,
  UpdateReconciliationInput,
  useDeleteReconciliationMutation,
  useGetReconciliationQuery,
  useUpdateJournalDraftsMutation,
  useUpdateReconciliationMutation,
} from 'api';
import { useAuth } from 'context';
import { useBooksBatchTask } from 'hooks/useBooksBatchTask';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useMeta } from 'hooks/useMeta';
import { useNotification } from 'hooks/useNotification';
import { useOpenSearchTask } from 'hooks/useOpenSearchTask';
import { chunk, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { safeSum, stripNonData } from 'system';
import { z } from 'zod';
import { storeFieldNameArgs } from '../utils';
import { useCommitReconciliation } from './useCommitReconciliation';

export type StartReconcilicationFormFields = {
  configuration: string;
  statementDate: string;
  statementBalance?: number;
};

export type UpdateReconciliationFormFields = {
  id: string;
  statementDate?: string;
  statementBalance?: number;
  documentKey?: string;
  ledgerDocumentKey?: string;
};

export type ReconciliationStats = {
  statementBalance: number;
  previousBalance: number;

  totalCleared: number;
  clearedWithdrawalsCount: number;
  totalWithdrawalsCleared: number;
  clearedDepositsCount: number;
  totalDepositsCleared: number;

  totalUncleared: number;
  unclearedWithdrawalsCount: number;
  totalWithdrawalsUncleared: number;
  unclearedDepositsCount: number;
  totalDepositsUncleared: number;

  endingBalance: number;
  accountBalance: number;
  difference: number;
};

export type ReconciliationJournal = {
  id: string;
  posted: string;
  amount: number;
  ref?: string;
  payeeName?: string;
  description?: string;
  reconciliationId?: string;
};

export const useUpdateReconciliation = () => {
  const { sendNotification } = useNotification();
  const [updateReconciliationMutation, { error, loading }] = useUpdateReconciliationMutation();
  useErrorNotifications(error);

  const updateReconciliation = async (values: UpdateReconciliationFormFields) => {
    const input: UpdateReconciliationInput = stripNonData({
      id: values.id,
      documentKey: values.documentKey,
      ledgerDocumentKey: values.ledgerDocumentKey,
      statementDate: values.statementDate,
      statementBalance: values.statementBalance,
    });

    try {
      await updateReconciliationMutation({ variables: { input } });
    } catch (e) {
      sendNotification('Something went wrong. Try again', 'error');
    }
  };

  return {
    updateReconciliation,
    loading,
  };
};

export const useDeleteReconciliation = () => {
  const { sendNotification } = useNotification();
  const [deleteReconciliationMutation, { error, loading }] = useDeleteReconciliationMutation();
  useErrorNotifications(error);

  const deleteReconciliation = async ({ id, next }: { id: string; next?: VoidFunction }) => {
    try {
      await deleteReconciliationMutation({
        variables: {
          id,
        },
        update(cache, { data }) {
          if (data?.deleteReconciliation?.success) {
            cache.evict({ id: cache.identify({ id, __typename: 'Reconciliation' }) });
            cache.gc();
          }
        },
      });
      next?.();
    } catch (e) {
      sendNotification('Something went wrong. Try again', 'error');
    }
  };

  return {
    loading,
    deleteReconciliation,
  };
};

export const useReconciliation = ({
  reconciliationId,
}: {
  reconciliationId: string;
  reconciledJEs?: { id: string }[];
}) => {
  const { accountId } = useAuth();
  const { sendNotification } = useNotification();

  const { cache } = useApolloClient();

  const cacheJournalsReconciliation = useCallback(
    (
      { newReconciliationId }: { newReconciliationId?: string; optimistic?: boolean },
      ...journals: { id: string; posted: string; amount: number }[]
    ) => {
      const storeArgsShape = z
        .object({
          filter: z.object({
            ids: z.array(z.string()).nullish(),
            draftReconciliationId: z.string(),
            transactionType: z.enum(['withdrawal', 'deposit']).nullish(),
          }),
        })
        .transform((o) => o.filter);

      cache.modify({
        id: cache.identify({ accountId, __typename: 'Books' }),
        fields: {
          pageDraftedJournalEntries(
            page: DraftedJournalEntriesPage,
            { storeFieldName }
          ): DraftedJournalEntriesPage {
            const result = storeArgsShape.safeParse(storeFieldNameArgs(storeFieldName));
            if (!result.success) {
              return page;
            }

            const { transactionType } = result.data;
            const journalAmounts = journals.map((j) => j.amount);

            const withdrawals = journalAmounts.filter((a) => a < 0);
            const withdrawalAmount = safeSum(withdrawals);
            const matchedWithdrawal = newReconciliationId ? withdrawalAmount : -withdrawalAmount;
            const withdrawalCount = newReconciliationId ? withdrawals.length : -withdrawals.length;

            const deposits = journalAmounts.filter((a) => a > 0);
            const depositAmount = safeSum(deposits);
            const matchedDeposit = newReconciliationId ? depositAmount : -depositAmount;
            const depositCount = newReconciliationId ? deposits.length : -deposits.length;

            const checked = Boolean(newReconciliationId);
            const newNodes = uniqBy(
              [
                ...journals.map(({ id, posted, amount }) => ({ id, posted, amount, checked })),
                ...page.edges.map((e) => e.node),
              ],
              'id'
            );

            return {
              ...page,
              edges: newNodes.map((node) => ({
                cursor: '',
                __typename: 'DraftedJournalEntryEdge',
                node: { ...node, __typename: 'DraftedJournalEntry' },
              })),
              ...(!transactionType && {
                totalUncleared: page.totalUncleared - matchedWithdrawal - matchedDeposit,
                totalCleared: page.totalCleared + matchedWithdrawal + matchedDeposit,
                totalDepositsCleared: page.totalDepositsCleared + matchedDeposit,
                totalDepositsUncleared: page.totalDepositsUncleared - matchedDeposit,
                totalWithdrawalsCleared: page.totalWithdrawalsCleared + matchedWithdrawal,
                totalWithdrawalsUncleared: page.totalWithdrawalsUncleared - matchedWithdrawal,
                clearedCount: page.clearedCount + withdrawalCount + depositCount,
                clearedDepositsCount: page.clearedDepositsCount + depositCount,
                clearedWithdrawalsCount: page.clearedWithdrawalsCount + withdrawalCount,
                unclearedCount: page.unclearedCount - withdrawalCount - depositCount,
                unclearedDepositsCount: page.unclearedDepositsCount - depositCount,
                unclearedWithdrawalsCount: page.unclearedWithdrawalsCount - withdrawalCount,
              }),
              ...(transactionType === 'deposit' && {
                totalUncleared: page.totalUncleared - matchedDeposit,
                totalCleared: page.totalCleared + matchedDeposit,
                totalDepositsCleared: page.totalDepositsCleared + matchedDeposit,
                totalDepositsUncleared: page.totalDepositsUncleared - matchedDeposit,
                clearedCount: page.clearedCount + depositCount,
                clearedDepositsCount: page.clearedDepositsCount + depositCount,
                unclearedCount: page.unclearedCount - depositCount,
                unclearedDepositsCount: page.unclearedDepositsCount - depositCount,
              }),
              ...(transactionType === 'withdrawal' && {
                totalUncleared: page.totalUncleared - matchedWithdrawal,
                totalCleared: page.totalCleared + matchedWithdrawal,
                totalWithdrawalsCleared: page.totalWithdrawalsCleared + matchedWithdrawal,
                totalWithdrawalsUncleared: page.totalWithdrawalsUncleared - matchedWithdrawal,
                clearedCount: page.clearedCount + withdrawalCount,
                clearedWithdrawalsCount: page.clearedWithdrawalsCount + withdrawalCount,
                unclearedCount: page.unclearedCount - withdrawalCount,
                unclearedWithdrawalsCount: page.unclearedWithdrawalsCount - withdrawalCount,
              }),
            };
          },
        },
      });

      journals.forEach((j) => {
        cache.modify({
          id: `DraftedJournalEntry:${j.id}`,
          fields: {
            checked: () => Boolean(newReconciliationId),
          },
        });
      });
    },
    [accountId, cache]
  );

  const { commitReconciliation, ...commitMeta } = useCommitReconciliation({ reconciliationId });
  const [updateJournalDraftsMutation, updateMeta] = useUpdateJournalDraftsMutation();

  const [showUncleared, setShowUncleared] = useState(false);
  const toggleShowUncleared = useCallback(() => setShowUncleared(!showUncleared), [showUncleared]);

  const { data, ...reconciliationMeta } = useGetReconciliationQuery({
    variables: { id: reconciliationId },
    skip: !reconciliationId,
  });

  const reconciliation = data?.reconciliation;
  const glAccount = reconciliation?.glAccount;
  const status = reconciliation?.status ?? ReconciliationStatus.Draft;

  useEffect(() => {
    if (
      reconciliation?.status === ReconciliationStatus.Committed ||
      reconciliation?.status === ReconciliationStatus.Reconciling
    ) {
      reconciliationMeta.startPolling(2000);
    } else {
      reconciliationMeta.stopPolling();
    }

    return () => reconciliationMeta.stopPolling();
  }, [reconciliation?.status, reconciliationMeta]);

  const openSearchTaskMeta = useOpenSearchTask({
    id: reconciliation?.reindexToken,
    untilCompleted: true,
    skip: status !== ReconciliationStatus.Draft,
  });

  const booksBatchMeta = useBooksBatchTask({
    id: reconciliation?.booksBatchId,
    untilCompleted: true,
    skip: ![ReconciliationStatus.Reconciling, ReconciliationStatus.Committed].includes(status),
  });

  const taskProgress = useMemo(
    () => booksBatchMeta?.taskProgress ?? openSearchTaskMeta.taskProgress,
    [booksBatchMeta?.taskProgress, openSearchTaskMeta.taskProgress]
  );

  const { loading } = useMeta(reconciliationMeta, commitMeta);
  const [waiting, setWaiting] = useState(!glAccount || !taskProgress.done);

  useEffect(() => {
    setWaiting(!taskProgress.done && status === ReconciliationStatus.Draft);
  }, [taskProgress.done, status]);

  const toggleEntries = async (
    entries: { id: string; posted: string; amount: number }[],
    newReconciliationId?: string
  ) => {
    if (entries.length) {
      try {
        cacheJournalsReconciliation({ newReconciliationId }, ...entries);

        const allJournalDrafts = entries.map(({ id, amount, posted }) => ({
          id,
          accountId,
          posted,
          amount,
        }));

        await Promise.all(
          chunk(allJournalDrafts, 1000).map((draftedJournalEntries) =>
            updateJournalDraftsMutation({
              variables: {
                input: {
                  draftedJournalEntries,
                  checked: Boolean(newReconciliationId),
                  draftReconciliationId: reconciliationId,
                },
              },
            })
          )
        );
      } catch (e) {
        console.error(e);
        sendNotification('Something went wrong. Try again', 'error');
        cacheJournalsReconciliation(
          { newReconciliationId: newReconciliationId ? undefined : reconciliationId },
          ...entries
        );
      }
    }
  };

  return {
    reconciliation,
    glAccount,
    taskProgress,
    loading,
    waiting,
    updating: updateMeta.loading,
    toggleEntries,
    commitReconciliation,
    showUncleared,
    toggleShowUncleared,

    failed: reconciliation?.status === ReconciliationStatus.Failed,
    errorMessage: reconciliation?.errorMessage,
  };
};

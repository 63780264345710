/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { OperatorVisitCancelledEvent } from './cancelled';
import OperatorVisitCancelledEventSchema from './cancelled.json';

export const isOperatorVisitCancelledEvent = guard<OperatorVisitCancelledEvent>(OperatorVisitCancelledEventSchema as any);
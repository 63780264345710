import { every } from 'lodash';
import { ensureArray, formatCurrency, safeSum } from './general';

type EffectShape = { amount: number } | { effect: number };
type Criteria<TItem> =
  | ((item: TItem) => boolean)
  | Partial<{ [K in keyof TItem]: TItem[K] | ((item: TItem[K]) => boolean) }>;

const getAmount = (eff: EffectShape) =>
  'amount' in eff ? eff.amount : 'effect' in eff ? eff.effect : 0;

const getPredicateFunction = <TItem extends EffectShape>(predicate?: Criteria<TItem>) =>
  typeof predicate === 'function'
    ? predicate
    : typeof predicate === 'object'
      ? (item: TItem) =>
          every(predicate, (value, key) =>
            typeof value === 'function'
              ? value(item[key as keyof TItem])
              : item[key as keyof TItem] === value
          )
      : () => true;

export const netEffect = <TKind extends EffectShape>(
  effects?: TKind[],
  criteria?: Criteria<TKind>
) =>
  ensureArray(effects)
    .filter(getPredicateFunction(criteria))
    .reduce((sum, eff) => safeSum(sum, getAmount(eff)), 0);

export const describeNetEffect = (...effects: EffectShape[]) => {
  const net = netEffect(effects);
  return `${net < 0 ? '↓' : '↑'} ${formatCurrency(Math.abs(net))}`;
};

export const formatNetEffect = (...effects: EffectShape[]) => formatCurrency(netEffect(effects));

import { TextFieldProps } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { partial } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, PathValue, useController, useFormContext } from 'react-hook-form';
import { parseDates, parseDatesWithTz } from 'system';

export default function DateTimeFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  timezone,
  minDate,
  maxDate,
  disableFuture,
  className,
  disabled,
  label,
  defaultValue,
  fullWidth = true,
}: TextFieldProps &
  Partial<Parameters<typeof DateTimePicker>[0]> & {
    name: Path<T>;
    timezone?: string;
    defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  }) {
  const { control, setValue, getValues } = useFormContext<T>();
  const { field, fieldState } = useController<T>({ name, control });

  useEffect(() => {
    if (defaultValue && !getValues(name)) {
      setValue(name, defaultValue, { shouldValidate: true });
    }
  });

  const dateTimeParser = useMemo(
    () => (timezone ? partial(parseDatesWithTz, timezone) : parseDates),
    [timezone]
  );

  const parsedValue = useMemo(() => {
    const [date] = dateTimeParser(field.value);
    return date.isValid ? date : null;
  }, [dateTimeParser, field.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateTimePicker
        {...field}
        value={parsedValue}
        format="yyyy-MM-dd hh:mm a"
        {...{
          minDate: minDate && DateTime.fromISO(minDate as string),
          maxDate: maxDate && DateTime.fromISO(maxDate as string),
          disableFuture,
          disabled,
        }}
        slotProps={{
          textField: {
            type: 'text',
            fullWidth,
            error: Boolean(fieldState.error?.message),
            helperText: fieldState.error?.message,
            label,
            disabled,
            variant: 'filled',
            className,
          },
        }}
      />
    </LocalizationProvider>
  );
}

import { useThrottledEffect } from 'hooks/useThrottledEffect';
import { compact } from 'lodash';
import { RefObject, useEffect, useRef, useState } from 'react';
import { ensureArray } from 'system';
import { usePrevious } from './usePrevious';

const autocompleteService: { current: google.maps.places.AutocompleteService | null } = {
  current: null,
};

const placesService: { current: google.maps.places.PlacesService | null } = {
  current: null,
};

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  if (!position.querySelector(`#${id}`)) {
    const script = document.createElement('script');
    script.async = true;
    script.id = id;
    script.src = src;
    position.appendChild(script);
  }
}

export type PlacePrediction =
  | string
  | {
      placeId: string;
      mainText: string;
      subText: string;
    }
  | null;

export type Place = {
  city: string;
  streetName: string;
  postalCode: string;
  province: string;
  formattedAddress: string;
  lat?: number;
  lng?: number;
};

function parsePlaceAddressComponents(placeResult: google.maps.places.PlaceResult | null): Place {
  const place: Place = {
    streetName: '',
    city: '',
    postalCode: '',
    province: '',
    formattedAddress: '',
  };

  for (const component of ensureArray(placeResult?.address_components)) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        place.streetName = `${component.long_name} ${place.streetName}`;
        break;
      }

      case 'route': {
        place.streetName += component.short_name;
        break;
      }

      case 'postal_code': {
        place.postalCode = `${component.long_name}${place.postalCode}`;
        break;
      }

      case 'postal_code_suffix': {
        place.postalCode = `${place.postalCode}-${component.long_name}`;
        break;
      }

      case 'locality': {
        place.city = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        place.province = component.short_name;
        break;
      }
    }
  }

  place.formattedAddress = compact([place.streetName, place.city, place.province]).join(', ');

  place.lat = placeResult?.geometry?.location?.lat();
  place.lng = placeResult?.geometry?.location?.lng();

  return place;
}

const defaultTypes = ['address'];
const defaultComponentRestrictions = {
  country: ['ca'],
};

export const useGoogleAutocompletePredictions = (
  {
    input,
    types = defaultTypes,
    componentRestrictions = defaultComponentRestrictions,
  }: google.maps.places.AutocompletionRequest,
  value?: PlacePrediction,
  placesRef?: RefObject<HTMLDivElement>
) => {
  loadScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    document.head,
    'google-api-script'
  );

  const previousInput = usePrevious(input);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [loadingPlace, setLoadingPlace] = useState(false);
  const [place, setPlace] = useState<Place | null>(null);
  const [options, setOptions] = useState<PlacePrediction[]>(input ? [input] : []);

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    if (
      typeof google === 'undefined' ||
      !placesRef?.current ||
      typeof value === 'string' ||
      !value?.placeId
    ) {
      setPlace(parsePlaceAddressComponents(null));
      return;
    }

    if (!placesService.current) {
      placesService.current = new google.maps.places.PlacesService(placesRef.current);
    }

    setLoadingPlace(true);
    placesService.current.getDetails({ placeId: value.placeId }, (result, status) => {
      setLoadingPlace(false);
      if (
        !status ||
        ![
          google.maps.places.PlacesServiceStatus.OK,
          google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
        ].includes(status)
      ) {
        return;
      }

      setPlace(parsePlaceAddressComponents(result));
    });
  }, [value, placesRef]);

  useThrottledEffect(
    () => {
      if (typeof google === 'undefined' || !input || input === previousInput) {
        return;
      }

      if (!autocompleteService.current) {
        autocompleteService.current = new google.maps.places.AutocompleteService();
      }

      setLoadingOptions(true);
      autocompleteService.current.getPlacePredictions(
        {
          input,
          types,
          componentRestrictions,
        },
        (results, serviceStatus) => {
          setLoadingOptions(false);

          if (
            serviceStatus &&
            [
              google.maps.places.PlacesServiceStatus.OK,
              google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
            ].includes(serviceStatus)
          ) {
            const predictions = ensureArray(results).map((prediction) => ({
              placeId: prediction.place_id,
              mainText: prediction.structured_formatting.main_text,
              subText: prediction.structured_formatting.secondary_text,
            }));
            setOptions(compact([...(value ? [value] : []), ...predictions]));
          }
        }
      );
    },
    200,
    [input, types, componentRestrictions]
  );

  return {
    place,
    options,
    loadingPlace,
    loadingOptions,
  };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAnnouncementReplyAddedEvent } from './replied';
import ManagerAnnouncementReplyAddedEventSchema from './replied.json';

export const isManagerAnnouncementReplyAddedEvent = guard<ManagerAnnouncementReplyAddedEvent>(ManagerAnnouncementReplyAddedEventSchema as any);
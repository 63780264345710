import { IconProps, styled } from '@mui/material';
import { spreadIf } from 'system';

const thumbnailOverlay = {
  opacity: '50%',
  transition: 'opacity 200ms ease-in',
};

export const iconLinkSx: IconProps['sx'] = {
  color: 'text.primary',
};

// SX/Style hybrid because there's no MUI wrapped `Image` component
export const thumbnailStyles: (src: {
  size?: 'small' | 'normal' | 'medium';
  active?: boolean;
  hover?: boolean;
}) => React.CSSProperties = ({ size, active, hover }) => ({
  objectFit: 'cover',
  width: 150,
  height: 150,
  ...spreadIf(size === 'medium', {
    width: 220,
    height: 220,
  }),
  ...spreadIf(size === 'small', {
    width: 70,
    height: 70,
  }),
  ...spreadIf(active || hover, thumbnailOverlay),
  '&:hover': {
    ...thumbnailOverlay,
  },
});

export const Overlay = styled('div')({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  color: 'black',
});

export const OptimizedImage = styled('img')({
  objectFit: 'cover',
  opacity: 1,
  transition: 'opacity 500ms ease-in',
});

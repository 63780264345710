import { PercentField, PercentFieldProps } from 'components/Fields';
import { Controller, useFormContext } from 'react-hook-form';

export default function PercentFieldController({
  name,
  variant = 'filled',
  disabled = false,
  helperText,
  type: _type,
  showClear = false,
  ...props
}: Omit<PercentFieldProps, 'onClear'> & { name: string; showClear?: boolean }) {
  const { control, setValue, resetField } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <PercentField
          {...field}
          {...props}
          {...{ variant, disabled }}
          onClear={
            showClear
              ? () => {
                  resetField(name);
                  setValue(name, '');
                }
              : undefined
          }
          error={Boolean(fieldState.error?.message)}
          helperText={fieldState.error?.message || helperText}
          onValueChange={(vals) => setValue(name, vals.formattedValue, { shouldValidate: true })}
        />
      )}
    />
  );
}

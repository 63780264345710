/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyEndsLaterEvent } from './endsLater';
import ManagerResidencyEndsLaterEventSchema from './endsLater.json';

export const isManagerResidencyEndsLaterEvent = guard<ManagerResidencyEndsLaterEvent>(ManagerResidencyEndsLaterEventSchema as any);
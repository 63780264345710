/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerBooksRunBatchPresetAction } from './runBatch';
import ManagerBooksRunBatchPresetActionSchema from './runBatch.json';

export const isManagerBooksRunBatchPresetAction = guard<ManagerBooksRunBatchPresetAction>(ManagerBooksRunBatchPresetActionSchema as any);
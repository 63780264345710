/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerOwnerEmailRemovedEvent } from './emailRemoved';
import ManagerOwnerEmailRemovedEventSchema from './emailRemoved.json';

export const isManagerOwnerEmailRemovedEvent = guard<ManagerOwnerEmailRemovedEvent>(ManagerOwnerEmailRemovedEventSchema as any);
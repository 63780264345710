/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseInspectionImagesReadyEvent } from './inspectionImagesReady';
import ManagerLeaseInspectionImagesReadyEventSchema from './inspectionImagesReady.json';

export const isManagerLeaseInspectionImagesReadyEvent = guard<ManagerLeaseInspectionImagesReadyEvent>(ManagerLeaseInspectionImagesReadyEventSchema as any);
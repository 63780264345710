/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerResidencyRefundablesUpdatedEvent } from './refundablesUpdated';
import ManagerResidencyRefundablesUpdatedEventSchema from './refundablesUpdated.json';

export const isManagerResidencyRefundablesUpdatedEvent = guard<ManagerResidencyRefundablesUpdatedEvent>(ManagerResidencyRefundablesUpdatedEventSchema as any);
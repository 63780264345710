import { QueryHookOptions } from '@apollo/client';
import { BatchTask, GetBooksBatchTaskQuery, useGetBooksBatchTaskQuery } from 'api';
import { useMeta } from 'hooks/useMeta';
import { useEffect, useMemo, useState } from 'react';
import { safeRound } from 'system';

const getBatchProgress = (
  result?: BatchTask
): {
  done?: boolean;
  progress: number;
  batchId?: string;
  updatedZ?: string;
} => {
  if (!result) {
    return { progress: 0, done: false };
  }

  const { taskProgress } = result;
  const progress = safeRound((taskProgress.processed ?? 0) / Math.max(taskProgress.total, 1)) * 100;

  return {
    progress,
    batchId: result.id,
    done: taskProgress.done ?? progress >= 100,
  };
};

type UseBooksBatchTaskProps = {
  id?: string;
  untilCompleted?: boolean;
  pollInterval?: number;
} & Omit<
  QueryHookOptions<GetBooksBatchTaskQuery>,
  'variables' | 'defaultFetchPolicy' | 'nextFetchPolicy' | 'defaultOptions'
>;

export const useBooksBatchTask = ({
  id = '',
  untilCompleted,
  pollInterval = 1000,
  skip = false,
  ...options
}: UseBooksBatchTaskProps) => {
  const [shouldPoll, setShouldPoll] = useState(untilCompleted);
  useEffect(() => {
    setShouldPoll(untilCompleted);
  }, [untilCompleted]);

  const { data, startPolling, stopPolling, ...meta } = useGetBooksBatchTaskQuery({
    variables: { id },
    skip: !id || skip,
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  const taskProgress = useMemo(
    () =>
      skip ? { progress: 100, done: true } : getBatchProgress(data?.account?.books?.batchTask),
    [data?.account?.books?.batchTask, skip]
  );

  const { loading } = useMeta(meta);

  useEffect(() => {
    if (shouldPoll && !taskProgress.done) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [taskProgress, pollInterval, shouldPoll, startPolling, stopPolling]);

  return {
    loading,
    stopPolling,
    startPolling,
    taskProgress,
  };
};

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerLeaseTerminateLeaseAction } from './terminateLease';
import ManagerLeaseTerminateLeaseActionSchema from './terminateLease.json';

export const isManagerLeaseTerminateLeaseAction = guard<ManagerLeaseTerminateLeaseAction>(ManagerLeaseTerminateLeaseActionSchema as any);
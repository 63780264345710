import * as Icons from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { useTheme } from 'context';
import { Categories, Occupancy, spreadIf } from 'system';

const iconSx: (obj: { darkMode: boolean; occupancy?: Occupancy }) => SvgIconProps['sx'] =
  ({ darkMode, occupancy }) =>
  (theme) => ({
    padding: theme.spacing(0.8, 1),
    height: 40,
    width: 40,
    borderRadius: 40,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primaryBackground.main,
    ...spreadIf(occupancy === 'common', {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondaryBackground.main,
    }),
    ...spreadIf(occupancy === 'commercial' || occupancy === 'commercialOwner', {
      color: theme.palette.warning.main,
      backgroundColor: darkMode ? theme.palette.warning.dark : theme.palette.warning.light,
    }),
  });

const iconComponent = (category: Categories) =>
  ({
    APPLIANCE: Icons.LocalLaundryService,
    CLEANING: Icons.Delete,
    DOOR: Icons.MeetingRoom,
    ELECTRICAL: Icons.OfflineBolt,
    EXTERIOR: Icons.NaturePeople,
    GENERAL: Icons.Build,
    HVAC: Icons.Waves,
    KEYS: Icons.VpnKey,
    PLUMBING: Icons.Bathtub,
    SAFETY: Icons.VerifiedUser,
    SECURITY: Icons.Security,
    WINDOW: Icons.Storefront,
  })[category];

const validCategory = (category: string): category is Categories =>
  Object.keys(Categories).includes(category);

export default function CategoryIcon({
  category,
  occupancy,
  className,
  ...props
}: { category: string; occupancy?: Occupancy } & SvgIconProps) {
  const Icon = validCategory(category) ? iconComponent(category) : Icons.Build;

  return <Icon {...props} sx={iconSx({ ...useTheme(), occupancy })} className={className} />;
}

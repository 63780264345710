import { FieldValues, Path, PathValue, useController, useFormContext } from 'react-hook-form';
import { DropdownField, DropdownFieldProps } from '../Fields/DropdownField';

export default function DropdownFieldController<T extends FieldValues = Record<string, unknown>>({
  name,
  renderValue,
  handleDropdownChange,
  defaultValue,
  ...props
}: Omit<
  DropdownFieldProps<PathValue<T, (string | undefined) & Path<T>>> & { name: Path<T> },
  'renderValue' | 'onChange'
> & {
  renderValue?: string;
  handleDropdownChange?: (value: string) => void;
}) {
  const { control } = useFormContext<T>();
  const {
    field: { ref, ...field },
    fieldState,
  } = useController({ control, name, defaultValue });

  return (
    <DropdownField<PathValue<T, (string | undefined) & Path<T>>>
      {...{ ...props, ...field }}
      inputRef={ref}
      error={fieldState.error}
      value={field.value || (renderValue as PathValue<T, (string | undefined) & Path<T>>) || ''}
      renderValue={renderValue ? () => <>{renderValue}</> : undefined}
      onChange={(event) => {
        handleDropdownChange?.(String(event.target.value));
        field.onChange(event);
      }}
    />
  );
}

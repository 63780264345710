import {
  BalanceType,
  PayeeType,
  PaymentMethod,
  UpdateJournalEntryInput,
  useUpdateJournalEntryMutation,
} from 'api';
import { useNotification } from 'hooks/useNotification';
import { ensureArray, stripNonData } from 'system';
import { coalesceTo, intoISODate, maybe } from 'system/shapes';
import { z } from 'zod';
import { clearableLabels } from '../utils';
import { useGlAccounts } from './useGlAccounts';

export const editClearingEntryShape = z.object({
  total: z.number().describe('Total').nonnegative(),
  unitId: maybe(z.string()).describe('Unit Id'),
  propertyId: z.string().describe('Property Id'),
  posted: z.string().transform(intoISODate).describe('Journal Date'),
  glId: z.string().describe('Account'),
  paymentMethod: z
    .nativeEnum(PaymentMethod)
    .describe('Payment Method')
    .nullish()
    .transform(coalesceTo(undefined)),
  payees: z.array(
    z.object({
      total: z.number().nonnegative(),
      payeeId: z.string(),
      payee: z.nativeEnum(PayeeType),
      payeeName: maybe(z.string()),
      ref: maybe(z.string()),
      clearableId: maybe(z.string()),
      description: maybe(z.string()),
      payeeClearables: z.array(
        z
          .object({
            id: z.string(),
            glId: z.string(),
            journalId: maybe(z.string()),
            clearingAmount: z.number().refine((val) => val !== 0, { message: 'Must not be zero' }),
            ownerId: z.string(),
            cleared: z
              .string()
              .nullish()
              .transform((val, ctx) => (val ? intoISODate(val, ctx) : undefined)),
            due: z.string().transform(intoISODate),
            index: z.number(),
            outstanding: maybe(z.number()).default(0),
            payeeIndex: z.number(),
            payeeName: z.string(),
            unitId: maybe(z.string()),
            unitName: maybe(z.string()),
            buildingName: maybe(z.string()),
            propertyName: z.string(),
            propertyKey: maybe(z.string()),
            posted: z.string().transform(intoISODate),
            sourceJournalEntry: maybe(z.object({}).passthrough()),
            sourceJournalId: z.string(),
            balanceType: z.string(),
          })
          .refine(stripNonData)
      ),
    })
  ),
});
export type EditClearingEntryShape = z.infer<typeof editClearingEntryShape>;

export const useEditClearingEntry = ({
  journalEntryId,
  balanceType,
}: {
  journalEntryId: string;
  balanceType: BalanceType;
}) => {
  const { sendNotification } = useNotification();
  const [updateJournalMutation, { loading }] = useUpdateJournalEntryMutation();
  const { findGlAccount } = useGlAccounts();
  const labels = clearableLabels(balanceType);

  const editClearingEntry = async ({
    glId,
    posted,
    payees,
    total: _total,
    ...sourceJournalData
  }: EditClearingEntryShape) => {
    const inputs = payees.map<UpdateJournalEntryInput>(
      ({ clearableId, payeeClearables, total, payeeName: _, ...payeeLineData }) => {
        const baseLine = { ...payeeLineData, ...sourceJournalData };

        return {
          posted,
          clearableId,
          id: journalEntryId,
          lines: [
            {
              ...baseLine,
              id: journalEntryId,
              glId,
              ownerId: payeeClearables[0].ownerId,
              amount: findGlAccount(glId)?.balanceType === balanceType ? total : -total,
            },
            ...payeeClearables
              .filter((payeeClearable) => (payeeClearable.clearingAmount ?? 0) !== 0)
              .map(
                ({ id, journalId, clearingAmount = 0, glId: clearableGlId, ownerId, unitId }) => ({
                  ...baseLine,
                  id: journalId,
                  glId: clearableGlId,
                  ownerId,
                  unitId,
                  clearableId: id,
                  amount:
                    findGlAccount(clearableGlId)?.balanceType === balanceType
                      ? -clearingAmount
                      : clearingAmount,
                })
              ),
          ],
        };
      }
    );

    try {
      const results = await Promise.all(
        inputs.map((input) =>
          updateJournalMutation({
            variables: { input },
            update(cache, { data }) {
              if (data?.updateJournalEntry?.success) {
                ensureArray(data.updateJournalEntry.journalIds).forEach((id) => {
                  cache.modify({
                    id: cache.identify({ id, __typename: 'JournalEntry' }),
                    fields: {
                      relatedClearables(_, { DELETE }) {
                        return DELETE;
                      },
                    },
                  });
                });

                input.lines
                  .filter((line) => line.glId !== glId)
                  .forEach(({ clearableId, amount }) => {
                    cache.modify({
                      id: cache.identify({ id: clearableId, __typename: 'Clearable' }),
                      fields: {
                        outstanding(prev) {
                          return prev + amount;
                        },
                        journalEntries(_, { DELETE }) {
                          return DELETE;
                        },
                      },
                    });
                  });
              }
            },
          })
        )
      );

      const errorResponse = results.find((r) => !r.data?.updateJournalEntry.success);

      if (errorResponse) {
        throw new Error(
          errorResponse?.data?.updateJournalEntry.error ??
            `Failed to update ${labels.clearingEntryLabel}`
        );
      }
    } catch (e) {
      sendNotification(e instanceof Error ? e.message : JSON.stringify(e), 'error');
      throw e;
    }
  };

  return {
    loading,
    editClearingEntry,
  };
};

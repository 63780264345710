import { AlertProps } from '@mui/material';
import { isEqual, uniqBy, uniqWith } from 'lodash';
import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { emptyArray, ksuid, noOp } from 'system';

type SiteAlertsContextType = {
  alerts: Array<AlertProps>;
  addAlert: (alert: AlertProps) => void;
  removeAlert: (alert: AlertProps) => void;
};

const SiteAlerts = createContext<SiteAlertsContextType>({
  alerts: emptyArray,
  addAlert: noOp,
  removeAlert: noOp,
});
export const useSiteAlerts = () => useContext(SiteAlerts);

export const SiteAlertsProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlerts] = useState<Array<AlertProps>>(emptyArray);

  const addAlert = useCallback((alert: AlertProps) => {
    setAlerts((prev) =>
      uniqBy(
        uniqWith(
          [
            ...prev,
            {
              key: ksuid(),
              ...alert,
            },
          ],
          isEqual
        ),
        'key'
      )
    );
  }, []);

  const removeAlert = useCallback((alert: AlertProps) => {
    setAlerts((prev) =>
      prev.filter(({ key, ...rest }) => (alert.key ? key !== alert.key : !isEqual(rest, alert)))
    );
  }, []);

  const value: SiteAlertsContextType = {
    alerts,
    addAlert,
    removeAlert,
  };

  return <SiteAlerts.Provider value={value}>{children}</SiteAlerts.Provider>;
};

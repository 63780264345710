import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteMultiField, AutocompleteMultiFieldProps } from '../Fields';

type AutocompleteMultiFieldControllerProps<TId extends string = string> = Omit<
  AutocompleteMultiFieldProps<TId>,
  'value'
> & {
  name: string;
  searchFieldName?: string;
  showSelectAllNone?: boolean;
};

export default function AutocompleteMultiFieldController<TId extends string = string>({
  name,
  options = [],
  onSelected,
  searchFieldName,
  ...props
}: AutocompleteMultiFieldControllerProps<TId>) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref: inputRef, onChange, ...field }, fieldState }) => (
        <AutocompleteMultiField
          {...{ ...field, ...props, options, inputRef }}
          error={get(errors, searchFieldName ?? '') ?? fieldState.error}
          onSelected={(ids: TId[]) => (onSelected ? onSelected(ids) : onChange(ids))}
          {...{
            ...(searchFieldName && {
              onSearchChanged: (evt) => {
                setValue(searchFieldName, evt.target.value, { shouldValidate: true });
              },
              onSearchKeydown: (e) => {
                if (e.key === 'Enter' && get(errors, searchFieldName)) {
                  e.stopPropagation();
                }
              },
            }),
          }}
        />
      )}
    />
  );
}

import { useMemo } from 'react';
import { useGlAccounts } from './useGlAccounts';

export const useGlAccount = ({ glAccountId }: { glAccountId?: string }) => {
  const { glAccounts, loading } = useGlAccounts();
  const glAccount = useMemo(
    () => glAccounts.find((account) => account.id === glAccountId),
    [glAccountId, glAccounts]
  );

  return { glAccount, loading };
};

import { partial } from 'lodash';
import { DateTime } from 'luxon';
import { rrulestr } from 'rrule';
import { DateLike } from 'system/types';
import { parseDates as _parseDates, parseDatesWithTz } from './general';

export const isoTo5545 = (iso: string) => {
  const dt = DateTime.fromISO(iso);
  return dt.isValid ? `${dt.toFormat('yyyyMMdd')}T080000Z` : '';
};

export const nextRecurrence = (
  rrule?: string,
  {
    after,
    dtstart,
    timezone,
    includeAfter = false,
  }: { includeAfter?: boolean; after?: DateLike; timezone?: string; dtstart?: DateLike } = {}
) => {
  const parseDates = timezone ? partial(parseDatesWithTz, timezone) : _parseDates;
  const [afterDate, start] = parseDates(after ?? DateTime.now().startOf('day'), dtstart);
  if (!rrule) return;

  const nextDate = rrulestr(rrule, { ...(dtstart && { dtstart: start.toJSDate() }) }).after(
    afterDate.toJSDate(),
    includeAfter
  );

  if (!nextDate) return;

  return rrule ? parseDates(nextDate)[0] : undefined;
};

export const formatNextRecurrence = (...[rrule, opts]: Parameters<typeof nextRecurrence>) =>
  nextRecurrence(rrule, opts)?.toLocaleString(DateTime.DATE_MED) ?? 'None';

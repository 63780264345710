import { InfoOutlined } from '@mui/icons-material';
import { InputAdornment, InputAdornmentProps } from '@mui/material';
import { ReactNode } from 'react';
import { Tooltip } from './Tooltip';

type InfoAdornmentProps = {
  children: ReactNode;
  position?: InputAdornmentProps['position'];
};

export default function InfoAdornment({ children, position = 'end' }: InfoAdornmentProps) {
  return (
    <InputAdornment position={position}>
      <Tooltip title={children} sx={{ cursor: 'help' }}>
        <InfoOutlined color="primary" />
      </Tooltip>
    </InputAdornment>
  );
}

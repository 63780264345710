import { ApolloClient, disableFragmentWarnings, from } from '@apollo/client';
import { Close } from '@mui/icons-material';
import { IconButton } from 'components';
import { useIdle } from 'context';
import { SnackbarKey, useSnackbar } from 'notistack';
import { Dispatch, useMemo, useState } from 'react';
import { titleCase } from 'system';
import { AuthAction, AuthState } from '../types';
import { createAppSyncHybridLink } from './appSyncHybridLink';
import { StatusChangeHandler } from './appSyncHybridLink/appSyncSubscriptionWebSocketLink';
import { idToken } from './authLink';
import { cache } from './cache';
import cleanVariablesLink from './cleanVariablesLink';
import errorLink from './errorLink';

disableFragmentWarnings();

const env = (process.env.REACT_APP_ENV ?? 'dev').toLowerCase();

export const useAuthClient = (dispatch: Dispatch<AuthAction>, state: AuthState) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const idle = useIdle();
  const closeSnackbarAction = (key: SnackbarKey) => (
    <IconButton onClick={() => closeSnackbar(key)}>
      <Close />
    </IconButton>
  );

  const [lastMessage, setLastMessage] = useState('');

  const onStatusChange: StatusChangeHandler = (status) => {
    if (idle || !state.token) {
      return;
    }
    if (status === 'disconnected') {
      const message = 'Disconnected from server';
      if (lastMessage !== message) {
        setLastMessage(message);
        enqueueSnackbar(message, {
          variant: 'warning',
          persist: true,
          preventDuplicate: true,
          action: closeSnackbarAction,
        });
      }
    } else if (status === 'error') {
      const message = 'Error connecting to server';
      if (lastMessage !== message) {
        setLastMessage(message);
        enqueueSnackbar(message, {
          variant: 'error',
          persist: true,
          preventDuplicate: true,
          action: closeSnackbarAction,
        });
      }
    } else {
      closeSnackbar();
      const message = `${titleCase(status)} to server`;
      // eslint-disable-next-line no-console
      console.debug(message);

      if (!status.endsWith('ing') && lastMessage !== message) {
        setLastMessage(message);
        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
          action: closeSnackbarAction,
        });
      }
    }
  };

  return useMemo(
    () =>
      new ApolloClient({
        link: from([
          cleanVariablesLink,
          errorLink(dispatch),
          createAppSyncHybridLink({
            appSyncApiUrl: `${process.env.REACT_APP_APPSYNC_GRAPHQL ?? 'invalid'}`,
            getJwtToken: idToken,
            onStatusChange,
          }),
        ]),
        cache,
        credentials: 'include',
        connectToDevTools: env !== 'prod',
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, state]
  );
};

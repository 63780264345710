import { TextFieldProps } from '@mui/material';
import { AccountType, GlAccountFieldsFragment } from 'api';
import { AutocompleteField, AutocompleteFieldProps } from 'components';
import { constant, isEmpty, uniqBy } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Option } from 'system';
import { useGlAccounts } from '../../pages/accounting/hooks';
import { byAccountTypeKeyName } from '../../pages/accounting/utils';
import { accountOption } from './GLAccountAutocompleteController';

export type GLAccountAutocompleteFieldProps = {
  accountTypes?: AccountType[];
  filter?: (args: { __typename?: 'GLAccount' | undefined } & GlAccountFieldsFragment) => boolean;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  manualSort?: boolean;
  sort?: (a: GlAccountFieldsFragment, b: GlAccountFieldsFragment) => number;
  onChange: (value?: string) => void;
} & Pick<TextFieldProps, 'variant' | 'size' | 'color'> &
  Partial<Omit<AutocompleteFieldProps<string>, 'color' | 'ref' | 'onInputChange' | 'onChange'>>;

export function GLAccountAutocompleteField({
  accountTypes,
  filter,
  label,
  required,
  sx,
  disabled,
  manualSort,
  fullWidth = true,
  sort = byAccountTypeKeyName,
  variant,
  size = 'medium',
  onChange,
  ...autocompleteFields
}: GLAccountAutocompleteFieldProps) {
  const { glAccounts } = useGlAccounts();
  const filteredAccounts = useMemo<Option[]>(
    () =>
      uniqBy(
        (isEmpty(accountTypes)
          ? glAccounts
          : glAccounts.filter(({ accountType }) => accountTypes?.includes(accountType))
        )
          .filter(filter ?? constant(true))
          .sort(sort)
          .map(accountOption),
        'id'
      ),
    [accountTypes, glAccounts, filter, sort]
  );

  return (
    <AutocompleteField
      {...{ variant, size, ...autocompleteFields }}
      disabled={disabled}
      manualSort={manualSort}
      fullWidth={fullWidth}
      options={filteredAccounts}
      hideIcons={true}
      required={required}
      label={label ?? 'GL Account'}
      sx={sx}
      onChange={(_evt, value) => {
        onChange(typeof value === 'string' ? value : value?.id);
      }}
    />
  );
}

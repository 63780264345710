/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
This file is an auto generated file.
DO NOT MODIFY THIS FILE DIRECTLY
ALL HELL WILL BREAK LOOSE
VALHALLA CALLS OUR NAME
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
*/
import { guard } from '@propra-system/registry';
import { ManagerAutopayCollectionTypesChangedEvent } from './collectionTypesChanged';
import ManagerAutopayCollectionTypesChangedEventSchema from './collectionTypesChanged.json';

export const isManagerAutopayCollectionTypesChangedEvent = guard<ManagerAutopayCollectionTypesChangedEvent>(ManagerAutopayCollectionTypesChangedEventSchema as any);
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, lazy } from 'react';

const retry = (
  fn: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft = 2,
  interval = 1500
) => {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    fn()
      .then((all) => resolve(all))
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          return retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export default function lazyload(fn: () => Promise<{ default: ComponentType<any> }>) {
  return lazy(() => retry(fn));
}

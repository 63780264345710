import { Box, styled } from '@mui/material';
import LoadingButton from '../LoadingButton';

export const CancelButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'noPrimary',
})<{ noPrimary?: boolean }>(({ theme, noPrimary }) => ({
  color: theme.palette.text.secondary,
  ...(noPrimary ? { marginLeft: 'auto' } : { marginRight: 'auto' }),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 0),
  justifyContent: 'space-between',
}));

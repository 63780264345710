import { Box, Tooltip as MUITooltip, TooltipProps as _TooltipProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type TooltipProps = PropsWithChildren<{
  disabled?: boolean;
  cursor?: 'help';
  wrapperSx?: _TooltipProps['sx'];
}> &
  Omit<_TooltipProps, 'children'>;

export function Tooltip({
  title,
  children,
  disabled,
  cursor = disabled ? undefined : 'help',
  wrapperSx,
  ...props
}: TooltipProps) {
  return (
    <MUITooltip
      title={title ?? ''}
      placement="top"
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: 14,
            maxWidth: 800,
            fontWeight: 400,
            lineHeight: '1.5em',
            boxShadow: 2,
            color: 'text.primary',
            backgroundColor: 'background.translucentPaper',
          },
        },
      }}
      {...props}
    >
      <Box component="span" display="flex" flexDirection="column" sx={{ cursor, ...wrapperSx }}>
        {children}
      </Box>
    </MUITooltip>
  );
}
